
import { Modal } from "@/components";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

@Options({
  components: {
    Modal,
  },
  emits: ["close-modal"],
})
export default class ShippingPromoLoginModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  dateDisplay!: string;

  private showModal = this.open;

  @Watch("open")
  onOpenChanged(val: boolean) {
    this.showModal = val;
  }

  @Emit()
  closeModal() {
    this.showModal = false;
  }

  onCloseClick() {
    this.$emit("close-modal");
  }
}
