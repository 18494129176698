import {
  IOrdersRepository,
  IUpdateOrderRequest,
  OrderDto,
  OrdersDto,
  OrdersWithInvoiceDto,
  AgencyOrdersDto,
} from "@/types";
import { injectable } from "inversify-props";
import axios from "@/axios";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Orders`;

@injectable()
export class OrdersRepository implements IOrdersRepository {
  public async get(orderGuid: string, brandId?: number): Promise<OrderDto> {
    const { data } =
      brandId === undefined
        ? await axios.get(`${ENDPOINT_URL}/${orderGuid}`)
        : await axios.get(`${ENDPOINT_URL}/${orderGuid}`, {
            headers: { brandId },
          });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async getAll(queryString = ""): Promise<OrdersDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}${queryString}`);
    return deserialize<OrdersDto>(data, OrdersDto);
  }

  public async put(
    orderGuid: string,
    payload: IUpdateOrderRequest,
    brandId?: number
  ): Promise<OrderDto> {
    const { data } = !brandId
      ? await axios.put(`${ENDPOINT_URL}/${orderGuid}`, payload)
      : await axios.put(`${ENDPOINT_URL}/${orderGuid}`, payload, {
          headers: { brandId },
        });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async update(orderGuid: string, payload: IUpdateOrderRequest) {
    const { data } = await axios.put(
      `${ENDPOINT_URL}/${orderGuid}/UpdateOrder`,
      payload
    );
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async getWithInvoices(
    queryString = ""
  ): Promise<OrdersWithInvoiceDto> {
    const { data } = await axios.get(
      `${ENDPOINT_URL}/OrdersWithInvoices${queryString}`
    );
    return deserialize<OrdersWithInvoiceDto>(data, OrdersWithInvoiceDto);
  }

  public async getAgencyOrders(queryString = ""): Promise<AgencyOrdersDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/Agency${queryString}`);
    return deserialize<AgencyOrdersDto>(data, AgencyOrdersDto);
  }

  public async cancel(
    orderId: number,
    cancellationReason = ""
  ): Promise<OrderDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${orderId}/Cancelled`, {
      reason: cancellationReason,
    });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async reject(
    orderId: number,
    rejectReason: string | null
  ): Promise<OrderDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${orderId}/Rejected`, {
      reason: rejectReason,
    });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async accept(
    orderId: number,
    { note }: { note: string }
  ): Promise<OrderDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${orderId}/Approved`, {
      note,
    });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async shipped(orderId: number): Promise<OrderDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${orderId}/Shipped`);
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async split(
    orderId: number,
    itemIds: number[]
  ): Promise<{ oldOrder: OrderDto; newOrder: OrderDto }> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/${orderId}/Split`,
      itemIds
    );
    // response from API is a tuple [oldOrder, newOrder]
    return {
      oldOrder: deserialize<OrderDto>(data[0], OrderDto),
      newOrder: deserialize<OrderDto>(data[1], OrderDto),
    };
  }
}
