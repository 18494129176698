import { ShipLinkDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShipLinksDto {
  @JsonProperty() first!: ShipLinkDto;
  @JsonProperty() last!: ShipLinkDto;
  @JsonProperty() prev!: ShipLinkDto;
  @JsonProperty() next!: ShipLinkDto;
}
