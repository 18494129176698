import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "disbursements-table table-wrapper h-256" }
const _hoisted_2 = {
  class: "actions",
  "data-test-id": "actions"
}
const _hoisted_3 = {
  key: 0,
  "data-test-id": "order-id-cell"
}
const _hoisted_4 = {
  key: 1,
  class: "text-error",
  "data-test-id": "fee-amount-cell"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminDateRange = _resolveComponent("AdminDateRange")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JunTable = _resolveComponent("JunTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_JunTable, {
      heading: _ctx.tableConfig.heading,
      headers: _ctx.tableConfig.headers,
      options: _ctx.tableConfig.options,
      items: _ctx.tableConfig.items,
      loading: _ctx.tableConfig.loading || !_ctx.sellerReferenceId,
      itemKey: _ctx.tableConfig.itemKey,
      showSelect: false,
      modelValue: _ctx.selectedItems,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
      onSort: _ctx.onSort,
      onFilter: _ctx.onFilter,
      onPaginate: _ctx.onPaginate
    }, {
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AdminDateRange, {
            modelValue: _ctx.dateRange,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
            onDateRangeUpdated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onUpdateDateRange('createdOn', $event))),
            latestAvailableDate: _ctx.today
          }, null, 8, ["modelValue", "latestAvailableDate"])
        ])
      ]),
      default: _withCtx(({ item, header }) => [
        (header.prop === 'orderId')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: _ctx.getOrderLink(item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.orderId), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true),
        (header.prop === 'feeAmount')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(item.feeAmount ? `-${item.feeAmount}` : ""), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["heading", "headers", "options", "items", "loading", "itemKey", "modelValue", "onSort", "onFilter", "onPaginate"])
  ]))
}