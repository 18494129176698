import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "view-wrapper" }
const _hoisted_2 = { class: "py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderDetailsBox = _resolveComponent("OrderDetailsBox")!
  const _component_OrderItemsBox = _resolveComponent("OrderItemsBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_OrderDetailsBox, {
        order: _ctx.order,
        loading: _ctx.orderLoading,
        orderEditMode: _ctx.orderEditMode,
        updateOrder: _ctx.updateOrder,
        buildUpdatePayload: _ctx.buildUpdatePayload,
        canSaveItems: _ctx.canSaveItems,
        isEditing: _ctx.isEditing,
        onRefreshData: _ctx.onRefreshData,
        onEditOrder: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEditing = true)),
        onCancelEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditing = false))
      }, null, 8, ["order", "loading", "orderEditMode", "updateOrder", "buildUpdatePayload", "canSaveItems", "isEditing", "onRefreshData"]),
      _createVNode(_component_OrderItemsBox, {
        order: _ctx.order,
        loading: _ctx.orderLoading,
        orderEditMode: _ctx.orderEditMode,
        updateOrder: _ctx.updateOrder,
        orderModelValue: _ctx.orderModelValue,
        isEditing: _ctx.isEditing,
        onRefreshData: _ctx.onRefreshData
      }, null, 8, ["order", "loading", "orderEditMode", "updateOrder", "orderModelValue", "isEditing", "onRefreshData"])
    ])
  ]))
}