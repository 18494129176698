
import { Options, Vue } from "vue-class-component";
import {
  ActiveTable,
  Brands,
  Breadcrumbs,
  CategoryMapping,
  AttributeMapping,
  LeadsTable,
  PriceMapping,
  ProductSets,
  ProductTemplate,
  Profile,
  Icon,
  Payment,
  Shipping,
  OrderSummary,
  OrderShipping,
  OpenCartsTable,
  TransactionsTable,
  DisbursementsTable,
  DisputesTable,
  OrdersTable,
} from "@/components";
import {
  MainMenuTab,
  SubRouteTypes,
  MainRouteTypes,
  IconNameTypes,
  IPublishStatus,
} from "@/types";
import { Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = namespace("authVuexModule");
const manufacturers = namespace("manufacturersVuexModule");
const publish = namespace("publishVuexModule");

@Options({
  components: {
    LeadsTable,
    ActiveTable,
    Profile,
    ProductTemplate,
    ProductSets,
    PriceMapping,
    CategoryMapping,
    AttributeMapping,
    Brands,
    Breadcrumbs,
    Icon,
    Payment,
    Shipping,
    OrderSummary,
    OrderShipping,
    OrdersTable,
    OpenCartsTable,
    TransactionsTable,
    DisbursementsTable,
    DisputesTable,
  },
})
export default class NavTabs extends Vue {
  @auth.Getter private isMultiline!: boolean | null;
  @manufacturers.Getter private isDirectSeller!: boolean;
  @manufacturers.Getter private manufacturerId!: number | null;

  @publish.Getter private isProfileValid!: boolean;
  @publish.Mutation private setStatus!: (status: IPublishStatus | null) => void;

  @Prop({
    type: String,
    required: true,
  })
  type!: MainRouteTypes;

  @Prop({
    type: Array,
  })
  breadcrumbPath?: string[];

  private IconNameTypes = IconNameTypes;
  private currentTab: MainMenuTab | null = null;

  private orderTabsConfig: Array<MainMenuTab> = [
    { name: "Summary", type: SubRouteTypes.Summary },
    {
      name: "Shipping",
      type: SubRouteTypes.OrderShipping,
      showOnlyIfSingleLine: true,
    },
  ];

  private ordersTabsConfig: Array<MainMenuTab> = [
    { name: "All", type: SubRouteTypes.AllOrders },
    { name: "Cancelled", type: SubRouteTypes.CancelledOrders },
    { name: "Open Carts", type: SubRouteTypes.OpenCarts },
  ];

  private buyerTabsConfig: Array<MainMenuTab> = [
    { name: "Leads", type: SubRouteTypes.Leads },
    { name: "Active", type: SubRouteTypes.Active },
  ];

  private creditTabsConfig: Array<MainMenuTab> = [
    { name: "Transactions", type: SubRouteTypes.Transactions },
    { name: "Disbursements", type: SubRouteTypes.Disbursements },
    { name: "Disputes", type: SubRouteTypes.Disputes },
  ];

  private settingsTabsConfig: Array<MainMenuTab> = [
    {
      name: "Profile",
      type: SubRouteTypes.Profile,
    },
    {
      name: "Category Mapping",
      type: SubRouteTypes.CategoryMapping,
      showOnlyIfSingleLine: true,
    },
    {
      name: "Price Mapping",
      type: SubRouteTypes.PriceMapping,
      showOnlyIfSingleLine: true,
    },
    {
      name: "Product Template",
      type: SubRouteTypes.ProductTemplate,
      showOnlyIfSingleLine: true,
    },
    {
      name: "Attribute Mapping",
      type: SubRouteTypes.AttributeMapping,
      showOnlyIfSingleLine: true,
    },
    {
      name: "Product Sets",
      type: SubRouteTypes.ProductSets,
      showOnlyIfSingleLine: true,
    },
    {
      name: "Brands",
      type: SubRouteTypes.Brands,
      showOnlyIfMultiline: true,
    },
    {
      name: "Shipping",
      type: SubRouteTypes.Shipping,
      showOnlyIfSingleLine: true,
    },
    {
      name: "Payment",
      type: SubRouteTypes.Payment,
    },
  ];

  // WARNING!!! - the line commented out below works perfectly fine locally but snaps production.
  // I can't explain the reason behind it but be VERY careful modifying this route watcher
  // @Watch("$route", { immediate: true, deep: true })
  @Watch("$route.path")
  onUrlChange(newPath: string, oldPath: string) {
    // TODO FIXME: add as a util related to routing
    const newMainRoute = newPath.split("/")[1];
    const oldMainRoute = oldPath.split("/")[1];

    if (newMainRoute === oldMainRoute) {
      this.setCurrentTab();
    }
  }

  @Watch("manufacturerId")
  onManufacturerIdChange() {
    this.setCurrentTab();
    // changing divisions should clear any publish warnings in the tabs
    this.setStatus(null);
  }

  mounted() {
    this.setCurrentTab();
  }

  setCurrentTab() {
    const hasSubRoute = Array.isArray(this.$route.params.subRoute);

    if (hasSubRoute) {
      const type = this.$route.params.subRoute[0];
      const tab =
        this.tabs?.find((item: MainMenuTab) => item.type === type) || null;

      if (tab === null) {
        this.goToDefaultPath();
      } else {
        this.currentTab = tab;
      }
    } else {
      this.goToDefaultPath();
    }
  }

  goToDefaultPath() {
    switch (this.type) {
      case MainRouteTypes.Settings:
        this.$router.replace({ params: { subRoute: [SubRouteTypes.Profile] } });
        this.currentTab = this.settingsTabsConfig[0];
        break;
      case MainRouteTypes.Buyers:
        this.$router.replace({ params: { subRoute: [SubRouteTypes.Leads] } });
        this.currentTab = this.buyerTabsConfig[0];
        break;
      case MainRouteTypes.Orders:
        this.$router.replace({
          params: { subRoute: [SubRouteTypes.AllOrders] },
        });
        this.currentTab = this.ordersTabsConfig[0];
        break;
      case MainRouteTypes.OrderDetails:
        this.$router.replace({
          params: { subRoute: [SubRouteTypes.Summary] },
          query: this.$route.query,
        });
        this.currentTab = this.orderTabsConfig[0];
        break;
      case MainRouteTypes.Credit:
        this.$router.replace({
          params: { subRoute: [SubRouteTypes.Transactions] },
        });
        this.currentTab = this.creditTabsConfig[0];
        break;
      default:
        return;
    }
  }

  filterTabs(config: MainMenuTab[]) {
    return config.filter(
      (item: MainMenuTab) =>
        ((this.isMultiline && !item.showOnlyIfSingleLine) ||
          (!this.isMultiline && !item.showOnlyIfMultiline)) &&
        (this.isDirectSeller ||
          (!this.isDirectSeller && !item.showOnlyIfDirectSeller))
    );
  }

  get tabs(): Array<MainMenuTab> | null {
    if (this.isMultiline === null) {
      return null;
    }
    switch (this.type) {
      case MainRouteTypes.Settings:
        return this.filterTabs(this.settingsTabsConfig);
      case MainRouteTypes.Buyers:
        return this.filterTabs(this.buyerTabsConfig);
      case MainRouteTypes.Orders:
        return this.filterTabs(this.ordersTabsConfig);
      case MainRouteTypes.OrderDetails:
        return this.filterTabs(this.orderTabsConfig);
      case MainRouteTypes.Credit:
        return this.filterTabs(this.creditTabsConfig);
      default:
        return null;
    }
  }

  get currentTabComponent() {
    switch (this.currentTab?.type) {
      case SubRouteTypes.Leads:
        return "LeadsTable";
      case SubRouteTypes.Active:
        return "ActiveTable";
      case SubRouteTypes.Profile:
        return "Profile";
      case SubRouteTypes.ProductTemplate:
        return "ProductTemplate";
      case SubRouteTypes.CategoryMapping:
        return "CategoryMapping";
      case SubRouteTypes.AttributeMapping:
        return "AttributeMapping";
      case SubRouteTypes.Brands:
        return "Brands";
      case SubRouteTypes.ProductSets:
        return "ProductSets";
      case SubRouteTypes.PriceMapping:
        return "PriceMapping";
      case SubRouteTypes.Payment:
        return "Payment";
      case SubRouteTypes.Shipping:
        return "Shipping";
      case SubRouteTypes.Summary:
        return "OrderSummary";
      case SubRouteTypes.OrderShipping:
        return "OrderShipping";
      case SubRouteTypes.Transactions:
        return "TransactionsTable";
      case SubRouteTypes.Disbursements:
        return "DisbursementsTable";
      case SubRouteTypes.Disputes:
        return "DisputesTable";
      case SubRouteTypes.OpenCarts:
        return "OpenCartsTable";
      case SubRouteTypes.AllOrders:
      case SubRouteTypes.CancelledOrders:
        return "OrdersTable";
      default:
        return null;
    }
  }

  showWarningIcon(tab: SubRouteTypes) {
    return tab === SubRouteTypes.Profile && !this.isProfileValid;
  }

  onTabButtonClick(tab: MainMenuTab) {
    this.currentTab = tab;
    this.$router.push({
      params: { subRoute: [tab.type] },
      query: this.$route.query,
    });
  }
}
