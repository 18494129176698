
import { mixins, Options } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";
import { inject } from "inversify-props";
import { namespace } from "vuex-class";
import {
  Box,
  AdminCollapsible,
  InfoList,
  JuniperCreditCollapsible,
  PaymentTermsCollapsible,
} from "@/components";
import { Utils } from "@/utils";
import { TabVisibilityMixin } from "@/mixins";
import {
  PaymentSettingsDto,
  SellerDto,
  CountryDto,
  SellerStatusTypes,
} from "@/types";
import type { IPaymentSettingsRepository, InfoListItem } from "@/types";

const seller = namespace("sellerVuexModule");
const staticModule = namespace("staticContentVuexModule");

@Options({
  name: "SingleLinePaymentSettings",
  components: {
    Box,
    AdminCollapsible,
    InfoList,
    JuniperCreditCollapsible,
    PaymentTermsCollapsible,
  },
})
export default class SingleLinePaymentSettings extends mixins(
  TabVisibilityMixin
) {
  @inject() private paymentSettingsRepository?: IPaymentSettingsRepository;
  @InjectReactive() private defaultTermsFlag!: boolean;

  @seller.Getter seller?: SellerDto | null;
  @seller.Action getSeller?: (
    suppressErrorToast?: boolean
  ) => Promise<SellerDto | null>;
  @seller.Getter canApplyForCredit?: boolean;
  @seller.Action applyForJuniperCredit?: () => Promise<void>;

  @staticModule.Getter private countries?: CountryDto[];

  private creditCardSetting: PaymentSettingsDto | null = null;
  private isLoading = false;

  async created() {
    await this.init();
  }

  private async init(): Promise<void> {
    if (!this.paymentSettingsRepository || !this.getSeller) {
      return;
    }
    this.isLoading = true;
    const queryString = Utils.buildQueryString({ isMultiline: false });
    const [paymentSettings] = await Utils.try(
      this.paymentSettingsRepository.get(queryString)
    );
    if (paymentSettings && paymentSettings.length) {
      this.creditCardSetting = paymentSettings[0];
    }

    if (this.getSeller) {
      await this.getSeller();
      this.onTabGainedVisibility = this.getSeller;
    } else {
      this.onTabGainedVisibility = null;
    }

    this.isLoading = false;
  }

  private async updatePaymentSettings(
    newPaymentSettings: PaymentSettingsDto
  ): Promise<boolean> {
    const [updatedPaymentSettings] = await Utils.try(
      this.paymentSettingsRepository?.put(newPaymentSettings)
    );
    if (updatedPaymentSettings) {
      this.creditCardSetting = newPaymentSettings;
      return true;
    }
    return false;
  }

  private get hideCreditCardInfo(): boolean {
    return Boolean(
      this.creditCardSetting && !this.creditCardSetting.acceptCreditCards
    );
  }

  private get hasRequestedJuniperCredit(): boolean {
    return this.seller?.status !== SellerStatusTypes.Created;
  }

  private get creditCardHeaderText(): string {
    if (!this.creditCardSetting) return "";
    if (!this.creditCardSetting.acceptCreditCards) {
      return "Credit cards not accepted. Manage credit card settings in web manager.";
    }
    let text = "";
    if (this.creditCardSetting.acceptedCreditCards?.length) {
      text += `Accepts ${Utils.joinStringParts(
        this.creditCardSetting.acceptedCreditCards.filter(Boolean) as string[]
      )}`;
    } else {
      text += `Accepts credit cards`;
    }
    if (this.creditCardSetting.acceptedCountries?.length) {
      const acceptedCountries = this.creditCardSetting.acceptedCountries
        .filter(Boolean)
        .map((ac) => this.countries?.find((c) => c.code === ac)?.name ?? ac);
      text += ` from ${Utils.joinStringParts(acceptedCountries as string[])}.`;
    }
    return text;
  }

  private get creditCardInfoListItems(): Array<InfoListItem> {
    if (!this.creditCardSetting) {
      return [];
    }
    return [
      {
        label: "Credit Card Payments",
        text: this.creditCardSetting.acceptCreditCards ? "Yes" : "No",
      },
      {
        label: "Credit Processor",
        text: this.creditCardSetting.paymentGatewayType || "N/A",
      },
      {
        label: "Credit Cards Accepted",
        text: this.creditCardSetting.acceptedCreditCards?.join(", ") || "N/A",
      },
      {
        label: "Only Accept Payments From",
        text: this.creditCardSetting.acceptedCountries?.join(", ") || "N/A",
      },
    ];
  }
}
