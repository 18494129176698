import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingAccountOptionsDto {
  @JsonProperty() options!: ShippingAccountOptionDto[];
}

@Serializable()
export class ShippingAccountOptionDto {
  @JsonProperty() name!: string | null;
  @JsonProperty() defaultValue!: string | null;
  @JsonProperty() description!: string | null;
}
