
import { Options, mixins } from "vue-class-component";
import { HandshakeCredentials, AuthTypes, MainRouteTypes } from "@/types";
import type { IHandshakeRepository } from "@/types";
import { AuthMixin } from "@/mixins";
import { inject } from "inversify-props";
import { namespace } from "vuex-class";

const manufacturers = namespace("manufacturersVuexModule");

@Options({})
export default class HandshakeLogin extends mixins(AuthMixin) {
  @inject() private handshakeRepository!: IHandshakeRepository;

  @manufacturers.Action private setManufacturer!: (
    manufacturerIds: number[]
  ) => Promise<void>;

  async mounted() {
    try {
      const jwt = await this.getJsonWebToken();
      const manufacturerId = this.getManufacturerIdFromUrl();

      if (manufacturerId) {
        await this.setManufacturer([manufacturerId]);
      }

      this.handleAuthCredentials(jwt, AuthTypes.Handshake, true, "/");
    } catch (err) {
      await this.$router.push(`/${MainRouteTypes.HandshakeError}`);
    }
  }

  private async getJsonWebToken(): Promise<string> {
    const query = Object.entries(this.$route.query).length
      ? this.$route.query
      : {
          username: "",
          password: "",
          passwordSalt: "",
        };

    //TODO: Please do not delete this, it is for testing purposes
    // const query = Object.entries(this.$route.query).length
    //   ? this.$route.query
    //   : {
    //       username: "mcuomo",
    //       password: "8CF299993350AB135BF36E4E500DFAD1A3D6E9D2",
    //       passwordSalt: "r0jFAIi7++56NaFkVLqNrbYle974qD1HvyWtms4iNgY="
    //     };

    const handshakeCredentials = new HandshakeCredentials(query);
    const resp = await this.handshakeRepository.post(handshakeCredentials);

    return resp.accessToken;
  }

  private getManufacturerIdFromUrl(): number | null {
    const strValue = this.$route.query.manufacturerId;
    return strValue ? Number(strValue) : null;
  }
}
