import { AxiosResponse } from "axios";
import axios from "@/axios";
import { injectable } from "inversify-props";
import { OrderMinimumsDto, IOrderingRepository } from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/Ordering`;

@injectable()
export class OrderingRepository implements IOrderingRepository {
  public async get(): Promise<OrderMinimumsDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<OrderMinimumsDto>(data, OrderMinimumsDto);
  }

  public async put(
    orderingMinimums: OrderMinimumsDto
  ): Promise<AxiosResponse<OrderMinimumsDto>> {
    const payload = serialize(orderingMinimums);
    return axios.put(ENDPOINT_URL, payload);
  }
}
