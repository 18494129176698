import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-728cdd79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cmp-container relative" }
const _hoisted_2 = { class: "checkbox-wrapper" }
const _hoisted_3 = ["name", "value", "checked", "disabled"]
const _hoisted_4 = { class: "message-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        class: _normalizeClass(['checkbox-container', _ctx.labelClass])
      }, [
        _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
        (!_ctx.label)
          ? _renderSlot(_ctx.$slots, "label", { key: 0 }, undefined, true)
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          type: "checkbox",
          name: _ctx.name,
          value: _ctx.modelValue,
          checked: _ctx.modelValue || _ctx.checked,
          disabled: _ctx.disabled,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 40, _hoisted_3),
        _createElementVNode("span", {
          class: _normalizeClass(['checkmark', { error: _ctx.error }])
        }, null, 2)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_Transition, { name: "slide-up" }, {
        default: _withCtx(() => [
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(['cmp-message', { error: _ctx.error }])
              }, _toDisplayString(_ctx.errorMessage), 3))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}