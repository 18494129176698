import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { inject } from "inversify-props";
import store from "@/store";
import { Utils } from "@/utils";
import { CreditCurrencyTypes, SellerDto, SellerStatusTypes } from "@/types";
import type { ISellerRepository } from "@/types";

const SELLER_REF_ID_STORAGE_KEY = "__jun_sellerReferenceId__";
const SELLER_STATUS_STORAGE_KEY = "__jun_sellerStatus__";

@Module({
  store,
  namespaced: true,
})
export default class SellerVuexModule extends VuexModule {
  @inject() private sellerRepository!: ISellerRepository;

  private _seller: SellerDto | null = null;
  private _showApprovedModal = false;
  private _isJuniperCreditEnabled = false;

  public get seller(): SellerDto | null {
    return this._seller;
  }

  public get sellerReferenceId(): string | null {
    return this.seller?.sellerReferenceId ?? null;
  }

  public get showApprovedModal(): boolean {
    return this._showApprovedModal;
  }

  public get isJuniperCreditEnabled(): boolean {
    return this._isJuniperCreditEnabled;
  }

  public get canApplyForCredit(): boolean {
    if (!this.seller) return false;
    return Boolean(
      this.seller.status === SellerStatusTypes.Created ||
        (this.seller.status === SellerStatusTypes.Declined &&
          this.seller.canApplyForCredit)
    );
  }

  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this._seller = null;
    this._showApprovedModal = false;
    this._isJuniperCreditEnabled = false;
  }

  @Mutation
  public setSeller(seller: SellerDto): void {
    this._seller = seller;
  }

  @Mutation
  public setShowApprovedModal(show: boolean): void {
    this._showApprovedModal = show;
  }

  @Mutation
  public setIsJuniperCreditEnabled(enabled: boolean): void {
    this._isJuniperCreditEnabled = enabled;
  }

  @Action({ rawError: true })
  public async initCredit(): Promise<void> {
    if (this.context.rootGetters["authVuexModule/isMultiline"]) {
      this.context.commit("setSeller", null);
      return;
    }
    try {
      await this.context.dispatch("getSeller", true);
    } catch (err) {
      // Seller doesn't have a record yet, create one.
      await this.context.dispatch("createSeller");
    }
  }

  @Action({ rawError: true })
  public async getSeller(
    suppressErrorToast = false
  ): Promise<SellerDto | null> {
    const [seller, err] = await Utils.try(
      this.sellerRepository.get(suppressErrorToast)
    );
    if (
      suppressErrorToast &&
      err &&
      err.response?.status &&
      [403, 404].includes(err.response.status)
    ) {
      // Propagate axios error if cannot find a seller record on app load
      throw err;
    }
    await this.context.dispatch("updateSellerStatusInStorage", seller);
    this.context.commit("setSeller", seller);
    return seller;
  }

  @Action({ rawError: true })
  public async createSeller(): Promise<SellerDto | null> {
    let manufacturerId: number | null =
      this.context.rootGetters["manufacturersVuexModule/manufacturerId"];
    if (!manufacturerId) {
      const localManufacturerId = localStorage.getItem("manufacturerId");
      manufacturerId = localManufacturerId ? Number(localManufacturerId) : null;
    }
    if (!manufacturerId) return null;
    await Utils.try(
      this.sellerRepository.post({
        manufacturerId,
        currency: CreditCurrencyTypes.USD,
      })
    );
    return await this.context.dispatch("getSeller");
  }

  @Action({ rawError: true })
  public async applyForJuniperCredit(): Promise<void> {
    await this.context.dispatch("getSeller");
    if (!this.canApplyForCredit) return;
    window.open(
      `${process.env.VUE_APP_JUNIPER_CREDIT_APPLY_URL}?client_reference_id=${
        (this.seller as SellerDto).sellerReferenceId
      }`
    );
  }

  @Action({ rawError: true })
  public async updateSellerStatusInStorage(seller: SellerDto): Promise<void> {
    const prevSellerReferenceId = localStorage.getItem(
      SELLER_REF_ID_STORAGE_KEY
    );
    const prevSellerStatus = localStorage.getItem(SELLER_STATUS_STORAGE_KEY);

    if (seller && seller.sellerReferenceId && seller.status) {
      // If seller's previous status in localStorage is not "Approved"
      // And new status from network request is "Approved"
      // We want to show the "Congratulations" modal to the seller
      if (
        prevSellerReferenceId === seller.sellerReferenceId &&
        prevSellerStatus &&
        prevSellerStatus !== SellerStatusTypes.Approved &&
        seller.status === SellerStatusTypes.Approved
      ) {
        this.context.commit("setShowApprovedModal", true);
      }

      localStorage.setItem(SELLER_REF_ID_STORAGE_KEY, seller.sellerReferenceId);
      localStorage.setItem(SELLER_STATUS_STORAGE_KEY, seller.status);
    } else {
      // If seller does not exist, remove previously stored sellerReferenceId and status
      // this happens when a user switches division/manufactureerId
      localStorage.removeItem(SELLER_REF_ID_STORAGE_KEY);
      localStorage.removeItem(SELLER_STATUS_STORAGE_KEY);
    }
  }
}
