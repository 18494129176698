
import { AdminButton, Icon } from "@/components";
import {
  EditableBoxStatusTypes,
  IconNameTypes,
  ShippingMethodTypes,
  ShippingSettingsConfirmationModalTypes,
  ShippingSettingsDto,
} from "@/types";
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import { Inject, InjectReactive, Prop } from "vue-property-decorator";

@Options({
  components: {
    Icon,
    AdminButton,
  },
})
export default class ShippingMethodInfo extends Vue {
  @Prop({
    type: Object as PropType<ShippingSettingsDto>,
  })
  shippingSettings!: ShippingSettingsDto;

  @Prop({
    type: String,
  })
  selectedShipMethod!: ShippingMethodTypes;

  private IconNameTypes = IconNameTypes;
  private ShippingMethodTypes = ShippingMethodTypes;

  @Inject() private fireWarningModal!: (
    modalType: ShippingSettingsConfirmationModalTypes
  ) => void;
  @Inject() private shipMethodChange!: () => void;
  @Inject() private updateSelectedShipMethod!: (
    shipMethod: ShippingMethodTypes
  ) => void;
  @Inject() private updateStatus!: (status: EditableBoxStatusTypes) => void;
  @InjectReactive() private shipWithJuniperFlag!: boolean;

  private setNewShippingMethod(newShipMethod: ShippingMethodTypes) {
    this.updateStatus(EditableBoxStatusTypes.Edit);
    this.updateSelectedShipMethod(newShipMethod);
    this.shipMethodChange();
    if (
      (this.selectedShipMethod === ShippingMethodTypes.CustomCarrier ||
        this.selectedShipMethod === ShippingMethodTypes.Juniper) &&
      this.shippingSettings.isCarrierActive
    ) {
      this.fireWarningModal(
        ShippingSettingsConfirmationModalTypes.MethodChange
      );
    }
  }
}
