import { CarrierTypes } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingAccountDto {
  @JsonProperty() carrierId!: number | null;
  @JsonProperty() externalCarrierId!: string | null;
  @JsonProperty() carrierName!: CarrierTypes;
  @JsonProperty() createdOn!: string | null;
  @JsonProperty() deletedOn!: string | null;
  @JsonProperty() isShared!: boolean;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty() isCarrierActive!: boolean;
  @JsonProperty() manufacturerId!: number | null;
  @JsonProperty() manufacturerConnectedOn!: string | null;
}
