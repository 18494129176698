
import { Options, mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import { AxiosResponse } from "axios";
import { inject } from "inversify-props";
import objectMapper from "object-mapper";
import {
  ActiveBuyersDrawer,
  Drawer,
  Icon,
  ConfirmationModal,
  NoResultsForFilter,
} from "@/components";
import { Utils } from "@/utils";
import {
  IconNameTypes,
  CustomerDto,
  PricingLevelAliasesDto,
  TableFilterTypes,
} from "@/types";
import type {
  IActiveCustomer,
  TableConfig,
  IAddress,
  ToastConfig,
  DrawerSubHeading,
  ICustomersRepository,
} from "@/types";
import { CustomersMap } from "@/maps";
import { TableMixin } from "@/mixins";
import { Watch } from "vue-property-decorator";

const notifications = namespace("notificationsVuexModule");
const staticContent = namespace("staticContentVuexModule");
const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    Drawer,
    Icon,
    ActiveBuyersDrawer,
    ConfirmationModal,
    NoResultsForFilter,
  },
})
export default class ActiveTable extends mixins(TableMixin) {
  @inject() private customersRepository!: ICustomersRepository;

  @manufacturers.Getter private manufacturerId!: number | null;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;
  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  @staticContent.Getter private priceLevelAlias!: (
    val: number
  ) => string | null;
  @staticContent.Getter private priceLevelAliasToId!: (
    val: string
  ) => number | null;
  @staticContent.Getter
  private priceLevelAliases!: Partial<PricingLevelAliasesDto> | null;

  private customerRequests: Array<CustomerDto> = [];
  private IconNameTypes = IconNameTypes;
  private drawerOpen = false;
  private showDeleteModal = false;
  private deleteLoading = false;
  private selectedCustomer: CustomerDto | null = null;
  private selectedItems: IActiveCustomer[] = [];
  private drawerSubheading: DrawerSubHeading | null = null;

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.Legacy });
  }

  async created() {
    this.initTable();
    await this.init({ filterVersion: TableFilterTypes.Legacy });
  }

  private initTable(): void {
    this.page = 1;
    this.pageSize = 50;
    this.sortBy = "name";
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    this.persistentFilters = [{ key: "IsDeleted", value: 0 }];
    this.noWildcardFilterKeys.push("priceLevel");
    this.getTableData = this.getCustomerRequests;
  }

  // turning table headers into a getter since it depends on price aliases which
  // change by manufacturer
  get headers() {
    return [
      {
        prop: "name",
        text: "Customer Name",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "customerNumber",
        text: "Customer #",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "priceLevel",
        text: "Price Level",
        canSort: true,
        canFilter: true,
        filterOptions: this.priceLevelAliases
          ? Object.entries(this.priceLevelAliases).map(([key, val]) => ({
              text: val || "",
              value: key,
              tag: val || "",
            }))
          : [],
      },
      {
        prop: "billingAddress",
        text: "Billing Address",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "shippingAddress",
        text: "Shipping Address",
        canSort: false,
        canFilter: false,
      },
    ];
  }

  private onNoFilterResultsGoBack() {
    this.removeLatestFilter(TableFilterTypes.Legacy);
  }

  private async getCustomerRequests(): Promise<void> {
    const queryString = this.getQueryString();
    const { customers, meta } = await this.customersRepository.getAll(
      queryString
    );
    this.customerRequests = customers;
    this.tableOptions.paginationOptions = {
      page: meta.page,
      pageSize: meta.pageSize,
      totalRecords: meta.totalRecords,
    };
  }

  get tableConfig(): TableConfig {
    return {
      heading: "All Active Customers",
      loading: this.isTableProcessing || !this.priceLevelAliases,
      headers: this.headers,
      options: this.tableOptions,
      items: this.customerRequests?.length
        ? objectMapper(this.customerRequests, CustomersMap)
        : [],
      itemKey: "customerNumber",
    };
  }

  get deleteDisabled() {
    return this.selectedItems.length === 0;
  }

  async onDelete() {
    const promises: Promise<AxiosResponse>[] = [];

    this.deleteLoading = true;
    this.selectedItems.forEach((item) => {
      const promise = this.customersRepository.delete(item.customerNumber);
      promises.push(promise);
    });

    const res = await Promise.allSettled(promises);

    // order of res from Promise.all is in order of promises given, not in order
    // of sequence resolved. this means we can't read the responses from each
    // delete and know which the latest one is, so have to make extra call to get
    // the new order after all deletes have resolved and refresh the data
    const queryString = this.getQueryString();
    const [data] = await Utils.try(
      this.customersRepository.getAll(queryString)
    );
    if (data) {
      await this.init({ filterVersion: TableFilterTypes.Legacy });
    }

    if (res.every((r) => r.status === "fulfilled")) {
      this.createToastSuccess({
        message: "Successfully deleted selected customer(s).",
      });
      this.selectedItems = [];
    } else {
      this.createToastError({
        message:
          "One or more customers failed to delete, please try again later.",
      });
    }

    this.closeDeleteModal();
    this.deleteLoading = false;
  }

  closeDeleteModal() {
    this.showDeleteModal = false;
  }

  openDeleteModal() {
    if (!this.deleteDisabled) {
      this.showDeleteModal = true;
    }
  }

  openDrawer(customer: IActiveCustomer) {
    if (customer) {
      this.selectedCustomer =
        this.customerRequests.find((c) => {
          return c.customerNumber === customer.customerNumber;
        }) || null;
      if (this.selectedCustomer?.customerNumber)
        this.drawerSubheading = {
          label: "Customer #",
          value: this.selectedCustomer?.customerNumber,
        };
    }
    this.drawerOpen = true;
  }

  addressFormat(address: IAddress) {
    return Utils.formatAddress(address);
  }

  closeDrawer() {
    this.selectedCustomer = null;
    this.drawerOpen = false;
  }
}
