import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { AuthTypes } from "@/types";
import type { IAuthCredentialsConfig, IDecodedAuthToken } from "@/types";
import jwtDecode, { JwtPayload } from "jwt-decode";

@Module({
  store: store,
  namespaced: true,
})
export default class AuthVuexModule extends VuexModule {
  private _authType: AuthTypes | null = null;
  private _isAuthenticated = false;
  private _userName = "";
  private _email = "";
  private _companyId = "";
  private _authToken: string | null = null;
  private _decodedAuthToken: IDecodedAuthToken | null = null;

  public get authType(): AuthTypes | null {
    return this._authType;
  }

  public get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  public get isMultiline(): boolean | null {
    const mnId: number | null =
      this.context.rootGetters["manufacturersVuexModule/manufacturerId"];

    if (!this._decodedAuthToken) {
      return null;
    }
    return this._decodedAuthToken.agencies_ids?.includes(String(mnId)) || false;
  }

  public get userName(): string {
    return this._userName;
  }

  public get email(): string {
    return this._email;
  }

  public get companyId(): string {
    return this._companyId;
  }

  public get authToken(): string | null {
    return this._authToken;
  }

  public get decodedAuthToken(): IDecodedAuthToken | null {
    return this._decodedAuthToken;
  }

  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this._authType = null;
    this._isAuthenticated = false;
    this._userName = "";
    this._email = "";
    this._companyId = "";
    this._authToken = null;
    this._decodedAuthToken = null;
  }

  @Mutation
  public setAuthType(value: AuthTypes): void {
    this._authType = value;
  }

  @Mutation
  public setUserName(value: string): void {
    this._userName = value;
  }

  @Mutation
  public setEmail(value: string): void {
    this._email = value;
  }

  @Mutation
  public setCompanyId(value: string): void {
    this._companyId = value;
  }

  @Mutation
  public setIsAuthenticated(value: boolean): void {
    this._isAuthenticated = value;
  }

  @Mutation
  public setAuthToken(value: string): void {
    localStorage.setItem("authToken", value);
    this._authToken = value;
  }

  @Mutation
  public setDecodedAuthToken(value: IDecodedAuthToken): void {
    this._decodedAuthToken = value;
  }

  @Action({ rawError: true })
  public async setAuthCredentials(
    config: IAuthCredentialsConfig
  ): Promise<void> {
    if (config.authToken) {
      window.localStorage.removeItem("authToken");
      window.localStorage.removeItem("isHandshakeLogin");

      if (config.authType === AuthTypes.Handshake) {
        window.localStorage.setItem("isHandshakeLogin", "true");

        // await this.context.dispatch("oidcVuexModule/signOutOidcSilent", null, {
        //   root: true
        // });
      }

      const decodedAuthToken = jwtDecode<JwtPayload>(
        config.authToken
      ) as IDecodedAuthToken;

      const userName = decodedAuthToken.name || "";
      const email = decodedAuthToken.email || "";
      const companyId = decodedAuthToken.companyId || "";

      this.context.commit("setAuthType", config.authType);
      this.context.commit("setAuthToken", `Bearer ${config.authToken}`);
      this.context.commit("setDecodedAuthToken", decodedAuthToken);
      this.context.commit("setUserName", userName);
      this.context.commit("setEmail", email);
      this.context.commit("setCompanyId", companyId);
      this.context.commit("setIsAuthenticated", true);

      const mnfIds = Array.isArray(decodedAuthToken.mnfIds)
        ? decodedAuthToken.mnfIds.map((id: string) => Number(id))
        : [Number(decodedAuthToken.mnfIds)];

      await this.context.dispatch(
        "manufacturersVuexModule/setManufacturer",
        mnfIds,
        { root: true }
      );
    }
  }
}
