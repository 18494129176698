import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5902f5e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["admin-btn", _ctx.btnClassList]),
    style: _normalizeStyle({ minWidth: _ctx.minWidth + 'rem' }),
    type: _ctx.type,
    disabled: _ctx.inactive || _ctx.inactiveFilled
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: _normalizeClass(["admin-btn-icon", _ctx.iconClassList]),
          name: _ctx.icon,
          size: _ctx.iconSize
        }, null, 8, ["class", "name", "size"]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
      : (_openBlock(), _createElementBlock("span", _hoisted_2, "Loading..."))
  ], 14, _hoisted_1))
}