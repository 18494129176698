import { JsonProperty, Serializable } from "typescript-json-serializer";
import { LinkDto, ListMetaDto, OrderWithInvoiceDto } from "@/types";

@Serializable()
export class OrdersWithInvoiceDto {
  @JsonProperty({ type: OrderWithInvoiceDto })
  data!: Array<OrderWithInvoiceDto>;
  @JsonProperty({ type: LinkDto }) links!: Array<LinkDto>;
  @JsonProperty() meta!: ListMetaDto;
}
