
import { inject } from "inversify-props";
import { DebouncedFunc, debounce } from "lodash";
import { Form } from "vee-validate";
import { nextTick } from "vue";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import * as yup from "yup";

import {
  AdminButton,
  AdminCollapsible,
  ConfirmationModal,
  Dropdown,
  Icon,
  Switch,
  Tooltip,
} from "@/components";
import { BackupDataService } from "@/services";
import type { IMarketPaymentSettingsRepository } from "@/types";
import {
  DropdownItem,
  IValidateResponse,
  IconNameTypes,
  MarketPaymentSettingsDto,
  ToastConfig,
  TooltipPosition,
} from "@/types";
import { Utils } from "@/utils";

const notifications = namespace("notificationsVuexModule");
const staticContent = namespace("staticContentVuexModule");

@Options({
  name: "PaymentTermsCollapsible",
  components: {
    Icon,
    Tooltip,
    AdminCollapsible,
    AdminButton,
    Switch,
    Dropdown,
    Form,
    ConfirmationModal,
  },
})
export default class PaymentTermsCollapsible extends Vue {
  @inject()
  private marketPaymentSettingsRepository!: IMarketPaymentSettingsRepository;
  @inject() private backupDataService!: BackupDataService;

  @staticContent.Getter private termsDropdownOptions!: DropdownItem[];
  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;
  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOff!: () => void;

  private marketPaymentSettings: MarketPaymentSettingsDto | null = null;
  private isCollapsibleOpen = false;
  private isModalOpen = false;
  public IconNameTypes = IconNameTypes;
  public TooltipPosition = TooltipPosition;
  private debouncedHandleEnableTerms!: DebouncedFunc<
    (enabled: boolean) => Promise<void>
  >;
  private debouncedHandleTermsChange!: DebouncedFunc<
    (defaultTerms: string) => Promise<void>
  >;

  private get hasTerms(): boolean {
    return Boolean(this.termsDropdownOptions.length > 0);
  }

  private get selectedTerms(): string | undefined {
    return this.marketPaymentSettings?.termsSettings.defaultTerms?.[0];
  }

  private get validationSchema(): ReturnType<typeof yup.object> | undefined {
    return this.marketPaymentSettings?.termsSettings?.enabled
      ? yup.object().shape({
          paymentTerms: yup.string().nullable().required("required"),
        })
      : undefined; // no validation required when default terms is not enabled
  }

  async created(): Promise<void> {
    this.debouncedHandleEnableTerms = debounce(this.handleEnableTerms, 500);
    // Longer debounce delay time for terms multi-select because it takes user
    // more time to read and select dropdown items than clicking on the toggle
    this.debouncedHandleTermsChange = debounce(this.handleTermsChange, 750);
    const [settings, error] = await Utils.try(
      this.marketPaymentSettingsRepository.get()
    );
    if (!error && settings) {
      this.marketPaymentSettings = settings;
      this.backupDataService.saveBackup(this, this.marketPaymentSettings);
    }
  }

  private async handleTermsChange(defaultTerms: string): Promise<void> {
    if (!this.marketPaymentSettings?.termsSettings.defaultTerms) return;
    this.marketPaymentSettings.termsSettings.defaultTerms[0] = defaultTerms;
    if (!(await this.isTermsSettingsValid())) return;
    await this.updateTermsSettings();
  }

  private async handleEnableTerms(enabled: boolean): Promise<void> {
    if (!this.marketPaymentSettings) return;
    this.marketPaymentSettings.termsSettings.enabled = enabled;
    if (!(await this.isTermsSettingsValid())) return;
    await this.updateTermsSettings();
  }

  private async isTermsSettingsValid(): Promise<boolean> {
    if (!this.marketPaymentSettings) return false;
    if (this.marketPaymentSettings.termsSettings.enabled) {
      if (!this.isCollapsibleOpen) {
        // If collapisble is not yet open at the time we turn on the toggle,
        // we need to open it first, so that the <Form/> gets rendered then
        // we can get a hold of it with $refs to validate the dropdown
        this.isCollapsibleOpen = true;
        await nextTick(); // nextTick here to wait for the browser to paint the element after vue renders the <Form/>
      }
      const validationResult: IValidateResponse = await (
        this.$refs.form as HTMLFormElement
      ).validate();
      const hasTermSelected =
        this.marketPaymentSettings.termsSettings.defaultTerms?.filter(
          Boolean
        ).length;
      if (!validationResult.valid || !hasTermSelected) {
        this.isModalOpen = true;
        this.backupDataService.restoreBackup(this, this.marketPaymentSettings);
        return false;
      }
    }
    return true;
  }

  private async updateTermsSettings(): Promise<void> {
    if (!this.marketPaymentSettings) return;
    const [settings, error] = await Utils.try(
      this.marketPaymentSettingsRepository.put(this.marketPaymentSettings)
    );
    if (!error && settings) {
      this.marketPaymentSettings = settings;
      this.backupDataService.saveBackup(this, this.marketPaymentSettings);
      this.handleAutoPublishOff();
      await nextTick();
      this.createToastSuccess({
        message: "Your Terms settings have been saved.",
      });
    } else {
      this.backupDataService.restoreBackup(this, this.marketPaymentSettings);
      this.createToastError({ message: "Failed to save your Terms settings." });
    }
  }
}
