
import { Options, Vue } from "vue-class-component";
import {
  AdminButton,
  CompanyAndContactDetails,
  OrderMinimumsCmp,
  CompanyImagesCmp,
} from "@/components";

@Options({
  components: {
    AdminButton,
    CompanyAndContactDetails,
    OrderMinimumsCmp,
    CompanyImagesCmp,
  },
})
export default class Profile extends Vue {}
