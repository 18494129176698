
import { Options, Vue } from "vue-class-component";
import { Icon } from "@/components";
import { PaymentSettingsDto, IconNameTypes } from "@/types";
import type { TableConfig, IPaymentSettingsRepository } from "@/types";
import { JunTableColumn } from "@juniper/ui";
import { inject } from "inversify-props";
import { Utils } from "@/utils";

@Options({
  components: {
    Icon,
  },
})
export default class MultiLinePaymentTable extends Vue {
  @inject() private paymentSettingsRepository?: IPaymentSettingsRepository;

  private tableData: Array<PaymentSettingsDto> = [];
  private isTableProcessing = false;

  async created() {
    await this.initTable();
  }

  private async initTable(): Promise<void> {
    this.isTableProcessing = true;
    const queryString = Utils.buildQueryString({ isMultiline: true });
    const [tableData] = await Utils.try(
      this.paymentSettingsRepository?.get(queryString)
    );
    if (tableData) this.tableData = tableData;
    this.isTableProcessing = false;
  }

  private getIconColorClass(acceptCC: boolean): string {
    return acceptCC ? "text-success" : "text-error";
  }
  private getIconName(acceptCC: boolean): string {
    return acceptCC ? IconNameTypes.Success : IconNameTypes.CloseV2;
  }

  private getPaymentGatewayType(paymentGatewayType?: string): string {
    if (!paymentGatewayType || paymentGatewayType === "None") {
      return "N/A";
    }
    return paymentGatewayType;
  }

  private buildTableItems(tableData: PaymentSettingsDto[]) {
    return tableData.map((item) => {
      return {
        vendorName: item.vendorName,
        paymentGatewayType: item.paymentGatewayType,
        acceptedCreditCards: item.acceptedCreditCards?.join(", ") ?? "",
        acceptedCountries: item.acceptedCountries?.join(", ") ?? "",
        acceptCreditCards: item.acceptCreditCards,
      };
    });
  }

  get tableConfig(): TableConfig {
    return {
      loading: this.isTableProcessing,
      heading: "Vendor Payment Options",
      headers: this.tableHeaders,
      items: this.buildTableItems(this.tableData),
      itemKey: "id",
    };
  }

  get tableHeaders(): JunTableColumn[] {
    return [
      {
        prop: "vendorName",
        text: "Vendors",
      },
      {
        prop: "paymentGatewayType",
        text: "Processor",
      },
      {
        prop: "acceptedCreditCards",
        text: "Credit Cards Accepted",
      },
      {
        prop: "acceptedCountries",
        text: "Only Accept Payments From",
      },
      {
        prop: "acceptCreditCards",
        text: "Credit Card Payments",
        align: "right",
      },
    ];
  }
}
