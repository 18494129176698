import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.isShowing)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['banner', _ctx.classes])
      }, [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        (_ctx.closable)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              onClick: _ctx.onClose,
              class: "cursor-pointer",
              name: _ctx.IconNameTypes.Close,
              size: 0.75
            }, null, 8, ["onClick", "name", "size"]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}