import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64710392"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunDatePicker = _resolveComponent("JunDatePicker")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "wrapper",
    class: _normalizeClass(['admin-date-picker', _ctx.classes]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.filterUnavailableDates && _ctx.filterUnavailableDates(...args))),
    onMouseoverPassive: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.filterUnavailableDates && _ctx.filterUnavailableDates(...args)))
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_JunDatePicker, {
      ref: "junDatePicker",
      modelValue: new Date(_ctx.modelValue),
      "onUpdate:modelValue": _ctx.onModelValueChange,
      type: "date",
      format: "MM/DD/YYYY"
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createElementVNode("input", {
      tabindex: "-1",
      readonly: "",
      id: _ctx.name,
      name: _ctx.name,
      type: "text",
      value: _ctx.modelValue
    }, null, 8, _hoisted_2),
    (_ctx.msg)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['cmp-message', 'text-right', { error: _ctx.error }])
        }, _toDisplayString(_ctx.error ? _ctx.errorMessage : _ctx.msg), 3))
      : _createCommentVNode("", true)
  ], 34))
}