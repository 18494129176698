import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_NavTabs = _resolveComponent("NavTabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar, { title: "Orders" }),
    _createVNode(_component_NavTabs, {
      type: _ctx.MainRouteTypes.Orders
    }, null, 8, ["type"])
  ], 64))
}