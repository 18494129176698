import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b709302"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper msg-panel-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_MessagePanel = _resolveComponent("MessagePanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar, { title: "Marketplace Admin" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MessagePanel, {
        title: 'No Division Set Up',
        message: 'Looks like you are not set up yet. Please contact your ANDMORE support team to set up your divisions for the marketplace'
      }, null, 8, ["message"])
    ])
  ], 64))
}