
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IconNameTypes } from "@/types";

@Options({})
export default class Icon extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  name!: IconNameTypes;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Number,
    default: 1.5,
  })
  size!: number;
}
