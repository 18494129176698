import { JsonProperty, Serializable } from "typescript-json-serializer";
import { OrderItemChannelDto } from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class OrderItemDto {
  @JsonProperty() importMapping!: number | null;
  @JsonProperty() additionalImageCount!: number | null;
  @JsonProperty() basePrice!: number; //float
  @JsonProperty() appendToItemID!: string;
  @JsonProperty() appendItemID!: boolean | null;
  @JsonProperty() catalogCode!: string;
  @JsonProperty() manufacturerOrderItemID!: string;
  @JsonProperty() manufacturerOrderItemStatus!: string;
  @JsonProperty() catalogName!: string;
  @JsonProperty() categoryName!: string;
  @JsonProperty() category!: string;
  @JsonProperty() completeItemID!: string;
  @JsonProperty() cubes!: number | null; //float
  @JsonProperty() customerNumber!: string;
  @JsonProperty() description!: string;
  @JsonProperty() dimensions!: string;
  @JsonProperty() discontinued!: boolean | null;
  @JsonProperty() discountIsQtyBased!: boolean | null;
  @JsonProperty() discountScope!: string;
  @JsonProperty() discountType!: string;
  @JsonProperty() discountValue!: number; //float
  @JsonProperty() exportDate!: string | null; //date
  @JsonProperty() exported!: boolean | null;
  @JsonProperty() hasLineItemDiscount!: boolean | null;
  @JsonProperty() hasModifiers!: boolean | null;
  @JsonProperty() itemID!: string;
  @JsonProperty() itemName!: string;
  @JsonProperty() itemType!: string;
  @JsonProperty() manufacturerID!: number | null;
  @JsonProperty() modifierListID!: number;
  @JsonProperty() notes!: string;
  @JsonProperty() orderGUID!: string;
  @JsonProperty() orderID!: number;
  @JsonProperty() orderItemID!: number;
  @JsonProperty() parentItemID!: string;
  @JsonProperty() photoName!: string;
  @JsonProperty() piecesBox!: number | null; //float
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  price!: string; //float
  @JsonProperty() productID!: number | null;
  @JsonProperty() qty!: number;
  @JsonProperty() onHandQuantity!: number | null;
  @JsonProperty() salesRepID!: number;
  @JsonProperty() sortOrder!: number;
  @JsonProperty() source!: string;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  subTotal!: string; //float
  @JsonProperty() userDefinedFields!: Array<string>;
  @JsonProperty() unitOfMeasure!: string;
  @JsonProperty() upc!: string;
  @JsonProperty() weight!: number | null; //float
  @JsonProperty() additionalPhotos!: string;
  @JsonProperty() reportCategory!: string;
  @JsonProperty() orderItemGUID!: string;
  @JsonProperty() map!: number; //float
  @JsonProperty() channel!: OrderItemChannelDto;
  @JsonProperty() id!: number;
  @JsonProperty() modifiedOn!: string | null; //date
  @JsonProperty() createdOn!: string | null; //date
  @JsonProperty() createdBy!: string;
  @JsonProperty() modifiedBy!: string;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty() deletedOn!: string | null; //date
  @JsonProperty() deletedBy!: string;
  @JsonProperty() destroy!: boolean;
  @JsonProperty() isDirty!: boolean;
}
