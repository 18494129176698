import { inject, injectable } from "inversify-props";
import fallback from "@/assets/images/image-placeholder.svg";
import { Utils } from "@/utils";
import type { IImagesRepository, ProfileImagesDto } from "@/types";

const imageCdn = process.env.VUE_APP_JUNIPER_IMAGE_CDN_URL;

type SetImagesArg = {
  logo: File | null;
  header: File | null;
  lifestyle1: File | null;
  lifestyle2: File | null | string;
  lifestyle3: File | null | string;
};

@injectable()
export class ImageUrlService {
  @inject() public imagesRepository!: IImagesRepository;

  public getImageOrFallback = async (imageType: keyof ProfileImagesDto) => {
    const [res, err] = await Utils.try(this.imagesRepository.get());

    if (err || res === null || !res[imageType]) {
      return { url: fallback, error: true };
    }

    return { url: `${res[imageType]}`, error: false };
  };

  public async getCompanyLogo() {
    return this.getImageOrFallback("logoImage");
  }

  public async getHeaderImage() {
    return this.getImageOrFallback("featureImage");
  }

  public async getLifestyleImages() {
    const images = [];

    for (let i = 0; i < 3; i++) {
      const image = await this.getImageOrFallback(
        `lifestyleImage${i + 1}` as keyof ProfileImagesDto
      );
      images.push(image);
    }

    return images;
  }

  public getProductImage(
    photoName: string,
    manufacturerId: number,
    query = {
      height: 75,
      width: 75,
      fit: "bounds",
    }
  ) {
    const queryString = Utils.buildQueryString(query);
    return `${imageCdn}/${manufacturerId}/${photoName}_lg.jpg${queryString}`.toLowerCase();
  }

  public async setImages({
    logo,
    header,
    lifestyle1,
    lifestyle2,
    lifestyle3,
  }: SetImagesArg) {
    const form = new FormData();
    const promiseAr = [];

    if (logo !== null) {
      form.append("CompanyLogo", logo);
    }
    if (header !== null) {
      form.append("FeatureImage", header);
    }
    if (lifestyle1 !== null) {
      form.append("LifestyleImage1", lifestyle1);
    }
    if (lifestyle2 !== null) {
      typeof lifestyle2 !== "string"
        ? form.append("LifestyleImage2", lifestyle2)
        : promiseAr.push(this.imagesRepository.delete(lifestyle2));
    }
    if (lifestyle3 !== null) {
      typeof lifestyle3 !== "string"
        ? form.append("LifestyleImage3", lifestyle3)
        : promiseAr.push(this.imagesRepository.delete(lifestyle3));
    }
    await Promise.allSettled(promiseAr);
    return await this.imagesRepository.post(form);
  }
}
