import { JsonProperty, Serializable } from "typescript-json-serializer";
import { SalesRepDto, PricingLevelAliasesDto, AddressDto } from "@/types";

@Serializable()
export class ManufacturerStaticDto {
  @JsonProperty() terms!: string[];
  @JsonProperty({ type: SalesRepDto }) salesReps!: SalesRepDto[];
  @JsonProperty() priceLevelAliases!: PricingLevelAliasesDto;
  @JsonProperty() paymentGatewayType!: string;
  @JsonProperty() address!: AddressDto;
  @JsonProperty() weightUnit!: string;
}
