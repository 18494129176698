import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingAccountServicesDto {
  @JsonProperty() services!: ShippingAccountServiceDto[];
}

@Serializable()
export class ShippingAccountServiceDto {
  @JsonProperty() carrierId!: number | null;
  @JsonProperty() externalCarrierId!: string | null;
  @JsonProperty() carrierCode!: string | null;
  @JsonProperty() serviceCode!: string | null;
  @JsonProperty() name!: string | null;
  @JsonProperty() domestic!: boolean;
  @JsonProperty() international!: boolean;
  @JsonProperty() isMultiPackageSupported!: boolean;
}
