import axios from "@/axios";
import { injectable } from "inversify-props";
import { IShippingTrackingRepository, ShippingTrackingDto } from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Shipping/Tracking`;

@injectable()
export class ShippingTrackingRepository implements IShippingTrackingRepository {
  public async getOrders(payload: {
    orders: number[];
  }): Promise<ShippingTrackingDto> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/orders`,
      serialize(payload)
    );
    return deserialize<ShippingTrackingDto>(data, ShippingTrackingDto);
  }
}
