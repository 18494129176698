
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Icon } from "@/components";
import { IconNameTypes, MainRouteTypes, NavigationItem } from "@/types";
import { OidcService } from "@/services";
import { inject } from "inversify-props";

@Options({
  components: {
    Icon,
  },
})
export default class SideNav extends Vue {
  @inject() private oidcService!: OidcService;

  @Prop({
    type: Array,
    default: [],
  })
  navigationItems!: NavigationItem[];

  private IconNameTypes = IconNameTypes;
  private subNavOpen = false;
  private subItems: NavigationItem[] = [];
  private subNavTitle = "";

  onNavigationItemHover(item: NavigationItem) {
    if (item.subItems) {
      this.subNavOpen = true;
      this.subItems = item.subItems;
      this.subNavTitle = item.name;
    } else {
      this.subNavOpen = false;
    }
  }

  private async onSignOutClick(): Promise<void> {
    if (window.localStorage.getItem("isHandshakeLogin")) {
      await this.$router.push(`/${MainRouteTypes.HandshakeSignOut}`);
    } else {
      await this.oidcService.signOut();
    }
  }
}
