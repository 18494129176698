
import { Options, Vue } from "vue-class-component";
import { TopBar, Box, DashboardComponent } from "@/components";

@Options({
  components: {
    TopBar,
    Box,
    DashboardComponent,
  },
})
export default class Dashboard extends Vue {
  private loading = false;
}
