import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77e90ff4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["name"]
const _hoisted_3 = { class: "upload-container" }
const _hoisted_4 = { class: "upload-input-container" }
const _hoisted_5 = {
  key: 0,
  class: "upload-icon"
}
const _hoisted_6 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.cmpWidth, 'upload-wrapper'])
    }, [
      _createElementVNode("label", {
        for: _ctx.name,
        class: _normalizeClass({ error: _ctx.error })
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
        (_ctx.tooltipMsg)
          ? (_openBlock(), _createBlock(_component_Tooltip, {
              key: 0,
              msg: _ctx.tooltipMsg
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  class: "tooltip-icon",
                  name: _ctx.IconNameTypes.Info,
                  size: 1
                }, null, 8, ["name"])
              ]),
              _: 1
            }, 8, ["msg"]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1),
      _createElementVNode("input", {
        type: "file",
        name: _ctx.name,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pickFile())),
        class: "hidden",
        ref: "inputRef"
      }, null, 40, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.imageEmpty)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([
              'upload-input',
              'text-sm',
              'w-full',
              {
                'images-empty': _ctx.imageEmpty,
                'drag-over': _ctx.isDraggedOver,
              },
              { error: _ctx.error },
            ]),
                onDragover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragover && _ctx.dragover(...args))),
                onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
                onDrop: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args))),
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleClickImage && _ctx.handleClickImage(...args)))
              }, [
                _createVNode(_component_Icon, {
                  class: "drop-icon",
                  name: _ctx.IconNameTypes.Upload,
                  size: 1
                }, null, 8, ["name"])
              ], 34))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([
            'upload-input',
            'text-sm',
            'w-full',
            {
              'images-empty': _ctx.imageEmpty,
              'drag-over': _ctx.isDraggedOver,
            },
            { error: _ctx.error },
          ]),
                onDragover: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.dragover && _ctx.dragover(...args))),
                onDragleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
                onDrop: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args))),
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleClickImage && _ctx.handleClickImage(...args)))
              }, [
                _createVNode(_component_Icon, {
                  class: "drop-icon",
                  name: _ctx.IconNameTypes.Upload,
                  size: 1
                }, null, 8, ["name"]),
                _createElementVNode("div", {
                  class: "img-preview",
                  style: _normalizeStyle({
              backgroundImage: 'url(' + _ctx.imageUrls + ')',
            })
                }, null, 4)
              ], 34))
        ]),
        (_ctx.canDelete)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Icon, {
                name: _ctx.IconNameTypes.Trash,
                size: 1,
                onClick: _ctx.openDeleteModal
              }, null, 8, ["name", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_Transition, { name: "slide-up" }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(["upload-message", { error: _ctx.error }])
          }, _toDisplayString(_ctx.error ? _ctx.errorMessage : _ctx.msg), 3)
        ]),
        _: 1
      })
    ], 2),
    _createVNode(_component_ConfirmationModal, {
      title: "Delete Image",
      open: _ctx.showDeleteModal,
      cancelText: "Cancel",
      confirmText: "Delete",
      onConfirm: _ctx.deleteImage,
      onCancel: _ctx.closeDeleteModal,
      onCloseModal: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showDeleteModal = false))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, "Are you sure you want to delete your " + _toDisplayString(_ctx.name) + " image?", 1)
        ])
      ]),
      _: 1
    }, 8, ["open", "onConfirm", "onCancel"])
  ], 64))
}