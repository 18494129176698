import EventEmitter from "events";
import Oidc, {
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client-ts";
import { injectable } from "inversify-props";
import isEqual from "lodash/isEqual";
import { IDecodedAuthToken, IOidcState, OidcEventTypes } from "@/types";

@injectable()
export class OidcService extends EventEmitter {
  private static instance: OidcService | null = null;
  private _state!: IOidcState;
  private _userManager: UserManager | null = null;

  public get state(): IOidcState {
    return this._state;
  }

  public get userManager(): UserManager | null {
    return this._userManager;
  }

  public set userManager(val: UserManager | null) {
    this._userManager = val;
  }

  private constructor() {
    super();
    OidcService.instance = this;
    this.setState(null); // sets initial state

    // @ts-ignore for debug only
    // window.OidcService = OidcService;
    // @ts-ignore for debug only
    // window.oidcService = this;
  }

  // Helper method to get the singleton instance
  public static getInstance(): OidcService {
    if (!OidcService.instance) {
      OidcService.instance = new OidcService();
    }

    return OidcService.instance;
  }

  // Initializes the OidcService, should be called as early on as possible on app load
  // Can be safely called in multiple places, but will only initialize once
  public initialize(config: UserManagerSettings): OidcService {
    if (this._userManager !== null) return this;
    this._userManager = new UserManager({
      ...config,
      loadUserInfo: true,
      automaticSilentRenew: false, // silent renew is handled by service
      userStore: new WebStorageStateStore({ store: localStorage }),
      stateStore: new WebStorageStateStore({ store: localStorage }),
    });
    this.attachOidcEvents();
    return this;
  }

  // Mutate the oidc state object, and emits a StateChanged event to be
  // caught by the onOidcStateChanged() callback
  public setState(newState: User | null): void {
    const prevOidcState = this._state;
    let newOidcState: IOidcState;
    if (!newState) {
      newOidcState = {
        isAuthenticated: false,
        accessToken: null,
        accessTokenExp: false,
        refreshToken: null,
        refreshTokenExp: false,
        idToken: null,
        idTokenExp: false,
        user: null,
      };
    } else {
      newOidcState = {
        accessToken: newState.access_token,
        accessTokenExp: this.tokenIsExpired(newState.access_token),
        refreshToken: newState.refresh_token ?? null,
        refreshTokenExp: newState.refresh_token
          ? this.tokenIsExpired(newState.refresh_token)
          : false,
        idToken: newState.id_token as string,
        idTokenExp: this.tokenIsExpired(newState.id_token as string),
        isAuthenticated: Boolean(newState.id_token),
        user: newState.profile as unknown as IDecodedAuthToken,
      };
    }
    const isOidcStateChanged = !isEqual(newOidcState, prevOidcState);
    if (!isOidcStateChanged) return;
    this._state = newOidcState;
    this.emit(OidcEventTypes.StateChanged, newOidcState, prevOidcState);
  }

  // Redirects user to the oidc sign in url (config.redirect_uri)
  public async signIn({
    redirectPath = "/",
  }: { redirectPath?: string } = {}): Promise<void> {
    if (!this._userManager) return;
    try {
      sessionStorage.setItem("oidc_active_route", redirectPath);
      await this._userManager.signinRedirect();
    } catch (error) {
      this.relayError("oidcService.signIn", error);
      throw error;
    }
  }

  // Handle redirects back from the oidc sign in page, will update the oidc state
  public async signInCallback(): Promise<string | null> {
    if (!this._userManager) return null;
    try {
      const user = await this._userManager.signinRedirectCallback();
      this.setState(user);
      return sessionStorage.getItem("oidc_active_route") ?? "/";
    } catch (error) {
      this.relayError("oidcService.signInCallback", error);
      await this.signOut();
      return null;
    }
  }

  // Handle signing user in silently without redirecting to sign in page
  // (creates an iframe and redirects to config.silent_redirect_uri behind the scene)
  public async signInSilent(ignoreThrow = true): Promise<void> {
    if (!this._userManager) return;
    try {
      const user = await this._userManager.signinSilent();
      this.setState(user);
    } catch (error) {
      // When it's not possible to sign in silently, try to sign user in with regular signIn
      if ((error as { message: string }).message === "login_required") {
        await this.signIn();
      } else {
        this.setState(null);
        if (!ignoreThrow) {
          this.relayError("oidcService.signInSilent", error);
          throw error;
        }
      }
    }
  }

  // Handle redirects back from config.silent_redirect_uri
  public async signInSilentCallback(): Promise<void> {
    if (!this._userManager) return;
    try {
      await this._userManager.signinSilentCallback();
    } catch (error) {
      this.relayError("oidcService.signInSilentCallback", error);
      await this.signOut();
    }
  }

  // Signs user out
  public async signOut(): Promise<void> {
    if (!this._userManager) return;
    try {
      await this._userManager.signoutRedirect();
    } catch (error) {
      this.relayError("oidcService.signOut", error);
      throw error;
    }
  }

  // Gets the current user
  public async getUser(ignoreThrow = true): Promise<Oidc.User | null> {
    if (!this._userManager) return null;
    const user = await this._userManager.getUser();
    if (user !== null) {
      this.setState(user);
      return user;
    }
    if (!ignoreThrow) {
      const error = new Error("no_current_user");
      this.relayError("oidcService.getUser", error);
      throw error;
    }
    return null;
  }

  // Re-emit userManager events with EventEmitter to be caught anywhere in the app
  // new OidcService().on(OidcEventTypes.AccessTokenExpiring, () => { // can handle the event here })
  public attachOidcEvents(): void {
    if (!this._userManager) return;
    this._userManager.events.addAccessTokenExpiring(async () => {
      this.emit(OidcEventTypes.AccessTokenExpiring);
      await this.signInSilent(); // handle automatic token renewal
    });
    this._userManager.events.addAccessTokenExpired(async () => {
      this.emit(OidcEventTypes.AccessTokenExpired);
      await this.signOut(); // In case of signInSilent didn't work
    });
    this._userManager.events.addUserSignedIn(() => {
      this.emit(OidcEventTypes.UserSignedIn);
    });
    this._userManager.events.addUserSignedOut(() => {
      this.emit(OidcEventTypes.UserSignedOut);
    });
    this._userManager.events.addUserSessionChanged(async () => {
      this.emit(OidcEventTypes.UserSessionChanged);
      const user = await this.getUser();
      if (!user) await this.signInSilent();
    });
    this._userManager.events.addSilentRenewError((error: Error) => {
      this.emit(OidcEventTypes.SilentRenewError, error);
    });
    this._userManager.events.addUserUnloaded(() => {
      this.emit(OidcEventTypes.UserUnloaded);
    });
  }

  // Emits OidcService errors as events
  public relayError(methodName: string, error?: unknown): void {
    this.emit(OidcEventTypes.Error, methodName, error, this.state);
  }

  // Helper method to check if a token is expired
  public tokenIsExpired(token: string): boolean {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const parsed = JSON.parse(window.atob(base64));
      const tokenExpiryTime = parsed.exp ? parsed.exp * 1000 : null;
      if (tokenExpiryTime) return tokenExpiryTime < new Date().getTime();
      return true;
    } catch (error) {
      this.relayError("oidcService.tokenIsExpired", error);
      return true;
    }
  }

  // Helper method to reset OidcService in unit tests
  public static resetService() {
    this.getInstance().userManager = null;
    this.getInstance().setState(null);
    OidcService.instance = null;
  }
}
