import { injectable } from "inversify-props";
import { ProductAttributesDto, IProductAttributesRepository } from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";
import { AxiosResponse } from "axios";
import axios from "@/axios";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/ProductAttributes`;

@injectable()
export class ProductAttributesRepository
  implements IProductAttributesRepository
{
  public async get(): Promise<ProductAttributesDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<ProductAttributesDto>(data, ProductAttributesDto);
  }

  public async put(
    productAttributes: ProductAttributesDto
  ): Promise<AxiosResponse<ProductAttributesDto>> {
    const payload = serialize(productAttributes);
    return axios.put(ENDPOINT_URL, payload);
  }
}
