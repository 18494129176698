import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7314a7c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "value", "checked", "disabled"]
const _hoisted_2 = { class: "flex items-center text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", null, [
    _createElementVNode("input", {
      type: "radio",
      name: _ctx.name,
      value: _ctx.value,
      checked: _ctx.value === _ctx.modelValue,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ]))
}