import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fd6f60e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-container" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "info-content"
}
const _hoisted_4 = {
  key: 0,
  class: "info-footer"
}
const _hoisted_5 = {
  key: 0,
  class: "subtext"
}
const _hoisted_6 = {
  key: 1,
  class: "tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunSpinner = _resolveComponent("JunSpinner")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_JunSpinner, {
            class: "loading-spinner",
            size: "3rem"
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'slot-wrapper': !_ctx.noPadding })
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 2),
          (_ctx.subtext || _ctx.tooltipMsg)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.subtext)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.subtext), 1))
                  : _createCommentVNode("", true),
                (_ctx.tooltipMsg)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createVNode(_component_Tooltip, {
                        position: _ctx.msgPosition,
                        msg: _ctx.tooltipMsg
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, {
                            class: "tooltip-icon",
                            name: _ctx.IconNameTypes.Info,
                            size: 1
                          }, null, 8, ["name"])
                        ]),
                        _: 1
                      }, 8, ["position", "msg"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}