import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-430d449c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-disabled", "id", "aria-expanded", "aria-controls"]
const _hoisted_2 = {
  key: 0,
  class: "spacer"
}
const _hoisted_3 = { class: "header-slot-wrapper" }
const _hoisted_4 = ["id", "aria-labelledby"]
const _hoisted_5 = {
  key: 0,
  class: "spacer"
}
const _hoisted_6 = { class: "content-slot-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "collapse-toggle",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleIsOpen && _ctx.toggleIsOpen(...args))),
      "aria-disabled": _ctx.disableToggle,
      id: _ctx.collapsibleToggleId,
      "aria-expanded": _ctx.isOpen,
      "aria-controls": _ctx.collapsiblePanelId
    }, [
      (!_ctx.hideChevronIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass([
            'chevron-icon-wrapper',
            {
              open: _ctx.isOpen,
              hidden: _ctx.disableToggle,
            },
          ])
            }, [
              _createVNode(_component_Icon, {
                name: "right-chevron",
                size: 0.875
              }, null, 8, ["size"])
            ], 2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header", { open: _ctx.isOpen }, undefined, true)
      ])
    ], 8, _hoisted_1),
    _createVNode(_Transition, {
      name: "collapse",
      onEnter: _ctx.handleCollapseEnter,
      onAfterEnter: _ctx.handleCollapseAfterEnter,
      onLeave: _ctx.handleCollapseLeave
    }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "collapse-content",
              role: "region",
              id: _ctx.collapsiblePanelId,
              "aria-labelledby": _ctx.collapsibleToggleId
            }, [
              (!_ctx.hideSpacer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "content", { open: _ctx.isOpen }, undefined, true)
              ])
            ], 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["onEnter", "onAfterEnter", "onLeave"])
  ]))
}