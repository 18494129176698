
import { Options, Vue } from "vue-class-component";
import { inject } from "inversify-props";
import { OidcService } from "@/services";
import * as Sentry from "@sentry/vue";

@Options({
  components: {},
})
export default class OidcSilentRenewCallback extends Vue {
  @inject() public oidcService!: OidcService;

  async mounted() {
    try {
      await this.oidcService.signInSilentCallback();
    } catch (error) {
      Sentry.captureMessage(`OidcSilentRenewCallback Error: ${error}`);
    }
  }
}
