
import { Options, Vue } from "vue-class-component";
import { Prop, InjectReactive } from "vue-property-decorator";
import { TableBox } from "@/components";
import { inject } from "inversify-props";
import { JunTableSortDirection } from "@juniper/ui";
import {
  TableBoxConfig,
  TopCustomerDto,
  DashboardComponentConfig,
} from "@/types";
import type { IReportsRepository, IDashboardBrand } from "@/types";

@Options({ components: { TableBox } })
export default class DashboardTopCustomersTable extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private config!: DashboardComponentConfig;

  @inject() private reportsRepository!: IReportsRepository;
  @InjectReactive() private brand!: IDashboardBrand;

  private get topCustomersTable(): TableBoxConfig<TopCustomerDto> {
    return {
      tableHeaders: [
        {
          prop: "customerName",
          text: "Customer Name",
          canSort: false,
          canFilter: false,
        },
        {
          prop: "totalSales",
          text: "Total Sales",
          canSort: false,
          canFilter: false,
        },
      ],
      hideTableHeadingBorder: true,
      pageSize: 15,
      sortDirection: JunTableSortDirection.Descending,
      sortBy: "totalSales",
      itemKey: "customerName",
      heading: "Top Customers",
      dataFetch: this.fetchData,
    };
  }

  private async fetchData() {
    return await this.reportsRepository.getTopCustomers(this.brand.brandId);
  }
}
