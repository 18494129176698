
import { mixins, Options } from "vue-class-component";
import { Prop, Watch, InjectReactive } from "vue-property-decorator";
import { inject } from "inversify-props";
import {
  AddressInfo,
  Breadcrumbs,
  Box,
  Status,
  AdminButton,
  Checkbox,
  Icon,
  ConfirmationModal,
  AssociateCustomerModal,
  AdminTextarea,
} from "@/components";
import {
  IconNameTypes,
  OrderStatusTypes,
  StatusComponentTypes,
  CarrierTypes,
  ShippingMethodTypes,
  OrderActionTypes,
  OrderDto,
  OrderTrackingDto,
  SalesRepDto,
  OrderEditModeTypes,
  PaymentMethodTypes,
} from "@/types";
import type {
  IAccessRequestsRepository,
  IAppUsersRepository,
  ICustomerPostPayload,
  INewCustomer,
  IOrdersRepository,
  IBrandOrdersRepository,
  IUpdateOrderRequest,
  IShippingTrackingRepository,
  ToastConfig,
  IShippingSettingsRepository,
} from "@/types";
import { Utils } from "@/utils";
import { namespace } from "vuex-class";
import { SegmentService } from "@/services";
import { SalesRepMixin } from "@/mixins";

const auth = namespace("authVuexModule");
const notifications = namespace("notificationsVuexModule");
const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    AddressInfo,
    Breadcrumbs,
    Box,
    Checkbox,
    Status,
    AdminButton,
    Icon,
    ConfirmationModal,
    AssociateCustomerModal,
    AdminTextarea,
  },
  emits: ["refresh-data", "edit-order", "cancel-edit"],
})
export default class OrderDetailsBox extends mixins(SalesRepMixin) {
  @inject() private ordersRepository!: IOrdersRepository;
  @inject() private brandOrdersRepository!: IBrandOrdersRepository;
  @inject() private accessRequestsRepository!: IAccessRequestsRepository;
  @inject() private appUsersRepository!: IAppUsersRepository;
  @inject() private shippingTrackingRepository!: IShippingTrackingRepository;
  @inject() private shippingSettingsRepository!: IShippingSettingsRepository;
  @inject() public segmentService!: SegmentService;

  @InjectReactive() private agencyOrderWorkflowEnabled!: boolean;

  @auth.Getter private isMultiline!: boolean | null;

  @manufacturers.Getter private isDirectSeller!: boolean | null;
  @manufacturers.Getter private isLive!: boolean | null;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;
  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  @Prop({
    type: Object,
  })
  order!: OrderDto | null;

  @Prop({
    type: Boolean,
  })
  loading!: boolean;

  @Prop({
    type: String,
  })
  orderEditMode!: OrderEditModeTypes;

  @Prop({
    type: Boolean,
  })
  canSaveItems!: boolean;

  @Prop({
    type: Boolean,
  })
  isEditing!: boolean;

  @Prop({
    type: Function,
  })
  updateOrder!: () => Promise<void>;

  @Prop({
    type: Function,
  })
  buildUpdatePayload!: (
    order: OrderDto,
    customerNumber?: string | null,
    repNumber?: string | null
  ) => IUpdateOrderRequest;

  private IconNameTypes = IconNameTypes;
  private showRejectModal = false;
  private showCancelModal = false;
  private showApproveModal = false;
  private showAssociateModal = false;
  private rejectLoading = false;
  private cancelLoading = false;
  private acceptLoading = false;
  private saveLoading = false;
  private trackingLoading = false;
  private rejectReason = "";
  private cancelReason = "";
  private approveNote = "";
  private orderTrackings: OrderTrackingDto[] | null = null;
  private shippingMethod: ShippingMethodTypes | null = null;
  private OrderActionTypes = OrderActionTypes;
  private OrderEditModeTypes = OrderEditModeTypes;
  private acceptTerms = false;

  @Watch("order", { immediate: true })
  async onOrderChange() {
    await Promise.all([this.checkTracking(), this.getShippingMethod()]);
    await this.initSalesRep({ repNumber: this.order?.repNumber });
  }

  get isMultilineAndFlagEnabled() {
    return this.isMultiline && this.agencyOrderWorkflowEnabled;
  }

  get showActions() {
    return (this.agencyOrderWorkflowEnabled || !this.isMultiline) && this.order;
  }

  get detailsLoading() {
    return this.loading || this.trackingLoading;
  }

  get orderStatusType() {
    switch (this.order?.catalogDetail.orderStatus) {
      case OrderStatusTypes.Processing:
        return StatusComponentTypes.Warning;
      case OrderStatusTypes.Approved:
        return StatusComponentTypes.Success;
      case OrderStatusTypes.Cancelled:
      case OrderStatusTypes.Rejected:
        return StatusComponentTypes.Error;
      case OrderStatusTypes.Shipped:
        return StatusComponentTypes.Success;
      case OrderStatusTypes.Paid:
        return StatusComponentTypes.Success;
      default:
        return StatusComponentTypes.Warning;
    }
  }

  get isNewOrder(): boolean {
    const { orderStatus } = this.order?.catalogDetail || {};
    return !orderStatus;
  }

  get phoneNumber(): string {
    return this.orderSalesRep?.cellPhone ?? this.orderSalesRep?.phone ?? "N/A";
  }

  get defaultCustomer(): INewCustomer {
    return {
      companyName: this.order?.shipToCompanyName,
      priceLevel: this.order?.priceLevel || 0,
      billingAddress: this.order?.billingAddress?.address1,
      billingAddress2: this.order?.billingAddress?.address2,
      billingCity: this.order?.billingAddress?.city,
      billingState: this.order?.billingAddress?.stateProvince,
      billingZipcode: this.order?.billingAddress?.postalCode,
      billingCountry: this.order?.billingAddress?.country,
      billingPhone: this.order?.contact?.phone,
      billingFax: this.order?.contact?.fax,
      shippingAddress: this.order?.shippingAddress?.address1,
      shippingAddress2: this.order?.shippingAddress?.address2,
      shippingContactName: this.order?.shippingAddress?.contact?.name,
      shippingCity: this.order?.shippingAddress?.city,
      shippingState: this.order?.shippingAddress?.stateProvince,
      shippingZipcode: this.order?.shippingAddress?.postalCode,
      shippingCountry: this.order?.shippingAddress?.country,
      shippingEmail: this.order?.shippingAddress?.contact?.email,
      shippingPhone: this.order?.shippingAddress?.contact?.phone,
      shippingFax: this.order?.shippingAddress?.contact?.fax,
    };
  }

  get orderSalesRep(): SalesRepDto | null {
    return this.order?.salesRep && this.order.salesRep.repNumber !== null
      ? this.order.salesRep
      : this.salesRep;
  }

  private get salesRepName(): string | null {
    return this.orderSalesRep
      ? Utils.getFullName(
          this.orderSalesRep.firstName,
          this.orderSalesRep.lastName
        )
      : null;
  }

  private get shippingCarrier(): CarrierTypes {
    return this.orderTrackings?.[0]?.carrierName ?? CarrierTypes.Unset;
  }

  private get trackingNumber(): string {
    return this.orderTrackings?.[0]?.trackingNumber ?? "";
  }

  private get shipDate(): string {
    return (
      this.orderTrackings?.[0]?.shipDate ??
      this.order?.catalogDetail.shipDate ??
      "N/A"
    );
  }

  // A seller can cancel an order after approval and before an invoice has been created when one of the conditions is met:
  // - Seller's shipping method is Unset
  // - Seller's shipping method is Juniper or CustomCarrier, and there are no orderTracking (Either no shipping label is purchased, or it has been cancelled)
  private get canCancelOrder(): boolean {
    if (
      !this.shippingMethod ||
      !this.order?.catalogDetail ||
      this.order.catalogDetail.orderStatus !== OrderStatusTypes.Approved
    ) {
      return false;
    }
    return Boolean(
      this.shippingMethod === ShippingMethodTypes.Unset ||
        (this.orderTrackings && !this.orderTrackings.length)
    );
  }

  private get modalConfirmText(): string {
    return this.showApproveModal
      ? "Accept"
      : this.canCancelOrder
      ? "Cancel"
      : "Reject";
  }

  private get modalTitle(): string {
    return this.modalConfirmText + " Order";
  }

  private get textAreaModelValue(): string {
    return this.showApproveModal
      ? this.approveNote
      : this.showRejectModal
      ? this.rejectReason
      : this.cancelReason;
  }

  private get modalLoading(): boolean {
    return this.showApproveModal
      ? this.acceptLoading
      : this.showRejectModal
      ? this.rejectLoading
      : this.cancelLoading;
  }

  private get modalOpen(): boolean {
    return (
      this.showRejectModal || this.showCancelModal || this.showApproveModal
    );
  }

  private get modalText(): string {
    return `The buyer will receive an email notification of the order ${
      this.showApproveModal
        ? "acceptance. Add a message to let them know about shipping delays, etc."
        : `${
            this.showRejectModal ? `rejection` : `cancellation`
          }. Add a message to let them know why.`
    }`;
  }

  private async onSaveEdit(): Promise<void> {
    this.saveLoading = true;
    const [, error] = await Utils.try(this.updateOrder());
    if (!error) {
      this.createToastSuccess({
        message: "The order has been updated successfully",
      });
      this.saveLoading = false;
      this.$emit("cancel-edit");
    } else {
      this.createToastError({
        message: "Failed to save order updates",
      });
    }
  }

  async onRejectConfirm() {
    this.rejectLoading = true;

    if (!this.order) {
      this.createToastError({
        message: "Something went wrong, please try again.",
      });
      return;
    }

    const rejectPayload: [number, string] = [
      this.order.orderID,
      this.rejectReason,
    ];
    const [data] = await Utils.try(
      this.isMultilineAndFlagEnabled
        ? this.brandOrdersRepository.reject(...rejectPayload)
        : this.ordersRepository.reject(...rejectPayload)
    );

    if (data) {
      this.onSuccess(data, "rejected");
      this.segmentService.trackOrderCancelled(data);
    }

    this.closeModal();
    this.rejectLoading = false;
  }

  async onAccept() {
    if (!this.order) {
      this.createToastError({
        message: "Something went wrong, please try again.",
      });
      return;
    }

    // buyers from ungated brands can create an order from the marketplace.
    // these buyers are not associated to a customer, check if no customer
    // id and send to a different flow for approval in that scenario
    if (!this.order.customerID) {
      await this.checkForExistingCustomer();
    } else {
      this.acceptLoading = true;

      const acceptPayload: [number, { note: string }] = [
        this.order.orderID,
        {
          note: this.approveNote,
        },
      ];
      const [data] = await Utils.try(
        this.isMultilineAndFlagEnabled
          ? this.brandOrdersRepository.accept(...acceptPayload)
          : this.ordersRepository.accept(...acceptPayload)
      );
      if (data) this.onSuccess(data, "approved");

      this.acceptLoading = false;
      this.closeModal();
    }
  }

  private async onCancelConfirm() {
    if (!this.order) return;
    this.cancelLoading = true;
    const cancelPayload: [number, string] = [
      this.order.orderID,
      this.cancelReason,
    ];
    const [data] = await Utils.try(
      this.isMultilineAndFlagEnabled
        ? this.brandOrdersRepository.cancel(...cancelPayload)
        : this.ordersRepository.cancel(...cancelPayload)
    );
    if (data) {
      this.onSuccess(data, "cancelled");
      this.segmentService.trackOrderCancelled(data);
    }
    this.closeModal();
    this.cancelLoading = false;
  }

  async checkForExistingCustomer() {
    this.acceptLoading = true;
    // check for existing customer association
    const [customer] = await Utils.try(
      this.appUsersRepository.getAppUserCustomer(
        this.order?.channel?.buyerId || null
      )
    );

    if (customer) {
      // update order with relevant customer details
      if (customer && this.order) {
        const updatedOrder = this.buildUpdatePayload(
          this.order,
          customer.customerNumber,
          customer.repNumber
        );

        const updatePayload: [string, IUpdateOrderRequest] = [
          this.order?.orderGUID || "",
          updatedOrder,
        ];
        await Utils.try(
          this.isMultilineAndFlagEnabled
            ? this.brandOrdersRepository.update(
                this.order?.orderID,
                ...updatePayload
              )
            : this.ordersRepository.update(...updatePayload)
        );

        // approve order
        const acceptPayload: [number, { note: string }] = [
          this.order?.orderID || 0,
          {
            note: this.approveNote,
          },
        ];
        const acceptResponse = this.isMultilineAndFlagEnabled
          ? await this.brandOrdersRepository.accept(...acceptPayload)
          : await this.ordersRepository.accept(...acceptPayload);

        if (acceptResponse) {
          this.onSuccess(acceptResponse, "approved");
          this.segmentService.trackOrderUpdated(acceptResponse);
        }
      }
    } else {
      this.showAssociateModal = true;
    }
    this.acceptLoading = false;
    this.closeModal();
  }

  async acceptWithExistingCustomer(customerNumber: string | null) {
    // 1. get customer details
    const customer = await this.customersRepository.get(customerNumber || "");

    // 2. associate buyer with customer
    await this.accessRequestsRepository.createAndApprove(
      Number(this.order?.channel.buyerId),
      customerNumber
    );

    // 3. update order with relevant customer details
    if (customer && this.order) {
      const updatedOrder = this.buildUpdatePayload(
        this.order,
        customer.customerNumber,
        customer.repNumber
      );

      const updatePayload: [string, IUpdateOrderRequest] = [
        this.order?.orderGUID || "",
        updatedOrder,
      ];
      await Utils.try(
        this.isMultilineAndFlagEnabled
          ? this.brandOrdersRepository.update(
              this.order?.orderID,
              ...updatePayload
            )
          : this.ordersRepository.update(...updatePayload)
      );

      // 4. approve order
      const acceptPayload: [number, { note: string }] = [
        this.order?.orderID || 0,
        {
          note: this.approveNote,
        },
      ];
      const acceptResponse = this.isMultilineAndFlagEnabled
        ? await this.brandOrdersRepository.accept(...acceptPayload)
        : await this.ordersRepository.accept(...acceptPayload);

      if (acceptResponse) {
        this.onSuccess(acceptResponse, "approved");
        this.segmentService.trackOrderUpdated(acceptResponse);
      }
    }
  }

  async acceptWithNewCustomer(customer: ICustomerPostPayload) {
    // 1. create new customer
    const newCustomer = await this.customersRepository.post(customer);

    // 2. associate buyer with customer
    await this.accessRequestsRepository.createAndApprove(
      Number(this.order?.channel.buyerId),
      customer.customerNumber || ""
    );

    // 3. update order with relevant customer details
    if (newCustomer && this.order) {
      const updatedOrder = this.buildUpdatePayload(
        this.order,
        newCustomer.customerNumber,
        newCustomer.repNumber
      );

      const updatePayload: [string, IUpdateOrderRequest] = [
        this.order?.orderGUID || "",
        updatedOrder,
      ];
      await Utils.try(
        this.isMultilineAndFlagEnabled
          ? this.brandOrdersRepository.update(
              this.order?.orderID,
              ...updatePayload
            )
          : this.ordersRepository.update(...updatePayload)
      );

      // 4. approve order
      const acceptPayload: [number, { note: string }] = [
        this.order?.orderID || 0,
        {
          note: this.approveNote,
        },
      ];
      const acceptResponse = this.isMultilineAndFlagEnabled
        ? await this.brandOrdersRepository.accept(...acceptPayload)
        : await this.ordersRepository.accept(...acceptPayload);

      if (acceptResponse) {
        this.onSuccess(acceptResponse, "approved");
        this.segmentService.trackOrderUpdated(acceptResponse);
      }
    }
  }

  private onUpdateAcceptTerms(checked: boolean): void {
    this.acceptTerms = checked;
  }

  private onShowModal(action: OrderActionTypes): void {
    switch (action) {
      case OrderActionTypes.Reject: {
        this.rejectReason = "";
        this.showRejectModal = true;
        break;
      }
      case OrderActionTypes.Cancel: {
        this.cancelReason = "";
        this.showCancelModal = true;
        break;
      }
      case OrderActionTypes.Accept: {
        this.approveNote = "";
        this.showApproveModal = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  private closeModal(): void {
    this.acceptTerms = false;
    this.showApproveModal = false;
    this.showRejectModal = false;
    this.showCancelModal = false;
  }

  onSuccess(order: OrderDto, verb: string) {
    this.createToastSuccess({
      message: `Successfully ${verb} Order #${order.orderID}`,
    });
    this.$emit("refresh-data", order);
  }

  private handleTextareaChange($event: string): void {
    this.showApproveModal
      ? (this.approveNote = $event)
      : this.showRejectModal
      ? (this.rejectReason = $event)
      : (this.cancelReason = $event);
  }

  private async handleModalConfirm(): Promise<void> {
    this.showApproveModal
      ? await this.onAccept()
      : this.showRejectModal
      ? await this.onRejectConfirm()
      : await this.onCancelConfirm();
  }

  get showAcceptTerms() {
    return (
      this.showApproveModal &&
      this.order?.catalogDetail.paymentMethod.type === PaymentMethodTypes.Terms
    );
  }

  get canShowTrackingNumber() {
    return this.order?.catalogDetail.orderStatus === OrderStatusTypes.Shipped;
  }

  get showTrackingField() {
    return (
      this.canShowTrackingNumber && this.shippingCarrier && this.trackingNumber
    );
  }

  get getOrderTrackingUrl() {
    return `${
      Utils.getShippingCarrierConfig(this.shippingCarrier)?.trackingUrl || ""
    }${this.trackingNumber}`;
  }

  private async checkTracking() {
    if (!this.order || !Number.isFinite(this.order.orderID)) return;
    await this.fetchTrackingInfo(this.order.orderID);
  }

  async fetchTrackingInfo(orderId: number) {
    this.trackingLoading = true;
    const [tracking] = await Utils.try(
      this.shippingTrackingRepository.getOrders({
        orders: [orderId],
      })
    );

    if (tracking?.ordersTracking) {
      this.orderTrackings = tracking.ordersTracking;
    }
    this.trackingLoading = false;
  }

  private async getShippingMethod(): Promise<void> {
    const [shippingSettings] = await Utils.try(
      this.shippingSettingsRepository.get()
    );
    if (!shippingSettings) return;
    this.shippingMethod = shippingSettings.shipMethod;
  }
}
