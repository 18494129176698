import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-365f64a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShippingSettings = _resolveComponent("ShippingSettings")!
  const _component_ShippingMethodInfo = _resolveComponent("ShippingMethodInfo")!
  const _component_EditableBoxSidebar = _resolveComponent("EditableBoxSidebar")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EditableBoxSidebar, {
      title: "Shipping Info",
      status: _ctx.status,
      noPadding: true,
      noShadow: true,
      saveDisabled: _ctx.saveDisabled,
      onEdit: _ctx.onEditableBoxEdit,
      onCancel: _ctx.onEditableBoxCancel,
      onSave: _ctx.onEditableBoxSave
    }, {
      sidebar: _withCtx(() => [
        _createVNode(_component_ShippingSettings, {
          status: _ctx.status,
          shippingSettings: _ctx.shippingSettings,
          selectedShipMethod: _ctx.selectedShipMethod
        }, null, 8, ["status", "shippingSettings", "selectedShipMethod"])
      ]),
      viewing: _withCtx(() => [
        _createVNode(_component_ShippingMethodInfo, {
          shippingSettings: _ctx.shippingSettings,
          selectedShipMethod: _ctx.selectedShipMethod
        }, null, 8, ["shippingSettings", "selectedShipMethod"])
      ]),
      editing: _withCtx(() => [
        _createVNode(_Transition, {
          name: "slide-up",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.currentShippingMethod)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentShippingMethod), {
                  key: 0,
                  status: _ctx.status,
                  selectedShipMethod: _ctx.selectedShipMethod,
                  shippingSettings: _ctx.shippingSettings,
                  sharedAccounts: _ctx.sharedAccounts,
                  carrierType: _ctx.carrierType,
                  onUpdateSharedCarrier: _ctx.onUpdateSharedCarrier
                }, null, 40, ["status", "selectedShipMethod", "shippingSettings", "sharedAccounts", "carrierType", "onUpdateSharedCarrier"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["status", "saveDisabled", "onEdit", "onCancel", "onSave"]),
    _createVNode(_component_ConfirmationModal, {
      title: "Warning",
      cancelText: "Cancel",
      confirmText: "Continue",
      onConfirm: _ctx.onConfirm,
      onCancel: _ctx.cancelModal,
      open: _ctx.showModal,
      loading: _ctx.modalLoading,
      onCloseModal: _ctx.closeModal
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.modalMessage), 1)
        ])
      ]),
      _: 1
    }, 8, ["onConfirm", "onCancel", "open", "loading", "onCloseModal"])
  ]))
}