import { JunToastType } from "@juniper/ui";
import { IMatchErrorConfig } from "@/types";

export const errorHandlerConfig: Array<IMatchErrorConfig> = [
  // Credit
  {
    pattern: [/should be <=/],
    toastConfig: {
      message:
        "Failed to process JuniperCredit. Customer has insufficient funds.",
      persist: true,
    },
  },
  {
    pattern: /payment required/,
    toastConfig: {
      message: "Failed to process JuniperCredit. Please contact support.",
      persist: true,
    },
  },

  // Customers
  {
    pattern: "Customer already exists",
    toastConfig: {
      message: "A customer with that customer number already exists.",
      type: JunToastType.Caution,
    },
  },
];
