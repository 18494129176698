
import { AdminButton, AdminInput, EditableBox, InfoList } from "@/components";
import { BackupDataService } from "@/services";
import type {
  IOrderingRepository,
  IValidateResponse,
  InfoListItem,
  ToastConfig,
} from "@/types";
import { EditableBoxStatusTypes, OrderMinimumsDto } from "@/types";
import { Utils, Validators } from "@/utils";
import { inject } from "inversify-props";
import { Form } from "vee-validate";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import * as yup from "yup";

const notifications = namespace("notificationsVuexModule");
const auth = namespace("authVuexModule");

@Options({
  components: {
    Form,
    EditableBox,
    AdminInput,
    InfoList,
    AdminButton,
  },
})
export default class OrderMinimumsCmp extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private orderingRepository?: IOrderingRepository;

  @auth.Getter private isMultiline!: boolean | null;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOff!: () => void;

  private validationSchema = yup.object().shape({
    order: yup.string().nullable().matches(Validators.currency, {
      message: "Order minimum must be a valid currency amount",
      excludeEmptyString: true,
    }),
    reorder: yup.string().nullable().matches(Validators.currency, {
      message: "Reorder minimum must be a valid currency amount",
      excludeEmptyString: true,
    }),
  });

  private status = EditableBoxStatusTypes.View;
  private orderMinimums: OrderMinimumsDto = new OrderMinimumsDto();
  private isProcessing = false;

  async created() {
    await this.init();
  }

  private async init() {
    this.status = EditableBoxStatusTypes.Loading;
    const [orderMinimums] = await Utils.try(this.orderingRepository?.get());
    if (!orderMinimums) return;
    this.orderMinimums = orderMinimums;
    this.status = EditableBoxStatusTypes.View;
  }

  get infoListItem(): Array<InfoListItem> {
    return [
      {
        label: "Order Minimum",
        text: this.orderMinimums.orderMinimum,
      },
      {
        label: "Reorder Minimum",
        text: this.orderMinimums.reOrderMinimum,
      },
    ];
  }

  get isAddMinimumsButtonVisible() {
    return (
      !this.hasMinimumValues && this.status === EditableBoxStatusTypes.View
    );
  }

  get hasMinimumValues() {
    return !Object.values(this.orderMinimums || {}).every(
      (item) => item === null
    );
  }

  private onAddMinimumsButtonClick() {
    this.onEditableBoxEdit();
  }

  private onEditableBoxEdit() {
    this.backupDataService?.saveBackup(this, this.orderMinimums);
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.backupDataService?.restoreBackup(this, this.orderMinimums);
    this.status = EditableBoxStatusTypes.View;
  }

  private async onEditableBoxSave() {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();

    if (validateResponse.valid && this.orderMinimums) {
      try {
        this.status = EditableBoxStatusTypes.Saving;
        await this.orderingRepository?.put(this.orderMinimums);
        this.handleAutoPublishOff();
        this.createToastSuccess?.({
          message: "Your order minimums have been successfully saved!",
        });
        this.status = EditableBoxStatusTypes.View;
      } catch (err) {
        this.status = EditableBoxStatusTypes.Edit;
      }
    }
  }
}
