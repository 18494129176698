
import { Options, Vue } from "vue-class-component";
import { AdminButton, Icon, Switch } from "@/components";
import {
  JunTableFilter,
  JunTableColumn,
  JunTableOptions,
  JunTableSortDirection,
  JunTableSortOptions,
} from "@juniper/ui";
import { BrandDto, BrandsDto } from "@/types";
import type { IBrandRepository, TableConfig, ToastConfig } from "@/types";
import { inject } from "inversify-props";
import { BackupDataService } from "@/services";
import { Watch } from "vue-property-decorator";
import { Form } from "vee-validate";
import orderBy from "lodash/orderBy";
import { namespace } from "vuex-class";
import { Utils } from "@/utils";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    Switch,
    AdminButton,
    Icon,
    Form,
  },
})
export default class Brands extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private brandRepository?: IBrandRepository;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;

  // JunTable config
  private isTableProcessing = false;
  private tableOptions: JunTableOptions = {
    sortOptions: {},
    filters: [],
  };
  private tableHeaders: JunTableColumn[] = [
    {
      prop: "publisher",
      text: "Brands",
      canSort: true,
      canFilter: true,
    },
    {
      prop: "catalogName",
      text: "Catalog Name",
      canSort: true,
      canFilter: true,
    },
    {
      prop: "catalogCode",
      text: "Catalog Code",
      canSort: true,
      canFilter: true,
    },
    {
      prop: "enabled",
      text: "Visible on the marketplace",
      canSort: false,
      canFilter: false,
    },
  ];

  private brands: Array<BrandDto> = [];
  private tableData: Array<BrandDto> = [];

  private isProcessing = false;
  private isDirty = false;
  private dirtyArray: number[] = [];

  async created() {
    await this.init();
  }

  private async init(): Promise<void> {
    this.isTableProcessing = true;
    const [brand] = await Utils.try(this.brandRepository?.get());
    if (!brand) return;
    this.setupBrandData(brand);
    this.isTableProcessing = false;
  }

  get tableConfig(): TableConfig {
    return {
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.tableData ? this.tableData : [],
      itemKey: "syndicationId",
    };
  }

  setupBrandData({ visibleForVendors, brands }: BrandsDto) {
    const brandsWithVisibility = brands.map((b) => {
      return { ...b, visible: visibleForVendors.includes(b.catalogCode || "") };
    });
    this.brands = brandsWithVisibility;
    this.tableData = brandsWithVisibility;
    this.backupDataService?.saveBackup(this, this.brands);
  }

  @Watch("dirtyArray", { deep: true })
  dirtyArrayChanged(val: Array<string>) {
    if (val.length > 0 && !this.isDirty) {
      this.isDirty = true;
    } else if (val.length === 0) {
      this.isDirty = false;
    }
  }

  private resetDirtyFlag(): void {
    this.dirtyArray = [];
  }

  private onChange(item: BrandDto) {
    const index = this.dirtyArray.findIndex((i) => i === item.syndicationId);
    if (index !== -1) {
      this.dirtyArray.splice(index, 1);
    } else {
      this.dirtyArray.push(item.syndicationId);
    }
  }

  private onCancelButtonClick() {
    this.resetDirtyFlag();
    this.backupDataService?.restoreBackup(this, this.brands);
    this.restoreAndFilterTableData();
  }

  private async onSaveButtonClick() {
    try {
      this.isProcessing = true;
      await this.brandRepository?.put(this.brands);
      this.backupDataService?.saveBackup(this, this.brands);
      this.resetDirtyFlag();
      this.restoreAndFilterTableData();
      this.createToastSuccess?.({
        message: "Your brands have been successfully saved!",
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.isProcessing = false;
    }
  }

  private onSort(sortOptions: JunTableSortOptions): void {
    Object.assign(this.tableOptions.sortOptions, sortOptions);
    Object.assign(
      this.brands,
      orderBy(
        this.brands,
        [sortOptions.sortBy],
        [sortOptions.sortDirection || JunTableSortDirection.Acsending]
      )
    );
    this.backupDataService?.saveBackup(this, this.brands);
    this.restoreAndFilterTableData();
  }

  private restoreAndFilterTableData(): void {
    this.tableData = this.brands;
    this.filterTableData(this.tableOptions.filters as Array<JunTableFilter>);
  }

  private onFilter(filters: Array<JunTableFilter>): void {
    this.filterTableData(filters);
  }

  private filterTableData(filters: Array<JunTableFilter>): void {
    Object.assign(this.tableOptions.filters, filters);

    const index = filters.length - 1;
    // filters.reverse();

    const filter = (brands: Array<BrandDto>, index: number): void => {
      const item = filters[index];

      const filteredBrands: Array<BrandDto> = brands.filter((o: BrandDto) =>
        Object.keys(o).some(() => {
          return String(o[item.prop as keyof BrandDto])
            .toLowerCase()
            .includes(item.value.toLowerCase());
        })
      );

      if (index > 0) {
        filter(filteredBrands, index - 1);
      } else {
        this.tableData = filteredBrands;
      }
    };

    if (this.brands && index > -1) {
      filter(this.brands, index);
    } else {
      this.tableData = this.brands;
    }
  }
}
