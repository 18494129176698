
import { Options, Vue } from "vue-class-component";
import "reflect-metadata";
import { Prop } from "vue-property-decorator";
import {
  IconNameTypes,
  AttributeMappingFieldData,
  UserDefinedFieldDto,
  DropdownItem,
} from "@/types";
import { Dropdown, Icon } from "@/components";

@Options({
  components: {
    Dropdown,
    Icon,
  },
  emits: ["update:modelValue"],
})
export default class AttributeMappingField extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  modelValue!: Array<AttributeMappingFieldData>;

  @Prop({
    type: [Array, null],
    default: null,
  })
  userDefinedFields!: Array<UserDefinedFieldDto>;

  private IconNameTypes = IconNameTypes;

  private getUDFDropdownItems(): Array<DropdownItem> {
    return this.userDefinedFields.map((udf: UserDefinedFieldDto) => {
      return { name: udf.name, value: udf.name };
    });
  }
}
