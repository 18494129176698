
import { Options, Vue } from "vue-class-component";
import { inject } from "inversify-props";
import { OidcService } from "@/services";
import type { IHandshakeRepository } from "@/types";

@Options({
  components: {},
})
export default class HandshakeSignOut extends Vue {
  @inject() public oidcService!: OidcService;
  @inject() private handshakeRepository!: IHandshakeRepository;

  async mounted() {
    if (window.localStorage.getItem("isHandshakeLogin")) {
      window.localStorage.removeItem("isHandshakeLogin");
    }

    const token = window.localStorage.getItem("authToken");
    if (token) await this.handshakeRepository.delete(token);

    this.oidcService.signIn();
  }
}
