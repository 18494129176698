import axios from "@/axios";
import { injectable } from "inversify-props";
import { ShippingSettingsDto, IShippingSettingsRepository } from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Profile/ShippingSettings`;

@injectable()
export class ShippingSettingsRepository implements IShippingSettingsRepository {
  public async get(): Promise<ShippingSettingsDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<ShippingSettingsDto>(data, ShippingSettingsDto);
  }
  public async put(shippingSettings: ShippingSettingsDto): Promise<void> {
    const payload = serialize(shippingSettings);
    return await axios.put(ENDPOINT_URL, payload);
  }
}
