
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Icon } from "@/components";
import { BannerTypes, IconNameTypes } from "@/types";

@Options({
  components: {
    Icon,
  },
})
export default class Banner extends Vue {
  @Prop({
    type: String as PropType<BannerTypes>,
    default: BannerTypes.Default,
  })
  type?: BannerTypes;

  @Prop({
    type: Boolean,
  })
  closable?: boolean;

  private IconNameTypes = IconNameTypes;
  private isShowing = true;

  get classes() {
    return {
      "bg-promo-brown-lite": this.type === BannerTypes.Default,
      "text-promo-brown-dark": this.type === BannerTypes.Default,
      "bg-warning-lite": this.type === BannerTypes.Warning,
      "text-warning-dark": this.type === BannerTypes.Warning,
      "bg-primary-lite": this.type === BannerTypes.Success,
      "text-primary": this.type === BannerTypes.Success,
      "bg-error-lite": this.type === BannerTypes.Error,
      "text-error": this.type === BannerTypes.Error,
    };
  }

  private onClose() {
    this.isShowing = false;
  }
}
