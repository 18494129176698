import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ff2be74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "drawer-heading"
}
const _hoisted_2 = {
  key: 0,
  class: "drawer-subheading"
}
const _hoisted_3 = { class: "mr-1" }
const _hoisted_4 = { class: "drawer-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AdminButton = _resolveComponent("AdminButton")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide-left" }, {
    default: _withCtx(() => [
      (_ctx.showDrawer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "drawer-container",
            style: _normalizeStyle({ width: _ctx.width + 'rem' })
          }, [
            _createElementVNode("div", {
              class: "drawer-bg",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args)))
            }),
            (_ctx.heading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.heading) + " ", 1),
                    (_ctx.subHeading)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.subHeading.label), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.subHeading.value), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.showCloseIcon)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        "aria-label": "Close drawer",
                        class: "drawer-close-btn",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args)))
                      }, [
                        _createVNode(_component_Icon, {
                          name: "close",
                          size: 0.875
                        }, null, 8, ["size"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass([
          'drawer-footer',
          {
            'justify-between': _ctx.secondaryAction,
            'justify-center': !_ctx.secondaryAction,
          },
        ])
            }, [
              (_ctx.secondaryAction)
                ? (_openBlock(), _createBlock(_component_AdminButton, {
                    key: 0,
                    onClick: _ctx.secondaryAction,
                    minWidth: _ctx.actionButtonWidth,
                    color: "outline",
                    inactive: _ctx.disableSecondaryAction
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.secondaryActionButtonText), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "minWidth", "inactive"]))
                : _createCommentVNode("", true),
              _createVNode(_component_AdminButton, {
                onClick: _ctx.handlePrimaryAction,
                minWidth: _ctx.actionButtonWidth,
                inactiveFilled: _ctx.disablePrimaryAction
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.primaryActionButtonText), 1)
                ]),
                _: 1
              }, 8, ["onClick", "minWidth", "inactiveFilled"])
            ], 2)
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}