
import {
  AdminInput,
  AdminTextarea,
  ConfirmationModal,
  Dropdown,
  EditableBox,
  EmailListInput,
  InfoList,
  Switch,
} from "@/components";
import { BackupDataService, IndustryCategoryService } from "@/services";
import type {
  DropdownItem,
  IBrandSettingsRepository,
  ICategoriesRepository,
  IValidateResponse,
  InfoListItem,
  ToastConfig,
} from "@/types";
import {
  CategoriesDto,
  EditableBoxStatusTypes,
  IndustryCategoryDto,
  ProfileBrandDto,
} from "@/types";
import { Utils } from "@/utils";
import { inject } from "inversify-props";
import { Form } from "vee-validate";
import { nextTick } from "vue";
import { Options, Vue } from "vue-class-component";
import { InjectReactive, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import * as yup from "yup";

const auth = namespace("authVuexModule");
const publish = namespace("publishVuexModule");
const notifications = namespace("notificationsVuexModule");
const manufacturers = namespace("manufacturersVuexModule");
const brandPreview = namespace("brandPreviewVuexModule");

@Options({
  components: {
    Form,
    EditableBox,
    AdminInput,
    AdminTextarea,
    Dropdown,
    InfoList,
    Switch,
    ConfirmationModal,
    EmailListInput,
  },
})
export default class CompanyAndContactDetails extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private brandSettingsRepository?: IBrandSettingsRepository;
  @inject() private industryCategoryService?: IndustryCategoryService;
  @inject() private categoriesRepository?: ICategoriesRepository;

  @InjectReactive() private contactEmailFieldsFlag!: boolean;
  @InjectReactive() private profileBrand!: ProfileBrandDto;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOff!: () => void;

  @auth.Getter private isMultiline?: boolean | null;

  @manufacturers.Getter private isDirectSeller?: boolean;
  @manufacturers.Action private initManufacturers?: () => Promise<void>;

  @publish.Getter private isCompanyDetailsValid?: boolean;
  @publish.Mutation private setCompanyDetailsAsValid?: () => void;

  @brandPreview.Mutation private setBrandDetailsValid!: () => void;
  @brandPreview.Mutation private setBrandDetails!: (
    brand: ProfileBrandDto
  ) => void;

  private validationSchema = (
    contactEmailFieldsEnabled: boolean,
    isDirectSeller: boolean,
    isGated: boolean
  ) => {
    return yup.object().shape({
      brandName: yup.string().nullable().required(),
      biography: yup.string().nullable().required(),
      industry: yup.string().nullable().required(),
      industryCategory: yup.array().nullable().required().min(1),
      customerEmail: contactEmailFieldsEnabled
        ? yup.string()
        : yup.string().email().nullable().required(),
      brandContactEmail: contactEmailFieldsEnabled
        ? yup.string().email().nullable().required()
        : yup.string(),
      registrationEmail: contactEmailFieldsEnabled
        ? yup.string()
        : yup.string().email().nullable().required(),
      accessRequestNotifications:
        contactEmailFieldsEnabled && isDirectSeller && isGated
          ? yup
              .array()
              .nullable()
              .min(1, "Access Request Notifications must have at least 1 email")
          : yup.array(),
      orderNotifications: contactEmailFieldsEnabled
        ? yup
            .array()
            .nullable()
            .min(1, "Order Notifications must have at least 1 email")
        : yup.array(),
      phone: yup.string().nullable().isPhone().required(),
      facebook: yup.string().nullable().url().urlHasProtocol(),
      twitter: yup.string().nullable().url().urlHasProtocol(),
      youtube: yup.string().nullable().url().urlHasProtocol(),
      instagram: yup.string().nullable().url().urlHasProtocol(),
      pinterest: yup.string().nullable().url().urlHasProtocol(),
      linkedin: yup.string().nullable().url().urlHasProtocol(),
    });
  };
  private EditableBoxStatusTypes = EditableBoxStatusTypes;
  private status = EditableBoxStatusTypes.View;
  private industries: Array<IndustryCategoryDto> | null = null;
  private industryCategories: Array<IndustryCategoryDto> | null = null;
  private categories: CategoriesDto = new CategoriesDto();
  private buyerAccessModalOpen = false;

  async created() {
    await this.init();
    if (!this.isCompanyDetailsValid) {
      this.handleInvalidForPublish();
    }
  }

  private async init(): Promise<void> {
    this.status = EditableBoxStatusTypes.Loading;
    const [categories] = await Utils.try(this.categoriesRepository?.get());
    if (!this.profileBrand || !categories) return;
    // check to see if brand info has been set to allow brand preview in actions dropdown
    if (this.profileBrand.sellerName) {
      this.setBrandDetailsValid();
      this.setBrandDetails(this.profileBrand);
    }
    this.categories = categories;
    this.industries = this.categories.industries;
    this.industryCategories = this.categories.subIndustries;
    this.status = EditableBoxStatusTypes.View;
  }

  @Watch("isCompanyDetailsValid")
  onCompanyDetailsValidChange(valid: boolean) {
    if (!valid) {
      this.handleInvalidForPublish();
    }
  }

  handleInvalidForPublish() {
    this.status = EditableBoxStatusTypes.Edit;
    // turning status to 'Edit' opens the form, so using nextTick here
    // to ensure the form has been mounted before trying to validate it
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).validate();
    });
  }

  private async onBuyerAccessUpdate(checked: boolean): Promise<void> {
    this.profileBrand.gated = checked;
    await nextTick();
    this.buyerAccessModalOpen = true;
  }

  private onBuyerAccessModalConfirm(): void {
    this.buyerAccessModalOpen = false;
  }

  private onBuyerAccessModalCancel(): void {
    this.profileBrand.gated = !this.profileBrand.gated;
    this.buyerAccessModalOpen = false;
  }

  get showGatedToggle(): boolean {
    return Boolean(
      this.status === EditableBoxStatusTypes.Edit ||
        (this.profileBrand.gated &&
          (this.isMultiline === true ||
            (this.isMultiline === false && this.isDirectSeller === true)))
    );
  }

  get infoListItem(): Array<InfoListItem> {
    const pb = this.profileBrand;
    return [
      { label: "Brand Name", text: pb?.sellerName || "" },
      { label: "Biography", text: pb?.sellerBiography || "" },
      { label: "Industry", text: pb?.industry },
      {
        label: "Industry Categories",
        list: pb?.industryCategory,
      },
      { label: "Phone Number", text: pb?.contact?.phone },
      ...(this.contactEmailFieldsFlag
        ? [
            {
              label: "Brand Contact Email",
              text: pb?.contact?.brandContactEmail,
            },
            ...(this.isDirectSeller && this.profileBrand.gated
              ? [
                  {
                    label: "Access Request Notifications",
                    text: pb?.contact?.requestNotificationEmails
                      .slice(0, 3)
                      .join(", "),
                    postText:
                      pb?.contact?.requestNotificationEmails.length > 3
                        ? `+${
                            pb?.contact?.requestNotificationEmails.length - 3
                          } more`
                        : "",
                    postTextColor: "text-primary",
                  },
                ]
              : []),
            {
              label: "Order Notifications",
              text: pb?.contact?.orderNotificationEmails.slice(0, 3).join(", "),
              postText:
                pb?.contact?.orderNotificationEmails.length > 3
                  ? `+${pb?.contact?.orderNotificationEmails.length - 3} more`
                  : "",
              postTextColor: "text-primary",
            },
          ]
        : [
            {
              label: "Customer Contact Email",
              text: pb?.contact?.customerContactEmail,
            },
            {
              label: "Contact Email for Access Request",
              text: pb?.contact?.registrationRequestContactEmail,
            },
          ]),
      { label: "Facebook", link: pb?.socialMedia?.facebook },
      { label: "Twitter", link: pb?.socialMedia?.twitter },
      { label: "YouTube", link: pb?.socialMedia?.youTube },
      { label: "Instagram", link: pb?.socialMedia?.instagram },
      { label: "Pinterest", link: pb?.socialMedia?.pinterest },
      { label: "LinkedIn", link: pb?.socialMedia?.linkedIn },
    ];
  }

  get isIndustryCategoryDisabled() {
    return this.profileBrand?.industry == null;
  }

  get industryCategoryDropdownItems() {
    // TODO FIXME: change hardcoded id at end of this funciton
    const filteredList = this.industryCategoryService?.getCategoriesByParentId(
      this.industryCategories,
      this.selectedIndustryId || 8550
    );

    return (
      filteredList?.map((industryCategory) => {
        return { name: industryCategory.name, value: industryCategory.name };
      }) ?? []
    );
  }

  // changing industry changes available subIndustries, so wipe out their current
  // subIndustry selections since they will no longer be valid
  private onIndustryChange() {
    if (this.profileBrand) {
      this.profileBrand.industryCategory = [];
    }
  }

  private get industryDropdownItems(): Array<DropdownItem> {
    if (this.industries === null) {
      return [];
    }

    return this.industries.map((industry) => {
      return { name: industry.name, value: industry.name } as DropdownItem;
    });
  }

  private get selectedIndustryId() {
    if (this.industries === null) {
      return null;
    }

    const selectedIndustry = this.industries.find((industry) => {
      return industry.name === this.profileBrand?.industry;
    });

    return selectedIndustry ? selectedIndustry.appCategoryId : null;
  }

  private get buyerAccessTooltipMsg(): string {
    let message =
      "When this toggle is turned ON, Buyers will be required to request access to your brand on the marketplace. When this toggle is turned OFF, Buyers will not need to request access to your brand on the marketplace. Buyer requests can be managed in the Buyers tab on the left-hand navigation.";
    if (!this.isMultiline) return message;
    return `${message} Your indirect vendors will mirror your buyer access setting. Your direct vendors will maintain their own buyer access settings and will not be affected by yours.`;
  }

  private get buyerAccessModalMsg(): string {
    return !this.profileBrand.gated
      ? "Turning this setting OFF will allow Buyers to place orders with your brand more easily. You will continue to have the ability to review, and approve or reject orders from Buyers. "
      : "ANDMORE thoroughly reviews and credentials all buyers. Turning this setting ON will require an extra step for Buyers to request access and wait for approval by you before they can place orders. ";
  }

  private get buyerAccessModalTitle(): string {
    return !this.profileBrand.gated ? "Great!" : "Are you sure?";
  }

  private get buyerAccessModalSubTitle(): string {
    return !this.profileBrand.gated
      ? ""
      : "WE RECOMMEND KEEPING THIS SETTING OFF";
  }

  private onEditableBoxEdit() {
    this.backupDataService?.saveBackup(this, this.profileBrand);
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.backupDataService?.restoreBackup(this, this.profileBrand);
    this.status = EditableBoxStatusTypes.View;
  }

  private async onEditableBoxSave() {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();

    if (validateResponse.valid && this.profileBrand) {
      try {
        this.status = EditableBoxStatusTypes.Saving;

        if (!this.profileBrand.gated)
          this.profileBrand.contact.requestNotificationEmails = [];

        await this.brandSettingsRepository?.put(this.profileBrand);
        this.handleAutoPublishOff();
        this.createToastSuccess?.({
          message:
            "Your company and contact details have been successfully saved!",
        });
        this.setCompanyDetailsAsValid?.();
        this.status = EditableBoxStatusTypes.View;
        // we're doing this in case someone modifies the sellerName. we're displaying
        // that sellerName inside DivisionsSelect so need the fresh data
        this.initManufacturers?.();
      } catch (err) {
        this.status = EditableBoxStatusTypes.Edit;
      }
    }
  }
}
