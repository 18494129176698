
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ActionDropdownItem } from "@/types";

enum ActionDropdownAlignment {
  Left = "left",
  Right = "right",
}

@Options({})
export default class ActionsDropdown extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  items!: ActionDropdownItem[];

  @Prop({
    type: String,
    default: ActionDropdownAlignment.Left,
  })
  align!: ActionDropdownAlignment;

  private ActionDropdownAlignment = ActionDropdownAlignment;
  private showDropdown = false;

  private onClick() {
    this.showDropdown = !this.showDropdown;
  }

  private closeDropdown() {
    this.showDropdown = false;
  }

  private onOptionClick(item: ActionDropdownItem): void {
    if (!item.disable && item.action) {
      item.action();
    }
  }
}
