
import { Options, mixins } from "vue-class-component";
import { inject } from "inversify-props";
import { JunTableSortDirection } from "@juniper/ui";
import { TableMixin } from "@/mixins";
import {
  TableConfig,
  MainRouteTypes,
  SubRouteTypes,
  CartDto,
  TableFilterTypes,
} from "@/types";
import { NoResultsForFilter } from "@/components";
import type { ICartsRepository } from "@/types";

@Options({
  components: {
    NoResultsForFilter,
  },
})
export default class TransactionsTable extends mixins(TableMixin) {
  @inject() private cartsRepository!: ICartsRepository;

  private carts: CartDto[] = [];
  private MainRouteTypes = MainRouteTypes;
  private SubRouteTypes = SubRouteTypes;

  async created() {
    this.initTable();
    await this.init({ filterVersion: TableFilterTypes.Legacy });
  }

  private initTable() {
    this.tableHeaders = [
      {
        prop: "createdOn",
        text: "Start Date",
        canSort: true,
        canFilter: false,
        width: "15%",
      },
      {
        prop: "customerName",
        text: "Customer Name",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "repNumber",
        text: "Rep #",
        canSort: true,
        canFilter: true,
        width: "15%",
      },
      {
        prop: "modifiedOn",
        text: "Last Touch",
        canSort: true,
        canFilter: false,
        width: "15%",
      },
    ];
    this.sortBy = "createdOn";
    this.sortDirection = JunTableSortDirection.Descending;
    this.pageSize = 16;
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    this.getTableData = this.getOpenCarts;
  }

  private async getOpenCarts() {
    const queryString = this.getQueryString();

    const { data, meta } = await this.cartsRepository.getAll(queryString);
    this.carts = data;

    this.tableOptions.paginationOptions = {
      page: meta.page,
      pageSize: meta.pageSize,
      totalRecords: meta.totalRecords,
    };
  }

  private onNoFilterResultsGoBack() {
    this.removeLatestFilter(TableFilterTypes.Legacy);
  }

  private get tableConfig(): TableConfig {
    return {
      heading: "Open Carts",
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.carts,
      itemKey: "id",
    };
  }
}
