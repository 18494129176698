
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Box } from "@/components";
import { TableConfig, UserDto, AppUserProfileDto } from "@/types";
import { JunTableColumn, JunTableOptions, JunTableContext } from "@juniper/ui";

@Options({
  components: {
    Box,
  },
})
export default class AssociatedBuyers extends Vue {
  private tableConfig: TableConfig | null = null;
  private isTableProcessing = false;

  @Prop({
    type: Boolean,
  })
  loading!: boolean;

  @Prop({
    type: Object,
  })
  profiles!: AppUserProfileDto[];

  @Watch("profiles")
  onProfileChange() {
    this.initTable();
  }

  buyerClick(buyer: JunTableContext<UserDto>) {
    this.$emit("buyer-click", buyer.item.appUserId);
  }

  async created() {
    this.initTable();
  }

  private initTable() {
    const options: JunTableOptions = {};

    const headers: JunTableColumn[] = [
      {
        prop: "buyerName",
        text: "Buyer Name",
        canSort: false,
        canFilter: false,
        canClick: true,
      },
      {
        prop: "emailAddress",
        text: "Email Address",
        canSort: false,
        canFilter: false,
        canClick: true,
        overflow: false,
        width: "66%",
      },
    ];

    this.tableConfig = {
      loading: this.isTableProcessing,
      headers,
      options,
      items: this.profiles ? this.profiles : [],
      itemKey: "email",
    };
  }
}
