import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class EventDto {
  @JsonProperty() occurredAt!: string | null;
  @JsonProperty() carrierOccurredAt!: string | null;
  @JsonProperty() description!: string | null;
  @JsonProperty() cityLocality!: string | null;
  @JsonProperty() stateProvince!: string | null;
  @JsonProperty() postalCode!: string | null;
  @JsonProperty() countryCode!: string | null;
  @JsonProperty() companyName!: string | null;
  @JsonProperty() signer!: string | null;
  @JsonProperty() eventCode!: string | null;
  @JsonProperty() latitude!: number;
  @JsonProperty() longitude!: number;
}
