import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class CustomerShippingAddressDto {
  @JsonProperty() shipToID!: number;
  @JsonProperty() manufacturerID!: number;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty() shipToName!: string | null;
  @JsonProperty() isDefault!: boolean;
  @JsonProperty() shipToAddress1!: string | null;
  @JsonProperty() shipToAddress2!: string | null;
  @JsonProperty() shipToCity!: string | null;
  @JsonProperty() shipToCode!: string | null;
  @JsonProperty() shipToContactName!: string | null;
  @JsonProperty() shipToCountry!: string | null;
  @JsonProperty() shipToEmail!: string | null;
  @JsonProperty() shipToFax!: string | null;
  @JsonProperty() shipToPhone!: string | null;
  @JsonProperty() shipToPostalCode!: string | null;
  @JsonProperty() shipToStateProvince!: string | null;
}
