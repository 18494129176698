import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44f50010"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs-container" }
const _hoisted_2 = {
  key: 0,
  class: "tabs"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tabs && _ctx.currentTab)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("button", {
              key: tab.type,
              class: _normalizeClass(['tab-button', { active: _ctx.currentTab?.type === tab.type }]),
              onClick: ($event: any) => (_ctx.onTabButtonClick(tab))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(tab.name) + " ", 1),
                (_ctx.showWarningIcon(tab.type))
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 0,
                      class: "warning-icon",
                      name: _ctx.IconNameTypes.Alert,
                      size: 1
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Breadcrumbs, { items: _ctx.breadcrumbPath }, null, 8, ["items"]),
      _createVNode(_Transition, {
        name: "slide-up",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.manufacturerId)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTabComponent), {
                key: _ctx.currentTab?.type,
                class: "tab"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}