export const CompanyImagesMap = {
  logo: "logo.url?",
  logoId: [
    {
      key: "logo.id",
      default: "logo",
    },
  ],
  header: "header.url?",
  headerId: [
    {
      key: "header.id",
      default: "header",
    },
  ],
  category1: "lifestyle1.url?",
  category1Id: [
    {
      key: "lifestyle1.id",
      default: "category1",
    },
  ],
  category2: "lifestyle2.url?",
  category2Id: [
    {
      key: "lifestyle2.id",
      default: "category2",
    },
  ],
  category3: "lifestyle3.url?",
  category3Id: [
    {
      key: "lifestyle3.id",
      default: "category3",
    },
  ],
};
