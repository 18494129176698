import { JsonProperty, Serializable } from "typescript-json-serializer";
import { PaymentMethodDto, AddressDto } from "@/types";

@Serializable()
export class CartCatalogDetailDto {
  @JsonProperty() cartId!: string | null;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() catalogName!: string | null;
  @JsonProperty() contactName!: string | null;
  @JsonProperty() contactEmail!: string | null;
  @JsonProperty() cancelDate!: string | null;
  @JsonProperty() doNotShipBefore!: string | null;
  @JsonProperty() poNumber!: string | null;
  @JsonProperty() shipDate!: string | null;
  @JsonProperty() shipVia!: string | null;
  @JsonProperty() shippingTotal!: number;
  @JsonProperty() subTotal!: number;
  @JsonProperty() lineItemCount!: number;
  @JsonProperty() lineItemQty!: number;
  @JsonProperty() terms!: string | null;
  @JsonProperty() paymentMethod!: PaymentMethodDto;
  @JsonProperty() shippingInfo!: AddressDto;
  @JsonProperty() freightTerms!: string | null;
  @JsonProperty() notes!: string | null;
  @JsonProperty() catalogCode!: string | null;
  @JsonProperty() vendorName!: string | null;
}
