<template>
  <div class="not-found-wrapper">
    <img src="../../assets/images/auth-error.svg" />
    <span class="my-4">Sorry! Something went wrong.</span>
    <span> Please try logging in again. </span>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class HandshakeError extends Vue {}
</script>

<style lang="scss" scoped>
.not-found {
  &-wrapper {
    @apply flex flex-col items-center justify-center w-full h-screen text-secondary-dark text-xl;
  }
}
</style>
