import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8254ba0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-bar" }
const _hoisted_2 = {
  class: "flex items-center",
  "data-test-id": "brand-wrapper"
}
const _hoisted_3 = { class: "text-4xl" }
const _hoisted_4 = {
  class: "flex items-center",
  "data-test-id": "actions-wrapper"
}
const _hoisted_5 = {
  key: 1,
  class: "mx-4 border-l border-gray-300 h-12"
}
const _hoisted_6 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AdminButton = _resolveComponent("AdminButton")!
  const _component_ActionsDropdown = _resolveComponent("ActionsDropdown")!
  const _component_DivisionSelect = _resolveComponent("DivisionSelect")!
  const _component_PublishChecklist = _resolveComponent("PublishChecklist")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!
  const _component_ProfilePreview = _resolveComponent("ProfilePreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "brand", {}, () => [
        (_ctx.showBackButton)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              name: _ctx.IconNameTypes.LeftChevron,
              class: "mr-2 cursor-pointer",
              onClick: _ctx.goBack
            }, null, 8, ["name", "onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ], true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "actions", {}, undefined, true),
      (_ctx.isActionsMenuVisible)
        ? (_openBlock(), _createBlock(_component_ActionsDropdown, {
            key: 0,
            align: "right",
            items: _ctx.actions
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AdminButton, {
                color: "primary",
                icon: _ctx.IconNameTypes.ArrowDown,
                iconSize: 0.75
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Actions ")
                ]),
                _: 1
              }, 8, ["icon", "iconSize"])
            ]),
            _: 1
          }, 8, ["items"]))
        : _createCommentVNode("", true),
      (_ctx.isActionsMenuVisible && !_ctx.hideDivisionSelect)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : _createCommentVNode("", true),
      (!_ctx.hideDivisionSelect)
        ? (_openBlock(), _createBlock(_component_DivisionSelect, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ConfirmationModal, {
      title: _ctx.publishChecklistModalTitle,
      closeOnly: "",
      open: _ctx.showPublishChecklistModal,
      onCloseModal: _ctx.closePublishChecklistModal,
      "data-test-id": "publish-checklist-modal"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", null, [
            _createVNode(_component_PublishChecklist, {
              onClose: _ctx.closePublishChecklistModal,
              paymentSettings: _ctx.paymentSettings
            }, null, 8, ["onClose", "paymentSettings"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "open", "onCloseModal"]),
    _createVNode(_component_ConfirmationModal, {
      title: "Profile Preview",
      closeOnly: "",
      open: _ctx.showProfilePreviewModal,
      onCloseModal: _ctx.closeProfilePreview,
      "data-test-id": "profile-preview-modal"
    }, {
      content: _withCtx(() => [
        _createVNode(_component_ProfilePreview, { brand: _ctx.profileBrand }, null, 8, ["brand"])
      ]),
      _: 1
    }, 8, ["open", "onCloseModal"])
  ]))
}