
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Icon, Tooltip } from "@/components";
import { IconNameTypes } from "@/types";
import type {
  HTMLElementEvent,
  AdminInputErrorEvent,
  InputModifiers,
} from "@/types";
import { Masks } from "@/utils";
import { VeeFieldMixin } from "@/mixins";

@Options({
  components: {
    Icon,
    Tooltip,
  },
  emits: ["update:modelValue", "error"],
})
export default class AdminInput extends mixins<
  [VueMixin<VeeFieldMixin<string>>]
>(VeeFieldMixin) {
  //region @Props
  @Prop({
    type: [Number, String],
    default: "",
  })
  modelValue!: string;

  @Prop({
    default: () => ({}),
  })
  modelModifiers!: InputModifiers;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: String,
    default: "",
  })
  innerLabel!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: Number,
    default: 96,
  })
  width!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  clearable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideLabels!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideArrows!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disableAutocomplete!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showCharLimit!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideErrorBox!: boolean;

  @Prop({
    type: Number,
    default: null,
  })
  maxLength!: number;

  @Prop({
    type: Number,
    default: null,
  })
  min!: number;

  @Prop({
    type: Number,
    default: null,
  })
  max!: number;

  @Prop({
    type: String,
    default: null,
  })
  msg!: string;

  @Prop({
    type: String,
    default: null,
  })
  tooltipMsg!: string;

  @Prop({
    type: String,
    default: "text",
  })
  type!: string;

  @Prop({
    type: String,
    default: "",
    required: true,
  })
  name!: string;

  @Prop({
    type: String,
    default: null,
  })
  icon!: IconNameTypes;

  @Prop({
    type: Function,
    default: null,
  })
  onBlur!: () => void;

  @Prop({
    type: Function,
    default: null,
  })
  onFocus!: () => void;
  //endregion

  // on every v-model change we need to make sure we update field state
  @Watch("modelValue")
  onModelValueChanged(val: string) {
    this.field.inputValue = val;
  }

  // when errorMessage changes emit the message as event object
  @Watch("field.errorMessage")
  onErrorChange(val: string) {
    this.$emit("error", {
      name: this.name,
      value: val,
    } as AdminInputErrorEvent);
  }

  private isInputActive = false;
  private IconNameTypes = IconNameTypes;

  getModelValue(value: string) {
    if (this.modelModifiers.phone) {
      return Masks.phone(value);
    } else if (this.modelModifiers.currency) {
      return Masks.currency(value);
    }
    return value;
  }

  get cmpWidth() {
    return this.fullWidth ? "w-full" : `w-${this.width}`;
  }

  get hideMsgBox() {
    return this.hideErrorBox && (!this.error || !this.msg);
  }

  get charLimitMsg() {
    if (this.modelValue === null) {
      return `${this.maxLength} characters remaining`;
    }
    return `${this.maxLength - this.modelValue.length} characters remaining`;
  }

  get messageContainerClass() {
    return [
      "flex",
      "w-full",
      `justify-${this.showCharLimit ? "between" : "end"}`,
    ];
  }

  onInputFocus() {
    this.isInputActive = true;

    if (this.onFocus) {
      this.onFocus();
    }
  }

  onInputBlur() {
    this.isInputActive = false;
    this.field.handleBlur();

    if (this.onBlur) {
      this.onBlur();
    }
  }

  onInputChange(e: HTMLElementEvent<HTMLInputElement>) {
    const value = this.getModelValue(e.target.value.trim());
    this.field.handleChange(value);
    this.$emit("update:modelValue", value);
  }

  focusInput() {
    const inputRef = this.$refs.inputRef as HTMLInputElement;
    inputRef.focus();
  }

  clearInput() {
    this.field.inputValue = "";
    this.$emit("update:modelValue", "");
    this.focusInput();
  }
}
