import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ProductAttributeDto {
  @JsonProperty() fieldName!: string | null;
  @JsonProperty() label!: string | null;

  public constructor(fieldName?: string | null, label?: string | null) {
    this.fieldName = fieldName || null;
    this.label = label || null;
  }
}
