import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class CustomsItemDto {
  @JsonProperty() customsItemId?: string | null;
  @JsonProperty() description!: string | null;
  @JsonProperty() quantity!: number | null;
  @JsonProperty() value!: number;
  @JsonProperty() harmonizedTariffCode?: string | null;
  @JsonProperty() countryOfOrigin!: string | null;
  @JsonProperty() unitOfMeasure!: string | null;
  @JsonProperty() sku!: string | null;
  @JsonProperty() skuDescription?: string | null;
}
