import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  IAppUsersRepository,
  AppUserCustomersDto,
  UserDto,
  AppUserCustomerDetailsDto,
  IExtendedAxiosRequestConfig,
} from "@/types";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/AppUsers`;

@injectable()
export class AppUsersRepository implements IAppUsersRepository {
  public async changeCustomerNumber(
    appUserId: number,
    customerNumber: string | null
  ): Promise<void> {
    await axios.put(
      `${ENDPOINT_URL}/${appUserId}/appUserCustomer/${customerNumber}`
    );
  }

  public async getAppUserCustomers(
    queryString: string
  ): Promise<AppUserCustomersDto> {
    const { data } = await axios.get(
      `${ENDPOINT_URL}/appUserCustomers${queryString}`
    );

    return deserialize<AppUserCustomersDto>(data, AppUserCustomersDto);
  }

  public async getAppUser(appUserId: number): Promise<UserDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${appUserId}`);

    return deserialize<UserDto>(data, UserDto);
  }

  public async getAppUserCustomer(
    appUserId: string | null
  ): Promise<AppUserCustomerDetailsDto> {
    const config: IExtendedAxiosRequestConfig = {
      suppressToastOnStatus: [404],
    };
    const { data } = await axios.get(
      `${ENDPOINT_URL}/appUserCustomerById/${appUserId}`,
      config
    );

    return deserialize<AppUserCustomerDetailsDto>(
      data,
      AppUserCustomerDetailsDto
    );
  }
}
