import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";
import {
  AppUserProfileDto,
  BillingAddressDto,
  BusinessInfoDto,
  ShippingAddressDto,
} from "@/types";

@Serializable()
export class AppUserCustomerDto {
  @JsonProperty() appId!: string | null;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  createdOn!: string | null;
  @JsonProperty() profile!: AppUserProfileDto;
  @JsonProperty() billingAddress!: BillingAddressDto;
  @JsonProperty() businessInfo!: BusinessInfoDto;
  @JsonProperty({ type: ShippingAddressDto })
  shippingAddresses!: ShippingAddressDto[];
}
