import Dashboard from "@/views/private/Dashboard";
import Orders from "@/views/private/Orders";
import Buyers from "@/views/private/Buyers";
import BuyerDetails from "@/views/private/BuyerDetails";
import LeadDetails from "@/views/private/LeadDetails";
import InvoiceDetails from "@/views/private/InvoiceDetails";
import Credit from "@/views/private/Credit";
import Settings from "@/views/private/Settings";
import OrderDetails from "@/views/private/OrderDetails";
import CartDetails from "@/views/private/CartDetails";
import NoDivisionsSetUp from "@/views/private/NoDivisionsSetUp";
import NotFound from "@/views/public/NotFound";
import OidcCallback from "@/views/public/OidcCallback";
import OidcSilentRenewCallback from "@/views/public/OidcSilentRenewCallback";
import OidcCallbackError from "@/views/public/OidcCallbackError";
import HandshakeLogin from "@/views/public/HandshakeLogin";
import HandshakeError from "@/views/public/HandshakeError";
import HandshakeSignOut from "@/views/public/HandshakeSignOut";
import BlankRedirect from "@/views/private/BlankRedirect";

export {
  Dashboard,
  Orders,
  Buyers,
  BuyerDetails,
  LeadDetails,
  InvoiceDetails,
  NotFound,
  OidcCallback,
  OidcSilentRenewCallback,
  OidcCallbackError,
  HandshakeLogin,
  HandshakeError,
  HandshakeSignOut,
  Settings,
  OrderDetails,
  CartDetails,
  Credit,
  NoDivisionsSetUp,
  BlankRedirect,
};
