import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class PricingSettingsDetailsDto {
  @JsonProperty() msrpPriceLevel?: number | null;
  @JsonProperty({
    afterSerialize: Utils.nullToZero,
  })
  defaultPriceLevel?: number | null;
  @JsonProperty() showMSRPForNonAuthenticatedUser?: boolean;
}
