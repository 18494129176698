import { JsonProperty, Serializable } from "typescript-json-serializer";
import { CarrierTypes, CountryTypes } from "@/types";

@Serializable()
export class ShippingCarrierSettingsDto {
  @JsonProperty() carrierId!: number;
  @JsonProperty() carrierName!: CarrierTypes;
  @JsonProperty() country!: CountryTypes;
  @JsonProperty() createdOn!: string;
  @JsonProperty() externalCarrierId!: string;
  @JsonProperty() isCarrierActive!: boolean;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty() isShared!: boolean;
  @JsonProperty() manufacturerConnectedOn!: string;
}
