import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize, serialize } from "typescript-json-serializer";
import {
  IShippingRatesPostPayload,
  IShippingRatesRepository,
  RatesDto,
  ShippingRatesDto,
  ShipRatesDto,
} from "@/types";
const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Shipping/Rates`;

@injectable()
export class ShippingRatesRepository implements IShippingRatesRepository {
  public async calculateRates(
    rateObj: IShippingRatesPostPayload
  ): Promise<ShippingRatesDto> {
    const payload = serialize(rateObj);
    const { data } = await axios.post(ENDPOINT_URL, payload);
    return deserialize<ShippingRatesDto>(data, ShippingRatesDto);
  }

  public async getSingleRate(rateId: string): Promise<RatesDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${rateId}`);
    return deserialize<RatesDto>(data, RatesDto);
  }

  public async getCalculatedRates(calcId: string): Promise<ShipRatesDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/Calculation/${calcId}`);
    return deserialize<ShipRatesDto>(data, ShipRatesDto);
  }
}
