
import { Vue, Options } from "vue-class-component";
import { InjectReactive, Inject, Watch } from "vue-property-decorator";
import { OrderDetailsBox, OrderItemsBox } from "@/components";
import type {
  IUpdateOrderRequest,
  IOrdersRepository,
  IBrandOrdersRepository,
} from "@/types";
import { OrderDto, OrderEditModeTypes, OrderStatusTypes } from "@/types";
import { inject } from "inversify-props";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { Utils } from "@/utils";
import { SegmentService } from "@/services";
import { namespace } from "vuex-class";

const auth = namespace("authVuexModule");

@Options({
  components: {
    OrderDetailsBox,
    OrderItemsBox,
  },
})
export default class OrderSummary extends Vue {
  @inject() private ordersRepository!: IOrdersRepository;
  @inject() private brandOrdersRepository!: IBrandOrdersRepository;
  @inject() public segmentService!: SegmentService;

  @InjectReactive() private order!: OrderDto | null;
  @InjectReactive() private orderLoading!: boolean;
  @InjectReactive() private agencyOrderWorkflowEnabled!: boolean;
  @Inject() private updateOrderState!: (state: OrderDto) => void;

  @auth.Getter private isMultiline!: boolean | null;

  private orderModelValue: OrderDto | null = null;
  private isEditing = false;

  @Watch("order", { immediate: true, deep: true })
  private onOrderChange(): void {
    this.orderModelValue = cloneDeep(this.order);
  }

  private get canSaveItems(): boolean {
    if (!this.order || !this.orderModelValue) return false;
    return !isEqual(this.order.orderItems, this.orderModelValue.orderItems);
  }

  private get orderEditMode(): OrderEditModeTypes {
    return this.order &&
      this.order.catalogDetail.orderStatus === OrderStatusTypes.Approved
      ? OrderEditModeTypes.PostApproval
      : OrderEditModeTypes.PreApproval;
  }

  private onRefreshData(data: OrderDto): void {
    this.updateOrderState(data);
  }

  private async updateOrder(): Promise<void> {
    if (!this.orderModelValue?.orderGUID) return;

    const updatePayload: [string, IUpdateOrderRequest] = [
      this.orderModelValue.orderGUID,
      this.buildUpdatePayload(this.orderModelValue),
    ];
    const updatedOrder =
      this.isMultiline && this.agencyOrderWorkflowEnabled
        ? await this.brandOrdersRepository.update(
            this.orderModelValue.orderID,
            ...updatePayload
          )
        : await this.ordersRepository.update(...updatePayload);
    this.segmentService.trackOrderUpdated(updatedOrder);
    this.onRefreshData(updatedOrder);
  }

  private buildUpdatePayload(
    order: OrderDto,
    customerNumber?: string | null,
    repNumber?: string | null
  ): IUpdateOrderRequest {
    return {
      updateOrderDetailsRequest: {
        manufacturerOrderId: order.manufacturerOrderID,
        manufacturerOrderStatus: order.manufacturerOrderStatus,
        customerNumber: customerNumber ?? order.customerNumber,
        customerID: order.customerID,
        billingAddress: order.billingAddress,
        shippingAddress: order.shippingAddress,
        customerName: order.customerName,
        contact: order.contact,
        shipToCompanyName: order.shipToCompanyName,
        cardOnFileLast4: order.last4CC,
        marketName: order.marketName,
        repNumber: repNumber ?? order.repNumber,
        salesRepID: order.salesRepID,
        freightOnBoardLast4: order.freightOnBoard,
        channel: order.channel,
      },
      updateCatalogDetailRequest: {
        notes: order.catalogDetail.notes,
        shipDate: order.catalogDetail.shipDate
          ? Utils.getDateISOString(order.catalogDetail.shipDate, {
              datePartOnly: true,
            })
          : null,
        shipVia: order.catalogDetail.shipVia,
        shippingPreferredRateCode:
          order.catalogDetail.shippingPreferredRateCode,
        shippingTotal: Utils.extractNumberFromCurrency(order.shippingTotal),
      },
      updateOrderItemsRequest: {
        orderItems: order.orderItems.map((o) => ({
          orderItemId: o.orderItemID,
          quantity: o.qty,
        })),
      },
    };
  }
}
