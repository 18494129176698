
import { Options, mixins } from "vue-class-component";
import { ChartMixin } from "@/mixins";
import { Watch, Prop, InjectReactive } from "vue-property-decorator";
import { Box, Dropdown, Icon, Checkbox } from "@/components";
import VueApexCharts from "vue3-apexcharts";
import {
  DashboardSortTypes,
  DashboardComponentConfig,
  IFormattedMonthReport,
  OrderReportDto,
} from "@/types";
import type { IDashboardBrand } from "@/types";
import { Utils } from "@/utils";

@Options({
  components: {
    Box,
    Dropdown,
    VueApexCharts,
    Icon,
    Checkbox,
  },
})
export default class DashboardSalesChart extends mixins(ChartMixin) {
  @Prop({
    type: Object,
    required: true,
  })
  private config!: DashboardComponentConfig;

  @InjectReactive() private brand!: IDashboardBrand;

  @Watch("brand.brandId", { immediate: true })
  private async onBrandIdChange(v: number, ov: number) {
    if (v !== ov) await this.init();
  }

  @Watch("compare")
  @Watch("chartSort")
  private onDataChange() {
    this.loading = true;
    this.setChartOptions();
  }

  private async init() {
    await this.fetchData(this.config.multiline, this.brand.brandId);
    this.chartId = "salesChart";
    this.setChartOptions();
  }

  private salesPoints() {
    return this.currentData.map((v, i) => {
      return {
        x: this.dateRange()[i],
        y: v,
        marker: {
          size: 0,
        },
        image:
          this.previousData[i] !== v
            ? {
                width: 15,
                height: 15,

                path:
                  this.previousData[i] > v
                    ? require("@/assets/images/dash-down-arrow.svg")
                    : require("@/assets/images/dash-up-arrow.svg"),
                offsetY: -10,
                offsetX: this.computeXOffset(),
              }
            : {},
      };
    });
  }

  private computeXOffset() {
    switch (this.chartSort) {
      case DashboardSortTypes.Week:
        return 17;
      case DashboardSortTypes.Month:
      case DashboardSortTypes.Quarter:
        return 27;
      case DashboardSortTypes.Year:
        return 10;
    }
  }

  private setChartOptions() {
    this.currentData =
      this.getCurrentData?.map((d: IFormattedMonthReport | OrderReportDto) =>
        Math.floor(d.totalSales)
      ) ?? [];
    this.previousData =
      this.getPreviousData?.map((d: IFormattedMonthReport | OrderReportDto) =>
        Math.floor(d.totalSales)
      ) ?? [];
    this.points = this.compare ? this.salesPoints() : [];
    this.dates = this.dateRange();
    this.customOptions = {
      yaxis: {
        labels: {
          formatter: (val: number) => {
            return "$" + Utils.formatNumber(val);
          },
        },
      },
    };
    this.setChart();
  }
}
