import {
  IImagesRepository,
  ProfileImageNameTypes,
  ProfileImagesDto,
  AxiosCacheIdTypes,
} from "@/types";
import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/Images`;

@injectable()
export class ImagesRepository implements IImagesRepository {
  public async post(form: FormData): Promise<void> {
    await axios.storage.remove(AxiosCacheIdTypes.ImagesRepository);
    return await axios.post(ENDPOINT_URL, form);
  }

  public async delete(name: ProfileImageNameTypes): Promise<void> {
    await axios.storage.remove(AxiosCacheIdTypes.ImagesRepository);
    return await axios.delete(`${ENDPOINT_URL}/${name}`);
  }

  public async get(): Promise<ProfileImagesDto> {
    const { data } = await axios.get(ENDPOINT_URL, {
      id: AxiosCacheIdTypes.ImagesRepository,
      cache: {
        ttl: 2 * 60 * 60 * 1000,
      },
    });
    return deserialize<ProfileImagesDto>(data, ProfileImagesDto);
  }
}
