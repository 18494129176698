import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize } from "typescript-json-serializer";
import {
  ITransactionsRepository,
  TransactionListDto,
  ChargeDto,
} from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Credit/Transactions`;

@injectable()
export class TransactionsRepository implements ITransactionsRepository {
  public async getAll(
    sellerId: string,
    queryString = ""
  ): Promise<TransactionListDto> {
    const { data } = await axios.get(
      `${ENDPOINT_URL}/${sellerId}${queryString}`
    );
    return deserialize<TransactionListDto>(data, TransactionListDto);
  }

  public async charge(orderGuid: string, buyerId: string): Promise<ChargeDto> {
    const { data } = await axios.post(`${ENDPOINT_URL}/charge`, {
      orderGuid: orderGuid,
      buyerReferenceId: buyerId,
    });
    return deserialize<ChargeDto>(data, ChargeDto);
  }
}
