
import { Options, Vue } from "vue-class-component";
import { Prop, Watch, Emit } from "vue-property-decorator";
import { IconNameTypes } from "@/types";
import { Icon, ImageCarousel, Modal } from "@/components";

@Options({
  components: {
    Modal,
    Icon,
    ImageCarousel,
  },
  emits: ["close-modal"],
})
export default class ImagePreview extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: Array,
    // TODO FIXME: add some sort of default 'not found' image here?
    default: [],
    required: true,
  })
  urls!: string[];

  @Prop({
    type: String,
    default: IconNameTypes.Media,
  })
  iconName!: IconNameTypes;

  private showModal = this.open;

  @Watch("open")
  onOpenChanged(val: boolean) {
    this.showModal = val;
  }

  @Emit()
  closeModal() {
    this.showModal = false;
  }

  openModal() {
    if (!this.imagesEmpty) {
      this.showModal = true;
    }
  }

  onCloseClick() {
    this.showModal = false;
    this.$emit("close-modal");
  }

  get imagesEmpty() {
    return this.urls.length === 0;
  }

  get useCarouselPreview() {
    return this.urls.length > 1;
  }
}
