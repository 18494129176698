import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51e1eaa2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "orders-table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunTag = _resolveComponent("JunTag")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TableBox = _resolveComponent("TableBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableBox, {
      pill: "",
      config: _ctx.ordersTable
    }, _createSlots({
      actions: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: `/${_ctx.MainRouteTypes.Orders}/${_ctx.SubRouteTypes.AllOrders}`,
          class: "tableAction"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" View All ")
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      default: _withCtx(({ item, header }) => [
        (header.prop === 'orderStatus')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.orderStatus ?? "New"), 1))
          : _createCommentVNode("", true),
        (header.prop === 'orderId' || header.prop === 'brandOrderId')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: _ctx.orderLink(item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.orderId(item)), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true),
        (header.prop === 'invoiceNumber')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: `/${_ctx.MainRouteTypes.InvoiceDetails}/${item.invoiceId}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.invoiceNumber), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.brand.brandName && !_ctx.removeFilter)
        ? {
            name: "info",
            fn: _withCtx(() => [
              _createVNode(_component_JunTag, {
                pill: "",
                removable: "",
                onRemove: _ctx.onRemove,
                class: "ml-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.brand.brandName), 1)
                ]),
                _: 1
              }, 8, ["onRemove"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["config"])
  ]))
}