import { injectable } from "inversify-props";
import { IndustryCategoryDto, ProductCategoryDto } from "@/types";

@injectable()
export class IndustryCategoryService {
  public getCategoriesByParentId(
    industryCategories: Array<IndustryCategoryDto> | null,
    parentId: number
  ): Array<IndustryCategoryDto> {
    return industryCategories
      ? industryCategories.filter(
          (category) => category.industryAppCategoryId === parentId
        )
      : [];
  }

  public getIndustryName(
    industries: Array<IndustryCategoryDto> | null,
    id?: number | null
  ): string | null {
    return id
      ? (
          industries?.find(
            (ic) => ic.appCategoryId === id
          ) as IndustryCategoryDto
        ).name
      : "";
  }

  public getIndustryId(
    industries: Array<IndustryCategoryDto> | null,
    name?: string | null
  ): number {
    return name
      ? (industries?.find((ic) => ic.name === name) as IndustryCategoryDto)
          .appCategoryId
      : 0;
  }

  public getSubcategoryName(
    subcategories: Array<IndustryCategoryDto> | null,
    id?: number | null
  ): string | null {
    return id
      ? (
          subcategories?.find(
            (ic) => ic.appCategoryId === id
          ) as IndustryCategoryDto
        ).name
      : "";
  }

  public getProductCategoryId(
    productCategories: Array<ProductCategoryDto> | null,
    name?: string
  ): number {
    return name
      ? (
          productCategories?.find(
            (ic) => ic.name === name
          ) as ProductCategoryDto
        ).categoryId
      : 0;
  }
}
