import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class CustomerDto {
  @JsonProperty() customerId!: number;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() name!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty() address1!: string | null;
  @JsonProperty() address2!: string | null;
  @JsonProperty() authorizedDealer!: boolean;
  @JsonProperty() city!: string | null;
  @JsonProperty() contactEmail!: string | null;
  @JsonProperty() contactName!: string | null;
  @JsonProperty() contactPhone!: string | null;
  @JsonProperty() country!: string | null;
  @JsonProperty() fax!: string | null;
  @JsonProperty() phone!: string | null;
  @JsonProperty() postalCode!: string | null;
  @JsonProperty() repNumber!: string | null;
  @JsonProperty() shipToAddress1!: string | null;
  @JsonProperty() shipToAddress2!: string | null;
  @JsonProperty() shipToCity!: string | null;
  @JsonProperty() shipToCode!: string | null;
  @JsonProperty() shipToContactName!: string | null;
  @JsonProperty() shipToCountry!: string | null;
  @JsonProperty() shipToEmail!: string | null;
  @JsonProperty() shipToFax!: string | null;
  @JsonProperty() shipToPhone!: string | null;
  @JsonProperty() shipToPostalCode!: string | null;
  @JsonProperty() shipToStateProvince!: string | null;
  @JsonProperty() source!: string | null;
  @JsonProperty() stateProvince!: string | null;
  @JsonProperty() priceLevel!: number;
}
