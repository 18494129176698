import {
  ActionsMenuActionConfig,
  ActionsMenuActionTypes,
  MainRouteTypes,
  SubRouteTypes,
} from "@/types";

// const juniperDataName = "JuniperData";
// const webManagerName = "WebManager";

const publishToMarketOption = {
  name: "Publish to the marketplace",
  action: ActionsMenuActionTypes.PublishToMarket,
};
// const genericJuniperDataNameOption = {
//   name: juniperDataName,
//   action: ActionsMenuActionTypes.NavigateToJuniperData
// };
// const genericWebManagerNameOption = {
//   name: webManagerName,
//   action: ActionsMenuActionTypes.NavigateToWebManager
// };

export const actionsMenuConfig: Array<ActionsMenuActionConfig> = [
  {
    routes: [
      `/${MainRouteTypes.Settings}/${SubRouteTypes.PriceMapping}`,
      `/${MainRouteTypes.Settings}/${SubRouteTypes.ProductTemplate}`,
      `/${MainRouteTypes.Settings}/${SubRouteTypes.AttributeMapping}`,
      `/${MainRouteTypes.Settings}/${SubRouteTypes.Shipping}`,
    ],
    options: [
      publishToMarketOption,
      // genericJuniperDataNameOption,
      // genericWebManagerNameOption
    ],
  },
  {
    routes: [`/${MainRouteTypes.Settings}/${SubRouteTypes.Profile}`],
    options: [
      publishToMarketOption,
      {
        name: "Preview Profile",
        action: ActionsMenuActionTypes.OpenBrandPreview,
      },
    ],
  },
  {
    routes: [`/${MainRouteTypes.Settings}/${SubRouteTypes.CategoryMapping}`],
    options: [
      publishToMarketOption,
      // {
      //   name: `${juniperDataName} Categories`,
      //   action: ActionsMenuActionTypes.NavigateToJuniperDataCategories
      // },
      // genericWebManagerNameOption
    ],
  },
  {
    routes: [`/${MainRouteTypes.Settings}/${SubRouteTypes.ProductSets}`],
    options: [
      publishToMarketOption,
      // {
      //   name: `${juniperDataName} Product Sets`,
      //   action: ActionsMenuActionTypes.NavigateToJuniperDataProductSets
      // },
      // genericWebManagerNameOption
    ],
  },
  {
    routes: [`/${MainRouteTypes.Settings}/${SubRouteTypes.Brands}`],
    options: [
      publishToMarketOption,
      // {
      //   name: `${juniperDataName} Vendors`,
      //   action: ActionsMenuActionTypes.NavigateToJuniperDataVendors
      // },
      // genericWebManagerNameOption
    ],
  },
  {
    routes: [`/${MainRouteTypes.Settings}/${SubRouteTypes.Payment}`],
    options: [
      publishToMarketOption,
      // genericJuniperDataNameOption,
      // {
      //   name: `${webManagerName} Payments`,
      //   action: ActionsMenuActionTypes.NavigateToWebManagerPayments
      // }
    ],
  },
  {
    routes: [`/${MainRouteTypes.OrderDetails}/${SubRouteTypes.Summary}`],
    options: [
      //publishToMarketOption
      // genericJuniperDataNameOption,
      {
        name: "Print Order",
        action: ActionsMenuActionTypes.PrintOrder,
      },
    ],
  },
  {
    routes: [`/${MainRouteTypes.InvoiceDetails}/`],
    options: [
      {
        name: "Print Invoice",
        action: ActionsMenuActionTypes.PrintInvoice,
      },
    ],
  },
  {
    routes: [`/${MainRouteTypes.Orders}/${SubRouteTypes.AllOrders}`],
    options: [
      {
        name: "Export & Download Data",
        action: ActionsMenuActionTypes.ExportOrders,
      },
    ],
  },
  // {
  //   routes: [
  //     `/${MainRouteTypes.Buyers}/${SubRouteTypes.Leads}`,
  //     `/${MainRouteTypes.Buyers}/${SubRouteTypes.Active}`
  //   ],
  //   options: [
  //     {
  //       name: `${webManagerName} Customers`,
  //       action: ActionsMenuActionTypes.NavigateToWebManagerCustomers
  //     }
  //   ]
  // }
];
