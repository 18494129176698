import {
  AppPaymentMethodDto,
  ShippingAddressDto,
  SocialMediaLinkDto,
  ProfileDto,
  BusinessInfoDto,
  AddressDto,
} from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class UserDto {
  @JsonProperty() modifiedOn!: string;
  @JsonProperty() createdOn!: string;
  @JsonProperty() createdBy!: string;
  @JsonProperty() modifiedBy!: string;
  @JsonProperty() deletedOn!: string;
  @JsonProperty() deletedBy!: string;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty({ type: AppPaymentMethodDto })
  paymentMethods!: Array<AppPaymentMethodDto>;
  @JsonProperty({ type: ShippingAddressDto })
  shippingAddresses!: Array<ShippingAddressDto>;
  @JsonProperty({ type: SocialMediaLinkDto })
  socialMediaLinks!: Array<SocialMediaLinkDto>;
  @JsonProperty() appUserId!: number;
  @JsonProperty() appId!: string;
  @JsonProperty() profile!: ProfileDto;
  @JsonProperty() businessInfo!: BusinessInfoDto;
  @JsonProperty() billingAddress!: AddressDto;
  @JsonProperty() status!: number | string;
}
