import { AxiosResponse } from "axios";
import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  CategoriesDto,
  ICategoriesRepository,
  MappingsCategoryDto,
} from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/Categories`;

@injectable()
export class CategoriesRepository implements ICategoriesRepository {
  public async get(): Promise<CategoriesDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<CategoriesDto>(data, CategoriesDto);
  }

  public async put(
    mappingsCategories: Array<MappingsCategoryDto>
  ): Promise<AxiosResponse<unknown>> {
    const payload = mappingsCategories.map((mc: MappingsCategoryDto) => {
      return serialize(mc);
    });
    return axios.put(ENDPOINT_URL, payload);
  }
}
