import { EventTypes, FeatureFlagTypes, IFeatureFlag } from "@/types";
import { EventEmitter } from "events";

interface IFeatureFlagServiceEventsMap {
  [EventTypes.FeatureFlagChange]: IFeatureFlag;
  [EventTypes.FeatureFlagError]: unknown;
}

export interface IFeatureFlagService extends EventEmitter {
  initialize(initialFlagIds?: FeatureFlagTypes[]): Promise<void>;

  on<K extends keyof IFeatureFlagServiceEventsMap>(
    event: K,
    listener: (args: IFeatureFlagServiceEventsMap[K]) => void
  ): this;

  emit<K extends keyof IFeatureFlagServiceEventsMap>(
    event: K,
    args: IFeatureFlagServiceEventsMap[K]
  ): boolean;
}
