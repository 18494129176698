import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b833d290"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(['icon', `juniper-${_ctx.name}`, { disabled: _ctx.disabled }]),
    style: _normalizeStyle({ height: _ctx.size + 'rem', width: _ctx.size + 'rem' })
  }, [
    _createElementVNode("use", {
      "xlink:href": `#juniper-${_ctx.name}`
    }, null, 8, _hoisted_1)
  ], 6))
}