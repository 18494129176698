import { injectable } from "inversify-props";
import { Vue } from "vue-class-component";
import cloneDeep from "lodash/cloneDeep";
import { IComponentDataBackup } from "@/types";

@injectable()
export class BackupDataService {
  public saveBackup<T>(instance: Vue, entity: T): void {
    const backup = cloneDeep(entity);
    Object.assign(instance, { backup });
  }

  public restoreBackup<T>(instance: Vue, entity: T): void {
    const backup = cloneDeep(
      (instance as Vue & IComponentDataBackup<T>).backup
    );
    Object.assign(entity, backup);
  }
}
