import { AddressDto, LinkDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingAddressDto extends AddressDto {
  @JsonProperty() appUserShippingAddressId!: number;
  @JsonProperty() appUserId!: number;
  @JsonProperty() defaultAddress!: boolean;
  @JsonProperty({ type: LinkDto }) links?: Array<LinkDto>;
}
