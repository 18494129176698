import { JsonProperty, Serializable } from "typescript-json-serializer";
import { WeightDto, CollectOnDeliveryOptionsDto } from "@/types";

@Serializable()
export class ShipmentAdvancedOptionsDto {
  @JsonProperty() billToAccount!: string | null;
  @JsonProperty() billToCountryCode!: string | null;
  @JsonProperty() billToParty!: string | null;
  @JsonProperty() billToPostalCode!: string | null;
  @JsonProperty() containsAlcohol!: boolean;
  @JsonProperty() deliveredDutyPaid!: boolean;
  @JsonProperty() dryIce!: boolean;
  @JsonProperty() dryIceWeight!: WeightDto;
  @JsonProperty() nonMachinable!: boolean;
  @JsonProperty() saturdayDelivery!: boolean;
  @JsonProperty() useUpsGroundFreightPricing!: boolean;
  @JsonProperty() freightClass!: string;
  @JsonProperty() customField1!: string | null;
  @JsonProperty() customField2!: string | null;
  @JsonProperty() customField3!: string | null;
  @JsonProperty() originType!: string | null;
  @JsonProperty() collectOnDelivery!: CollectOnDeliveryOptionsDto;
}
