
import { Options, Vue } from "vue-class-component";
import { InjectReactive, Watch } from "vue-property-decorator";
import { inject } from "inversify-props";
import { ImageUrlService, OidcService, SegmentService } from "@/services";
import { AdminInput, Icon } from "@/components";
import {
  IconNameTypes,
  ManufacturerDetailsDto,
  MainRouteTypes,
  ProfileBrandDto,
} from "@/types";
import type { DivisionSelectItem, IBrandSettingsRepository } from "@/types";
import { namespace } from "vuex-class";

const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    AdminInput,
    Icon,
  },
})
export default class DivisionSelect extends Vue {
  @inject() private imageUrlService!: ImageUrlService;
  @inject() public segmentService!: SegmentService;
  @inject() public brandSettingsRepository!: IBrandSettingsRepository;
  @inject() private oidcService!: OidcService;

  @InjectReactive() private profileBrand!: ProfileBrandDto;

  @manufacturers.Getter private manufacturerId!: number | null;
  @manufacturers.Getter private manufacturersDetails!:
    | ManufacturerDetailsDto[]
    | undefined;
  @manufacturers.Mutation private setManufacturerId!: (
    manufacturerId: number
  ) => void;

  public static isInitialMounted = false;
  private IconNameTypes = IconNameTypes;
  private showDropdown = false;
  private loading = false;
  private errorFetchingDivisions = false;
  private companyLogo = "";
  private divisions: DivisionSelectItem[] = [];
  private query = "";

  mounted() {
    this.setCompanyLogo();

    if (!DivisionSelect.isInitialMounted) {
      this.trackManufacturer();
      DivisionSelect.isInitialMounted = true;
    }

    if (this.manufacturersDetails) {
      this.setDivisionInfo(this.manufacturersDetails);
    }
  }

  get filteredDivisions() {
    return this.divisions.filter((division: DivisionSelectItem) => {
      return (
        division.name?.toLowerCase().includes(this.query.toLowerCase()) &&
        !division.selected
      );
    });
  }

  get currentDivision() {
    return this.divisions.find((item) => item.selected);
  }

  get isMultiDivision(): boolean {
    return this.divisions.length > 1;
  }

  @Watch("manufacturersDetails", { deep: true })
  onManufacturersDetailsChanged(details: ManufacturerDetailsDto[]) {
    if (details) {
      this.setDivisionInfo(details);
    } else {
      this.errorFetchingDivisions = true;
    }
  }

  @Watch("manufacturerId")
  onManufacturerIdChange() {
    this.setCompanyLogo();

    if (this.manufacturersDetails) {
      this.setDivisionInfo(this.manufacturersDetails);
    }
  }

  async setCompanyLogo() {
    const { url } = await this.imageUrlService.getCompanyLogo();
    this.companyLogo = url;
  }

  private openDropdown() {
    this.showDropdown = true;
    this.$nextTick(() => {
      const searchRef = this.$refs.searchRef as typeof AdminInput;
      // input won't appear if in loading or error state
      if (searchRef) {
        searchRef.focusInput();
      }
    });
  }

  private setDivisionInfo(data: ManufacturerDetailsDto[]) {
    const divisions: DivisionSelectItem[] = [];

    data.forEach((division) => {
      divisions.push({
        name: division.settings.brandSettings.sellerName,
        value: division.manufacturerId,
        selected: division.manufacturerId === this.manufacturerId,
      });
    });

    // want the selected item to always be at the top of list
    this.divisions = divisions.sort(
      (a, b) => Number(b.selected) - Number(a.selected)
    );
  }

  private async onSignOutClick(): Promise<void> {
    if (window.localStorage.getItem("isHandshakeLogin")) {
      await this.$router.push(`/${MainRouteTypes.HandshakeSignOut}`);
    } else {
      await this.oidcService.signOut();
    }
  }

  private closeDropdown() {
    this.showDropdown = false;
    this.query = "";
  }

  private onDivisionClick(item: DivisionSelectItem) {
    this.setManufacturerId(item.value);
    this.closeDropdown();
    this.trackManufacturer();
  }

  private async trackManufacturer() {
    if (this.manufacturerId === null || !this.profileBrand) return;
    this.segmentService.setGroup(this.manufacturerId, this.profileBrand);
  }
}
