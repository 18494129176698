
import {
  ActionsDropdown,
  AdminButton,
  ConfirmationModal,
  DivisionSelect,
  Icon,
  ProfilePreview,
  PublishChecklist,
} from "@/components";
import { actionsMenuConfig } from "@/config";
import type {
  ActionDropdownItem,
  ActionsMenuAction,
  ActionsMenuActionConfig,
  IAutoPublishRepository,
  IComponentPublishStatus,
  IPaymentSettingsRepository,
  IPublishStatus,
  ToastConfig,
} from "@/types";
import {
  ActionsMenuActionTypes,
  IconNameTypes,
  MainRouteTypes,
  ManufacturerDetailsDto,
  PaymentSettingsDto,
  ProfileBrandDto,
  SellerDto,
  SubRouteTypes,
} from "@/types";
import { Utils } from "@/utils";
import { AxiosResponse } from "axios";
import { inject } from "inversify-props";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const notifications = namespace("notificationsVuexModule");
const manufacturers = namespace("manufacturersVuexModule");
const publish = namespace("publishVuexModule");
const brandPreview = namespace("brandPreviewVuexModule");
const exportOrders = namespace("exportOrdersVuexModule");
const auth = namespace("authVuexModule");
const seller = namespace("sellerVuexModule");

@Options({
  components: {
    Icon,
    AdminButton,
    ActionsDropdown,
    DivisionSelect,
    ConfirmationModal,
    PublishChecklist,
    ProfilePreview,
  },
})
export default class TopBar extends Vue {
  @inject() private autoPublishRepository!: IAutoPublishRepository;
  @inject() private paymentSettingsRepository?: IPaymentSettingsRepository;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOn!: () => Promise<void>;

  @publish.Mutation private setStatus!: (status: IPublishStatus | null) => void;

  @exportOrders.Getter private selectedOrderIds!: number[];
  @exportOrders.Action
  private getOrdersData!: () => Promise<AxiosResponse | null>;

  @manufacturers.Getter private manufacturersDetails!:
    | ManufacturerDetailsDto[]
    | undefined;
  @manufacturers.Getter private isLive!: boolean | null;
  @manufacturers.Getter private isDivisionsSetUp!: boolean;

  @brandPreview.Getter private isProfileValid!: boolean;
  @brandPreview.Getter private profileBrand!: ProfileBrandDto | null;
  @brandPreview.Getter private showProfilePreviewModal!: boolean;
  @brandPreview.Mutation private setShowProfilePreviewModal!: (
    show: boolean
  ) => void;

  @auth.Getter private isMultiline!: boolean | null;

  @seller.Getter seller?: SellerDto | null;

  @Prop({
    type: String,
  })
  title!: string;

  @Prop({
    type: Boolean,
  })
  showBackButton!: boolean;

  @Prop({
    type: String,
  })
  backPath!: string;

  @Prop({
    type: Boolean,
  })
  hideDivisionSelect!: boolean;

  private showPublishChecklistModal = false;
  private publishStatus: IPublishStatus | null = null;
  private IconNameTypes = IconNameTypes;
  private paymentSettings: PaymentSettingsDto | null = null;

  private async publish() {
    const pubStatus = await this.autoPublishRepository.getStatus();
    this.publishStatus = pubStatus;

    const valid = Object.keys(pubStatus.subComponents).every(
      (key) => pubStatus.subComponents[key as keyof IComponentPublishStatus]
    );

    if (valid && !pubStatus.isError) {
      await this.handleAutoPublishOn();
      this.createToastSuccess({
        message: "Your settings have been successfully published!",
      });
    } else if (pubStatus.isValidationError) {
      await this.$router.push(
        `/${MainRouteTypes.Settings}/${SubRouteTypes.Profile}`
      );
    }

    if (!this.isMultiline) {
      const queryString = Utils.buildQueryString({ isMultiline: false });
      const [paySettings] = await Utils.try(
        this.paymentSettingsRepository?.get(queryString)
      );
      if (paySettings && paySettings.length) {
        this.paymentSettings = paySettings[0];
      }
    }

    // show publish checklist modal if specifically a tab validation error
    // or meets conditions for payment reminders
    if (
      (!pubStatus.isError && this.needsPaymentReminders) ||
      pubStatus.isValidationError
    ) {
      this.showPublishChecklistModal = true;
    }
  }

  private get actions(): Array<ActionDropdownItem> {
    const config: ActionsMenuActionConfig | undefined = actionsMenuConfig.find(
      (config: ActionsMenuActionConfig) => {
        let flag = false;
        config.routes.forEach((x) => {
          if (this.$router.currentRoute.value.path.startsWith(x)) {
            flag = true;
          }
        });
        return flag;
      }
    );

    const actions: Array<ActionDropdownItem> = [];
    if (config) {
      config.options.forEach((value: ActionsMenuAction) => {
        const item: ActionDropdownItem = {};
        item.name = value.name;
        switch (value.action) {
          case ActionsMenuActionTypes.PublishToMarket:
            item.action = this.publish;
            item.disable = !this.isLive;
            break;
          case ActionsMenuActionTypes.OpenBrandPreview:
            item.action = this.openProfilePreview;
            item.disable = !this.isProfileValid;
            break;
          case ActionsMenuActionTypes.ExportOrders:
            item.action = this.downloadOrders;
            item.disable = !this.selectedOrderIds.length;
            break;
          case ActionsMenuActionTypes.NavigateToJuniperData:
            item.action = () => {
              window.open(process.env.VUE_APP_JUNIPER_DATA_URL);
            };
            break;
          case ActionsMenuActionTypes.NavigateToJuniperDataProductSets:
            item.action = () => {
              window.open(
                `${process.env.VUE_APP_JUNIPER_DATA_URL}/product-manager/product-sets`
              );
            };
            break;
          case ActionsMenuActionTypes.NavigateToJuniperDataCategories:
            item.action = () => {
              window.open(
                `${process.env.VUE_APP_JUNIPER_DATA_URL}/product-manager/categories`
              );
            };
            break;
          case ActionsMenuActionTypes.NavigateToJuniperDataVendors:
            item.action = () => {
              window.open(`${process.env.VUE_APP_JUNIPER_DATA_URL}/vendors`);
            };
            break;
          case ActionsMenuActionTypes.NavigateToWebManager:
            item.action = () => {
              window.open(process.env.VUE_APP_WEB_MANAGER_URL);
            };
            break;
          case ActionsMenuActionTypes.NavigateToWebManagerPayments:
            item.action = () => {
              window.open(
                `${process.env.VUE_APP_WEB_MANAGER_URL}/appsettings/acceptpayments`
              );
            };
            break;
          case ActionsMenuActionTypes.NavigateToWebManagerCustomers:
            item.action = () => {
              window.open(
                `${process.env.VUE_APP_WEB_MANAGER_URL}/customers/editor`
              );
            };
            break;
          case ActionsMenuActionTypes.PrintOrder:
            item.action = () => {
              window.print();
            };
            break;
          case ActionsMenuActionTypes.PrintInvoice:
            item.action = () => {
              window.print();
            };
            break;
        }
        actions.push(item);
      });
    }
    return actions;
  }

  get needsPaymentReminders(): boolean {
    if (this.isMultiline || !this.paymentSettings) return false;
    return Boolean(!this.paymentSettings.acceptCreditCards);
  }

  get publishChecklistModalTitle(): string {
    return this.publishStatus?.isValidationError
      ? "Publish Failed"
      : "Publish Checklist";
  }

  get isActionsMenuVisible(): boolean {
    return this.actions.length > 0 && this.isDivisionsSetUp;
  }

  @Watch("publishStatus", { deep: true })
  onStatusChange(status: IPublishStatus | null) {
    this.setStatus(status);
  }

  private openProfilePreview() {
    this.setShowProfilePreviewModal(true);
  }

  private closeProfilePreview() {
    this.setShowProfilePreviewModal(false);
  }

  private closePublishChecklistModal() {
    this.showPublishChecklistModal = false;
  }

  private async downloadOrders() {
    const res = await this.getOrdersData();
    if (res) {
      Utils.downloadBlobFromResponse(res);
    }
  }

  goBack() {
    if (this.backPath) {
      this.$router.push(this.backPath);
    } else {
      this.$router.go(-1);
    }
  }
}
