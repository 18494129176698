
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class Breadcrumbs extends Vue {
  @Prop({
    type: Array,
  })
  items?: string[];

  get breadcrumbs() {
    if (this.items) {
      return this.items;
    }

    const crumbs = this.$route.fullPath.slice(1).split("/");
    return crumbs.map((crumb: string) => crumb.replace("-", " "));
  }
}
