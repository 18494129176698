import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class CardDetailDto {
  @JsonProperty() friendlyCardName!: string;
  @JsonProperty() firstName!: string;
  @JsonProperty() lastName!: string;
  @JsonProperty() cardType!: string;
  @JsonProperty() universalToken!: string;
  @JsonProperty() expYear!: string;
  @JsonProperty() expMonth!: string;
  @JsonProperty() cvV2!: string;
}
