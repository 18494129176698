import {
  IconNameTypes,
  MainRouteTypes,
  NavigationItem,
  SubRouteTypes,
} from "@/types";
export const navigationConfig: NavigationItem[] = [
  {
    name: "Dashboard",
    route: "/",
    iconName: IconNameTypes.Dashboard,
    mainRouteId: MainRouteTypes.Dashboard,
  },
  {
    name: "Buyers",
    route: `/${MainRouteTypes.Buyers}/${SubRouteTypes.Leads}`,
    iconName: IconNameTypes.Buyers,
    mainRouteId: MainRouteTypes.Buyers,
  },
  {
    name: "Orders",
    route: `/${MainRouteTypes.Orders}/${SubRouteTypes.AllOrders}`,
    iconName: IconNameTypes.Orders,
    mainRouteId: MainRouteTypes.Orders,
  },
  {
    name: "Credit",
    route: `/${MainRouteTypes.Credit}/${SubRouteTypes.Transactions}`,
    iconName: IconNameTypes.CostBoards,
    mainRouteId: MainRouteTypes.Credit,
  },
  {
    name: "Settings",
    route: `/${MainRouteTypes.Settings}/${SubRouteTypes.Profile}`,
    iconName: IconNameTypes.Settings,
    mainRouteId: MainRouteTypes.Settings,
  },
];
