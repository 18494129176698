
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import * as yup from "yup";
import { Form } from "vee-validate";
import {
  Icon,
  Tooltip,
  ConfirmationModal,
  AdminInput,
  AdminButton,
} from "@/components";
import { IconNameTypes, IValidateResponse } from "@/types";
import { VeeFieldMixin } from "@/mixins";

@Options({
  components: {
    Icon,
    Tooltip,
    ConfirmationModal,
    AdminInput,
    AdminButton,
    Form,
  },
  emits: ["update:modelValue"],
  inheritAttrs: false,
})
export default class EmailListInput extends mixins<
  [VueMixin<VeeFieldMixin<string[]>>]
>(VeeFieldMixin) {
  @Prop({
    type: Array,
    default: null,
  })
  modelValue!: string[];

  @Prop({
    type: Array,
    default: [],
  })
  list!: string[];

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: String,
    default: "",
    required: true,
  })
  name!: string;

  @Prop({
    type: String,
    default: null,
  })
  msg!: string;

  @Prop({
    type: Number,
    default: 20,
  })
  itemLimit!: number;

  @Prop({
    type: String,
    default: null,
  })
  tooltipMsg!: string;

  @Prop({
    type: String,
    default: null,
  })
  modalCopy!: string;

  private IconNameTypes = IconNameTypes;
  private validationSchema = yup.object().shape({
    newItem: yup.string().email().required(),
  });
  private listModalOpen = false;
  private newItem = "";
  private localList: string[] = [];

  mounted() {
    this.localList = [...this.modelValue];
  }

  @Watch("modelValue")
  onModelValueChanged(val: string[]) {
    this.field.handleChange(val);
  }

  private onListModalCancel() {
    this.newItem = "";
    this.localList = [...this.modelValue];
    this.listModalOpen = false;
  }

  private onListModalConfirm() {
    this.$emit("update:modelValue", [...this.localList]);
    this.newItem = "";
    this.listModalOpen = false;
  }

  private async addItem(item: string) {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();
    if (validateResponse.valid) {
      if (!this.localList.includes(item) && !this.itemLimitExceeded) {
        this.localList.push(item);
      }
      (this.$refs.form as HTMLFormElement).resetForm();
    }
  }

  private removeItem(item: string) {
    this.localList.splice(this.localList.indexOf(item), 1);
  }

  private get modalTriggerText() {
    const numOfEmails = this.modelValue.length;
    if (!numOfEmails) return "Add email addresses";
    return numOfEmails > 1
      ? `See all ${numOfEmails} email addresses`
      : `See 1 email address`;
  }

  private get itemLimitExceeded() {
    return this.localList.length >= this.itemLimit;
  }
}
