import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";
import { MaskedValueDto, CarrierTypes } from "@/types";

@Serializable()
export class IndependentShipmentDto {
  @JsonProperty() orderId!: number;
  @JsonProperty() trackable!: boolean | null;
  @JsonProperty() trackingNumber!: string | null;
  @JsonProperty() carrierName!: CarrierTypes;
  @JsonProperty({ type: MaskedValueDto })
  totalShipmentCost!: MaskedValueDto;
  // shipment shipDate
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
    afterSerialize: Utils.getDateISOString,
  })
  shipDate!: string | null;
  @JsonProperty() independentShipmentId!: number;
  @JsonProperty() createdOn!: string | null;

  constructor() {
    this.totalShipmentCost = new MaskedValueDto();
  }
}
