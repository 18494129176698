import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class OrderMinimumsDto {
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  orderMinimum?: string | null;

  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  reOrderMinimum?: string | null;
}
