import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class PaymentSettingsDto {
  @JsonProperty() paymentGatewayType!: string | null;
  @JsonProperty() useUniversalToken!: boolean;
  @JsonProperty() acceptCreditCards!: boolean;
  @JsonProperty() vendorName!: string | null;
  @JsonProperty() acceptedCreditCards!: Array<string | null>;
  @JsonProperty() acceptedCountries!: Array<string | null>;
  @JsonProperty() enableJuniperCredit!: boolean;
}
