import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";
import { PaymentMethodTypes } from "@/types/enums/PaymentMethodTypes";

@Serializable()
export class PaymentMethodDto {
  @JsonProperty({ beforeDeserialize: Utils.getPaymentMethodLabel })
  type!: PaymentMethodTypes;
  @JsonProperty() paymentMethodId!: number;
  @JsonProperty() last4CC!: string | null;
  @JsonProperty() isUniversalToken!: boolean;
}
