import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Masks, Utils } from "@/utils";

@Serializable()
export class ProfileBrandContactDto {
  @JsonProperty() registrationRequestContactEmail!: string;
  @JsonProperty() requestNotificationEmails!: string[];
  @JsonProperty() orderNotificationEmails!: string[];
  @JsonProperty() brandContactEmail!: string;
  @JsonProperty() customerContactEmail!: string;
  @JsonProperty({
    beforeDeserialize: Masks.phone,
    afterSerialize: Utils.cleanPhone,
  })
  phone!: string;

  constructor() {
    this.requestNotificationEmails = [];
    this.orderNotificationEmails = [];
    this.registrationRequestContactEmail = "";
    this.brandContactEmail = "";
    this.customerContactEmail = "";
    this.phone = "";
  }
}
