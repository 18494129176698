import {
  IAccessRequestsRepository,
  AccessRequestDto,
  AccessRequestsDto,
} from "@/types";
import axios from "@/axios";
import { deserialize } from "typescript-json-serializer";
import { injectable } from "inversify-props";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/leads/AccessRequests`;

@injectable()
export class AccessRequestsRepository implements IAccessRequestsRepository {
  public async get(id: number): Promise<AccessRequestDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${id}`);
    return deserialize<AccessRequestDto>(data, AccessRequestDto);
  }

  public async getAll(queryString = ""): Promise<AccessRequestsDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}${queryString}`);
    return deserialize<AccessRequestsDto>(data, AccessRequestsDto);
  }

  public async decline(
    id: number,
    statusNotes: string
  ): Promise<AccessRequestDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${id}/decline`, {
      statusNotes,
    });

    return deserialize<AccessRequestDto>(data, AccessRequestDto);
  }

  public async revoke(id: number, statusNotes = ""): Promise<AccessRequestDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${id}/revoke`, {
      statusNotes,
    });

    return deserialize<AccessRequestDto>(data, AccessRequestDto);
  }

  public async undecline(
    id: number,
    statusNotes = ""
  ): Promise<AccessRequestDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${id}/undecline`, {
      statusNotes,
    });

    return deserialize<AccessRequestDto>(data, AccessRequestDto);
  }

  public async approve(
    id: number,
    customerNumber: string | null
  ): Promise<AccessRequestDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${id}/approve`, {
      customerNumber,
    });

    return deserialize<AccessRequestDto>(data, AccessRequestDto);
  }

  public async createAndApprove(
    id: number,
    customerNumber: string | null
  ): Promise<AccessRequestDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${id}/CreateAndApprove`, {
      appUserId: id,
      customerNumber,
    });

    return deserialize<AccessRequestDto>(data, AccessRequestDto);
  }

  public async delete(id: number) {
    return axios.delete(`${ENDPOINT_URL}/${id}`);
  }
}
