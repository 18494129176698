import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShippingCarrierAcknowledgement = _resolveComponent("ShippingCarrierAcknowledgement")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ShippingCarrierAcknowledgement, {
      status: _ctx.status,
      selectedShipMethod: _ctx.selectedShipMethod
    }, null, 8, ["status", "selectedShipMethod"])
  ]))
}