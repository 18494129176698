import { JsonProperty } from "typescript-json-serializer";
import { DisputeDetailDto, DisputeShippingTaxDetailDto } from "@/types";
import { Utils } from "@/utils";

export class DisputeDto {
  @JsonProperty() chargeId!: string | null;
  @JsonProperty() sellerId!: string | null;
  @JsonProperty() buyerId!: string | null;
  @JsonProperty() currency!: string | null;
  @JsonProperty() status!: string | null;
  @JsonProperty() creditMemoUrl!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  created!: string;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  modified!: string;
  @JsonProperty() originalTotalAmount!: number;
  @JsonProperty() sellerCreditNoteNumber!: string | null;
  @JsonProperty() sellerTaxCreditNoteUrl!: string | null;
  @JsonProperty() totalAmount!: number;
  @JsonProperty() taxAmount!: number;
  @JsonProperty() shippingAmount!: number;
  @JsonProperty() shippingTaxAmount!: number;
  @JsonProperty({ type: DisputeShippingTaxDetailDto })
  shippingTaxDetails!: Array<DisputeShippingTaxDetailDto | null>;
  @JsonProperty() shippingDiscountAmount!: number;
  @JsonProperty() discountAmount!: number;
  @JsonProperty({ type: DisputeDetailDto })
  details!: Array<DisputeDetailDto | null>;
  @JsonProperty() refundReason!: string | null;
  @JsonProperty() refundComment!: string | null;
}
