import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { inject } from "inversify-props";
import store from "@/store";
import { Utils } from "@/utils";
import type { IOrdersExportRepository } from "@/types";
import { AxiosResponse } from "axios";

@Module({
  store,
  namespaced: true,
})
export default class ExportOrdersVuexModule extends VuexModule {
  @inject() private ordersExportRepository!: IOrdersExportRepository;

  private _selectedOrderIds: number[] = [];
  private _exportLoading = false;

  public get selectedOrderIds() {
    return this._selectedOrderIds;
  }

  public get exportLoading() {
    return this._exportLoading;
  }

  @Mutation
  public setSelectedOrderIds(ids: number[]) {
    this._selectedOrderIds = [...ids];
  }

  @Mutation
  public setExportLoading(loading: boolean) {
    this._exportLoading = loading;
  }

  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this._selectedOrderIds = [];
    this._exportLoading = false;
  }

  @Action({ rawError: true })
  public async getOrdersData(): Promise<AxiosResponse | null> {
    const manufacturerName: string | null =
      this.context.rootGetters["manufacturersVuexModule/manufacturerName"];

    this.context.commit("setExportLoading", true);
    const [data] = await Utils.try(
      this.ordersExportRepository.post({
        manufacturerName,
        orderIds: this._selectedOrderIds,
      })
    );
    this.context.commit("setExportLoading", false);

    return data;
  }
}
