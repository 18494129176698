import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  IApiErrorResponse,
  IAutoPublishRepository,
  IPublishStatus,
  PublishValidationSubTypes,
} from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/Autopublish/on`;

@injectable()
export class AutoPublishRepository implements IAutoPublishRepository {
  public async getStatus(): Promise<IPublishStatus> {
    const status = {
      subComponents: {
        companyDetails: true,
        orderMinimums: true,
        images: true,
        msrp: true,
      },
      // to check if this error is caused from our validation checks and not just
      // a generic 500 API error
      isValidationError: false,
      isError: false,
    };

    try {
      await axios.put(ENDPOINT_URL);
    } catch (err) {
      const apiError: IApiErrorResponse = err.response.data;
      const errors = apiError?.errors || [];

      status.isError = true;

      errors.forEach((error) => {
        // field names come back as 'Category:Subcategory'
        // ie 'CompanyInformation:OrderMinimums'
        const field = error.field?.split(":");

        if (field && field.length > 1) {
          const subCategory = field[1];

          switch (subCategory) {
            case PublishValidationSubTypes.SellerBiography:
            case PublishValidationSubTypes.SellerName:
            case PublishValidationSubTypes.Industry:
            case PublishValidationSubTypes.IndustryCategory:
            case PublishValidationSubTypes.CustomerContactEmail:
            case PublishValidationSubTypes.Contact:
              status.subComponents.companyDetails = false;
              status.isValidationError = true;
              break;
            case PublishValidationSubTypes.OrderMinimum:
            case PublishValidationSubTypes.ReOrderMinimum:
              status.subComponents.orderMinimums = false;
              status.isValidationError = true;
              break;
            case PublishValidationSubTypes.ImagesUploaded:
              status.subComponents.images = false;
              status.isValidationError = true;
              break;
            case PublishValidationSubTypes.MSRP:
              status.subComponents.msrp = false;
              status.isValidationError = true;
              break;
            default:
              break;
          }
        }
      });
    }

    return status;
  }
}
