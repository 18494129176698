export enum SubRouteTypes {
  // Buyers
  Leads = "leads",
  Active = "active",
  // Settings
  Profile = "profile",
  CategoryMapping = "category-mapping",
  PriceMapping = "price-mapping",
  ProductTemplate = "product-template",
  AttributeMapping = "attribute-mapping",
  ProductSets = "product-sets",
  Brands = "brands",
  Payment = "payment",
  Shipping = "shipping",
  // Orders
  AllOrders = "all",
  CancelledOrders = "cancelled",
  OpenCarts = "open-carts",
  // Order Details
  Summary = "summary",
  OrderShipping = "order-shipping",
  // Credit
  Transactions = "transactions",
  Disbursements = "disbursements",
  Disputes = "disputes",
}
