import {
  ValueDto,
  LabelDownloadDto,
  ShipmentDto,
  ShipLinkDto,
  PackageDto,
} from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class LabelDto {
  @JsonProperty() externalLabelId!: string | null;
  @JsonProperty() labelId!: number;
  @JsonProperty() status!: string;
  @JsonProperty() externalShipmentId!: string;
  @JsonProperty() shipmentId!: number;
  @JsonProperty() shipment!: ShipmentDto;
  @JsonProperty() shipDate!: string | null;
  @JsonProperty() createdAt!: string | null;
  @JsonProperty() shipmentCost!: ValueDto;
  @JsonProperty() insuranceCost!: ValueDto;
  @JsonProperty() trackingNumber!: string | null;
  @JsonProperty() isReturnLabel!: boolean;
  @JsonProperty() rmaNumber!: string | null;
  @JsonProperty() isInternational!: boolean;
  @JsonProperty() externalCarrierId!: string | null;
  @JsonProperty() carrierId!: number;
  @JsonProperty() chargeEvent!: string | null;
  @JsonProperty() externalOutboundLabelId!: string | null;
  @JsonProperty() outboundLabelId!: number;
  @JsonProperty() serviceCode!: string | null;
  @JsonProperty() packageCode!: string | null;
  @JsonProperty() validateAddress!: string | null;
  @JsonProperty() voided!: boolean;
  @JsonProperty() voidedAt!: string | null;
  @JsonProperty() labelDownloadType!: string | null;
  @JsonProperty() labelFormat!: string | null;
  @JsonProperty() displayScheme!: string | null;
  @JsonProperty() labelLayout!: string | null;
  @JsonProperty() trackable!: boolean;
  @JsonProperty() labelImageId!: string | null;
  @JsonProperty() carrierCode!: string | null;
  @JsonProperty() trackingStatus!: string | null;
  @JsonProperty() labelDownload!: LabelDownloadDto;
  @JsonProperty() formDownload!: ShipLinkDto;
  @JsonProperty() insuranceClaim!: ShipLinkDto;
  @JsonProperty({ type: PackageDto }) packages!: PackageDto[];
}
