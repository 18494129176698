import { ManufacturerSettingsDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ManufacturerDetailsDto {
  @JsonProperty() appId!: string | null;
  @JsonProperty() autoPublish!: boolean;
  @JsonProperty() createdBy!: string | null;
  @JsonProperty() createdOn!: string | null;
  @JsonProperty() deletedBy!: string | null;
  @JsonProperty() deletedOn!: string | null;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty() isLive!: boolean;
  @JsonProperty() manufacturerAppSettingsId!: string | null;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() modifiedBy!: string | null;
  @JsonProperty() modifiedOn!: string | null;
  @JsonProperty() settings!: ManufacturerSettingsDto;
}
