
import { mixins, Options } from "vue-class-component";
import { inject } from "inversify-props";
import { Utils } from "@/utils";
import {
  AccessRequestDto,
  AccessRequestStatusTypes,
  MainRouteTypes,
  SubRouteTypes,
} from "@/types";
import type { IAccessRequestsRepository } from "@/types";
import { Breadcrumbs, CompanyInfo, BuyerInfo, TopBar } from "@/components";
import { DetailsMixin } from "@/mixins";
//TODO: Fix problem with @ alias with webpack for the component imports
@Options({
  components: {
    Breadcrumbs,
    BuyerInfo,
    CompanyInfo,
    TopBar,
  },
})
export default class LeadDetails extends mixins(DetailsMixin) {
  @inject() private accessRequestsRepository!: IAccessRequestsRepository;

  private status: AccessRequestStatusTypes | null = null;
  private backPath = `/${MainRouteTypes.Buyers}/${SubRouteTypes.Leads}`;

  async created() {
    this.getDetailsData = this.getLeadsDetails;
    await this.init();
  }

  private async getLeadsDetails() {
    this.loading = true;

    const { leadId } = this.$route.params;
    const [data] = await Utils.try(
      this.accessRequestsRepository.get(Number(leadId))
    );
    if (data) {
      this.populateData(data.user);
      this.status = data.status;
      this.breadcrumbPreviousTitle = "All Leads";
    }

    this.loading = false;
  }

  onRefreshData(data: AccessRequestDto) {
    this.populateData(data.user);
    this.status = data.status;
  }
}
