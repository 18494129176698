
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Icon, Tooltip } from "@/components";
import { IconNameTypes, HTMLElementEvent } from "@/types";
import { VeeFieldMixin } from "@/mixins";

@Options({
  components: {
    Icon,
    Tooltip,
  },
  emits: ["update:modelValue"],
})
export default class AdminTextarea extends mixins<
  [VueMixin<VeeFieldMixin<string>>]
>(VeeFieldMixin) {
  @Prop({
    type: String,
    default: "",
  })
  modelValue!: string;

  @Prop({
    type: String,
    default: null,
  })
  label!: string;

  @Prop({
    type: String,
    default: null,
  })
  name!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: Number,
    default: 96,
  })
  width!: number;

  @Prop({
    type: Number,
    default: null,
  })
  maxLength!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  showCharLimit!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  msg!: string;

  @Prop({
    type: Number,
    default: null,
  })
  minHeight!: number;

  @Prop({
    type: String,
    default: null,
  })
  tooltipMsg!: string;

  @Prop({
    type: Function,
    default: null,
  })
  onBlur!: () => void;

  @Prop({
    type: Function,
    default: null,
  })
  onFocus!: () => void;

  private isActive = false;
  private IconNameTypes = IconNameTypes;

  get cmpWidth() {
    return this.fullWidth ? "w-full" : `w-${this.width}`;
  }

  get charLimitMsg() {
    if (this.modelValue === null) {
      return `${this.maxLength} characters remaining`;
    }
    return `${this.maxLength - this.modelValue.length} characters remaining`;
  }

  get messageContainerClass() {
    return [
      "flex",
      "w-full",
      `justify-${this.showCharLimit ? "between" : "end"}`,
    ];
  }

  onChange(e: HTMLElementEvent<HTMLTextAreaElement>) {
    const value = e.target.value.trim();
    this.field.handleChange(value);
    this.$emit("update:modelValue", value);
  }

  onInputFocus() {
    this.isActive = true;

    if (this.onFocus) {
      this.onFocus();
    }
  }

  onInputBlur() {
    this.isActive = false;

    if (this.onBlur) {
      this.onBlur();
    }
  }
}
