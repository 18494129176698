
import { Vue, Options } from "vue-class-component";
import { inject } from "inversify-props";
import { Utils } from "@/utils";
import { JunTableColumn } from "@juniper/ui";
import { TopBar, AddressInfo, Icon } from "@/components";
import { ImageUrlService } from "@/services";
import {
  MainRouteTypes,
  SubRouteTypes,
  CartDto,
  CartLineItemDto,
  IconNameTypes,
} from "@/types";
import type { ICartsRepository } from "@/types";
import { namespace } from "vuex-class";

const manufacturers = namespace("manufacturersVuexModule");
const auth = namespace("authVuexModule");

@Options({
  components: {
    TopBar,
    AddressInfo,
    Icon,
  },
})
export default class CartDetails extends Vue {
  @inject() private cartsRepository!: ICartsRepository;
  @inject() private imageUrlService!: ImageUrlService;

  @manufacturers.Getter private manufacturerId!: number | null;

  @auth.Getter private isMultiline!: boolean | null;

  private backPath = `/${MainRouteTypes.Orders}/${SubRouteTypes.OpenCarts}`;
  private loading = false;
  private cart: CartDto | null = null;
  private tableItemKey = "id";
  private IconNameTypes = IconNameTypes;
  private tableItems: CartLineItemDto[] = [];

  get tableHeaders(): JunTableColumn[] {
    return [
      { prop: "photoName", text: "", width: "5rem" },
      { prop: "itemId", text: "ID #", width: "7rem" },
      { prop: "itemName", text: "Item", width: "15rem", wrap: true },
      ...(this.isMultiline
        ? [
            {
              prop: "vendorName",
              text: "Vendor Name",
              width: "10rem",
              wrap: true,
            },
          ]
        : []),
      { prop: "notes", text: "Note", wrap: true },
      { prop: "qty", text: "QTY", width: "5rem" },
      { prop: "price", text: "Price Per", width: "7rem" },
      { prop: "subTotal", text: "Subtotal", width: "8rem" },
    ];
  }

  async mounted() {
    await this.getCartDetails();
  }

  get cartId() {
    const { cartId } = this.$route.params;
    return Array.isArray(cartId) ? cartId[0] : cartId;
  }

  get vendorNames() {
    const names = this.cart?.lineItems.flatMap(({ vendorName }) =>
      vendorName ? vendorName : []
    );

    return Array.from(new Set(names)).join(", ");
  }

  get shippingAddress() {
    return this.cart?.catalogDetails[0].shippingInfo;
  }

  get shippingEmail() {
    return this.shippingAddress?.contact?.email || "N/A";
  }

  get shippingPhone() {
    return this.shippingAddress?.contact?.phone || "N/A";
  }

  get billingEmail() {
    return this.cart?.billingAddress?.contact?.email || "N/A";
  }

  get billingPhone() {
    return this.cart?.billingAddress?.contact?.phone || "N/A";
  }

  private async getCartDetails() {
    this.loading = true;

    const [cart] = await Utils.try(this.cartsRepository.getCart(this.cartId));

    if (cart) {
      this.cart = cart.data;
      this.tableItems = cart.data.lineItems;
    }
    this.loading = false;
  }
}
