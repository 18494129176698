import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42037d08"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mb-8 mt-4",
  "data-test-id": "invoice-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminButton = _resolveComponent("AdminButton")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    title: "Invoice",
    loading: _ctx.orderLoading || _ctx.shippingLoading
  }, {
    content: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.invoiceText), 1),
      _createVNode(_component_AdminButton, {
        class: "mb-4",
        onClick: _ctx.invoiceClick,
        disabled: _ctx.isInvoiceDisabled,
        inactiveFilled: _ctx.isInvoiceDisabled,
        fullWidth: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled", "inactiveFilled"])
    ]),
    _: 1
  }, 8, ["loading"]))
}