import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  ShipmentDto,
  ShipmentsDto,
  IShipmentsRepository,
  IShipmentsPostPayloadWrapper,
  ShipRatesDto,
  GetAllShipmentsDto,
} from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Shipping/Shipments`;

@injectable()
export class ShipmentsRepository implements IShipmentsRepository {
  public async getAll(queryString = ""): Promise<GetAllShipmentsDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}${queryString}`);
    return deserialize<GetAllShipmentsDto>(data, GetAllShipmentsDto);
  }

  public async get(shipmentId: string): Promise<ShipmentDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${shipmentId}`);
    return deserialize<ShipmentDto>(data, ShipmentDto);
  }

  public async post(
    shipment: IShipmentsPostPayloadWrapper
  ): Promise<ShipmentsDto> {
    const payload = serialize(shipment);
    const { data } = await axios.post(ENDPOINT_URL, payload);
    return deserialize<ShipmentsDto>(data, ShipmentsDto);
  }

  public async update(
    shipmentId: string,
    shipmentData: ShipmentDto
  ): Promise<ShipmentDto> {
    const payload = serialize(shipmentData);
    const { data } = await axios.put(`${ENDPOINT_URL}/${shipmentId}`, payload);
    return deserialize<ShipmentDto>(data, ShipmentDto);
  }

  public async cancel(shipmentId: string): Promise<void> {
    return await axios.put(`${ENDPOINT_URL}/${shipmentId}/cancel`);
  }

  public async getRates(shipmentId: string): Promise<ShipRatesDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${shipmentId}/rates`);
    return deserialize<ShipRatesDto>(data, ShipRatesDto);
  }
}
