import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f1e5f77"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "table-wrapper h-128"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  "data-test-id": "actions-slot-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_AdminButton = _resolveComponent("AdminButton")!
  const _component_JunTable = _resolveComponent("JunTable")!

  return (_ctx.tableConfig)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_JunTable, {
          heading: "Brands",
          headers: _ctx.tableConfig.headers,
          options: _ctx.tableConfig.options,
          loading: _ctx.tableConfig.loading,
          items: _ctx.tableConfig.items,
          itemKey: _ctx.tableConfig.itemKey,
          onSort: _ctx.onSort,
          onFilter: _ctx.onFilter,
          "data-test-id": "jun-table"
        }, {
          default: _withCtx(({ item, header }) => [
            (header.prop === 'enabled')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_Switch, {
                    name: item.catalogCode,
                    modelValue: item.visible,
                    "onUpdate:modelValue": ($event: any) => ((item.visible) = $event),
                    onChange: ($event: any) => (_ctx.onChange(item)),
                    "data-test-id": "default-slot-switch"
                  }, null, 8, ["name", "modelValue", "onUpdate:modelValue", "onChange"])
                ]))
              : _createCommentVNode("", true)
          ]),
          actions: _withCtx(() => [
            (_ctx.isDirty)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_AdminButton, {
                    size: "sm",
                    minWidth: 6,
                    color: "outline",
                    onClick: _ctx.onCancelButtonClick,
                    disabled: _ctx.isProcessing,
                    inactiveFilled: _ctx.isProcessing,
                    "data-test-id": "cancel-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cancel")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled", "inactiveFilled"]),
                  _createVNode(_component_AdminButton, {
                    size: "sm",
                    minWidth: 6,
                    class: "button-save",
                    onClick: _ctx.onSaveButtonClick,
                    loading: _ctx.isProcessing,
                    disabled: _ctx.isProcessing,
                    inactiveFilled: _ctx.isProcessing,
                    "data-test-id": "save-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Save")
                    ]),
                    _: 1
                  }, 8, ["onClick", "loading", "disabled", "inactiveFilled"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headers", "options", "loading", "items", "itemKey", "onSort", "onFilter"])
      ]))
    : _createCommentVNode("", true)
}