import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShipAddressDto {
  @JsonProperty() name!: string | null;
  @JsonProperty() phone!: string | null;
  @JsonProperty() companyName!: string | null;
  @JsonProperty() addressLine1!: string | null;
  @JsonProperty() addressLine2!: string | null;
  @JsonProperty() addressLine3!: string | null;
  @JsonProperty() cityLocality!: string | null;
  @JsonProperty() stateProvince!: string | null;
  @JsonProperty() postalCode!: string | null;
  @JsonProperty() countryCode!: string | null;
  @JsonProperty() addressResidentialIndicator!: string | null;
}
