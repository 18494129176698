
import { Options, Vue } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";
import { Box, AdminButton } from "@/components";
import { ButtonColorTypes, ButtonSizeTypes } from "@/types";

import BladeOneLeaf1 from "@/assets/images/credit-landing/section-1/leaf-1.svg";
import BladeOneLeaf2 from "@/assets/images/credit-landing/section-1/leaf-2.svg";
import BladeOneLeaf3 from "@/assets/images/credit-landing/section-1/leaf-3.svg";
import BladeOnePC from "@/assets/images/credit-landing/section-1/pc.png";
import BladeTwoLeaf1 from "@/assets/images/credit-landing/section-2/leaf-1.png";
import BladeTwoLeaf2 from "@/assets/images/credit-landing/section-2/leaf-2.png";
import BladeTwoLeaf3 from "@/assets/images/credit-landing/section-2/leaf-3.png";
import BladeTwoIPad from "@/assets/images/credit-landing/section-2/ipad.png";
import BladeThreeAccount from "@/assets/images/credit-landing/section-3/account.svg";
import BladeThreePCI from "@/assets/images/credit-landing/section-3/pci.svg";
import BladeThreeIntegration from "@/assets/images/credit-landing/section-3/integration.svg";

@Options({
  name: "CreditLandingPage",
  components: {
    Box,
    AdminButton,
  },
})
export default class CreditLandingPage extends Vue {
  @InjectReactive() private disableApplyButton!: boolean;
  @InjectReactive() private applyForJuniperCredit!: () => Promise<void>;
  private ButtonColorTypes = ButtonColorTypes;
  private ButtonSizeTypes = ButtonSizeTypes;

  private bladeOneGraphics = [
    {
      classes: "absolute -left-9 bottom-4",
      src: BladeOneLeaf3,
      alt: "Leaf",
    },
    {
      classes: "absolute left-0 bottom-4",
      src: BladeOneLeaf2,
      alt: "Leaf",
    },
    {
      classes: "absolute -right-8 bottom-4",
      src: BladeOneLeaf1,
      alt: "Leaf",
    },
    {
      classes: "relative w-full h-auto",
      src: BladeOnePC,
      alt: "PC",
    },
  ];

  private bladeTwoFeatureList = [
    {
      title: "No risk terms",
      text: "offer net 60 to your customers with no collection cost and no credit risk",
    },
    {
      title: "More ways to sell",
      text: "90% of buyers surveyed plan to use terms. don't miss out on sales.",
    },
    {
      title: "Fast payment",
      text: "reliable payment as quick as 2 days",
    },
  ];

  private bladeTwoGraphics = [
    {
      classes: "absolute -right-8 bottom-16",
      src: BladeTwoLeaf2,
      alt: "Leaf",
    },
    {
      classes: "relative h-auto w-full",
      styles: "filter: drop-shadow(0px 10px 5px rgba(49, 109, 97, 0.4));",
      src: BladeTwoIPad,
      alt: "IPad",
    },
    {
      classes: "absolute right-6 -bottom-2",
      src: BladeTwoLeaf3,
      alt: "Leaf",
    },
    {
      classes: "absolute -right-16 -bottom-2",
      src: BladeTwoLeaf1,
      alt: "Leaf",
    },
  ];

  private bladeThreeFeatureList = [
    {
      title: "Simple account setup",
      src: BladeThreeAccount,
    },
    {
      title: "PCI compliant",
      src: BladeThreePCI,
    },
    {
      title: "Seamless, full integration",
      src: BladeThreeIntegration,
    },
  ];
}
