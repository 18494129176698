import { JsonProperty, Serializable } from "typescript-json-serializer";
import {
  AddressDto,
  LinkDto,
  InvoiceItemDto,
  PaymentMethodDto,
  OrderChannelDto,
} from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class InvoiceDto {
  @JsonProperty() manufacturerInvoiceStatus!: string | null;
  @JsonProperty() invoiceId!: number;
  @JsonProperty() manufacturerInvoiceId!: string | null;
  @JsonProperty() manufacturerInvoiceNo!: string | null;
  @JsonProperty() manufacturerOrderNo!: string | null;
  @JsonProperty() orderId!: number;
  @JsonProperty() poNumber!: string | null;
  @JsonProperty() terms!: string | null;
  @JsonProperty({ beforeDeserialize: Utils.parseDate }) invoiceDate!:
    | string
    | null;
  @JsonProperty({ beforeDeserialize: Utils.parseDate }) dueDate!: string | null;
  @JsonProperty({ beforeDeserialize: Utils.floatToCurrencyString })
  amount!: string;
  @JsonProperty() balance!: number;
  @JsonProperty() notes!: string | null;
  @JsonProperty() repNumber!: string | null;
  @JsonProperty() currencyCode!: string | null;
  @JsonProperty() shippingAddress!: AddressDto;
  @JsonProperty() companyName!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty({ beforeDeserialize: Utils.floatToCurrencyString })
  subTotal!: string;
  @JsonProperty({ beforeDeserialize: Utils.floatToCurrencyString })
  shipping!: string;
  @JsonProperty() tax!: number;
  @JsonProperty() billingAddress!: AddressDto;
  @JsonProperty() catalogCode!: string | null;
  @JsonProperty() catalogName!: string | null;
  @JsonProperty({ beforeDeserialize: Utils.parseDate }) paidOnDate!:
    | string
    | null;
  @JsonProperty() marketName!: string | null;
  @JsonProperty() paymentMethod!: PaymentMethodDto;
  @JsonProperty() source!: string | null;
  // shipment shipDate
  @JsonProperty() shipDate!: string | null;
  @JsonProperty({ type: InvoiceItemDto }) invoiceItems!: InvoiceItemDto[];
  @JsonProperty({ type: LinkDto }) links!: LinkDto[];
  @JsonProperty() orderChannel!: OrderChannelDto;
}
