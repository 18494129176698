
import { AdminButton, EditableBox, Switch } from "@/components";
import { BackupDataService } from "@/services";
import type { IProductRepository, TableConfig, ToastConfig } from "@/types";
import { ProductSetDto } from "@/types";
import { Utils } from "@/utils";
import { JunTableColumn, JunTableItem, JunTableOptions } from "@juniper/ui";
import { inject } from "inversify-props";
import { Form } from "vee-validate";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    EditableBox,
    Switch,
    AdminButton,
    Form,
  },
})
export default class ProductSets extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private productRepository?: IProductRepository;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOff!: () => void;

  // JunTable config
  private isTableProcessing = false;
  private tableOptions: JunTableOptions = {};
  private tableHeaders: JunTableColumn[] = [
    {
      prop: "name",
      text: "Product Sets",
      canSort: false,
      canFilter: false,
    },
    {
      prop: "numberOfProducts",
      text: "# of Products",
      canSort: false,
      canFilter: false,
    },
    {
      prop: "description",
      text: "Description",
      canSort: false,
      canFilter: false,
    },
    {
      prop: "showOnJuniperMarket",
      text: "Show on the marketplace",
      canSort: false,
      canFilter: false,
    },
  ];

  private productSets: Array<ProductSetDto> = [];
  private isProcessing = false;
  private isDirty = false;
  private dirtyArray: Array<string> = [];

  async created() {
    await this.init();
  }

  private async init(): Promise<void> {
    this.isTableProcessing = true;
    const [productSets] = await Utils.try(this.productRepository?.get());
    if (!productSets) return;
    this.productSets = productSets;
    this.backupDataService?.saveBackup(this, this.productSets);
    this.isTableProcessing = false;
  }

  get tableConfig(): TableConfig {
    return {
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.productSets ? this.productSets : [],
      itemKey: "id",
    };
  }

  @Watch("dirtyArray", { deep: true })
  dirtyArrayChanged(val: Array<string>) {
    if (val.length > 0 && !this.isDirty) {
      this.isDirty = true;
    } else if (val.length === 0) {
      this.isDirty = false;
    }
  }

  private resetDirtyFlag(): void {
    this.dirtyArray = [];
  }

  private onChange(item: JunTableItem) {
    const index = this.dirtyArray.findIndex((i: string) => i === item.id);
    if (index !== -1) {
      this.dirtyArray.splice(index, 1);
    } else {
      this.dirtyArray.push(item.id);
    }
  }

  private onCancelButtonClick() {
    this.resetDirtyFlag();
    this.backupDataService?.restoreBackup(this, this.productSets);
  }

  private async onSaveButtonClick() {
    try {
      this.isProcessing = true;
      await this.productRepository?.put(this.productSets);
      this.handleAutoPublishOff();
      this.createToastSuccess?.({
        message: "Your product sets have been successfully saved!",
      });
      this.backupDataService?.saveBackup(this, this.productSets);
      this.resetDirtyFlag();
    } catch (err) {
      console.log(err);
    } finally {
      this.isProcessing = false;
    }
  }

  // private async onCreateProductSetsButtonClick() {
  //   window.open(
  //     `${process.env.VUE_APP_JUNIPER_DATA_URL}/product-manager/product-sets`
  //   );
  // }
}
