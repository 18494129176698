import { IFormattedMonthReport, OrderReportDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";
import { TimeDateLanguageConfig } from "@/config";

@Serializable()
export class OrderSalesHistoryDto {
  @JsonProperty() currentWeek!: Array<OrderReportDto>;
  @JsonProperty() currentMonth!: Array<OrderReportDto>;
  @JsonProperty() currentYear!: Array<OrderReportDto>;
  @JsonProperty() currentYearQuarters!: Array<OrderReportDto>;
  @JsonProperty() previousWeek!: Array<OrderReportDto>;
  @JsonProperty() previousMonth!: Array<OrderReportDto>;
  @JsonProperty() previousYear!: Array<OrderReportDto>;
  @JsonProperty() previousYearQuarters!: Array<OrderReportDto>;

  getFormattedMonth(arr: Array<OrderReportDto>): Array<IFormattedMonthReport> {
    const ar = arr;
    const weeks: Array<Array<OrderReportDto>> = [];

    const startFirstWeek = ar.findIndex(
      (order) =>
        new Date(order.orderDate ?? "").toLocaleDateString(
          TimeDateLanguageConfig.usEnglish,
          {
            weekday: "short",
          }
        ) === "Sun"
    );

    while (weeks.length <= 2) {
      if (!weeks.length) {
        ar.splice(0, startFirstWeek);
      }
      const splice = ar.splice(0, 7);
      weeks.push(splice);
    }
    weeks.push(ar);

    return weeks
      .filter((w) => {
        return w.length > 0;
      })
      .map((w) => {
        return {
          start: w[0].orderDate ?? "",
          end: w[w.length - 1].orderDate ?? "",
          totalSales: w.map((d) => d.totalSales).reduce((p, c) => p + c),
          numberOfOrders: w
            .map((d) => d.numberOfOrders)
            .reduce((p, c) => p + c),
          dates: [...w],
        };
      })
      .filter((w) => {
        return w.dates.length > 0;
      });
  }
}
