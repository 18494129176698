import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bbc622fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "list-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = {
  key: 1,
  class: "no-results"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "list-title" }
const _hoisted_7 = { class: "company-id" }
const _hoisted_8 = { class: "text-primary" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "px-2 mb-2" }
const _hoisted_11 = { class: "overflow-y-scroll h-52" }
const _hoisted_12 = {
  key: 0,
  class: "no-results"
}
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunSpinner = _resolveComponent("JunSpinner")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AdminInput = _resolveComponent("AdminInput")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "dropdown-wrapper",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openDropdown && _ctx.openDropdown(...args)))
  }, [
    _createElementVNode("div", {
      class: "division-select",
      style: _normalizeStyle({ 'background-image': `url(${_ctx.companyLogo})` })
    }, null, 4),
    _createVNode(_Transition, { name: "slide-up" }, {
      default: _withCtx(() => [
        (_ctx.showDropdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_JunSpinner)
                  ]))
                : (_ctx.errorFetchingDivisions)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Error retrieving manufacturers. "))
                  : (_ctx.currentDivision)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("section", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.currentDivision.name), 1),
                          _createElementVNode("div", _hoisted_7, [
                            _createTextVNode(" ID #: "),
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.currentDivision.value), 1)
                          ]),
                          _createElementVNode("a", {
                            class: "logout",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSignOutClick && _ctx.onSignOutClick(...args)))
                          }, [
                            _createTextVNode(" Logout "),
                            _createVNode(_component_Icon, {
                              class: "ml-1",
                              name: _ctx.IconNameTypes.Logout,
                              size: 1
                            }, null, 8, ["name"])
                          ])
                        ]),
                        (_ctx.isMultiDivision)
                          ? (_openBlock(), _createElementBlock("section", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_AdminInput, {
                                  modelValue: _ctx.query,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
                                  icon: _ctx.IconNameTypes.Search,
                                  placeholder: "Search",
                                  name: "search",
                                  type: "text",
                                  fullWidth: "",
                                  disableAutocomplete: "",
                                  hideLabels: "",
                                  ref: "searchRef"
                                }, null, 8, ["modelValue", "icon"])
                              ]),
                              _createElementVNode("div", _hoisted_11, [
                                (_ctx.filteredDivisions.length === 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, " No results found for search criteria... "))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDivisions, (item) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "list-item",
                                    key: item.name,
                                    onClick: ($event: any) => (_ctx.onDivisionClick(item))
                                  }, [
                                    _createElementVNode("p", null, _toDisplayString(item.name), 1)
                                  ], 8, _hoisted_13))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_click_away, _ctx.closeDropdown]
  ])
}