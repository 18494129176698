import { injectable } from "inversify-props";
import { deserialize, serialize } from "typescript-json-serializer";
import axios from "@/axios";
import { ProductSettingsDto, IProductSettingsRepository } from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/ProductSettings`;

@injectable()
export class ProductSettingsRepository implements IProductSettingsRepository {
  public async get(): Promise<ProductSettingsDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<ProductSettingsDto>(data, ProductSettingsDto);
  }

  public async put(productSettings: ProductSettingsDto): Promise<void> {
    const payload = serialize(productSettings);
    return await axios.put(ENDPOINT_URL, payload);
  }
}
