import { IDashboardWidget, DashboardDisplayTypes } from "@/types";

export class DashboardComponentConfig {
  public type = "";
  public subType = "";

  public rowStart = 4;
  public columnStart = 1;
  public rowSpan = 2;
  public columnSpan = 8;
  public rowSpanOptions!: Array<number>;
  public columnSpanOptions!: Array<number>;

  public rowMinSpan!: number;
  public columnMinSpan!: number;
  public rowMaxSpan!: number;
  public columnMaxSpan!: number;

  public multiline!: boolean;
  public direct!: boolean;
  public credit!: boolean;

  constructor(conf: IDashboardWidget, dashStatus: DashboardDisplayTypes) {
    Object.assign(this, conf.config);

    const colSpan = conf.config.columnSpanOptions[dashStatus];
    const colStart = conf.config.columnStartOptions[dashStatus];

    const rowSpan = conf.config.rowSpanOptions[dashStatus];
    const rowStart = conf.config.rowStartOptions[dashStatus];

    colSpan && (this.columnSpan = colSpan);
    colStart && (this.columnStart = colStart);

    rowStart && (this.rowStart = rowStart);
    rowSpan && (this.rowSpan = rowSpan);

    this.multiline = conf.multiline;
    this.direct = conf.direct;
    this.credit = conf.credit;
  }
}
