import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class TopCustomerDto {
  @JsonProperty() customerName!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  totalSales!: string;
}
