
import { Options, Vue } from "vue-class-component";
import { TopBar, MessagePanel } from "@/components";

@Options({
  components: {
    TopBar,
    MessagePanel,
  },
})
export default class NoDivisionsSetUp extends Vue {}
