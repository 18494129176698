import { CarrierTypes } from "@/types";

export const shippingCarriersConfig = [
  {
    type: CarrierTypes.FedEx,
    label: "FedEx",
    trackingUrl:
      "https://www.fedex.com/fedextrack/?cntry_code=us&tracknumbers=",
  },
  {
    type: CarrierTypes.FedExCA,
    label: "FedEx Canada",
    trackingUrl:
      "https://www.fedex.com/fedextrack/?cntry_code=ca&tracknumbers=",
  },
  {
    type: CarrierTypes.UPS,
    label: "UPS",
    trackingUrl:
      "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=",
  },
  {
    type: CarrierTypes.USPS,
    label: "USPS",
    trackingUrl:
      "https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=",
  },
  {
    type: CarrierTypes.DHL,
    label: "DHL Express",
    trackingUrl: "http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=",
  },
  {
    type: CarrierTypes.Canpar,
    label: "Canpar Express",
    trackingUrl: "https://www.canpar.com/en/tracking/track.htm",
  },
];
