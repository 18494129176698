import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ThirtyDayHistoryDto {
  @JsonProperty() totalSales!: number;
  @JsonProperty() averageOrderAmount!: number;
  @JsonProperty() newOrders!: number;
  @JsonProperty() inProgressOrders!: number;
  @JsonProperty() closedOrders!: number;
}
