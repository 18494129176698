import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1edf92a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ImageCarousel = _resolveComponent("ImageCarousel")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Icon, {
      class: "cursor-pointer",
      name: _ctx.iconName,
      size: 1,
      disabled: _ctx.imagesEmpty,
      onClick: _ctx.openModal
    }, null, 8, ["name", "disabled", "onClick"]),
    _createVNode(_component_Modal, {
      open: _ctx.showModal,
      showCloseBtn: "",
      onCloseModal: _ctx.onCloseClick
    }, {
      body: _withCtx(() => [
        (_ctx.useCarouselPreview)
          ? (_openBlock(), _createBlock(_component_ImageCarousel, {
              key: 0,
              imageUrls: _ctx.urls
            }, null, 8, ["imageUrls"]))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "image-preview",
              src: _ctx.urls[0]
            }, null, 8, _hoisted_1))
      ]),
      _: 1
    }, 8, ["open", "onCloseModal"])
  ], 64))
}