import { JsonProperty, Serializable } from "typescript-json-serializer";
import { PaymentMethodDto } from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class AgencyOrderDto {
  @JsonProperty() brandOrderId!: number;
  @JsonProperty() agencyOrderId!: number;
  @JsonProperty() brandId!: number;
  @JsonProperty() agencyId!: number;
  @JsonProperty() customerName!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty() catalogName!: string | null;
  @JsonProperty() paymentMethod!: PaymentMethodDto;
  @JsonProperty() currencyCode!: string | null;
  @JsonProperty({ beforeDeserialize: Utils.floatToCurrencyString })
  orderTotal!: string;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  orderDate!: string | null;
  // buyer preferred shipDate
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  shipDate!: string | null;
  @JsonProperty() orderStatus!: string | null;
  @JsonProperty() poNumber!: string | null;
  @JsonProperty() orderGUID!: string | null;
  @JsonProperty() channel!: string | null;
}
