export const MultiLineOrdersMap = {
  "[].poNumber": "[].poNumber?",
  "[].brandOrderId": "[].brandOrderId?",
  "[].agencyOrderId": "[].agencyOrderId?",
  "[].brandId": "[].brandId?",
  "[].orderGUID": "[].orderGuid?",
  "[].customerName": "[].customerName?",
  "[].customerNumber": "[].customerNumber?",
  "[].catalogName": "[].catalogName?",
  "[].orderStatus": "[].orderStatus?",
  "[].orderDate": "[].orderDate?",
  "[].shipDate": "[].shipDate?",
  "[].orderTotal": "[].orderTotal?",
  "[].paymentMethod.type": "[].paymentMethod?",
};
