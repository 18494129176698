import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ProfileSocialMediaDto {
  @JsonProperty() facebook!: string;
  @JsonProperty() instagram!: string;
  @JsonProperty() twitter!: string;
  @JsonProperty() linkedIn!: string;
  @JsonProperty() pinterest!: string;
  @JsonProperty() youTube!: string;

  constructor() {
    this.facebook = "";
    this.instagram = "";
    this.twitter = "";
    this.linkedIn = "";
    this.pinterest = "";
    this.youTube = "";
  }
}
