import { ShipRatesErrorDto, RatesDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShipRatesDto {
  @JsonProperty({ type: RatesDto }) rates!: RatesDto[];
  @JsonProperty({ type: RatesDto }) invalidRates!: RatesDto[];
  @JsonProperty() externalRateRequestId!: string | null;
  @JsonProperty() rateRequestId!: number | null;
  @JsonProperty() externalShipmentId!: string | null;
  @JsonProperty() shipmentId!: number | null;
  @JsonProperty() createdAt!: string | null;
  @JsonProperty() status!: string | null;
  @JsonProperty({ type: ShipRatesErrorDto }) errors!: ShipRatesErrorDto[];
}
