import { JsonProperty, Serializable } from "typescript-json-serializer";
import { ShippingMethodTypes, CountryTypes, CarrierTypes } from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class ShippingSettingsDto {
  @JsonProperty() carrierAccountId!: number;
  @JsonProperty() externalCarrierAccountId!: string;
  @JsonProperty() carrierName!: CarrierTypes;
  @JsonProperty() isShared!: boolean;
  @JsonProperty() isCarrierActive!: boolean;
  @JsonProperty() shipMethod!: ShippingMethodTypes;
  @JsonProperty() shipFromCountry!: CountryTypes;
  @JsonProperty() shipToCountries!: Array<CountryTypes>;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  packingFees!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  handlingFees!: string | null;
}
