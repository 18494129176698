import "reflect-metadata"; // Metadata Reflection API needed for inversify-props to work
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { IoCHelper, ValidationHelper } from "@/helpers";
import VueClickAway from "vue3-click-away";
import { JuniperUI } from "@juniper/ui";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";

const app = createApp(App);
if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== "localhost") {
  Sentry.init({
    app: app,
    dsn: process.env.VUE_APP_SENTRY_URL,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // list of URLs to capture for tracing
        tracingOrigins: ["localhost", process.env.VUE_APP_API_URL || "", /^\//],
      }),
    ],
    attachProps: true,
    attachStacktrace: true,
    hooks: ["create", "mount"],
    timeout: 0,
    trackComponents: true,
    logErrors: true,
    debug: false,
    autoSessionTracking: true,
    tracesSampleRate: 1.0,
  });
} else {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  app.config.errorHandler = (err, vm, info) => {
    console.log(err);
  };
}

if (process.env.VUE_APP_LOGROCKET_ENABLED === "true") {
  LogRocket.init("ica1xz/juniper-marketplace-selleradmin");

  // connects LogRocket session to each sentry log
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
      localStorage.setItem("logRocketSessionUrl", sessionURL);
    });
  });
}

IoCHelper.buildDependencyContainer();
ValidationHelper.registerValidations();

app.use(store);
app.use(router);
app.use(VueClickAway);
app.use(JuniperUI);
app.mount("#app");
