import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IconNameTypes, EditableBoxStatusTypes } from "@/types";

@Options({
  emits: ["edit", "cancel", "save"],
})
export class EditableBoxMixin extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  title!: string;

  @Prop({
    type: String,
    default: EditableBoxStatusTypes.Loading,
  })
  status!: EditableBoxStatusTypes;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideIcon!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideActions!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  noPadding!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  noShadow!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  noContentShadow!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  saveDisabled!: boolean;
  @Prop({
    type: Boolean,
    default: false,
  })
  cancelDisabled!: boolean;

  private IconNameTypes = IconNameTypes;

  get isViewStatus() {
    return this.status === EditableBoxStatusTypes.View;
  }

  get isEditStatus() {
    return this.status === EditableBoxStatusTypes.Edit;
  }

  get isSavingStatus() {
    return this.status === EditableBoxStatusTypes.Saving;
  }

  get isLoadingStatus() {
    return this.status === EditableBoxStatusTypes.Loading;
  }

  get isSaveDisabled() {
    return this.status === EditableBoxStatusTypes.Saving || this.saveDisabled;
  }

  get isCancelDisabled() {
    return this.status === EditableBoxStatusTypes.Saving || this.cancelDisabled;
  }

  onEditClick() {
    this.$emit("edit");
  }

  onCancelClick() {
    this.$emit("cancel");
  }

  onSaveClick() {
    this.$emit("save");
  }
}
