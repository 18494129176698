
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

import { Checkbox, ShippingCarrierAcknowledgement } from "@/components";
import { EditableBoxStatusTypes, ShippingMethodTypes } from "@/types";

@Options({
  components: {
    Checkbox,
    ShippingCarrierAcknowledgement,
  },
})
export default class ShipOnYourOwnSettings extends Vue {
  @Inject() private acknowledgementChange!: (acknowledged: boolean) => void;

  @Prop({
    type: String,
    default: EditableBoxStatusTypes.View,
  })
  status!: EditableBoxStatusTypes;

  @Prop({
    type: String,
  })
  selectedShipMethod!: ShippingMethodTypes;
}
