import { CarrierTypes } from "@/types";
import { Utils } from "@/utils";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class OrderTrackingDto {
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() orderId!: number;
  @JsonProperty() trackable!: boolean;
  @JsonProperty() carrierName!: CarrierTypes;
  @JsonProperty() trackingNumber!: string | null;
  @JsonProperty() trackingUrl!: string | null;
  @JsonProperty({
    beforeDeserialize: (v: string | null) =>
      typeof v === "string" ? Utils.parseDate(v) : null,
  })
  shipDate!: string | null;
  @JsonProperty() totalShipmentCostAmount!: number;
  @JsonProperty() totalShipmentCostCurrency!: string | null;
  @JsonProperty() trackingStatus!: string | null;
  @JsonProperty() shipmentStatus!: string | null;
  @JsonProperty() actualShipDate!: string | null;
  @JsonProperty() trackingStatusCode!: string | null;
  @JsonProperty() trackingStatusCodeDescription!: string | null;
  @JsonProperty() carrierTrackingStatusCode!: string | null;
  @JsonProperty() carrierTrackingStatusDescription!: string | null;
  @JsonProperty() estimatedDeliveryDate!: string | null;
  @JsonProperty() actualDeliveryDate!: string | null;
  @JsonProperty() exceptionDescription!: string | null;
}

@Serializable()
export class ShippingTrackingDto {
  @JsonProperty({ type: OrderTrackingDto }) ordersTracking!: OrderTrackingDto[];
}
