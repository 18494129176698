import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4720d58"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "dropdown-wrapper",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_Transition, { name: "slide-up" }, {
      default: _withCtx(() => [
        (_ctx.showDropdown)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["dropdown-container", {
          left: _ctx.align === _ctx.ActionDropdownAlignment.Left,
          right: _ctx.align === _ctx.ActionDropdownAlignment.Right,
        }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.name,
                  onClick: ($event: any) => (_ctx.onOptionClick(item)),
                  class: _normalizeClass(["dropdown-item", item.disable ? 'disable' : '']),
                  disabled: item.disable
                }, [
                  _createElementVNode("span", null, _toDisplayString(item.name), 1)
                ], 10, _hoisted_1))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_click_away, _ctx.closeDropdown]
  ])
}