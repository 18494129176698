
import { PropType } from "vue";
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { JunDatePicker } from "@juniper/ui";
import { VeeFieldMixin } from "@/mixins";
import { Utils } from "@/utils";
import { DatePickerAlignmentTypes } from "@/types";

@Options({
  name: "AdminDatePicker",
  components: {
    JunDatePicker,
  },
  emits: ["update:modelValue", "dateSelected"],
})
export default class AdminDatePicker extends mixins<
  [VueMixin<VeeFieldMixin<string>>]
>(VeeFieldMixin) {
  @Prop({
    type: String as PropType<string>,
    default: "date",
  })
  name!: string;

  @Prop({
    type: [String, null] as PropType<string | null>,
    default: null,
  })
  label!: string | null;

  @Prop({
    type: String as PropType<string>,
    required: true,
  })
  modelValue!: string;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: true,
  })
  closeOnSelect!: boolean;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: String as PropType<DatePickerAlignmentTypes>,
    default: DatePickerAlignmentTypes.Left,
  })
  alignment!: DatePickerAlignmentTypes;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  showChevronIcon!: boolean;

  @Prop({
    type: [Object, null] as PropType<Date | null>,
    default: null,
  })
  earliestAvailableDate!: Date | null;

  @Prop({
    type: [Object, null] as PropType<Date | null>,
    default: null,
  })
  latestAvailableDate!: Date | null;

  @Prop({
    type: [String, null] as PropType<string | null>,
    default: null,
  })
  msg!: string | null;

  private DatePickerAlignmentTypes = DatePickerAlignmentTypes;
  private classes = {
    "left-align": this.alignment === DatePickerAlignmentTypes.Left,
    "right-align": this.alignment === DatePickerAlignmentTypes.Right,
    "show-chevron": this.showChevronIcon,
    "full-width": this.fullWidth,
  };

  @Watch("disabled")
  private onDisabledChange(): void {
    this.checkShouldDisableDatePicker();
  }

  mounted(): void {
    this.validateModelValue();
    this.checkShouldDisableDatePicker();
  }

  private onModelValueChange(newDate: Date): void {
    this.field.handleChange(newDate.toISOString());
    this.$emit("update:modelValue", newDate.toISOString());
    this.$emit("dateSelected", newDate.toISOString());
    if (this.closeOnSelect) this.toggleCalendar(false);
  }

  // TODO: Replace method with native JunDatePicker functionality when its implemented
  // querySelector may fail if @juniper/ui changes the class names
  private async checkShouldDisableDatePicker(): Promise<void> {
    const wrapper = this.$refs.wrapper as HTMLDivElement;
    const toggleButton = wrapper.querySelector(
      `.jun-date-picker > button[type="button"]`
    ) as HTMLButtonElement | null;
    if (!toggleButton) return;
    if (this.disabled && !toggleButton.getAttribute("disabled")) {
      toggleButton.setAttribute("disabled", "true");
    } else {
      toggleButton.removeAttribute("disabled");
    }
  }

  private validateModelValue(): void {
    if (Number.isFinite(Date.parse(this.modelValue))) return;
    const validDate = this.earliestAvailableDate ?? new Date();
    this.onModelValueChange(validDate);
  }

  // TODO: Replace method with native JunDatePicker functionality when its implemented
  // querySelector may fail if @juniper/ui changes the class names
  private async filterUnavailableDates(): Promise<void> {
    const wrapper = this.$refs.wrapper as HTMLDivElement;
    const dateSelectButtons = wrapper.querySelectorAll(
      `.jun-calendar-days-grid__item > .jun-calendar-day`
    );
    if (!dateSelectButtons.length) return;

    dateSelectButtons.forEach((dateButton) => {
      dateButton.setAttribute("type", "button");
      const dateString = dateButton.getAttribute("aria-label");
      if (!dateString) return;

      const timestamp = Utils.roundToNearestDate(
        new Date(dateString)
      ).getTime();
      if (!Number.isFinite(timestamp)) return;

      if (
        (this.earliestAvailableDate &&
          timestamp <
            Utils.roundToNearestDate(this.earliestAvailableDate).getTime()) ||
        (this.latestAvailableDate &&
          timestamp >
            Utils.roundToNearestDate(this.latestAvailableDate).getTime())
      ) {
        dateButton.setAttribute("disabled", "true");
      } else {
        dateButton.removeAttribute("disabled");
      }
    });
  }

  public async toggleCalendar(isOpen?: boolean): Promise<void> {
    const junDatePicker = this.$refs.junDatePicker as typeof JunDatePicker;
    if (
      junDatePicker &&
      "isVisible" in junDatePicker &&
      typeof junDatePicker.isVisible === "boolean"
    ) {
      if (isOpen === undefined) {
        junDatePicker.isVisible = !junDatePicker.isVisible;
      } else {
        junDatePicker.isVisible = isOpen;
      }
    }
  }
}
