
import { Vue, Options } from "vue-class-component";
import { Box, AdminButton } from "@/components";
import { OrderDto, MainRouteTypes, OrderStatusTypes } from "@/types";
import { Utils } from "@/utils";
import { InjectReactive } from "vue-property-decorator";

@Options({
  components: { Box, AdminButton },
})
export default class OrderShippingInvoice extends Vue {
  @InjectReactive() private order!: OrderDto | null;
  @InjectReactive() private orderLoading!: boolean;
  @InjectReactive() private shippingLoading!: boolean;
  @InjectReactive() private canCreateInvoice!: boolean;

  private invoiceClick() {
    const query = Utils.buildQueryString({ guid: this.order?.orderGUID || "" });
    const invoiceId = this.order?.invoices[0]?.id;

    this.hasInvoice
      ? this.$router.push(`/${MainRouteTypes.InvoiceDetails}/${invoiceId}`)
      : this.$router.push(`/${MainRouteTypes.InvoicePreview}${query}`);
  }

  private get hasInvoice(): boolean {
    return Boolean(this.order?.invoices.length);
  }

  private get invoiceText() {
    return this.hasInvoice
      ? this.order?.invoices[0].invoiceNumber
      : "Create invoice when you've completed shipment details and shipping labels.";
  }

  private get buttonText() {
    return this.hasInvoice ? "View Invoice" : "Create Invoice";
  }

  private get isInvoiceDisabled() {
    if (this.hasInvoice) return false;

    return (
      this.order?.catalogDetail?.orderStatus !== OrderStatusTypes.Approved ||
      !this.canCreateInvoice // Need to have a shipment first
    );
  }

  // get showCreateInvoiceButton() {
  //   if (!this.order) return false;

  //   const invoiceAlreadyMade =
  //     Array.isArray(this.order.invoices) && this.order.invoices.length > 0;
  //   const orderIsApproved =
  //     this.order.catalogDetail.orderStatus === OrderStatusTypes.Approved;

  //   return orderIsApproved && !invoiceAlreadyMade;
  // }
}
