import { Vue } from "vue-class-component";
import {
  JunTableColumn,
  JunTableFilter,
  JunTableOptions,
  JunTableSortDirection,
  JunTableSortOptions,
} from "@juniper/ui";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import {
  IDateRange,
  IQueryFilter,
  QueryOperationTypes,
  TableFilterTypes,
} from "@/types";
import { Utils } from "@/utils";

export class TableMixin extends Vue {
  protected page = 1;
  protected pageSize = 10;
  protected sortDirection: JunTableSortDirection =
    JunTableSortDirection.Acsending;
  protected sortBy = "";
  protected isTableProcessing = false;
  protected tableOptions: JunTableOptions = {
    sortOptions: {},
    filters: [],
    paginationOptions: {},
  };
  protected tableHeaders!: JunTableColumn[];
  protected getTableData?: Function;
  protected filters: IQueryFilter[] = [];
  protected persistentFilters: IQueryFilter[] = [];
  protected filterTransformers: Record<string, Function> = {};
  protected filterKeyTransformers: Record<string, Function> = {};
  protected noWildcardFilterKeys: string[] = [];
  protected dateFilterKeys: string[] = [];
  protected filterVersion!: TableFilterTypes;
  protected cellClick?: Function;

  protected async init({
    filterVersion,
  }: {
    filterVersion: TableFilterTypes;
  }): Promise<void> {
    this.isTableProcessing = true;
    this.filterVersion = filterVersion;
    this.addPersistentFilters();
    await this.getTableData?.();
    this.isTableProcessing = false;
  }

  protected getQueryString(): string {
    return Utils.buildQueryString({
      page: this.page,
      pageSize: this.pageSize,
      sortDirection: this.sortDirection,
      sortBy: this.sortBy,
      filters: Utils.encodeFilters(this.filters, this.filterVersion),
    });
  }

  protected async onSort(sortOptions: JunTableSortOptions): Promise<void> {
    this.sortBy = sortOptions.sortBy || this.sortBy;
    this.sortDirection = sortOptions.sortDirection || this.sortDirection;
    Object.assign(this.tableOptions.sortOptions, sortOptions);
    await this.init({ filterVersion: this.filterVersion });
  }

  protected async onFilter(filters: Array<JunTableFilter>): Promise<void> {
    const newFilters: IQueryFilter[] = [];
    filters.forEach((tf: JunTableFilter) => {
      const transformer = this.filterTransformers[tf.prop];
      const keyTransformer = this.filterKeyTransformers[tf.prop];
      const isDateFilter = this.dateFilterKeys.includes(tf.prop);
      if (!isDateFilter) {
        newFilters.push({
          key: keyTransformer ? keyTransformer(tf.prop) : tf.prop,
          value: transformer ? transformer(tf.value) : tf.value,
          useWildcard: !this.noWildcardFilterKeys.includes(tf.prop),
        });
      } else {
        // Transform date filter into a range
        const date = new Date(tf.value);
        const day = 60 * 60 * 24 * 1000;
        newFilters.push({
          key: tf.prop,
          // creates yyyy-mm-dd format
          value: date.toISOString().slice(0, -14),
          operation: QueryOperationTypes.GreaterOrEqual,
          useWildcard: false,
        });
        newFilters.push({
          key: tf.prop,
          // creates yyyy-mm-dd format one day ahead of filter value
          value: new Date(date.getTime() + day).toISOString().slice(0, -14),
          operation: QueryOperationTypes.LessOrEqual,
          useWildcard: false,
        });
      }
    });
    this.filters = newFilters.sort((a, b) => a.key.localeCompare(b.key));
    await this.init({ filterVersion: this.filterVersion });
  }

  protected async onPaginate(page: number): Promise<void> {
    this.page = page;
    await this.init({ filterVersion: this.filterVersion });
  }

  protected paginate<T>(array: Array<T>): T[] {
    return array.slice(
      (this.page - 1) * this.pageSize,
      this.page * this.pageSize
    );
  }

  protected addPersistentFilters() {
    // prevent any dup filters from being added
    this.filters = uniqWith(
      [...this.filters, ...this.persistentFilters],
      isEqual
    ).sort((a, b) => a.key.localeCompare(b.key));
  }

  protected async onUpdateDateRange(
    dateRangeFilterKey: string,
    dateRange: IDateRange,
    reInitialize = true
  ): Promise<void> {
    // Remove previous date range
    this.filters = this.filters.filter((f) => f.key !== dateRangeFilterKey);
    this.persistentFilters = [
      ...this.persistentFilters.filter((f) => f.key !== dateRangeFilterKey),
      {
        key: dateRangeFilterKey,
        value: dateRange.from.toISOString(),
        operation: QueryOperationTypes.GreaterOrEqual,
      },
      {
        key: dateRangeFilterKey,
        value: dateRange.to.toISOString(),
        operation: QueryOperationTypes.LessOrEqual,
      },
    ];
    if (reInitialize) await this.init({ filterVersion: this.filterVersion });
  }

  protected async removeLatestFilter(filterVersion: TableFilterTypes) {
    const removedFilter = this.tableOptions.filters?.pop();
    this.filters = this.filters?.filter(
      (filter) => filter.key !== removedFilter?.prop
    );
    await this.init({ filterVersion });
  }
}
