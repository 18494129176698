import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-779c6b61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-wrapper" }
const _hoisted_2 = { class: "grid-wrapper" }
const _hoisted_3 = { class: "left-panel" }
const _hoisted_4 = { class: "right-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_CompanyInfo = _resolveComponent("CompanyInfo")!
  const _component_BuyerInfo = _resolveComponent("BuyerInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar, {
      title: "Lead Details",
      showBackButton: "",
      backPath: _ctx.backPath,
      hideDivisionSelect: ""
    }, null, 8, ["backPath"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumbs, { items: _ctx.breadcrumbItems }, null, 8, ["items"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CompanyInfo, {
            loading: _ctx.loading,
            billingAddress: _ctx.billingAddress,
            shippingAddress: _ctx.shippingAddress,
            businessInfo: _ctx.businessInfo
          }, null, 8, ["loading", "billingAddress", "shippingAddress", "businessInfo"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BuyerInfo, {
            loading: _ctx.loading,
            profile: _ctx.profile,
            billingAddress: _ctx.billingAddress,
            shippingAddress: _ctx.shippingAddress,
            businessInfo: _ctx.businessInfo,
            status: _ctx.status,
            onRefreshData: _ctx.onRefreshData
          }, null, 8, ["loading", "profile", "billingAddress", "shippingAddress", "businessInfo", "status", "onRefreshData"])
        ])
      ])
    ])
  ], 64))
}