
import { Options, Vue } from "vue-class-component";
import { Icon, Tooltip, AdminInput } from "@/components";
import { Prop, Watch } from "vue-property-decorator";
import { AdminInputErrorEvent, IconNameTypes, MultiInputItem } from "@/types";

@Options({
  components: {
    Icon,
    Tooltip,
    AdminInput,
  },
  emits: ["update:modelValue"],
})
export default class AdminMultiInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  modelValue!: Record<string, string | number>;

  @Prop({
    type: String,
    default: null,
  })
  label!: string;

  @Prop({
    type: Number,
    default: 96,
  })
  width!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideLabels!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideErrorBox!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disableAutocomplete!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  msg!: string;

  @Prop({
    type: String,
    default: null,
  })
  tooltipMsg!: string;

  @Prop({
    type: String,
    required: true,
  })
  name!: string;

  @Prop({
    type: Function,
    default: null,
  })
  onBlur!: () => void;

  @Prop({
    type: Function,
    default: null,
  })
  onFocus!: () => void;

  @Prop({
    type: String,
    default: null,
  })
  separator!: string;

  @Prop({
    type: Array,
    required: true,
  })
  inputConfig!: MultiInputItem[];
  //endregion

  private incomingChange = false;
  private localInputs: MultiInputItem[] = this.inputConfig;
  private inputErrors: AdminInputErrorEvent[] = [];
  private IconNameTypes = IconNameTypes;

  // on every model change we need to make sure we update local input state
  // and set values from the model object
  @Watch("modelValue", { immediate: true, deep: true })
  private onModelValueChanged(val: Record<string, string | number>) {
    this.incomingChange = true;
    const inp = this.localInputs;
    // take the key value pairs and find the associated input to update
    Object.keys(val).forEach((k) => {
      const f = inp.find((i) => i.name === k);
      f && (inp[inp.indexOf(f)].value = val[k]);
    });
    this.localInputs = inp;
  }

  // on every change of localInputs we turn the array of
  // input objects into a single key value object and emit
  @Watch("localInputs", { deep: true })
  private handleInputChange(inp: MultiInputItem[]) {
    this.incomingChange &&
      this.$emit(
        "update:modelValue",
        inp.reduce((p, c) => ({ ...p, [c.name]: c.value }), {})
      );
    this.incomingChange = false;
  }

  get cmpWidth() {
    return this.fullWidth ? "w-full" : `w-${this.width}`;
  }

  get hideMsgBox() {
    return (this.hideErrorBox && (!this.error || !this.msg)) || this.hideLabels;
  }

  get error() {
    return Boolean(this.inputErrors.length);
  }

  private handleError(err: AdminInputErrorEvent) {
    // assign error array to small const for easy reading
    const ea = this.inputErrors;
    // try to find an existing error
    const er = ea.find((e) => e.name === err.name);
    er // check to see if the error exists already
      ? // if it exists check to see if the error was resolved
        !err.value
        ? // if it was resolved remove from array
          ea.splice(ea.indexOf(er), 1)
        : // if it wasnt update the error in array
          (ea[ea.indexOf(er)] = err)
      : // if we never found the error add to array
        ea.push(err);
  }

  get errorMessage() {
    // only return the first error for now
    return this.inputErrors[0]
      ? this.inputErrors[0].value.replace(`${this.name}.`, "")
      : "";
  }
}
