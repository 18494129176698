import { Options, Vue } from "vue-class-component";
import { Form } from "vee-validate";
import { Inject, InjectReactive, Prop, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import {
  EditableBoxStatusTypes,
  IconNameTypes,
  CarrierTypes,
  CountryTypes,
  ShippingCarrierSettingsDto,
  ShippingSettingsDto,
  CountryDto,
  ProvinceDto,
  UpsInformationDto,
  FedExInformationDto,
  UspsInformationDto,
  DhlInformationDto,
  CanparInformationDto,
} from "@/types";
import type {
  ToastConfig,
  IValidateResponse,
  IShippingConnectionsRepository,
  DropdownItem,
  IStateOptions,
  IApiErrorResponse,
  InfoListItem,
  CarrierAccountInfo,
} from "@/types";
import { inject } from "inversify-props";
import { namespace } from "vuex-class";
import { Utils } from "@/utils";
import { PropType } from "vue";

const notifications = namespace("notificationsVuexModule");
const staticModule = namespace("staticContentVuexModule");

@Options({
  components: {
    Form,
  },
})
export class ShippingCarrierMixin extends Vue {
  @staticModule.Getter private countries?: CountryDto[];
  @staticModule.Getter private caProvinces?: ProvinceDto[];
  @staticModule.Getter private usStates?: ProvinceDto[];

  @inject()
  shippingConnectionsRepository!: IShippingConnectionsRepository;
  @Inject() private updateCarrierSettings!: (
    carrierSettings: ShippingCarrierSettingsDto
  ) => void;
  @Inject() disconnectAccount!: () => Promise<AxiosResponse | null | undefined>;
  @Inject() private updateStatus!: (status: EditableBoxStatusTypes) => void;
  @Inject() protected setCarrierInfo!: (
    carrierType: CarrierTypes,
    accountInfo: CarrierAccountInfo
  ) => void;
  @Inject() protected backupCarrierInfo!: () => void;
  @Inject() protected restoreCarrierInfo!: () => void;
  @InjectReactive() protected carrierType?: CarrierTypes;
  @InjectReactive() protected accountInfo?: CarrierAccountInfo;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;

  @notifications.Mutation private createToastWarning!: (
    payload: ToastConfig
  ) => void;

  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  @Prop({
    type: String,
    default: EditableBoxStatusTypes.View,
  })
  status!: EditableBoxStatusTypes;

  @Prop({
    type: Object as PropType<ShippingSettingsDto>,
  })
  shippingSettings!: ShippingSettingsDto;

  @Watch("currentCountry", { immediate: true })
  toggleStateDropdown(val: string) {
    this.stateOptions = this.getCountryStates(val);
  }

  protected endpoint!: Function;

  private IconNameTypes = IconNameTypes;
  private carrierSettings: ShippingCarrierSettingsDto =
    new ShippingCarrierSettingsDto();

  protected stateOptions: IStateOptions = {
    items: this.usStateDropdown,
    placeholder: "Select State",
    label: "State",
  };
  protected currentCountry = CountryTypes.US;

  protected async connectAccount(c: CarrierTypes, i: CarrierAccountInfo) {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();

    if (validateResponse.valid) {
      this.backupCarrierInfo();
      this.updateStatus(EditableBoxStatusTypes.Loading);
      const [settings, error] = await Utils.try(
        this.shippingConnectionsRepository.post(i, c)
      );
      if (settings) {
        this.carrierSettings = settings;
        this.updateCarrierSettings(this.carrierSettings);
        this.createToastSuccess({
          message: `Shipping carrier successfully connected.`,
        });
        this.backupCarrierInfo();
      } else if (error) {
        switch (c) {
          case CarrierTypes.UPS: {
            const apiError: IApiErrorResponse = error?.response?.data;
            const errors = Utils.parseErrorArray(apiError?.errors || []);
            const invoiceErr = errors.find((el) => {
              return el.includes("invoice information");
            });

            this.updateStatus(EditableBoxStatusTypes.Edit);
            // this.restoreCarrierInfo();

            invoiceErr
              ? this.createToastWarning({
                  message: `UPS Invoice information is required to connect to this account.`,
                })
              : this.createToastError({
                  message: errors.map((m) => `<p>${m}</p>`).join(),
                  rawHtml: true,
                });
            break;
          }
          default:
            this.updateStatus(EditableBoxStatusTypes.Edit);
            this.restoreCarrierInfo();
            this.createToastError({
              message: `Shipping carrier couldn't connected.`,
            });
            break;
        }
      }
    }
  }

  get statusIsSaving() {
    return this.status === EditableBoxStatusTypes.Saving;
  }
  get statusIsView() {
    return this.status === EditableBoxStatusTypes.View;
  }
  get statusIsEdit() {
    return this.status === EditableBoxStatusTypes.Edit;
  }

  get infoListItem(): Array<InfoListItem> {
    return [
      {
        label: "Carrier Name",
        text:
          Utils.getShippingCarrierConfig(this.carrierType ?? CarrierTypes.Unset)
            ?.label || this.carrierType,
      },
    ];
  }

  protected get countryDropdown(): Array<DropdownItem> {
    return (
      this.countries
        ?.filter((c) => c.code)
        .map((country: CountryDto) => {
          return { name: country.code || "", value: country.code || "" };
        }) ?? []
    );
  }

  protected get caProvinceDropdown(): Array<DropdownItem> {
    return (
      this.caProvinces
        ?.filter((p) => p.code)
        .map((prov: ProvinceDto) => {
          return { name: prov.code || "", value: prov.code || "" };
        }) ?? []
    );
  }

  protected get usStateDropdown(): Array<DropdownItem> {
    return (
      this.usStates
        ?.filter((s) => s.code)
        .map((state: ProvinceDto) => {
          return { name: state.code || "", value: state.code || "" };
        }) ?? []
    );
  }

  protected getCountryStates(country: string): IStateOptions {
    switch (country) {
      case CountryTypes.US:
        return {
          items: this.usStateDropdown,
          placeholder: "Select State",
          label: "State",
        };
      case CountryTypes.CA:
        return {
          items: this.caProvinceDropdown,
          placeholder: "Select Province",
          label: "Province",
        };
      default:
        return {
          items: this.usStateDropdown,
          placeholder: "Select State",
          label: "State",
        };
    }
  }

  protected isUps(d: CarrierAccountInfo): d is UpsInformationDto {
    return (d as UpsInformationDto).agreeToTechnologyAgreement !== undefined;
  }

  protected isFedEx(d: CarrierAccountInfo): d is FedExInformationDto {
    return (d as FedExInformationDto).agreeToEula !== undefined;
  }

  protected isUsps(d: CarrierAccountInfo): d is UspsInformationDto {
    return (
      (d as DhlInformationDto).accountNumber === undefined &&
      (d as UspsInformationDto).password !== undefined &&
      (d as UspsInformationDto).userName !== undefined
    );
  }

  protected isDhl(d: CarrierAccountInfo): d is DhlInformationDto {
    return (
      (d as DhlInformationDto).accountNumber !== undefined &&
      (d as CanparInformationDto).password === undefined &&
      (d as CanparInformationDto).userName === undefined
    );
  }

  protected isCanpar(d: CarrierAccountInfo): d is CanparInformationDto {
    return (
      (d as CanparInformationDto).accountNumber !== undefined &&
      (d as CanparInformationDto).password !== undefined &&
      (d as CanparInformationDto).userName !== undefined
    );
  }
}
