import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-540a9771"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-wrapper min-h-128" }
const _hoisted_2 = {
  key: 0,
  "data-test-id": "accept-credit-cards-cell"
}
const _hoisted_3 = {
  key: 1,
  "data-test-id": "processor-cell"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_JunTable = _resolveComponent("JunTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_JunTable, {
      heading: _ctx.tableConfig.heading,
      headers: _ctx.tableConfig.headers,
      loading: _ctx.tableConfig.loading,
      items: _ctx.tableConfig.items,
      itemKey: _ctx.tableConfig.itemKey
    }, {
      default: _withCtx(({ item, header }) => [
        (header.prop === 'acceptCreditCards')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Icon, {
                name: _ctx.getIconName(item.acceptCreditCards),
                size: 1.125,
                class: _normalizeClass(_ctx.getIconColorClass(item.acceptCreditCards))
              }, null, 8, ["name", "size", "class"])
            ]))
          : _createCommentVNode("", true),
        (header.prop === 'paymentGatewayType')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.getPaymentGatewayType(item.paymentGatewayType)), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["heading", "headers", "loading", "items", "itemKey"])
  ]))
}