
import { Options, mixins } from "vue-class-component";
import { inject } from "inversify-props";
import { namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";
import { JunTableSortDirection } from "@juniper/ui";
import { CreditDrawer, Icon, AdminDateRange } from "@/components";
import { TableMixin } from "@/mixins";
import { Utils } from "@/utils";
import { transactionStatusLabelMap } from "@/config";
import {
  TransactionDto,
  MainRouteTypes,
  SubRouteTypes,
  QueryOperationTypes,
  TransactionTypes,
  TransactionStatusTypes,
  CreditDrawerTypes,
  TableFilterTypes,
} from "@/types";
import type { ITransactionsRepository, TableConfig, IDateRange } from "@/types";

const seller = namespace("sellerVuexModule");
const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    CreditDrawer,
    Icon,
    AdminDateRange,
  },
})
export default class DisputesTable extends mixins(TableMixin) {
  @inject() private transactionsRepository!: ITransactionsRepository;

  @seller.Getter private sellerReferenceId!: string | null;
  @manufacturers.Getter private manufacturerId!: number | null;

  private transactions: TransactionDto[] = [];
  private selectedItems: TransactionDto[] = [];
  private MainRouteTypes = MainRouteTypes;
  private SubRouteTypes = SubRouteTypes;
  private drawerTransaction: TransactionDto | null = null;
  private CreditDrawerTypes = CreditDrawerTypes;
  private today = Utils.roundToNearestDate();
  private dateRange: IDateRange = Utils.getDefaultDateRange();
  private TableFilterTypes = TableFilterTypes;

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  async created() {
    this.initTable();

    if (this.sellerReferenceId) {
      await this.init({ filterVersion: TableFilterTypes.OData });
    }
  }

  // watcher handles scenario where sellerReferenceId not resolved yet or user
  // changes division
  @Watch("sellerReferenceId")
  async onSellerReferenceIdChange(sellerId: string | null) {
    if (sellerId) {
      await this.init({ filterVersion: TableFilterTypes.OData });
    }
  }

  private initTable() {
    this.tableHeaders = [
      {
        prop: "orderId",
        text: "Order #",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "customerName",
        text: "Customer Name",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "customerNumber",
        text: "Customer Number",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "createdOn",
        text: "Date Submitted",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "disputeExpirationDate",
        text: "Expiration Date",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "status",
        text: "Status",
        canSort: true,
        canFilter: true,
        filterOptions: Object.values(TransactionStatusTypes).map(
          (statusType) => ({
            text: transactionStatusLabelMap[statusType],
            value: statusType,
          })
        ),
      },
      {
        prop: "disputeDetails",
        text: "Details",
        canSort: false,
        canFilter: false,
      },
    ];
    this.persistentFilters = [
      {
        key: "transactionType",
        value: TransactionTypes.Dispute,
        operation: QueryOperationTypes.Equals,
      },
    ];
    this.noWildcardFilterKeys.push("orderId", "customerNumber", "status");
    this.onUpdateDateRange("createdOn", this.dateRange, false);
    this.sortBy = "createdOn";
    this.sortDirection = JunTableSortDirection.Descending;
    this.pageSize = 16;
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    this.getTableData = this.getTransactions;
  }

  private async getTransactions() {
    const queryString = this.getQueryString();

    const transactions = await this.transactionsRepository.getAll(
      this.sellerReferenceId || "",
      queryString
    );

    this.transactions = transactions.data;

    this.tableOptions.paginationOptions = {
      page: transactions.meta.page,
      pageSize: transactions.meta.pageSize,
      totalRecords: transactions.meta.totalRecords,
    };
  }

  private handleOpenDrawer(item: TransactionDto) {
    if (!item.transactionReferenceId) return;
    const transaction = this.transactions.find(
      (t) => t.transactionReferenceId === item.transactionReferenceId
    );
    if (!transaction) return;
    this.drawerTransaction = transaction;
  }

  private handleCloseDrawer() {
    this.drawerTransaction = null;
  }

  private getOrderLink(item: TransactionDto): string {
    return `/${MainRouteTypes.OrderDetails}/${SubRouteTypes.Summary}?guid=${item.orderNumber}`;
  } // This is to prevent string literals in the template from wrapping, which causes vetur's syntax highlighting to break.

  get tableConfig(): TableConfig {
    return {
      heading: "Disputes",
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.transactions,
      itemKey: "transactionReferenceId",
    };
  }
}
