export enum DashboardRequestsTableHeightTypes {
  Normal = 2,
}

export enum DashboardRequestsTableWidthTypes {
  Normal = 3,
}

export enum DashboardRequestsTableColumnStartTypes {
  Normal = 6,
  Middle = 4,
}
