
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Tooltip, Icon } from "@/components";
import { IconNameTypes, TooltipPosition } from "@/types";

@Options({
  components: { Tooltip, Icon },
})
export default class InfoBox extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  tooltipMsg!: string;

  @Prop({
    type: String,
    default: TooltipPosition.Top,
  })
  msgPosition!: string;

  @Prop({
    type: String,
    default: null,
  })
  subtext!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  noPadding!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  noShadow!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  private IconNameTypes = IconNameTypes;
}
