export const SegmentOrderMap = {
  orderID: "order_id",
  subTotal: "subtotal",
  orderTotal: "total",
  shippingTotal: "shipping",
  taxTotal: "tax",
  discount: "discount",
  currencyCode: "currency",
  "orderItems.[].itemID": [
    {
      key: "[].product.product_id",
    },
    {
      key: "[].product.sku",
    },
  ],
  "orderItems.[].category": "[].product.category",
  "orderItems.[].itemName": "[].product.name",
  "orderItems.[].catalogName": "[].product.brand",
  "orderItems.[].price": "[].product.price",
  "orderItems.[].qty": "[].product.quantity",
};
