
import { Vue, Options } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { inject } from "inversify-props";
import { ImageUrlService, SegmentService } from "@/services";
import {
  IconNameTypes,
  MainRouteTypes,
  SubRouteTypes,
  MainRouteNameTypes,
  PaymentMethodTypes,
  OrderStatusTypes,
  InvoiceDto,
  OrderDto,
  OrderTrackingDto,
  ButtonColorTypes,
  PaymentTransactionDto,
  OriginationsTypes,
} from "@/types";
import type {
  IInvoiceDetails,
  ITableInvoiceItem,
  ToastConfig,
  IOrdersRepository,
  IInvoicesRepository,
  IShippingTrackingRepository,
  IPaymentsRepository,
  ITransactionsRepository,
  IAppUsersRepository,
} from "@/types";
import { Utils } from "@/utils";
import {
  Icon,
  AdminButton,
  TopBar,
  AddressInfo,
  Modal,
  Drawer,
} from "@/components";
import { JunTableColumn } from "@juniper/ui";
import { namespace } from "vuex-class";

const notifications = namespace("notificationsVuexModule");
const manufacturers = namespace("manufacturersVuexModule");
const staticContent = namespace("staticContentVuexModule");
const auth = namespace("authVuexModule");

@Options({
  components: {
    AddressInfo,
    Icon,
    AdminButton,
    TopBar,
    Modal,
    Drawer,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isPreview || this.invoiceConfirmed) {
      next();
    } else {
      if (window.confirm("Are you sure you want to leave?")) {
        next();
      }
    }
  },
})
export default class InvoiceDetails extends Vue {
  @inject() private ordersRepository!: IOrdersRepository;
  @inject() private imageUrlService!: ImageUrlService;
  @inject() private invoicesRepository!: IInvoicesRepository;
  @inject() private shippingTrackingRepository!: IShippingTrackingRepository;
  @inject() private paymentsRepository!: IPaymentsRepository;
  @inject() private transactionsRepository!: ITransactionsRepository;
  @inject() private appUsersRepository!: IAppUsersRepository;
  @inject() public segmentService!: SegmentService;

  @auth.Getter protected isMultiline!: boolean | null;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;

  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  @manufacturers.Getter private manufacturerId!: number | null;

  @staticContent.Getter private paymentProcessorName!: string;

  @Prop({
    type: String,
  })
  orderGUID!: string;

  @Prop({
    type: String,
  })
  brandId!: string;

  @Prop({
    type: Boolean,
  })
  isPreview!: boolean;

  private loading = false;
  private invoiceConfirmed = false;
  private confirmLoading = false;
  private markPaidLoading = false;
  private retryPaymentLoading = false;
  private IconNameTypes = IconNameTypes;
  private ButtonColorTypes = ButtonColorTypes;
  private buyerId = "";
  private paymentType = "";
  private orderId = 0;
  private orderItemTableHeaders: JunTableColumn[] = [
    { prop: "image", text: "", width: "5rem" },
    { prop: "itemId", text: "ItemID", width: "6.25rem" },
    { prop: "itemName", text: "Item", width: "12.5rem" },
    { prop: "note", text: "Note", wrap: true },
    { prop: "quantity", text: "QTY", width: "5rem" },
    { prop: "price", text: "Price Per", width: "7.5rem" },
    { prop: "subtotal", text: "Subtotal", width: "8.25rem" },
  ];
  private orderTableItems: ITableInvoiceItem[] = [];
  private transactionTableHeaders: JunTableColumn[] = [
    { prop: "success", text: "SUCCESS", width: "3rem" },
    { prop: "status", text: "STATUS", width: "3rem" },
    {
      prop: "response",
      text: "RESPONSE",
      width: "12.5rem",
      wrap: true,
    },
    { prop: "cardLast4Digits", text: "CARD", width: "3rem", align: "center" },
    {
      prop: "timeStamp",
      text: "TIME",
      width: "4rem",
    },
  ];
  private paymentTransactions: PaymentTransactionDto[] = [];
  private invoiceDetails: IInvoiceDetails | null = null;
  private orderTracking: OrderTrackingDto | null = null;
  private OrderStatusTypes = OrderStatusTypes;
  private showPaymentFailedModal = false;
  private isTransactionDrawerOpen = false;
  private paymentProcessorResponse = "";

  @Watch("isPreview")
  private async onIsPreviewChange(): Promise<void> {
    // This Watcher handles re-initializing this component when we redirect from /invoice-preview to /invoice-details page,
    // and vice versa, in which case the mounted() method will not be triggered automatically
    await this.init();
  }

  async mounted() {
    await this.init();
  }

  get invoiceId() {
    let { invoiceId } = this.$route.params;
    if (Array.isArray(invoiceId)) {
      invoiceId = invoiceId[0];
    }
    return Number(invoiceId);
  }

  get statusIsPaid() {
    return this.invoiceDetails?.invoiceStatus === OrderStatusTypes.Paid;
  }

  get invoiceStatus() {
    return !this.isPreview && !this.statusIsPaid && !this.canShowPaid
      ? "Not Paid"
      : this.invoiceDetails?.invoiceStatus;
  }

  get topBarTitle() {
    return this.isPreview
      ? "Invoice Preview"
      : `Invoice #${this.invoiceDetails?.invoiceNumber || ""}`;
  }

  get getOrderGUID(): string | undefined {
    return this.orderGUID ? this.orderGUID : undefined;
  }

  get getBrandId(): number | undefined {
    return this.brandId ? Number(this.brandId) : undefined;
  }

  get shipDateDisplay() {
    return this.invoiceDetails?.shipDate
      ? Utils.parseDate(this.invoiceDetails.shipDate)
      : "N/A";
  }

  get canShowPaid() {
    return (
      this.invoiceDetails?.paymentMethod !== "Credit Card" &&
      this.invoiceDetails?.paymentMethod !== "JuniperCredit"
    );
  }

  get transactionTableItems() {
    return this.paymentTransactions.sort(
      (a, b) =>
        new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
    );
  }

  private async init(): Promise<void> {
    if (this.isPreview) {
      await this.buildViewFromOrder();
    } else {
      await this.buildViewFromInvoice();
      this.fetchPaymentTransactions();
    }
  }

  async buildViewFromOrder() {
    this.loading = true;
    if (this.isPreview && this.getOrderGUID) {
      const [data] = await Utils.try(
        this.ordersRepository.get(this.getOrderGUID, this.getBrandId)
      );

      if (data) {
        // set customerID and type for payment processing
        this.buyerId = data?.channel.buyerId ?? "";
        this.orderId = data.orderID;
        this.paymentType = data?.catalogDetail.paymentMethod.type;
        await this.getOrderTracking(data.orderID);
        this.setTableAndDetailsFromOrder(data);
      }
    }
    this.loading = false;
  }

  setTableAndDetailsFromOrder(data: OrderDto) {
    this.invoiceDetails = {
      poNumber: data.catalogDetail?.poNumber,
      orderNumber: data.orderID,
      customer: data.customerName,
      customerNumber: data.customerNumber,
      paidOnDate: null,
      dueDate: null,
      shipDate: this.orderTracking?.shipDate ?? data.catalogDetail?.shipDate,
      total: data.orderTotal,
      subTotal: data.subTotal,
      shippingTotal: data.shippingTotal,
      paymentMethod: data.catalogDetail?.paymentMethod?.type,
      paymentMethodId: data.catalogDetail?.paymentMethod?.paymentMethodId,
      billingAddress: data.billingAddress,
      shippingAddress: data.shippingAddress,
      note: data.catalogDetail?.notes,
      invoiceDate: null,
      invoiceNumber: null,
      invoiceStatus: null,
    };

    this.orderTableItems = data.orderItems
      .filter((item) => !item.isDeleted)
      .map((item) => {
        return {
          image: this.imageUrlService.getProductImage(
            item.photoName,
            this.getBrandId || this.manufacturerId || 0
          ),
          itemId: item.itemID,
          itemName: item.itemName,
          note: item.notes,
          quantity: item.qty,
          price: item.price,
          subtotal: item.subTotal,
        };
      });
  }

  async buildViewFromInvoice() {
    this.loading = true;
    const [data] = await Utils.try(
      this.invoicesRepository.getInvoice(this.invoiceId)
    );

    if (data) {
      // Need to refactor
      this.orderId = data?.orderId;
      this.buyerId = (await this.fetchBuyerId()) ?? "";
      this.paymentType = data?.paymentMethod.type;
      await this.getOrderTracking(data.orderId);
      this.setTableAndDetailsFromInvoice(data);
    }
    this.loading = false;
  }

  private async getOrderTracking(orderId: number): Promise<void> {
    const [tracking] = await Utils.try(
      this.shippingTrackingRepository.getOrders({
        orders: [orderId],
      })
    );
    if (tracking && tracking.ordersTracking[0]) {
      this.orderTracking = tracking.ordersTracking[0];
    }
  }

  setTableAndDetailsFromInvoice(data: InvoiceDto) {
    this.invoiceDetails = {
      poNumber: data.poNumber,
      orderNumber: data.orderId,
      customer: data.companyName,
      customerNumber: data.customerNumber,
      paidOnDate: data.paidOnDate,
      dueDate: data.dueDate,
      shipDate: this.orderTracking?.shipDate ?? data.shipDate,
      total: data.amount,
      subTotal: data.subTotal,
      shippingTotal: data.shipping,
      paymentMethod: data.paymentMethod?.type,
      paymentMethodId: data.paymentMethod?.paymentMethodId,
      billingAddress: data.billingAddress,
      shippingAddress: data.shippingAddress,
      note: data.notes,
      invoiceDate: data.invoiceDate,
      invoiceNumber: data.manufacturerInvoiceNo,
      invoiceStatus: data.manufacturerInvoiceStatus,
    };

    this.orderTableItems = data.invoiceItems.map((item) => {
      return {
        image: this.imageUrlService.getProductImage(
          item.photoName || "",
          this.getBrandId || this.manufacturerId || 0
        ),
        itemId: item.itemId,
        itemName: item.itemName,
        note: item.notes,
        quantity: item.quantity,
        price: item.price,
        subtotal: item.subTotal,
      };
    });
  }

  private get filterByOrderId() {
    return Utils.buildQueryString({
      filters: Utils.encodeFilters([
        {
          key: "orderId",
          value: this.orderId,
        },
      ]),
    });
  }

  async fetchOrderGuid() {
    const [order] = await Utils.try(
      this.ordersRepository.getAll(this.filterByOrderId)
    );

    if (order && order.data.length && order.data[0].orderGUID) {
      return order.data[0].orderGUID;
    }
    return undefined;
  }

  // Need to refactor
  async fetchBuyerId() {
    const id = this.getOrderGUID
      ? this.getOrderGUID
      : await this.fetchOrderGuid();

    if (id) {
      const [order] = await Utils.try(
        this.ordersRepository.get(id, this.getBrandId)
      );

      if (order && order.channel.buyerId) {
        return order.channel.buyerId;
      }
    }
    return undefined;
  }

  async fetchBuyerRefId(id: string | undefined) {
    if (id) {
      const [order] = await Utils.try(
        this.ordersRepository.get(id, this.getBrandId)
      );
      if (order && order.channel.buyerId) {
        const [buyer] = await Utils.try(
          this.appUsersRepository.getAppUser(Number(order.channel.buyerId))
        );

        if (buyer && buyer.profile?.buyerReferenceId) {
          return buyer.profile.buyerReferenceId;
        }
      }
    }

    return undefined;
  }

  async fetchPaymentTransactions() {
    const [paymentTransactions] = await Utils.try(
      this.paymentsRepository.getTransactions(this.orderId)
    );
    if (paymentTransactions) {
      this.paymentTransactions = paymentTransactions;
    }
  }

  async processCredit(invoiceId: number): Promise<boolean> {
    const id = this.getOrderGUID
      ? this.getOrderGUID
      : await this.fetchOrderGuid();
    const buyer = await this.fetchBuyerRefId(id);
    if (id && buyer) {
      const [charge] = await Utils.try(
        this.transactionsRepository.charge(id, buyer)
      );
      if (charge) {
        const [status] = await Utils.try(
          this.invoicesRepository.setPaid(invoiceId)
        );
        if (status) {
          this.createToastSuccess({
            message: `Invoice #${status.manufacturerInvoiceNo} JuniperCredit Payment processed successfully and marked as paid!`,
          });
          this.setTableAndDetailsFromInvoice(status);
          await this.trackOrder(status.orderId);
          return true;
        } else {
          this.createToastError({
            message: `Failed to set payment status. Contact support.`,
          });
          return false;
        }
      } else {
        return false;
      }
    } else {
      this.createToastError({
        message: `Failed to process JuniperCredit. Try again Later.`,
      });
      return false;
    }
  }

  async processPayment(id: number): Promise<boolean> {
    const [payment, error] = await Utils.try(
      this.paymentsRepository.post({
        payment: {
          amount: Utils.extractNumberFromCurrency(
            this.invoiceDetails?.total ?? ""
          ).toString(),
          // TODO: This should not be hard coded, and eventually should come from the currency the Buyer is using to pay
          currencyCode: "USD",
          paymentMethodId: this.invoiceDetails?.paymentMethodId ?? 0,
        },
        appUserId: Number(this.buyerId),
        orderId: this.invoiceDetails?.orderNumber.toString() ?? "",
      })
    );
    if (payment) {
      const [status] = await Utils.try(this.invoicesRepository.setPaid(id));
      if (status) {
        this.createToastSuccess({
          message: `Invoice #${status.manufacturerInvoiceNo} Payment processed successfully and marked as paid!`,
        });
        this.setTableAndDetailsFromInvoice(status);
        await this.trackOrder(status.orderId);
        await this.fetchPaymentTransactions();
        return true;
      } else {
        this.createToastError({
          message: `Failed to set payment status. Contact support.`,
        });
        return false;
      }
    } else if (
      error &&
      error.response?.data.origination ===
        OriginationsTypes.PaymentTransactionFailure
    ) {
      // suppressing network error toast via toastConfig in App.vue
      this.paymentProcessorResponse =
        error.response.data.errors[0].message || "Unspecified";
      await this.fetchPaymentTransactions();
      this.showPaymentFailedModal = true;
      return false;
    } else {
      this.createToastError({
        message: `Failed to process payment. Please retry.`,
      });
      return false;
    }
  }

  async onRetryPaymentClick() {
    this.retryPaymentLoading = true;
    if (this.paymentType === "JuniperCredit") {
      await this.processCredit(this.invoiceId);
    } else {
      await this.processPayment(this.invoiceId);
    }
    this.retryPaymentLoading = false;
  }

  async trackOrder(orderId: number) {
    const [order] = await Utils.try(this.ordersRepository.shipped(orderId));
    if (order) {
      this.segmentService.trackOrderCompleted(order);
    }
  }

  async onMarkPaidClick() {
    this.markPaidLoading = true;
    const [data] = await Utils.try(
      this.invoicesRepository.setPaid(this.invoiceId)
    );

    if (data) {
      this.createToastSuccess({
        message: `Invoice #${data.manufacturerInvoiceNo} successfully marked as paid.`,
      });
      this.setTableAndDetailsFromInvoice(data);
    }
    this.markPaidLoading = false;
  }

  goBack() {
    const previousRoute: string | null = history.state?.back;
    if (previousRoute !== null) return this.$router.go(-1);

    const query = Utils.buildQueryString({ guid: this.getOrderGUID || "" });
    this.$router.replace(
      `/${MainRouteTypes.OrderDetails}/${SubRouteTypes.OrderShipping}${query}`
    );
  }

  onCancel() {
    this.goBack();
  }

  async onConfirm() {
    this.confirmLoading = true;
    if (this.isPreview && this.getOrderGUID) {
      const [data] = await Utils.try(
        this.invoicesRepository.createInvoice(this.getOrderGUID)
      );

      if (data) {
        this.createToastSuccess({
          message: `Invoice #${data.manufacturerInvoiceNo} created successfully!`,
        });
        // flag to prevent 'beforeRouteLeave' hook from creating alert
        this.invoiceConfirmed = true;
        let paymentSuccess = false;
        switch (this.paymentType) {
          case Utils.getPaymentMethodLabel(PaymentMethodTypes.CreditCard):
            paymentSuccess = await this.processPayment(data.invoiceId);
            break;
          case Utils.getPaymentMethodLabel(PaymentMethodTypes.JuniperCredit):
            paymentSuccess = await this.processCredit(data.invoiceId);
            break;
          case Utils.getPaymentMethodLabel(PaymentMethodTypes.Terms):
            await this.trackOrder(data.orderId);
            paymentSuccess = true;
            break;
          default:
            break;
        }
        if (paymentSuccess) {
          this.goBack();
        } else {
          // If charge failed, need to redirect to invoice-details screen where "Retry Processing Payment" button is displayed
          await this.$router.replace({
            name: MainRouteNameTypes.InvoiceDetails,
            params: { invoiceId: data.invoiceId },
          });
        }
      }
    }

    this.confirmLoading = false;
  }

  openTransactionDrawer() {
    this.showPaymentFailedModal = false;
    this.isTransactionDrawerOpen = true;
  }

  closeTransactionDrawer() {
    this.isTransactionDrawerOpen = false;
  }
}
