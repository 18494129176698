import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize } from "typescript-json-serializer";
import {
  ISellerRepository,
  ISellerPostPayload,
  SellerDto,
  IExtendedAxiosRequestConfig,
} from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Credit/Seller`;

@injectable()
export class SellerRepository implements ISellerRepository {
  public async get(suppressErrorToast = false): Promise<SellerDto> {
    const config: IExtendedAxiosRequestConfig = suppressErrorToast
      ? { suppressToastOnStatus: [403, 404] }
      : {};
    const { data } = await axios.get(`${ENDPOINT_URL}`, config);
    return deserialize<SellerDto>(data, SellerDto);
  }

  public async getBySellerId(sellerReferenceId: string): Promise<SellerDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${sellerReferenceId}`);
    return deserialize<SellerDto>(data, SellerDto);
  }

  public async post({
    manufacturerId,
    currency,
  }: ISellerPostPayload): Promise<string> {
    const { data: sellerReferenceId } = await axios.post(ENDPOINT_URL, {
      manufacturerId,
      currency,
    });
    return sellerReferenceId;
  }
}
