import { OrderDto, ProfileBrandDto, SegmentEventsTypes } from "@/types";
import { injectable } from "inversify-props";
import objectMapper from "object-mapper";
import { SegmentOrderMap } from "@/maps";

@injectable()
export class SegmentService {
  public identifyUser(email: string, userName: string, companyId: string) {
    // @ts-ignore
    window.analytics.identify(email, {
      name: userName,
      email: email,
      company: companyId,
    });
  }

  public setGroup(manufacturerId: number, profileBrand: ProfileBrandDto) {
    // @ts-ignore
    window.analytics.group(manufacturerId, {
      name: profileBrand.sellerName,
      email: profileBrand.contact.brandContactEmail,
      phone: profileBrand.contact.phone,
    });
  }

  public trackOrderUpdated(order: OrderDto) {
    // @ts-ignore
    window.analytics.track(
      SegmentEventsTypes.OrderUpdated,
      objectMapper(order, SegmentOrderMap)
    );
  }

  public trackOrderCompleted(order: OrderDto) {
    // @ts-ignore
    window.analytics.track(
      SegmentEventsTypes.OrderCompleted,
      objectMapper(order, SegmentOrderMap)
    );
  }

  public trackOrderRefunded(order: OrderDto) {
    // @ts-ignore
    window.analytics.track(
      SegmentEventsTypes.OrderRefunded,
      objectMapper(order, SegmentOrderMap)
    );
  }

  public trackOrderCancelled(order: OrderDto) {
    // @ts-ignore
    window.analytics.track(
      SegmentEventsTypes.OrderCancelled,
      objectMapper(order, SegmentOrderMap)
    );
  }
}
