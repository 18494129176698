import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95805954"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiLinePaymentTable = _resolveComponent("MultiLinePaymentTable")!
  const _component_SingleLinePaymentSettings = _resolveComponent("SingleLinePaymentSettings")!
  const _component_JunSpinner = _resolveComponent("JunSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isMultiline)
            ? (_openBlock(), _createBlock(_component_MultiLinePaymentTable, { key: 0 }))
            : (_openBlock(), _createBlock(_component_SingleLinePaymentSettings, { key: 1 }))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_JunSpinner, { size: "3rem" })
        ]))
  ]))
}