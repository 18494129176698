import { ValueDto, WeightDto, DimensionsDto, LabelMessagesDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class PackageDto {
  @JsonProperty() packageCode!: string | null;
  @JsonProperty() weight!: WeightDto;
  @JsonProperty() dimensions!: DimensionsDto;
  @JsonProperty() insuredValue?: ValueDto;
  @JsonProperty() trackingNumber?: string | null;
  @JsonProperty() labelMessages?: LabelMessagesDto;
  @JsonProperty() packageId?: string | null;
}
