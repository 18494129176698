import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40535308"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dynamic-labels-container" }
const _hoisted_2 = { class: "dynamic-labels-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AdminInput = _resolveComponent("AdminInput")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "slide-left" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "dynamic-labels-row",
            key: index
          }, [
            _createVNode(_component_Dropdown, {
              class: "mr-4",
              items: _ctx.availableDropdownItems(index),
              modelValue: _ctx.modelValue[index].fieldName,
              "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[index].fieldName) = $event),
              placeholder: "Select UDF",
              fullWidth: "",
              name: 'key-' + index,
              label: index === 0 ? 'UDF' : null
            }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "name", "label"]),
            _createVNode(_component_AdminInput, {
              name: 'label-' + index,
              label: index === 0 ? 'Custom Label' : null,
              msg: "required",
              modelValue: _ctx.modelValue[index].label,
              "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[index].label) = $event),
              fullWidth: ""
            }, null, 8, ["name", "label", "modelValue", "onUpdate:modelValue"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Icon, {
                class: "cursor-pointer",
                onClick: ($event: any) => (_ctx.deleteRow(index)),
                name: _ctx.IconNameTypes.Trash,
                size: 1.25,
                disabled: _ctx.modelValue.length === 1
              }, null, 8, ["onClick", "name", "size", "disabled"]),
              (index === _ctx.modelValue.length - 1)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    class: "cursor-pointer",
                    onClick: _ctx.addRow,
                    name: _ctx.IconNameTypes.AddV2,
                    size: 1.25,
                    disabled: _ctx.addDisabled
                  }, null, 8, ["onClick", "name", "size", "disabled"]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}