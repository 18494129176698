
import { mixins, Options, VueMixin } from "vue-class-component";
import "reflect-metadata";
import { Prop, Watch } from "vue-property-decorator";
import {
  DropdownItem,
  IconNameTypes,
  IndustryCategoryDto,
  CategoryMappingFieldData,
  TableFilterTypes,
} from "@/types";
import { inject } from "inversify-props";
import { IndustryCategoryService } from "@/services";
import { VeeFieldMixin } from "@/mixins";
import { Icon, Dropdown } from "@/components";
import { TableMixin } from "@/mixins";
import { namespace } from "vuex-class";

const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    Dropdown,
    Icon,
  },
})
export default class CategoryMappingField extends mixins<
  [VueMixin<VeeFieldMixin<CategoryMappingFieldData[]>>, VueMixin<TableMixin>]
>(VeeFieldMixin, TableMixin) {
  @inject() private industryCategoryService!: IndustryCategoryService;

  @manufacturers.Getter private manufacturerId!: number | null;

  @Prop({
    type: Array,
    default: [],
  })
  modelValue!: CategoryMappingFieldData[];

  @Prop({
    type: String,
    default: "",
  })
  name!: string;

  @Prop({
    type: [Array, null],
    default: [],
  })
  industries!: IndustryCategoryDto[];

  @Prop({
    type: [Array, null],
    default: null,
  })
  industrySubCategories!: IndustryCategoryDto[];

  label = null;

  private IconNameTypes = IconNameTypes;
  private tableItems: CategoryMappingFieldData[] = [];
  private indexedModelData: CategoryMappingFieldData[] = [];

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  created() {
    this.initTable();
  }

  private initTable() {
    this.tableHeaders = [
      {
        prop: "dataField",
        text: "Your Data Fields",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "industry",
        text: "Marketplace Categories",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "subcategory",
        text: "Marketplace SubCategories",
        canSort: false,
        canFilter: false,
      },
    ];
    this.page = 1;
    this.pageSize = 16;
    this.getTableData = this.getTableItems;
    this.indexModelData();
    this.init({ filterVersion: TableFilterTypes.OData });
  }

  private indexModelData() {
    this.indexedModelData = this.modelValue.map((item, index) => {
      return { ...item, index: index };
    });
  }

  private getTableItems() {
    this.tableItems = this.paginate(this.indexedModelData);

    this.tableOptions.paginationOptions = {
      page: this.page,
      pageSize: this.pageSize,
      totalRecords: this.modelValue.length,
    };
  }

  private getSubCategoryDropdownItems(index: number) {
    const filteredList = this.industryCategoryService.getCategoriesByParentId(
      this.industrySubCategories,
      this.modelValue[index].industry || 0
    );
    return filteredList.map((industryCategory) => {
      return {
        name: industryCategory.name,
        value: industryCategory.appCategoryId,
      };
    });
  }

  private getIndustryDropdownItems(): Array<DropdownItem> {
    return this.industries.map((industry) => {
      return {
        name: industry.name,
        value: industry.appCategoryId,
      } as DropdownItem;
    });
  }

  onIndustryChange(index: number) {
    this.modelValue[index].subcategory = null;
  }

  @Watch("modelValue", { deep: true })
  onModelValueChanged(val: CategoryMappingFieldData[]) {
    this.field.handleChange(val);
  }
}
