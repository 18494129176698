
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Utils } from "@/utils";
import {
  Dropdown,
  Checkbox,
  ShippingCarrierAcknowledgement,
} from "@/components";
import {
  EditableBoxStatusTypes,
  ShippingSettingsDto,
  ShippingCarrierAccountDto,
  ShippingMethodTypes,
} from "@/types";
import { PropType } from "@vue/runtime-core";

@Options({
  emits: ["updateSharedCarrier"],
  components: {
    Dropdown,
    Checkbox,
    ShippingCarrierAcknowledgement,
  },
})
export default class ShipWithJuniperSettings extends Vue {
  @Prop({
    type: String,
    default: EditableBoxStatusTypes.View,
  })
  status!: EditableBoxStatusTypes;

  @Prop({
    type: Object as PropType<ShippingSettingsDto>,
  })
  shippingSettings!: ShippingSettingsDto;

  @Prop({
    type: String,
  })
  selectedShipMethod!: ShippingMethodTypes;

  @Prop({
    type: Array,
  })
  sharedAccounts!: ShippingCarrierAccountDto[];

  private selectedCarrier = this.shippingSettings.carrierAccountId;

  get carrierDropdownItems() {
    return this.sharedAccounts.map((carrier) => {
      return {
        name:
          Utils.getShippingCarrierConfig(carrier.carrierName)?.label ||
          carrier.carrierName,
        value: carrier.carrierId,
      };
    });
  }
  get statusIsView() {
    return this.status === EditableBoxStatusTypes.View;
  }
  @Watch("selectedCarrier")
  onCarrierChanged() {
    this.$emit("updateSharedCarrier", this.selectedCarrier);
  }
}
