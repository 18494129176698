import store from "@/store";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { JunToastType } from "@juniper/ui";
import type { ToastConfig } from "@/types";

const PUBLISH_WARNING_TOAST_ID = "publish-warning-id";

@Module({
  store,
  namespaced: true,
})
export default class NotificationsVuexModule extends VuexModule {
  private defaultToastDuration = 7000;
  private _toasts: ToastConfig[] = [];

  // GETTERS
  public get toasts(): ToastConfig[] {
    return this._toasts;
  }

  // MUTATIONS
  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this.defaultToastDuration = 7000;
    this._toasts = [];
  }

  @Mutation
  public createToastError(payload: ToastConfig) {
    this._toasts.push({
      message: payload.message,
      rawHtml: payload.rawHtml,
      duration: payload.persist
        ? undefined // Pass 'undefined' to JunToast 'duration' prop to persist the toast indefinitely
        : payload.duration || this.defaultToastDuration,
      type: payload.type ?? JunToastType.Error,
      id: payload.id,
    });
  }

  @Mutation
  public createToastWarning(payload: ToastConfig) {
    this._toasts.push({
      message: payload.message,
      rawHtml: payload.rawHtml,
      duration: payload.persist
        ? undefined
        : payload.duration || this.defaultToastDuration,
      type: payload.type ?? JunToastType.Caution,
      id: payload.id,
    });
  }

  @Mutation
  public createToastSuccess(payload: ToastConfig) {
    this._toasts.push({
      message: payload.message,
      rawHtml: payload.rawHtml,
      duration: payload.persist
        ? undefined
        : payload.duration || this.defaultToastDuration,
      type: payload.type ?? JunToastType.Success,
      id: payload.id,
    });
  }

  @Mutation
  public removeToastById(id: string) {
    this._toasts = this._toasts.filter((toast) => {
      toast.id !== id;
    });
  }

  // ACTIONS
  @Action
  public handleAutoPublishOff() {
    this.context.commit("manufacturersVuexModule/setAutoPublishStatus", false, {
      root: true,
    });
    this.context.dispatch("openAutoPublishWarning");
  }

  @Action
  public async openAutoPublishWarning() {
    if (!this.context.rootGetters["manufacturersVuexModule/autoPublish"]) {
      // prevent dup of this toast by always attempting to remove before adding
      await this.context.commit("removeToastById", PUBLISH_WARNING_TOAST_ID);
      this.context.commit("createToastWarning", {
        message: "Recent update requires publishing.",
        persist: true,
        id: PUBLISH_WARNING_TOAST_ID,
      });
    }
  }

  @Action
  public handleAutoPublishOn() {
    this.context.commit("manufacturersVuexModule/setAutoPublishStatus", true, {
      root: true,
    });
    this.context.commit("removeToastById", PUBLISH_WARNING_TOAST_ID);
  }
}
