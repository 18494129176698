import {
  IHandshakeRepository,
  HandshakeCredentials,
  HandshakeTokenDto,
} from "@/types";
import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/handshake-login`;

@injectable()
export class HandshakeRepository implements IHandshakeRepository {
  public async post(
    handShakeCredentials: HandshakeCredentials
  ): Promise<HandshakeTokenDto> {
    const { data } = await axios.post(ENDPOINT_URL, handShakeCredentials);
    return deserialize<HandshakeTokenDto>(data, HandshakeTokenDto);
  }

  public async delete(token: string): Promise<void> {
    return axios.delete(ENDPOINT_URL, { data: { token } });
  }
}
