
import { nextTick, PropType } from "vue";
import { namespace } from "vuex-class";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { inject } from "inversify-props";
import { Form } from "vee-validate";
import * as yup from "yup";
import { JunToastConfig } from "@juniper/ui";
import {
  Drawer,
  Icon,
  AdminButton,
  InfoList,
  Dropdown,
  AdminInput,
  Checkbox,
  AdminTextarea,
} from "@/components";
import { transactionStatusLabelMap } from "@/config";
import { Utils, Validators } from "@/utils";
import {
  CreditDrawerStepTypes,
  InfoListVerticalAlignmentTypes,
  TransactionStatusTypes,
  TransactionTypes,
  CreditDrawerTypes,
  CreditRefundReasonDto,
  TransactionDto,
  DisputeDto,
} from "@/types";
import type {
  DisputeFormType,
  DropdownItem,
  IDisputeAcceptPayload,
  IDisputeCancelPayload,
  InfoListItem,
  IDisputesRepository,
  IValidateResponse,
  ITransactionsRepository,
} from "@/types";

const staticContent = namespace("staticContentVuexModule");
const notifications = namespace("notificationsVuexModule");
const seller = namespace("sellerVuexModule");

@Options({
  name: "CreditDrawer",
  components: {
    Drawer,
    Icon,
    AdminButton,
    InfoList,
    Dropdown,
    AdminInput,
    Checkbox,
    AdminTextarea,
    Form,
  },
  emits: ["close-drawer", "refresh-table"],
})
export default class CreditDrawer extends Vue {
  @inject() private disputesRepository?: IDisputesRepository;
  @inject() private transactionsRepository!: ITransactionsRepository;

  @seller.Getter private sellerReferenceId!: string | null;

  @staticContent.Getter private creditRefundReasons?: CreditRefundReasonDto[];
  @notifications.Mutation private createToastSuccess?: (
    payload: JunToastConfig
  ) => void;
  @notifications.Mutation private createToastError?: (
    payload: JunToastConfig
  ) => void;

  @Prop({
    type: String as PropType<CreditDrawerTypes>,
    default: CreditDrawerTypes.Dispute,
  })
  type!: CreditDrawerTypes;

  @Prop({
    type: [Object as PropType<TransactionDto>, null],
    default: null,
  })
  transaction!: TransactionDto | null;

  private loading = false;
  private step: CreditDrawerStepTypes = CreditDrawerStepTypes.Preview;
  private isFullRefund = true;
  private disputeAcceptFormValues: DisputeFormType | null = null;
  private disputeRejectFormValues: IDisputeCancelPayload | null = null;
  private acceptValidationSchema: ReturnType<typeof yup.object> | null = null;
  private rejectValidationSchema: ReturnType<typeof yup.object> | null = null;
  private CreditDrawerStepTypes = CreditDrawerStepTypes;
  private InfoListVerticalAlignmentTypes = InfoListVerticalAlignmentTypes;
  private disputeChargeTransaction: TransactionDto | null = null;
  private disputeRefundTransactions: TransactionDto[] | null = null;
  private actionInProgress = false;

  @Watch("transaction", { immediate: true })
  async initCreditDrawer(transaction: TransactionDto | null) {
    if (!transaction) return;
    this.loading = true;
    if (this.type === CreditDrawerTypes.Refund) {
      this.step = CreditDrawerStepTypes.Accept;
    }
    if (this.type === CreditDrawerTypes.Dispute) {
      await Promise.all([
        this.getDisputeChargeTransaction(),
        this.getDisputeRefundTransactions(),
      ]);
    }
    this.acceptValidationSchema = this.buildAcceptFormValidationSchema();
    this.rejectValidationSchema = this.buildRejectFormValidationSchema();
    this.disputeAcceptFormValues = this.buildInitialAcceptForm();
    this.disputeRejectFormValues = this.buildInitialRejectForm();
    this.isFullRefund = true;
    this.loading = false;
  }

  @Watch("isFullRefund", { immediate: true })
  async onisFullRefundChange(isFullRefund: boolean) {
    if (!this.disputeAcceptFormValues || !this.transaction) return;
    if (isFullRefund) {
      this.disputeAcceptFormValues.refundAmount = this.transaction.totalAmount;
    } else {
      this.disputeAcceptFormValues.refundAmount = "$0.00";
      await nextTick();
      const inputComponent = this.$refs.refundAmountInput as Vue | undefined;
      const input: HTMLInputElement | null =
        inputComponent?.$el?.querySelector("input") ?? null;
      input?.focus();
    }
  }

  private buildAcceptFormValidationSchema(): ReturnType<typeof yup.object> {
    return yup.object().shape({
      disputeReason: yup
        .mixed()
        .test(
          "test-has-dispute-reason",
          "Refund reason is required",
          Number.isFinite
        ),
      refundAmount: yup
        .string()
        .required("Refund amount is required")
        .matches(Validators.currency, {
          message: "Must be a valid currency amount",
          excludeEmptyString: true,
        })
        .test(
          "test-no-greater-than-total",
          `Cannot exceed - ${this.transaction?.totalAmount ?? "$0.00"}`,
          (val) =>
            !val ||
            Utils.extractNumberFromCurrency(val as string) <=
              Utils.extractNumberFromCurrency(
                this.transaction?.totalAmount ?? "$0.00"
              )
        )
        .test(
          "test-no-less-than-0",
          `Must be greater than $0.00`,
          (val) =>
            !val ||
            Utils.extractNumberFromCurrency(val as string) >
              Utils.extractNumberFromCurrency("$0.00")
        ),
      refundComment: yup.string().required().min(1, "Note is required"),
    });
  }

  private buildRejectFormValidationSchema(): ReturnType<typeof yup.object> {
    return yup.object().shape({
      disputeCancellationComment: yup
        .string()
        .required()
        .min(1, "Note is required"),
    });
  }

  private buildInitialAcceptForm(): DisputeFormType {
    return {
      refundReasonId: 0,
      refundAmount: this.transaction?.totalAmount ?? "$0.00",
      refundComment: "",
      isDispute: this.type === CreditDrawerTypes.Dispute,
      transactionReferenceId: this.transaction?.transactionReferenceId ?? "",
      createdBy: this.transaction?.createdBy ?? "",
    };
  }

  private buildInitialRejectForm(): IDisputeCancelPayload {
    return {
      disputeCancellationComment: "",
      transactionReferenceId: this.transaction?.transactionReferenceId ?? "",
      createdBy: this.transaction?.createdBy ?? "",
      authorizationId: this.transaction?.authorizationId ?? "",
    };
  }

  private async handleDrawerLeftBtnClick(): Promise<void> {
    if (this.step === CreditDrawerStepTypes.Preview) {
      this.step = CreditDrawerStepTypes.Reject;
    } else {
      if (this.type === CreditDrawerTypes.Refund) {
        this.handleCloseDrawer();
      } else if (this.type === CreditDrawerTypes.Dispute) {
        this.step = CreditDrawerStepTypes.Preview;
      }
    }
  }

  private async handleDrawerRightBtnClick(): Promise<void> {
    if (this.step === CreditDrawerStepTypes.Preview) {
      this.step = CreditDrawerStepTypes.Accept;
      return;
    }
    this.actionInProgress = true;
    if (this.step === CreditDrawerStepTypes.Accept) {
      await this.handleAcceptDisputeAndRefund();
    } else {
      await this.handleRejectDispute();
    }
    this.actionInProgress = false;
  }

  private async handleAcceptDisputeAndRefund(): Promise<void> {
    if (
      !this.transaction?.transactionReferenceId ||
      !this.disputeAcceptFormValues
    ) {
      return;
    }
    const validateResponse: IValidateResponse = await (
      this.$refs.acceptForm as HTMLFormElement
    ).validate();
    if (validateResponse.valid) {
      const disputeAcceptPayload: IDisputeAcceptPayload = {
        ...this.disputeAcceptFormValues,
        refundAmount: Utils.extractNumberFromCurrency(
          this.disputeAcceptFormValues.refundAmount
        ),
      };
      if (
        disputeAcceptPayload.refundAmount ===
        Utils.extractNumberFromCurrency(this.transaction.totalAmount)
      ) {
        delete disputeAcceptPayload.refundAmount; // Omit refundAmount field in the payload to do a full dispute/refund
      }
      let response: DisputeDto | string | null = null;
      let error: unknown;
      if (this.type === CreditDrawerTypes.Dispute) {
        [response, error] = await Utils.try(
          this.disputesRepository?.accept(disputeAcceptPayload)
        );
      } else if (this.type === CreditDrawerTypes.Refund) {
        if (await this.hasUnResolvedDisputes()) {
          this.handleCloseDrawer();
          this.$emit("refresh-table");
          return this.createToastError?.({
            message:
              "A dispute has been requested for this transaction. Please resolve the dispute.",
          });
        }
        [response, error] = await Utils.try(
          this.disputesRepository?.refund(disputeAcceptPayload)
        );
      }
      if (response !== null) {
        this.showResolvedNotification();
        this.handleCloseDrawer();
        this.$emit("refresh-table");
      }
      if (error) this.showErrorNotification();
    }
  }

  private async getDisputeChargeTransaction(): Promise<void> {
    if (
      !this.sellerReferenceId ||
      !this.transaction?.parentTransactionReferenceId
    ) {
      return;
    }
    const [disputeChargeTransaction] = await Utils.try(
      this.transactionsRepository.getAll(
        this.sellerReferenceId,
        Utils.buildQueryString({
          filters: Utils.encodeFilters([
            {
              key: "transactionReferenceId",
              value: this.transaction.parentTransactionReferenceId,
            },
            { key: "transactionType", value: TransactionTypes.Charge },
          ]),
        })
      )
    );
    this.disputeChargeTransaction = disputeChargeTransaction?.data?.[0] ?? null;
  }

  private async getDisputeRefundTransactions(): Promise<void> {
    if (!this.sellerReferenceId || !this.transaction?.transactionReferenceId) {
      return;
    }
    const [disputeRefundTransactions] = await Utils.try(
      this.transactionsRepository.getAll(
        this.sellerReferenceId,
        Utils.buildQueryString({
          filters: Utils.encodeFilters([
            {
              key: "parentTransactionReferenceId",
              value: this.transaction.transactionReferenceId,
            },
            { key: "transactionType", value: TransactionTypes.Refund },
          ]),
        })
      )
    );
    this.disputeRefundTransactions = disputeRefundTransactions?.data ?? null;
  }

  private async hasUnResolvedDisputes(): Promise<boolean> {
    if (!this.transaction?.transactionReferenceId || !this.sellerReferenceId) {
      return false;
    }
    const [childTransactions] = await Utils.try(
      this.transactionsRepository.getAll(
        this.sellerReferenceId,
        Utils.buildQueryString({
          filters: Utils.encodeFilters([
            {
              key: "parentTransactionReferenceId",
              value: this.transaction.transactionReferenceId,
            },
            { key: "transactionType", value: TransactionTypes.Dispute },
          ]),
        })
      )
    );
    return Boolean(
      childTransactions?.data.some(
        (ct) =>
          ct.status ===
          transactionStatusLabelMap[TransactionStatusTypes.Created]
      )
    );
  }

  private async handleRejectDispute(): Promise<void> {
    if (
      !this.transaction?.transactionReferenceId ||
      !this.disputeRejectFormValues
    ) {
      return;
    }
    const validateResponse: IValidateResponse = await (
      this.$refs.rejectForm as HTMLFormElement
    ).validate();
    if (validateResponse.valid) {
      const [res, error] = await Utils.try(
        this.disputesRepository?.cancel(this.disputeRejectFormValues)
      );
      if (res) {
        this.showResolvedNotification();
        this.handleCloseDrawer();
        this.$emit("refresh-table");
      }
      if (error) this.showErrorNotification();
    }
  }

  private showResolvedNotification(): void {
    this.createToastSuccess?.({
      message:
        this.type === CreditDrawerTypes.Refund
          ? `Successfully refunded Order# ${this.transaction?.orderId}`
          : this.type === CreditDrawerTypes.Dispute
          ? `Dispute for Order# ${this.transaction?.orderId} is successfully resolved.`
          : "",
    });
  }

  private showErrorNotification(): void {
    this.createToastError?.({
      message:
        this.type === CreditDrawerTypes.Refund
          ? `Failed to issue refund for Order# ${this.transaction?.orderId}. Please contact support.`
          : this.type === CreditDrawerTypes.Dispute
          ? `Failed to resolve dispute for Order# ${this.transaction?.orderId}. Please contact support.`
          : "",
    });
  }

  private handleCloseDrawer(): void {
    this.step = CreditDrawerStepTypes.Preview;
    this.$emit("close-drawer");
  }

  private get isDrawerOpen(): boolean {
    return Boolean(this.transaction);
  }

  private get primaryActionButtonText(): string {
    return this.step === CreditDrawerStepTypes.Preview ? `Accept` : `Submit`;
  }

  private get secondaryActionButtonText(): string {
    return this.step === CreditDrawerStepTypes.Preview ? `Reject` : `Back`;
  }

  private get showDisputePreview(): boolean {
    return Boolean(
      this.step === CreditDrawerStepTypes.Preview ||
        this.step === CreditDrawerStepTypes.Reject
    );
  }

  private get disputeReasonDropdownItems(): DropdownItem[] {
    return (this.creditRefundReasons?.filter((r) => Boolean(r.name)) ??
      []) as DropdownItem[];
  }

  private get drawerHeaderText(): string {
    if (!this.transaction) return "";
    switch (this.step) {
      case CreditDrawerStepTypes.Reject: {
        return `Reject Dispute: #${this.transaction.orderId}`;
      }
      case CreditDrawerStepTypes.Accept: {
        return this.type === CreditDrawerTypes.Refund
          ? "Issue Refund"
          : this.type === CreditDrawerTypes.Dispute
          ? `Accept Dispute: #${this.transaction.orderId}`
          : "";
      }
      case CreditDrawerStepTypes.Preview:
      default: {
        return `Order #${this.transaction.orderId}`;
      }
    }
  }

  private get disputePreviewInfoListItems(): InfoListItem[] {
    if (!this.transaction) return [];
    return [
      { label: "Customer name", text: this.transaction.customerName || "N/A" },
      {
        label: "Date submitted",
        text: this.transaction.createdOn || "N/A",
      },
      {
        label: "Expiration Date",
        text: this.transaction.disputeExpirationDate || "N/A",
      },
      {
        label: "Transaction Amount",
        text: this.disputeChargeTransaction?.totalAmount || "N/A",
      },
      {
        label: "Disputed Amount",
        text: this.transaction.totalAmount || "N/A",
      },
      ...(this.disputeRefundTotalAmount
        ? [
            {
              label: "Refunded Amount",
              text: this.disputeRefundTotalAmount,
              textColor: "text-error",
            },
          ]
        : []),
      {
        label: "Reason",
        text:
          ([
            transactionStatusLabelMap[TransactionStatusTypes.Created],
            transactionStatusLabelMap[TransactionStatusTypes.DisputeCancelled],
          ].includes(this.transaction.status)
            ? this.transaction.disputeReason
            : this.disputeRefundTransactions?.find((drt) => drt.returnReason)
                ?.returnReason) || "N/A", // After a dispute is resolved, its child refund transaction has the up-to-date refund reason
      },
      {
        label: "Comments",
        text:
          ([
            transactionStatusLabelMap[TransactionStatusTypes.Created],
            transactionStatusLabelMap[TransactionStatusTypes.DisputeCancelled],
          ].includes(this.transaction.status)
            ? this.transaction.disputeComment
            : this.disputeRefundTransactions?.find((t) => t.returnComment)
                ?.returnComment) || "N/A", // After a dispute is resolved, its child refund transaction has the up-to-date refund comment
      },
    ];
  }

  private get disputeRefundTotalAmount(): string | null {
    if (
      !this.disputeRefundTransactions ||
      !this.disputeRefundTransactions.length
    ) {
      return null;
    }
    return `-${Utils.floatToCurrencyString(
      this.disputeRefundTransactions.reduce(
        (totalRefund, transaction) =>
          totalRefund +
          Utils.extractNumberFromCurrency(transaction.totalAmount),
        0
      )
    )}`;
  }

  private get editInfoListItems(): InfoListItem[] {
    if (!this.transaction) return [];
    return [
      { label: "Customer", text: this.transaction.customerName || "N/A" },
      { label: "Order #", text: `${this.transaction.orderId}` || "N/A" },
      ...(this.type === CreditDrawerTypes.Dispute
        ? [
            {
              label: "Transaction Amount",
              text: this.disputeChargeTransaction?.totalAmount || "N/A",
            },
          ]
        : []),
      {
        label:
          this.type === CreditDrawerTypes.Dispute
            ? "Dispute Amount"
            : "Transaction Amount",
        text: this.transaction.totalAmount,
      },
    ];
  }

  private get disputeHasBeenProcessed(): boolean {
    return Boolean(
      this.type === CreditDrawerTypes.Dispute &&
        this.transaction &&
        this.transaction.status !==
          transactionStatusLabelMap[TransactionStatusTypes.Created]
    );
  }

  private get disputeIsExpired(): boolean {
    if (
      this.type !== CreditDrawerTypes.Dispute ||
      !this.transaction ||
      typeof this.transaction.disputeExpirationDate !== "string"
    ) {
      return false;
    }
    const timestamp = Date.parse(this.transaction.disputeExpirationDate);
    if (!Number.isFinite(timestamp) || Date.now() < timestamp) return false;
    return true;
  }

  private get shouldDisableActions(): boolean {
    return Boolean(
      this.loading ||
        this.actionInProgress ||
        this.disputeHasBeenProcessed ||
        this.disputeIsExpired
    );
  }
}
