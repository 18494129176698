import { CarrierTypes } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingCarrierAccountDto {
  @JsonProperty() carrierId!: number;
  @JsonProperty() externalCarrierId!: string;
  @JsonProperty() carrierName!: CarrierTypes;
  @JsonProperty() createdOn!: string | null;
  @JsonProperty() deletedOn!: string | null;
  @JsonProperty() isShared!: boolean;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty() isCarrierActive!: boolean;
}
