import { injectable } from "inversify-props";
import axios from "@/axios";
import { ManufacturerDetailsDto, IManufacturersRepository } from "@/types";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/manufacturers/getMany`;

@injectable()
export class ManufacturersRepository implements IManufacturersRepository {
  public async getMany(): Promise<ManufacturerDetailsDto[]> {
    const { data } = await axios.post(ENDPOINT_URL);
    //TODO: Check if possible to deserialize the whole list at once (not using map)
    return data.map((md: ManufacturerDetailsDto) => {
      return deserialize<ManufacturerDetailsDto>(md, ManufacturerDetailsDto);
    });
  }
}
