
import { Vue, Options } from "vue-class-component";
import { Prop, Watch, InjectReactive } from "vue-property-decorator";
import { inject } from "inversify-props";
import {
  Box,
  AdminButton,
  AdminInput,
  AdminTextarea,
  Icon,
  ConfirmationModal,
} from "@/components";
import {
  IconNameTypes,
  OrderDto,
  OrderItemDto,
  MainRouteTypes,
  OrderStatusTypes,
  SubRouteTypes,
  OrderEditModeTypes,
} from "@/types";
import type {
  ToastConfig,
  IOrdersRepository,
  IBrandOrdersRepository,
} from "@/types";
import { ImageUrlService, SegmentService } from "@/services";
import { Utils } from "@/utils";
import { JunTableColumn } from "@juniper/ui";
import { namespace } from "vuex-class";

const auth = namespace("authVuexModule");
const notifications = namespace("notificationsVuexModule");
const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    Box,
    AdminButton,
    AdminInput,
    AdminTextarea,
    Icon,
    ConfirmationModal,
  },
  emits: ["refresh-data"],
})
export default class OrderItemsBox extends Vue {
  @inject() private ordersRepository!: IOrdersRepository;
  @inject() private brandOrdersRepository!: IBrandOrdersRepository;
  @inject() private imageUrlService!: ImageUrlService;
  @inject() public segmentService!: SegmentService;

  @InjectReactive() private agencyOrderWorkflowEnabled!: boolean;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;

  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  @manufacturers.Getter private manufacturerId!: number | null;
  @auth.Getter private isMultiline!: boolean | null;

  @Prop({
    type: Object,
  })
  order!: OrderDto | null;

  @Prop({
    type: Boolean,
  })
  loading!: boolean;

  @Prop({
    type: Object,
  })
  orderModelValue!: OrderDto | null;

  @Prop({
    type: String,
  })
  orderEditMode!: OrderEditModeTypes;

  @Prop({
    type: Boolean,
  })
  isEditing!: boolean;

  @Prop({
    type: Function,
  })
  updateOrder!: () => Promise<void>;

  private IconNameTypes = IconNameTypes;
  private productIdLoading: number | null = null;
  private splitLoading = false;
  private deleteLoading = false;
  private initQuantities: Record<number, number | undefined> = {};
  private showDeleteModal = false;
  private showSplitOrderModal = false;
  private selectedItems: OrderItemDto[] = [];
  private tableItemKey = "itemID";
  private tableHeaders: JunTableColumn[] = [
    { prop: "image", text: "", width: "5rem" },
    { prop: "itemID", text: "ItemID", width: "6.25rem" },
    { prop: "itemName", text: "Item", width: "15rem", wrap: true },
    { prop: "notes", text: "Note", wrap: true },
    { prop: "qty", text: "QTY", width: "10rem" },
    { prop: "price", text: "Price Per", width: "7.5rem" },
    { prop: "subTotal", text: "Subtotal", width: "8.25rem" },
  ];
  private OrderEditModeTypes = OrderEditModeTypes;

  // populate table whenever order changes, which will be from a data refresh
  // caused by some update (accept/reject/update qty etc) or initial load
  @Watch("order", { deep: true, immediate: true })
  onOrderChange() {
    this.setInitQuantities();
  }

  private getItemImage(item: OrderItemDto): string {
    return this.imageUrlService.getProductImage(
      item.photoName,
      this.getBrandId() || this.manufacturerId || 0
    );
  }

  //TODO: Refactor this, same code in multiple places.

  get orderGUID() {
    return this.order?.orderGUID || "";
  }

  private getBrandId(): number | undefined {
    const { brand } = this.$route.query;
    return brand ? Number(brand) : undefined;
  }

  get actionsDisabled() {
    return (
      this.selectedItems.length === 0 ||
      this.selectedItems.length === this.selectableItems.length ||
      this.deleteLoading ||
      this.splitLoading
    );
  }

  private get actionsHidden(): boolean {
    const { orderStatus } = this.order?.catalogDetail || {};
    return Boolean(
      (this.isMultiline && !this.agencyOrderWorkflowEnabled) ||
        (orderStatus &&
          (orderStatus !== OrderStatusTypes.Approved ||
            (orderStatus === OrderStatusTypes.Approved && !this.isEditing)))
    );
  }

  get orderIsApproved() {
    return this.order?.catalogDetail?.orderStatus === OrderStatusTypes.Approved;
  }

  private clickDeleteIcon() {
    !this.actionsDisabled && (this.showDeleteModal = true);
  }

  private get tableItems(): OrderItemDto[] {
    return (
      this.orderModelValue?.orderItems
        .filter((oi) => oi.qty > 0 && !oi.isDeleted)
        .sort((a: OrderItemDto, b: OrderItemDto) => a.id - b.id) ?? []
      // order of items can change in response from backend, sort by id every time
      // to prevent table rows from shifting positions
    );
  }

  private get selectableItems(): OrderItemDto[] {
    return this.tableItems.filter((oi) => !oi.itemType);
  }

  private async onUpdateQtyClick(id: number): Promise<void> {
    this.productIdLoading = id;
    const tableItem = this.getTableItemById(id);
    // if we can't find a tableItem, something has gone wrong, want to exit
    if (!tableItem) {
      this.createToastError({
        message: "Something went wrong, please try again later",
      });
      this.productIdLoading = null;
      return;
    }
    const [, error] = await Utils.try(this.updateOrder());
    if (!error) {
      this.createToastSuccess({
        message: `Successfully updated product #${id} to quantity of ${tableItem.qty}`,
      });
      this.productIdLoading = null;
    }
  }

  private async onDelete(): Promise<void> {
    if (this.orderEditMode === OrderEditModeTypes.PreApproval) {
      this.deleteLoading = true;
      this.clearSelectedItemsQuantity();
      const [, error] = await Utils.try(this.updateOrder());
      if (!error) {
        this.selectedItems = [];
        this.closeDeleteModal();
        this.deleteLoading = false;
        this.createToastSuccess({
          message: "Successfully deleted selected item(s).",
        });
      } else {
        this.createToastError({
          message: "Failed to deleted selected item(s).",
        });
      }
    } else {
      this.clearSelectedItemsQuantity();
      this.selectedItems = [];
      this.closeDeleteModal();
    }
  }

  private clearSelectedItemsQuantity(): void {
    this.selectedItems.forEach((selectedItem) => {
      if (!this.orderModelValue) return;
      const item = this.orderModelValue.orderItems.find(
        (oi) => oi.itemID === selectedItem.itemID
      );
      if (item) item.qty = 0;
    });
  }

  async onSplitOrder() {
    this.splitLoading = true;
    const itemIds = this.selectedItems.map((item) => item.id);

    const splitPayload: [number, number[]] = [
      Number(this.order?.orderID),
      itemIds,
    ];
    const [data] = await Utils.try(
      this.isMultiline && this.agencyOrderWorkflowEnabled
        ? this.brandOrdersRepository.split(...splitPayload)
        : this.ordersRepository.split(...splitPayload)
    );

    if (data) {
      this.$emit("refresh-data", data.oldOrder);
      const { id } = data.newOrder;
      const orderGuid = data.newOrder.orderGUID;

      const urlParams = this.isMultiline
        ? `?guid=${orderGuid}&brand=${this.getBrandId()}`
        : `?guid=${orderGuid}`;

      this.createToastSuccess({
        message: `Successfully split to new order &nbsp <a href="/${MainRouteTypes.OrderDetails}/${SubRouteTypes.Summary}${urlParams}">#${id}</a>`,
        rawHtml: true,
        duration: 12000,
      });

      this.selectedItems = [];
      this.closeSplitOrderModal();
      this.segmentService.trackOrderUpdated(data.oldOrder);
      this.segmentService.trackOrderUpdated(data.newOrder);
    }

    this.splitLoading = false;
  }

  setInitQuantities() {
    if (!this.order) return;
    const initQuantities: Record<number, number> = {};
    this.order.orderItems.forEach((oi) => {
      initQuantities[oi.id] = oi.qty;
    });
    this.initQuantities = initQuantities;
  }

  getTableItemById(id: number) {
    return this.tableItems.find((item) => item.id === id);
  }

  get qtyIsDirty() {
    return (id: number) => {
      const { qty } = this.getTableItemById(id) || {};
      return qty !== this.initQuantities[id];
    };
  }

  get qtyIsLoading() {
    return (id: number) => this.productIdLoading === id;
  }

  closeDeleteModal() {
    this.showDeleteModal = false;
  }

  closeSplitOrderModal() {
    this.showSplitOrderModal = false;
  }
}
