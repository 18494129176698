import axios from "@/axios";
import { deserialize } from "typescript-json-serializer";
import { injectable } from "inversify-props";
import { IInvoicesRepository, InvoiceDto } from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Invoices`;

@injectable()
export class InvoicesRepository implements IInvoicesRepository {
  public async getInvoice(invoiceId: number): Promise<InvoiceDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/id/${invoiceId}`);
    return deserialize<InvoiceDto>(data, InvoiceDto);
  }

  public async setPaid(invoiceId: number): Promise<InvoiceDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${invoiceId}/paid`);
    return deserialize<InvoiceDto>(data, InvoiceDto);
  }

  public async createInvoice(orderGuid: string): Promise<InvoiceDto> {
    const { data } = await axios.post(`${ENDPOINT_URL}/${orderGuid}/create`);
    return deserialize<InvoiceDto>(data, InvoiceDto);
  }
}
