import { setup, Vue } from "vue-class-component";
import { useField } from "vee-validate";

export abstract class VeeFieldMixin<T = string> extends Vue {
  abstract modelValue: T;
  abstract name: string;
  abstract label: string | null;

  protected field = setup(() => {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(this.name, undefined, {
      initialValue: this.modelValue,
    });
    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  });

  protected get error() {
    return Boolean(this.field.errorMessage);
  }

  protected get errorMessage() {
    return this.field.errorMessage
      ? this.label
        ? (this.field.errorMessage as unknown as string).replace(
            this.name,
            this.label
          )
        : this.field.errorMessage
      : "";
  }
}
