export enum IconNameTypes {
  ActiveChannel = "active-channel",
  Add = "add",
  AddFavorite = "add-favorite",
  AddList = "add-list",
  AddV2 = "add-v2",
  Agency = "agency",
  Alert = "alert",
  ArrowDown = "arrow-down",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ArrowUp = "arrow-up",
  BackArrow = "back-arrow",
  Buyers = "buyers",
  Cart = "cart",
  Channels = "channels",
  Check = "check",
  Close = "close",
  CloseV2 = "close-v2",
  Connect = "connect",
  CostBoards = "cost-boards",
  Dashboard = "dashboard",
  Directory = "directory",
  Download = "download",
  DownChevron = "down-chevron",
  DownArrow = "down-arrow",
  Edit = "edit",
  Email = "email",
  Facebook = "facebook",
  Favorite = "favorite",
  ForwardArrow = "forward-arrow",
  GridView = "grid-view",
  History = "history",
  Home = "home",
  Info = "info",
  JCredit = "jcredit",
  LeftChevron = "left-chevron",
  LineColumns = "line-columns",
  LinkedIn = "linkedin",
  ListView = "list-view",
  Location = "location",
  Lock = "lock",
  Logo = "logo",
  Logout = "logout",
  Media = "media",
  MoreMenu = "more-menu",
  Notification = "notification",
  Orders = "orders",
  Pending = "pending",
  Phone = "phone",
  Products = "products",
  PushData = "push-data",
  Refresh = "refresh",
  Rep = "rep",
  RightChevron = "right-chevron",
  Search = "search",
  SearchCategory = "search-category",
  SearchOrders = "search-orders",
  Settings = "settings",
  Share = "share",
  ShareColumn = "share-column",
  StaticNotification = "static-notification",
  StatusReview = "status-review",
  Success = "success",
  JunTableFilter = "table-filter",
  Trash = "trash",
  Twitter = "twitter",
  UpArrow = "up-arrow",
  UpChevron = "up-chevron",
  Upload = "upload",
  User = "user",
  VendorV2 = "vendor-v2",
  Vendors = "vendors",
  Details = "details",
  Currency = "currency",
  Completed = "progress-completed",
  BrandIcon = "brand-icon",
  Truck = "truck",
  Store = "store",
}
