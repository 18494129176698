import { Utils } from "@/utils";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class OrderInvoiceDto {
  @JsonProperty() id!: number;
  @JsonProperty() invoiceNumber!: string | null;
  // shipment shipDate
  @JsonProperty({ beforeDeserialize: Utils.parseDate })
  shipDate!: string;
}
