
import { Vue, Options } from "vue-class-component";
import { InjectReactive, Prop, Watch } from "vue-property-decorator";
import { customRef } from "vue";
import { Form } from "vee-validate";
import { Utils } from "@/utils";
import * as yup from "yup";
import {
  RatesDto,
  OrderDto,
  ShippingSettingsDto,
  NonDeliveryTypes,
  PackageContentTypes,
  WeightTypes,
  WeightLabelTypes,
} from "@/types";
import type {
  DropdownItem,
  IShipmentsPostPayload,
  MultiInputItem,
  ShippingRateItem,
  DimensionsStore,
  ToastConfig,
  IDateRange,
} from "@/types";
import {
  AdminInput,
  AdminMultiInput,
  RadioButton,
  Dropdown,
  AdminDatePicker,
} from "@/components";
import { inject } from "inversify-props";
import { ShippingAccountRepository } from "@/services";
import { namespace } from "vuex-class";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    AdminInput,
    AdminMultiInput,
    Dropdown,
    RadioButton,
    Form,
    AdminDatePicker,
  },
  emits: ["update:modelValue", "update:rateId", "doneLoading"],
})
export default class ShipWithJuniperEdit extends Vue {
  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  @inject() private shippingAccountRepository!: ShippingAccountRepository;
  @InjectReactive() order!: OrderDto;
  @InjectReactive() private shippingSettings!: ShippingSettingsDto;

  @Prop({
    type: Object,
    required: true,
  })
  modelValue!: IShipmentsPostPayload;

  @Prop({
    type: Number,
    required: true,
  })
  rateId!: number;

  @Prop({
    type: Array,
    default: [],
  })
  rates!: RatesDto[];

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isInternational!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  weightUnit!: string;

  private shippingRates: ShippingRateItem[] = [];
  private formLoading = true;
  private packageTypes: DropdownItem[] = [];
  private dimensions = this.handleDimensions({
    length: "",
    width: "",
    height: "",
  });
  private availableShipDateRange: IDateRange = (() => {
    const dates = Utils.getDatesInRange();
    return {
      from: dates[0],
      to: dates[dates.length - 1],
    };
  })();

  @Watch("rates")
  private ratesChange() {
    this.rates.length && this.getShippingRates();
  }

  @Watch("modelValue")
  private modelClear() {
    if (!this.modelValue.packages[0].dimensions.length) {
      this.dimensions = this.handleDimensions({
        length: "",
        width: "",
        height: "",
      });
    }
  }

  private emitUpdate(mv: IShipmentsPostPayload) {
    this.$emit("update:modelValue", mv);
  }

  async created() {
    await this.getPackageTypes();
    this.formLoading = false;
    this.rates.length && this.getShippingRates();
    this.$emit("doneLoading");
  }

  private dimensionsConfig: MultiInputItem[] = [
    { label: "in", name: "length", type: "number" },
    { label: "in", name: "width", type: "number" },
    { label: "in", name: "height", type: "number" },
  ];

  private handleDimensions(dims: DimensionsStore) {
    const mv = this.modelValue,
      update = this.emitUpdate.bind(this);

    return customRef((track, trigger) => {
      return {
        get() {
          track();
          const d = mv.packages[0].dimensions;
          return d.length && d.width && d.height
            ? {
                length: d.length,
                width: d.width,
                height: d.height,
              }
            : dims;
        },
        set(val: DimensionsStore) {
          mv.packages[0].dimensions = {
            unit: "Inch",
            length: Number(val.length),
            width: Number(val.width),
            height: Number(val.height),
          };
          update(mv);
          dims = val;
          trigger();
        },
      };
    });
  }

  private validationSchema = yup.object().shape({
    packageType: yup.string().required(),
    dimensions: yup
      .object()
      .shape({
        length: yup
          .mixed()
          .test(
            "test-has-weight",
            "All dimensions must have a value.",
            (v) => v !== "" || yup.number().isValidSync(v)
          )
          .transform((v) => (v === "" ? v : +v)),
        width: yup
          .mixed()
          .test(
            "test-has-weight",
            "All dimensions must have a value.",
            (v) => v !== "" || yup.number().isValidSync(v)
          )
          .transform((v) => (v === "" ? v : +v)),
        height: yup
          .mixed()
          .test(
            "test-has-weight",
            "All dimensions must have a value.",
            (v) => v !== "" || yup.number().isValidSync(v)
          )
          .transform((v) => (v === "" ? v : +v)),
      })
      .required(),
    weight: yup
      .number()
      .test(
        "greater-than-0",
        "Weight must be greater than 0",
        (v) => !!v && v > 0
      )
      .required(),
    shipmentDate: yup.string().required(),
    packageContents: yup
      .string()
      .test((v) =>
        this.isInternational ? (v && v?.length > 0) || false : true
      ),
    nonDelivery: yup
      .string()
      .test((v) =>
        this.isInternational ? (v && v?.length > 0) || false : true
      ),
  });

  private async getPackageTypes(): Promise<void> {
    const [packages] = await Utils.try(
      this.shippingAccountRepository.getPackages()
    );
    if (packages) {
      this.packageTypes = packages?.packages
        .filter((p) => p.name && p.packageCode)
        .map((p) => {
          return {
            name: p.name || "",
            value: p.packageCode || "",
          };
        });
    } else {
      this.createToastError({
        message: "Failed to fetch package types.",
      });
    }
  }

  private get weightLabel() {
    return WeightLabelTypes[
      (this.weightUnit as keyof typeof WeightTypes) || "Pound"
    ];
  }

  private get getPreferredRate() {
    return (
      this.rates.find(
        (r) =>
          r.serviceType === this.order.catalogDetail.shippingPreferredRateCode
      ) || null
    );
  }

  private getShippingRates() {
    const r = Utils.transformShippingRates(this.rates).map((r) => {
      return {
        title: r.carrierDeliveryDays,
        name: r.serviceType,
        value: r.rateId.toString(),
        price: Utils.floatToCurrencyString(r.shippingAmount.amount || 0.0),
      } as ShippingRateItem;
    });

    let sm = r[0];

    r.forEach((rt) => {
      if (Number(rt.price.split("$")[1]) < Number(sm.price.split("$")[1]))
        sm = rt;
    });

    sm.value && this.$emit("update:rateId", Number(sm.value));
    this.shippingRates = r;
  }

  private get nonDeliveryTypes() {
    return Object.keys(NonDeliveryTypes)
      .filter((x) => isNaN(parseInt(x)))
      .map((key) => {
        const deliveryType = key as keyof typeof NonDeliveryTypes;
        return {
          name: deliveryType,
          value: deliveryType,
        };
      });
  }

  private get packageContentTypes() {
    return Object.keys(PackageContentTypes)
      .filter((x) => isNaN(parseInt(x)))
      .map((key) => {
        const packageType = key as keyof typeof PackageContentTypes;
        return {
          name: packageType,
          value: packageType,
        };
      });
  }

  private get preferredShipDate() {
    return this.order.catalogDetail.shipDate
      ? Utils.parseDate(this.order.catalogDetail.shipDate)
      : null;
  }
}
