import { JsonProperty, Serializable } from "typescript-json-serializer";
import { CompanyAddressDto, TrackingDto } from "@/types";

@Serializable()
export class ShipToDto {
  @JsonProperty() recipientName!: string | null;
  @JsonProperty() companyName!: string | null;
  @JsonProperty() companyAddress!: CompanyAddressDto;
  @JsonProperty() pickup!: boolean;
  @JsonProperty() tracking!: TrackingDto;
}
