export enum TransactionStatusTypes {
  Created = "Created",
  Preauthorized = "Preauthorized",
  Captured = "Captured",
  Disputed = "Disputed",
  Cancelled = "Cancelled",
  Error = "Error",
  Unknown = "Unknown",
  Refunded = "Refunded",
  Paid = "Paid",
  PaymentSettled = "PaymentSettled",
  DisputeResolved = "DisputeResolved",
  DisputePartiallyPaid = "DisputePartiallyPaid",
  DisputeCancelled = "DisputeCancelled",
  ChargeRefunded = "ChargeRefunded",
  ChargePartiallyPaid = "ChargePartiallyPaid",
}
