import {
  ShipRatesDto,
  ShipmentItemDto,
  ShipAddressDto,
  CustomsDto,
  ShipmentAdvancedOptionsDto,
  TagsDto,
  PackageDto,
  WeightDto,
} from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingRatesDto {
  @JsonProperty() validateAddress?: string | null;
  @JsonProperty() globalIdentifier!: string | null;
  @JsonProperty() externalShipmentId!: string | null;
  @JsonProperty() shipmentId!: number | null;
  @JsonProperty() externalCarrierId!: string | null;
  @JsonProperty() carrierId!: number | null;
  @JsonProperty() serviceCode!: string | null;
  @JsonProperty() orderId!: number | null;
  @JsonProperty({ type: ShipmentItemDto }) items!: ShipmentItemDto[];
  @JsonProperty() shipDate!: string | null;
  @JsonProperty() createdAt!: string | null;
  @JsonProperty() modifiedAt!: string | null;
  @JsonProperty() shipmentStatus!: string | null;
  @JsonProperty() shipTo!: ShipAddressDto;
  @JsonProperty() shipFrom!: ShipAddressDto;
  @JsonProperty() returnTo!: ShipAddressDto;
  @JsonProperty() confirmation!: string | null;
  @JsonProperty() customs!: CustomsDto;
  @JsonProperty() advancedOptions!: ShipmentAdvancedOptionsDto;
  @JsonProperty() originType!: string | null;
  @JsonProperty() insuranceProvider!: string | null;
  @JsonProperty() orderSourceCode!: string | null;
  @JsonProperty({ type: TagsDto }) tags!: TagsDto[];
  @JsonProperty({ type: PackageDto }) packages!: PackageDto[];
  @JsonProperty() totalWeight!: WeightDto;
  @JsonProperty() rateResponse!: ShipRatesDto;
}
