import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9747842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cmp-wrapper" }
const _hoisted_2 = ["placeholder", "value", "disabled", "maxlength"]
const _hoisted_3 = {
  key: 0,
  class: "limit-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cmp-container", _ctx.cmpWidth])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        class: _normalizeClass({ error: _ctx.error, disabled: _ctx.disabled, active: _ctx.isActive })
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
        (_ctx.tooltipMsg)
          ? (_openBlock(), _createBlock(_component_Tooltip, {
              key: 0,
              msg: _ctx.tooltipMsg
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  class: "tooltip-icon",
                  name: _ctx.IconNameTypes.Info,
                  size: 1
                }, null, 8, ["name"])
              ]),
              _: 1
            }, 8, ["msg"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("textarea", {
        placeholder: _ctx.placeholder,
        class: _normalizeClass({
          error: _ctx.error,
        }),
        style: _normalizeStyle({
          minHeight: _ctx.minHeight ? _ctx.minHeight + 'rem' : null,
          resize: 'none',
        }),
        value: _ctx.field.inputValue,
        disabled: _ctx.disabled,
        maxlength: _ctx.maxLength,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputBlur && _ctx.onInputBlur(...args))),
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInputFocus && _ctx.onInputFocus(...args)))
      }, null, 46, _hoisted_2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.messageContainerClass)
    }, [
      (_ctx.showCharLimit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.charLimitMsg), 1))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "slide-up" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["cmp-message", { error: _ctx.error }])
          }, _toDisplayString(_ctx.error ? _ctx.errorMessage : _ctx.msg), 3)
        ]),
        _: 1
      })
    ], 2)
  ], 2))
}