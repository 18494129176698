
import { Options, mixins } from "vue-class-component";
import { Watch, InjectReactive } from "vue-property-decorator";
import {
  AdminButton,
  AdminInput,
  AdminCollapsible,
  AdminDatePicker,
  Dropdown,
  InfoList,
  Checkbox,
  Icon,
} from "@/components";
import {
  InfoListItem,
  CarrierTypes,
  CountryTypes,
  DropdownItem,
  CreditCurrencyTypes,
} from "@/types";
import type { UpsInformationDto } from "@/types";
import * as yup from "yup";
import { ShippingCarrierMixin } from "@/mixins";
import { Utils } from "@/utils";

@Options({
  components: {
    AdminButton,
    AdminInput,
    AdminCollapsible,
    AdminDatePicker,
    Dropdown,
    InfoList,
    Checkbox,
    Icon,
  },
})
export default class Ups extends mixins(ShippingCarrierMixin) {
  @InjectReactive() private upsInvoiceConnectionFlag!: boolean;

  created() {
    this.accountInfo &&
      this.isUps(this.accountInfo) &&
      (this.upsInformation = {
        ...this.accountInfo,
        ...(this.upsInformation.invoice && {
          invoice: { ...this.upsInformation.invoice },
        }),
      });
    this.updateInfo();
  }

  @Watch("upsInformation", { deep: true })
  @Watch("upsInformation.invoice", { deep: true })
  onInfoChange() {
    this.updateInfo();
  }

  @Watch("countryCode", { immediate: true })
  updateStateOptions(val: CountryTypes) {
    this.currentCountry = val;
  }

  private currencies: Array<DropdownItem> = this.constructCurrencies();

  private removeInvoice(ups: UpsInformationDto) {
    return Object.keys(ups).reduce((object, key) => {
      if (key !== "invoice") {
        const t = ups[key as keyof UpsInformationDto];
        const k = key as keyof UpsInformationDto;
        (object as { [kk in keyof UpsInformationDto]: typeof t })[k] = ups[k];
      }
      return object;
    }, {} as UpsInformationDto);
  }

  private get countryCode() {
    return this.upsInformation.countryCode;
  }

  private get doesntNeedInvoice() {
    return (
      (!this.upsInformation.invoice?.invoiceNumber &&
        !this.upsInformation.invoice?.controlId &&
        !this.upsInformation.invoice?.invoiceAmount &&
        !this.upsInformation.invoice?.invoiceCurrencyCode) ||
      !this.upsInvoiceConnectionFlag
    );
  }

  private constructCurrencies() {
    return Object.keys(CreditCurrencyTypes).map((key) => {
      const currencyType = key as keyof typeof CreditCurrencyTypes;
      return {
        name: currencyType,
        value: CreditCurrencyTypes[currencyType],
      };
    });
  }

  private connectClick() {
    this.updateInfo();
    this.connectAccount(
      CarrierTypes.UPS,
      this.doesntNeedInvoice
        ? this.removeInvoice(this.upsInformation)
        : this.upsInformation
    );
  }

  private updateInfo() {
    const info = !this.accountInfo
        ? this.doesntNeedInvoice
          ? this.removeInvoice(this.upsInformation)
          : this.upsInformation
        : this.accountInfo,
      type = !this.carrierType ? CarrierTypes.UPS : this.carrierType;
    this.setCarrierInfo(type, info);
  }

  private upsInformation: UpsInformationDto = {
    accountNumber: "",
    accountCountryCode: "",
    accountPostalCode: "",
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
    email: "",
    phone: "",
    invoice: {
      invoiceDate: "",
      invoiceNumber: "",
      controlId: "",
      invoiceAmount: "",
      invoiceCurrencyCode: "",
    },
    agreeToTechnologyAgreement: false,
  };

  get infoListItem(): Array<InfoListItem> {
    return [{ label: "Carrier Name", text: "UPS" }];
  }

  private validationSchema = yup.object().shape({
    accountCountryCode: yup.string().nullable().required(),
    accountPostalCode: yup.string().required(),
    title: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    accountNumber: yup.string().required(),
    email: yup.string().email().required(),
    address1: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().nullable().required(),
    postalCode: yup.string().required(),
    countryCode: yup.string().nullable().required(),
    company: yup.string().required(),
    phone: yup.string().isPhone().required(),
    invoice: yup.object().shape({
      invoiceDate: yup
        .string()
        .test("test-has-invoice-date", "Invoice Date is required", (v) => {
          return !this.doesntNeedInvoice && this.upsInvoiceConnectionFlag
            ? yup.string().isValid(v)
            : true;
        }),
      invoiceNumber: yup
        .string()
        .test("test-has-invoice-number", "Invoice Number is required", (v) => {
          return !this.doesntNeedInvoice && this.upsInvoiceConnectionFlag
            ? yup.string().isValid(v)
            : true;
        }),
      controlId: yup
        .string()
        .test("test-has-invoice-control-id", "Control Id is required", (v) => {
          return !this.doesntNeedInvoice && this.upsInvoiceConnectionFlag
            ? yup.string().isValid(v)
            : true;
        }),
      invoiceAmount: yup
        .mixed()
        .transform((v) => Utils.extractNumberFromCurrency(v))
        .test("test-has-invoice-amount", "Invoice Amount is required", (v) => {
          return !this.doesntNeedInvoice && this.upsInvoiceConnectionFlag
            ? yup.number().isValid(v)
            : true;
        }),
      invoiceCurrencyCode: yup
        .string()
        .test(
          "test-has-invoice-currency-code",
          "Currency Code is required",
          (v) => {
            return !this.doesntNeedInvoice && this.upsInvoiceConnectionFlag
              ? yup.string().isValid(v)
              : true;
          }
        ),
    }),
    agreeToTechnologyAgreement: yup
      .boolean()
      .oneOf([true], "You must accept the Technology Agreement to continue."),
  });
}
