
import { AdminButton, EditableBox, ImageUpload, InfoList } from "@/components";
import { imageDimensionsConfig } from "@/config";
import { CompanyImagesMap } from "@/maps";
import { BackupDataService, ImageUrlService } from "@/services";
import {
  CompanyImagesDto,
  EditableBoxStatusTypes,
  IImage,
  IValidateResponse,
  ImageFileFormats,
  InfoListItem,
  ToastConfig,
} from "@/types";
import { Utils } from "@/utils";
import { inject } from "inversify-props";
import objectMapper from "object-mapper";
import { deserialize } from "typescript-json-serializer";
import { Form } from "vee-validate";
import { Options, Vue } from "vue-class-component";
import { InjectReactive, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import * as yup from "yup";

const manufacturers = namespace("manufacturersVuexModule");
const notifications = namespace("notificationsVuexModule");
const publish = namespace("publishVuexModule");
const brandPreview = namespace("brandPreviewVuexModule");

@Options({
  components: {
    Form,
    EditableBox,
    InfoList,
    AdminButton,
    ImageUpload,
  },
})
export default class CompanyImagesCmp extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private imageUrlService?: ImageUrlService;

  @manufacturers.Getter private manufacturerId?: number | null;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;

  @notifications.Mutation private createToastWarning?: (
    payload: ToastConfig
  ) => void;

  @publish.Getter private isImageUploadValid?: boolean;
  @publish.Mutation private setImageUploadAsValid?: () => void;

  @brandPreview.Getter private isProfileValid!: boolean;
  @brandPreview.Mutation private setBrandImagesValid!: () => void;
  @brandPreview.Mutation private setLocalBrandLogo!: (
    logo: File | null
  ) => void;
  @brandPreview.Mutation private setLocalBrandHeader!: (
    header: File | null
  ) => void;
  @brandPreview.Mutation private setShowProfilePreviewModal!: (
    show: boolean
  ) => void;

  private validationSchema = yup.object().shape({
    logo: yup
      .mixed()
      .maxFileSize(300)
      .fileFormats([ImageFileFormats.PNG])
      .fileDimensionsRange(imageDimensionsConfig.logo)
      .fileRequired(),
    header: yup
      .mixed()
      .maxFileSize(1000)
      .fileFormats([ImageFileFormats.JPG, ImageFileFormats.JPEG])
      .fileDimensionsRange(imageDimensionsConfig.header)
      .fileRequired(),
    lifestyle1: yup
      .mixed()
      .maxFileSize(1000)
      .fileFormats([ImageFileFormats.JPG, ImageFileFormats.JPEG])
      .fileDimensionsRange(imageDimensionsConfig.lifestyle)
      .fileRequired(),
    lifestyle2: yup
      .mixed()
      .nullable(true)
      .maxFileSize(1000)
      .fileFormats([ImageFileFormats.JPG, ImageFileFormats.JPEG])
      .fileDimensionsRange(imageDimensionsConfig.lifestyle),
    lifestyle3: yup
      .mixed()
      .nullable(true)
      .maxFileSize(1000)
      .fileFormats([ImageFileFormats.JPG, ImageFileFormats.JPEG])
      .fileDimensionsRange(imageDimensionsConfig.lifestyle),
  });

  private status = EditableBoxStatusTypes.View;
  private companyImages: CompanyImagesDto | null = null;
  private hasImages: boolean | null = false;
  private previewDisabled = false;
  private infoListItems: InfoListItem[] = [];

  async created() {
    await Utils.try(Promise.all([this.init(), this.setInfoList()]));
    if (!this.isImageUploadValid) {
      this.handleInvalidForPublish();
    }
  }

  @Watch("isImageUploadValid")
  onImageUploadValidChange(valid: boolean) {
    if (!valid) {
      this.handleInvalidForPublish();
    }
  }

  // set local images for brand preview modal
  @Watch("companyImages", { deep: true })
  async onCompanyImagesChange(images: CompanyImagesDto | null) {
    this.setLocalBrandLogo(images?.logo?.file || null);
    this.setLocalBrandHeader(images?.header?.file || null);

    if (this.$refs.form) {
      const validateResponse = await (
        this.$refs.form as HTMLFormElement
      ).validate();
      this.previewDisabled = !validateResponse.valid;
    }
  }

  handleInvalidForPublish() {
    this.status = EditableBoxStatusTypes.Edit;
    // turning status to 'Edit' opens the form, so using nextTick here
    // to ensure the form has been mounted before trying to validate it
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).validate();
    });
  }

  private async init() {
    this.status = EditableBoxStatusTypes.Loading;
    await this.setCompanyImages();
    this.status = EditableBoxStatusTypes.View;
  }

  async setInfoList() {
    const images = await this.fetchImages();
    if (!images) return;
    const { logo, header, lifestyles } = images;
    this.infoListItems = [
      { label: "Company Logo", images: [logo?.url] },
      { label: "Header Image", images: [header?.url] },
      {
        label: "Lifestyle Images",
        images: this.getLifestyleImagesNames(lifestyles),
      },
    ];
  }

  async setCompanyImages() {
    const images = await this.fetchImages();
    if (!images) return;
    const { logo, header, lifestyles } = images;
    const [category1, category2, category3] = lifestyles;
    // TODO FIXME: rethink criteria for showing this
    if (!logo.error) {
      this.hasImages = true;
      this.setBrandImagesValid();
    }
    const json = {
      logo: logo.error ? null : logo.url,
      header: header.error ? null : header.url,
      category1: category1.error ? null : category1.url,
      category2: category2.error ? null : category2.url,
      category3: category3.error ? null : category3.url,
    };
    this.companyImages = deserialize<CompanyImagesDto>(
      objectMapper(json, CompanyImagesMap),
      CompanyImagesDto
    );
  }

  private async fetchImages() {
    const [data] = await Utils.try(
      Promise.all([
        this.imageUrlService?.getCompanyLogo(),
        this.imageUrlService?.getHeaderImage(),
        this.imageUrlService?.getLifestyleImages(),
      ])
    );

    if (!data) return null;
    const [logo, header, lifestyles] = data;
    if (!logo || !header || !lifestyles) return null;
    return { logo, header, lifestyles };
  }

  get isAddCompanyImageryButtonVisible() {
    return !this.hasImages && this.status === EditableBoxStatusTypes.View;
  }

  private getLifestyleImagesNames(lifestyle?: Array<IImage> | null): string[] {
    const arr: string[] = [];
    if (lifestyle) {
      lifestyle.forEach((value) => {
        if (value?.url) {
          arr.push(value.url);
        }
      });
    }
    return arr;
  }

  private openProfilePreview() {
    this.setShowProfilePreviewModal(true);
  }

  private onAddCompanyImageryButtonClick() {
    this.onEditableBoxEdit();
  }

  private onEditableBoxEdit() {
    this.backupDataService?.saveBackup(this, this.companyImages);
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.backupDataService?.restoreBackup(this, this.companyImages);
    this.previewDisabled = false;
    this.status = EditableBoxStatusTypes.View;
  }

  private shouldDeleteFile(image: IImage | null | undefined, name: string) {
    return image?.url === "delete" ? name : image?.file ? image.file : null;
  }

  private async onEditableBoxSave() {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();
    if (validateResponse.valid) {
      try {
        this.status = EditableBoxStatusTypes.Saving;

        await this.imageUrlService?.setImages({
          logo: this.companyImages?.logo?.file || null,
          header: this.companyImages?.header?.file || null,
          lifestyle1: this.companyImages?.lifestyle1?.file || null,
          lifestyle2: this.shouldDeleteFile(
            this.companyImages?.lifestyle2,
            "lifestyle2"
          ),
          lifestyle3: this.shouldDeleteFile(
            this.companyImages?.lifestyle3,
            "lifestyle3"
          ),
        });

        await this.setInfoList();
        this.hasImages = true;
        this.createToastSuccess?.({
          message: "Your company images have been successfully saved!",
        });
        this.setImageUploadAsValid?.();
        await this.setCompanyImages();
        this.status = EditableBoxStatusTypes.View;
      } catch (err) {
        this.status = EditableBoxStatusTypes.Edit;
      }
    } else if (validateResponse.errors) {
      const key = Object.keys(validateResponse.errors)[0];
      this.createToastWarning?.({
        message: `${key}: ${validateResponse.errors[key]}`,
      });
    }
  }
}
