import { AddressDto, IShipAddress } from "@/types";
import { Utils } from "@/utils";

export const ShipAddressMap = (address: AddressDto): IShipAddress => {
  // TODO: add in functionality to accept contact info from order contact obj and brandSettings
  return Utils.removeEmptyAndNull({
    addressLine1: address.address1,
    addressLine2: address.address2,
    countryCode: Utils.toCountryCode(address.country || ""),
    name: address.name || "Ship name",
    phone: address.contact?.phone || "5555555555",
    cityLocality: address.city,
    postalCode: address.postalCode,
    stateProvince: address.stateProvince,
  });
};
