import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bc3decb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-describedby"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tooltip-wrapper",
    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onHover && _ctx.onHover(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onHoverLeave && _ctx.onHoverLeave(...args)))
  }, [
    _createElementVNode("div", { "aria-describedby": _ctx.id }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_1),
    _createVNode(_Transition, { name: _ctx.tooltipTransitionName }, {
      default: _withCtx(() => [
        (_ctx.showTooltip)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: _ctx.id,
              role: "tooltip",
              class: _normalizeClass(["tooltip-container", _ctx.tooltipClass]),
              style: _normalizeStyle({ width: _ctx.width + 'rem' })
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(['tooltip-content', _ctx.tooltipBorderRadius])
              }, [
                _renderSlot(_ctx.$slots, "content", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.msg), 1)
                ], true)
              ], 2)
            ], 14, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["name"])
  ], 32))
}