
import { Options, Vue } from "vue-class-component";
import { Prop, Watch, Emit } from "vue-property-decorator";

@Options({
  emits: ["close-modal"],
})
export default class Modal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showCloseBtn!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disableClickaway!: boolean;

  @Watch("open")
  onOpenChanged(val: boolean) {
    this.showModal = val;
  }

  private showModal = this.open;

  private onClickaway(): void {
    !this.disableClickaway && this.closeModal();
  }

  @Emit()
  closeModal() {
    this.showModal = false;
  }
}
