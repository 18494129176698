import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "table-wrapper h-128",
  "data-test-id": "table-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunTable = _resolveComponent("JunTable")!
  const _component_CategoryMappingField = _resolveComponent("CategoryMappingField")!
  const _component_Form = _resolveComponent("Form")!
  const _component_EditableBox = _resolveComponent("EditableBox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EditableBox, {
      title: "Category Mapping",
      status: _ctx.status,
      noPadding: true,
      noShadow: _ctx.isNoShadowSlot,
      onEdit: _ctx.onEditableBoxEdit,
      onCancel: _ctx.onEditableBoxCancel,
      onSave: _ctx.onEditableBoxSave,
      "data-test-id": "editable-box"
    }, {
      viewing: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_JunTable, {
            headers: _ctx.tableHeaders,
            items: _ctx.tableItems,
            loading: _ctx.isTableProcessing,
            itemKey: "dataField"
          }, null, 8, ["headers", "items", "loading"])
        ])
      ]),
      editing: _withCtx(() => [
        _createVNode(_component_Form, {
          ref: "form",
          "validation-schema": _ctx.validationSchema,
          class: "form",
          "data-test-id": "edit-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CategoryMappingField, {
              modelValue: _ctx.categoryMappingFields,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.categoryMappingFields) = $event)),
              industries: _ctx.industries,
              industrySubCategories: _ctx.industrySubCategories
            }, null, 8, ["modelValue", "industries", "industrySubCategories"])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["status", "noShadow", "onEdit", "onCancel", "onSave"])
  ]))
}