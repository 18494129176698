
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { AddressDto } from "@/types";

@Options({})
export default class AddressInfo extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  address!: AddressDto;

  private renderCityInfo(address: AddressDto): string {
    const { city = "", stateProvince = "", postalCode = "" } = address;
    return `${city}${city ? ", " : " "}${stateProvince} ${postalCode}`;
  }
}
