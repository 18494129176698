import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class SalesRepDto {
  @JsonProperty() salesRepId!: number;
  @JsonProperty() agencyName!: string | null;
  @JsonProperty() firstName!: string | null;
  @JsonProperty() lastName!: string | null;
  @JsonProperty() repNumber!: string | null;
  @JsonProperty() phone!: string | null;
  @JsonProperty() cellPhone!: string | null;
  @JsonProperty() emailAddress!: string | null;
}
