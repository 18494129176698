
import { mixins, Options } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import {
  AdminButton,
  AdminInput,
  Dropdown,
  InfoList,
  Checkbox,
  Icon,
} from "@/components";
import { CarrierTypes, CountryTypes, FedExInformationDto } from "@/types";

import * as yup from "yup";
import { ShippingCarrierMixin } from "@/mixins";

@Options({
  components: {
    AdminButton,
    AdminInput,
    Dropdown,
    InfoList,
    Checkbox,
    Icon,
  },
})
export default class FedExCA extends mixins(ShippingCarrierMixin) {
  created() {
    this.accountInfo &&
      this.isFedEx(this.accountInfo) &&
      (this.fedExInformation = this.accountInfo);
    this.updateInfo();
  }

  @Watch("fedExInformation", { deep: true })
  onInfoChange() {
    this.updateInfo();
  }

  private connectClick() {
    this.updateInfo();
    this.connectAccount(CarrierTypes.FedExCA, this.fedExInformation);
  }

  private updateInfo() {
    this.setCarrierInfo(
      !this.carrierType ? CarrierTypes.FedExCA : this.carrierType,
      !this.accountInfo ? this.fedExInformation : this.accountInfo
    );
  }

  @Watch("countryCode", { immediate: true })
  updateStateOptions(val: CountryTypes) {
    this.currentCountry = val;
  }

  private get countryCode() {
    return this.fedExInformation.countryCode;
  }

  private fedExInformation: FedExInformationDto = {
    accountNumber: "",
    address1: "",
    address2: "",
    city: "",
    company: "",
    countryCode: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    postalCode: "",
    state: "",
    agreeToEula: false,
  };

  private validationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    accountNumber: yup.string().required(),
    email: yup.string().email(),
    address1: yup.string().required(),
    address2: yup.string(),

    city: yup.string().required(),
    state: yup.mixed().required(),
    postalCode: yup.string().required(),
    countryCode: yup.mixed().required(),
    company: yup.string(),
    phone: yup.string().isPhone().required(),
    agreeToEula: yup
      .boolean()
      .oneOf([true], "You must accept the EULA to continue."),
  });
}
