
import { Vue, Options } from "vue-class-component";
import { NavTabs, TopBar } from "@/components";
import { MainRouteTypes } from "@/types";

@Options({
  components: {
    NavTabs,
    TopBar,
  },
})
export default class Buyers extends Vue {
  private MainRouteTypes = MainRouteTypes;
}
