import { AxiosResponse } from "axios";
import axios from "@/axios";
// have to import directly from axios library instead of our wrapper to get
// access to the .Cancel method for token expiration logic
import origAxiosInstance from "axios";
import { injectable } from "inversify-props";
import { EventEmitter } from "events";
import jwtDecode, { JwtPayload } from "jwt-decode";

import {
  IDecodedAuthToken,
  IExtendedAxiosError,
  IExtendedAxiosRequestConfig,
  EventTypes,
} from "@/types";
import * as Sentry from "@sentry/browser";

@injectable()
export class HttpClientService extends EventEmitter {
  public constructor() {
    super();

    axios.interceptors.request.use(
      this.requestInterceptor,
      async (error) => await this.errorInterceptor(error)
    );
    axios.interceptors.response.use(
      this.responseInterceptor,
      async (error) => await this.errorInterceptor(error)
    );
  }

  // Interceptor for requests
  private requestInterceptor(config: IExtendedAxiosRequestConfig) {
    const authToken = localStorage.getItem("authToken") || "";

    if (!config.url?.includes("handshake-login")) {
      const decodedAuthToken = jwtDecode<JwtPayload>(
        authToken
      ) as IDecodedAuthToken;

      const isTokenExpired = decodedAuthToken.exp
        ? decodedAuthToken.exp * 1000 <= Date.now()
        : false;

      if (isTokenExpired) {
        throw new origAxiosInstance.Cancel("tokenExpired");
      }
    }

    if (
      !config.url?.includes("https://applicationimages.s3.amazonaws.com") &&
      !config.url?.includes("https://atlas.microsoft.com")
    ) {
      config.headers["Authorization"] = authToken;
      config.headers["manufacturerId"] = localStorage.getItem("manufacturerId");
      config.headers["Content-Type"] = "application/json";
      config.headers["X-LogRocket-URL"] = localStorage.getItem(
        "logRocketSessionUrl"
      );
    }
    // Do something before request is sent
    return config;
  }

  // Interceptor for responses
  private responseInterceptor(response: AxiosResponse) {
    switch (response.status) {
      case 200:
        // yay!
        break;
      // any other cases
      default:
      // default case
    }

    return response;
  }

  // Interceptor to catch errors
  private errorInterceptor(error: IExtendedAxiosError): Promise<never> {
    if (error.message === "tokenExpired") {
      this.emit(EventTypes.TokenExpired);
      return Promise.reject(error);
    }
    // check if it's a server error
    if (!error.response) {
      this.emit(EventTypes.NetworkServerError, error, "Network/Server error");

      return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
      case 400:
        this.emit(EventTypes.BadRequest, error, "Network/Server error");
        break;

      case 401:
        this.emit(EventTypes.Unauthorized, error, "Unauthorized");

        // authentication error, logout the user
        // localStorage.removeItem("token");
        // router.push("/private");
        break;

      case 404:
        this.emit(EventTypes.NotFound, error, "Not Found");
        break;

      default:
        this.emit(EventTypes.ServerError, error, "Server error");
    }

    Sentry.setTag("traceparent", error.response.data.requestId);
    Sentry.captureException(error);
    Sentry.setTag("traceparent", ""); // To Reset Tag
    return Promise.reject(error);
  }
}
