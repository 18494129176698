
import { Options, mixins } from "vue-class-component";
import { inject } from "inversify-props";
import { namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";
import { JunTableSortDirection } from "@juniper/ui";
import { Drawer, CreditDrawer, Icon, AdminDateRange } from "@/components";
import { TableMixin } from "@/mixins";
import { Utils } from "@/utils";
import { transactionStatusLabelMap } from "@/config";
import {
  MainRouteTypes,
  SubRouteTypes,
  IconNameTypes,
  QueryOperationTypes,
  TransactionStatusTypes,
  TransactionTypes,
  CreditDrawerTypes,
  TransactionDto,
  TableFilterTypes,
} from "@/types";
import type { ITransactionsRepository, TableConfig, IDateRange } from "@/types";

const seller = namespace("sellerVuexModule");
const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    Drawer,
    CreditDrawer,
    Icon,
    AdminDateRange,
  },
})
export default class TransactionsTable extends mixins(TableMixin) {
  @inject() private transactionsRepository!: ITransactionsRepository;

  @seller.Getter private sellerReferenceId!: string | null;
  @manufacturers.Getter private manufacturerId!: number | null;

  private transactions: TransactionDto[] = [];
  private selectedItems: TransactionDto[] = [];
  private MainRouteTypes = MainRouteTypes;
  private SubRouteTypes = SubRouteTypes;
  private IconNameTypes = IconNameTypes;
  private drawerTransaction: TransactionDto | null = null;
  private TransactionTypes = TransactionTypes;
  private CreditDrawerTypes = CreditDrawerTypes;
  private today = Utils.roundToNearestDate();
  private dateRange: IDateRange = Utils.getDefaultDateRange();
  private TableFilterTypes = TableFilterTypes;

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  async created() {
    this.initTable();

    if (this.sellerReferenceId) {
      await this.init({ filterVersion: TableFilterTypes.OData });
    }
  }

  // watcher handles scenario where sellerReferenceId not resolved yet or user
  // changes division
  @Watch("sellerReferenceId")
  async onSellerReferenceIdChange(sellerId: string | null) {
    if (sellerId) {
      await this.init({ filterVersion: TableFilterTypes.OData });
    }
  }

  private initTable() {
    this.tableHeaders = [
      {
        prop: "poNumber",
        text: "PO #",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "orderId",
        text: "Order #",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "customerName",
        text: "Customer Name",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "customerNumber",
        text: "Customer Number",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "createdOn",
        text: "Transaction Date",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "totalAmount",
        text: "Charge Amount",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "status",
        text: "Payment Status",
        canSort: true,
        canFilter: true,
        filterOptions: Object.values(TransactionStatusTypes).map(
          (statusType) => ({
            text: transactionStatusLabelMap[statusType],
            value: statusType,
          })
        ),
      },
    ];
    this.persistentFilters = [
      {
        key: "transactionType",
        value: TransactionTypes.Charge,
        operation: QueryOperationTypes.Equals,
      },
    ];
    this.noWildcardFilterKeys.push(
      "poNumber",
      "orderId",
      "customerNumber",
      "status"
    );
    this.onUpdateDateRange("createdOn", this.dateRange, false);
    this.sortBy = "createdOn";
    this.sortDirection = JunTableSortDirection.Descending;
    this.pageSize = 16;
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    this.getTableData = this.getTransactions;
  }

  private async getTransactions() {
    const queryString = this.getQueryString();

    const transactions = await this.transactionsRepository.getAll(
      this.sellerReferenceId || "",
      queryString
    );

    this.transactions = transactions.data;

    this.tableOptions.paginationOptions = {
      page: transactions.meta.page,
      pageSize: transactions.meta.pageSize,
      totalRecords: transactions.meta.totalRecords,
    };
  }

  private getOrderLink(item: TransactionDto): string {
    return `/${MainRouteTypes.OrderDetails}/${SubRouteTypes.Summary}?guid=${item.orderNumber}`;
  } // This is to prevent string literals in the template from wrapping, which causes vetur's syntax highlighting to break.

  private get tableConfig(): TableConfig {
    return {
      heading: "Transactions",
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.transactions,
      itemKey: "transactionReferenceId",
    };
  }

  private get canRefund(): boolean {
    return Boolean(
      this.selectedItems.length === 1 &&
        this.selectedItems[0].transactionType === "Charge" &&
        this.selectedItems[0].status ===
          transactionStatusLabelMap[TransactionStatusTypes.Created]
    );
  }

  private openDrawer() {
    if (!this.canRefund) return;
    this.drawerTransaction = this.selectedItems[0];
  }

  private closeDrawer() {
    this.drawerTransaction = null;
    this.selectedItems = [];
  }
}
