import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03f6278e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel" }
const _hoisted_2 = { class: "carousel-container" }
const _hoisted_3 = { class: "carousel-left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "carousel-right" }
const _hoisted_6 = { class: "stepper-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          class: _normalizeClass({ disabled: _ctx.disableLeftArrow }),
          onClick: _ctx.onLeftArrowClick,
          name: _ctx.IconNameTypes.LeftChevron
        }, null, 8, ["class", "onClick", "name"])
      ]),
      _createVNode(_Transition, {
        name: "slide-right",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            class: "carousel-image",
            key: _ctx.currentIndex,
            style: _normalizeStyle({
            width: _ctx.width + 'rem',
            minHeight: _ctx.minHeight + 'rem',
          })
          }, [
            _createElementVNode("img", {
              src: _ctx.imageUrls[_ctx.currentIndex]
            }, null, 8, _hoisted_4)
          ], 4))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Icon, {
          class: _normalizeClass({ disabled: _ctx.disableRightArrow }),
          onClick: _ctx.onRightArrowClick,
          name: _ctx.IconNameTypes.RightChevron
        }, null, 8, ["class", "onClick", "name"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageUrls.length, (n) => {
        return (_openBlock(), _createElementBlock("div", {
          key: n,
          class: _normalizeClass(["stepper-dot", { full: n === _ctx.currentIndex + 1 }])
        }, null, 2))
      }), 128))
    ])
  ]))
}