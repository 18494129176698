import { injectable } from "inversify-props";
import { deserialize, serialize } from "typescript-json-serializer";
import axios from "axios";
import {
  OrderStatusTypes,
  OrderSalesHistoryDto,
  OrderReportDto,
  ThirtyDayHistoryDto,
  TopCustomerDto,
  ReportsGroupByTypes,
} from "@/types";
import type { IReportsRepository } from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Reports`;

@injectable()
export class ReportsRepository implements IReportsRepository {
  public async getOrderAndSalesHistory(
    brandId?: number
  ): Promise<OrderSalesHistoryDto> {
    const { data } = await axios.get(
      `${ENDPOINT_URL}/OrderHistoryReport`,
      brandId ? { headers: { brandId } } : {}
    );
    return deserialize<OrderSalesHistoryDto>(data, OrderSalesHistoryDto);
  }

  public async postCustomHistoryRange(
    start: string,
    end: string,
    groupBy: ReportsGroupByTypes,
    brandId?: number
  ): Promise<Array<OrderReportDto>> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/OrderHistory`,
      serialize({
        start: start,
        end: end,
        groupBy: groupBy,
        statuses: [OrderStatusTypes.Paid, OrderStatusTypes.Shipped],
      }),
      brandId ? { headers: { brandId } } : {}
    );
    //TODO: Check if possible to deserialize the whole list at once (not using map)
    return data.map((ps: OrderReportDto) => {
      return deserialize<OrderReportDto>(ps, OrderReportDto);
    });
  }

  public async getThirtyDayHistory(
    brandId?: number
  ): Promise<ThirtyDayHistoryDto> {
    const { data } = await axios.get(
      `${ENDPOINT_URL}/ThirtyDayHistory`,
      brandId ? { headers: { brandId } } : {}
    );
    return deserialize<ThirtyDayHistoryDto>(data, ThirtyDayHistoryDto);
  }

  public async getTopCustomers(
    brandId?: number
  ): Promise<Array<TopCustomerDto>> {
    const { data } = await axios.get(
      `${ENDPOINT_URL}/TopCustomers`,
      brandId ? { headers: { brandId: brandId } } : {}
    );
    //TODO: Check if possible to deserialize the whole list at once (not using map)
    return data.map((ps: TopCustomerDto) => {
      return deserialize<TopCustomerDto>(ps, TopCustomerDto);
    });
  }
}
