import {
  EventTypes,
  FeatureFlagTypes,
  IFeatureFlag,
  IFeatureFlagService,
} from "@/types";
import { EventEmitter } from "events";
import { injectable } from "inversify-props";
import * as LaunchDarklyJsClientSdk from "launchdarkly-js-client-sdk";

@injectable()
export class FeatureFlagService
  extends EventEmitter
  implements IFeatureFlagService
{
  private readonly clients: LaunchDarklyJsClientSdk.LDClient[] = [];

  public constructor() {
    super();
    this.onClientReady = this.onClientReady.bind(this);
    this.onClientChange = this.onClientChange.bind(this);
    this.onClientError = this.onClientError.bind(this);
  }

  public async initialize(initialFlagIds: FeatureFlagTypes[] = []) {
    this.addClient(process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID, {
      initialFlagIds,
    });
    // TODO: Uncomment when multi-project flags needed
    // this.addClient(process.env.VUE_APP_LAUNCH_DARKLY_ANDMORE_CLIENT_SIDE_ID);
    await this.waitForAllClientsReady();
  }

  private addClient(
    clientId: string | undefined,
    options: {
      initialFlagIds?: FeatureFlagTypes[];
    } = {}
  ) {
    const { initialFlagIds = [] } = options;
    const client = LaunchDarklyJsClientSdk.initialize(clientId || "", {
      key: "",
    });
    client.on("ready", () => this.onClientReady(client, initialFlagIds));
    client.on("change", this.onClientChange);
    client.on("error", this.onClientError);
    this.clients.push(client);
  }

  private waitForAllClientsReady() {
    const allClientsReady = this.clients.map((client) =>
      client.waitUntilReady()
    );
    return Promise.all(allClientsReady);
  }

  private onClientReady(
    client: LaunchDarklyJsClientSdk.LDClient,
    initialFlagIds: FeatureFlagTypes[] = []
  ) {
    const sourceFlagSet = client.allFlags();
    const flagIdsOrdered = this.sortFlags(initialFlagIds, sourceFlagSet);
    for (const id of flagIdsOrdered) {
      const value = sourceFlagSet[id];
      if (typeof value === "undefined") return;
      const event: IFeatureFlag = { id, value };
      this.emit(EventTypes.FeatureFlagChange, event);
    }
  }

  private onClientChange(changeSet: LaunchDarklyJsClientSdk.LDFlagChangeset) {
    const [id, flagState] = Object.entries(changeSet)[0];
    const event: IFeatureFlag = { id, value: flagState.current };
    this.emit(EventTypes.FeatureFlagChange, event);
  }

  private onClientError(err: unknown) {
    this.emit(EventTypes.FeatureFlagError, err);
  }

  private sortFlags(
    initialFlagIds: FeatureFlagTypes[],
    sourceFlagSet: LaunchDarklyJsClientSdk.LDFlagSet
  ) {
    const sourceFlagIds = Object.keys(sourceFlagSet) as FeatureFlagTypes[];
    const sourceFlagsIdsWithoutInitialFlags = this.removeItems(
      sourceFlagIds,
      initialFlagIds
    );
    return [...initialFlagIds, ...sourceFlagsIdsWithoutInitialFlags];
  }

  private removeItems<T>(from: T[], remove: T[]): T[] {
    return from.filter((item) => !remove.includes(item));
  }
}
