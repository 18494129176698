import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize } from "typescript-json-serializer";
import {
  CustomerDto,
  FullCustomerDto,
  ICustomerPostPayload,
  ICustomersRepository,
  CustomersDto,
} from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Customers/Customers`;

@injectable()
export class CustomersRepository implements ICustomersRepository {
  public async search(query: string): Promise<Array<CustomerDto>> {
    const { data } = await axios.get(`${ENDPOINT_URL}/search?search=${query}`);
    //TODO: Check if possible to serialize the whole list at once (not using map)
    return data.map((c: CustomerDto) => {
      return deserialize<CustomerDto>(c, CustomerDto);
    });
  }

  public async get(customerNumber: string): Promise<FullCustomerDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${customerNumber}`);
    return deserialize<FullCustomerDto>(data, FullCustomerDto);
  }

  public async getAll(queryString: string): Promise<CustomersDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}${queryString}`);
    return deserialize<CustomersDto>(data, CustomersDto);
  }

  public async post(payload: ICustomerPostPayload): Promise<FullCustomerDto> {
    const { data } = await axios.post(ENDPOINT_URL, payload);
    return deserialize<FullCustomerDto>(data, FullCustomerDto);
  }

  public async delete(id: string) {
    return axios.delete(`${ENDPOINT_URL}/${id}`);
  }
}
