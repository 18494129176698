
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TooltipPosition, ToolTipColorSchemeTypes } from "@/types";

@Options({})
export default class Tooltip extends Vue {
  @Prop({
    type: String,
  })
  msg?: string;

  @Prop({
    type: String,
    default: TooltipPosition.Top,
  })
  position!: string;

  @Prop({
    type: Number as PropType<number>,
    default: 16,
  })
  width!: number;

  @Prop({
    type: String as PropType<ToolTipColorSchemeTypes>,
    default: ToolTipColorSchemeTypes.Dark,
  })
  colorScheme!: ToolTipColorSchemeTypes;

  @Prop({
    type: String as PropType<"default" | "lg">,
    default: "lg",
  })
  borderRadius!: "default" | "lg";

  @Prop({
    type: [String, undefined] as PropType<string | undefined>,
    default: undefined,
  })
  id!: string | undefined;

  private showTooltip = false;
  private tooltipClass = {
    right: this.position === TooltipPosition.Right,
    left: this.position === TooltipPosition.Left,
    top: this.position === TooltipPosition.Top,
    bottom: this.position === TooltipPosition.Bottom,
    light: this.colorScheme === ToolTipColorSchemeTypes.Light,
  };
  private tooltipBorderRadius =
    this.borderRadius === "default"
      ? "rounded"
      : `rounded-${this.borderRadius}`;

  get tooltipTransitionName() {
    switch (this.position) {
      case TooltipPosition.Right:
        return "slide-left";
      case TooltipPosition.Left:
        return "slide-right";
      case TooltipPosition.Top:
        return "slide-down";
      case TooltipPosition.Bottom:
        return "slide-up";
      default:
        return "slide-left";
    }
  }

  onHover() {
    this.showTooltip = true;
  }

  onHoverLeave() {
    this.showTooltip = false;
  }
}
