
import { Options, Vue } from "vue-class-component";
import { TopBar, OrdersTable, NavTabs } from "@/components";
import { MainRouteTypes } from "@/types";

@Options({
  components: {
    TopBar,
    OrdersTable,
    NavTabs,
  },
})
export default class Orders extends Vue {
  private MainRouteTypes = MainRouteTypes;
}
