
import { nextTick, PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Icon } from "@/components";
import { Utils } from "@/utils";
import { IconNameTypes } from "@/types";

@Options({
  name: "AdminCollapsible",
  components: {
    Icon,
  },
  emits: ["change"],
})
export default class AdminCollapsible extends Vue {
  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  private initialOpen!: boolean;

  @Prop({
    type: [Boolean, undefined] as PropType<boolean | undefined>,
    default: undefined,
  })
  private open!: boolean | undefined;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  private disableToggle!: boolean;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  hideChevronIcon!: boolean;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  noContentSpacer!: boolean;

  @Prop({
    type: String as PropType<string>,
    default: Utils.generateRandomId(),
  })
  id!: string;

  public IconNameTypes = IconNameTypes;
  private collapsiblePanelId = `panel_${this.id}`;
  private collapsibleToggleId = `toggle_${this.id}`;
  private internalOpen = this.initialOpen;

  private get isExternalControl(): boolean {
    return this.open !== undefined;
  }

  private get isOpen(): boolean {
    return Boolean(this.isExternalControl ? this.open : this.internalOpen);
  }

  private toggleIsOpen(): void {
    if (this.disableToggle) return;
    const prevOpen = this.isOpen;
    if (!this.isExternalControl) {
      this.internalOpen = !prevOpen;
    }
    this.$emit("change", !prevOpen);
  }

  private async handleCollapseEnter(element: HTMLElement) {
    const width = getComputedStyle(element).width;
    element.style.position = "absolute";
    element.style.visibility = "hidden";
    element.style.width = width;
    element.style.height = "auto";

    const height = getComputedStyle(element).height;
    element.style.height = "0";
    element.style.width = "initial";
    element.style.position = "initial";
    element.style.visibility = "initial";

    // Force browser re-paint before setting height to content height
    getComputedStyle(element).height;
    await nextTick();
    element.style.height = height;
  }

  private handleCollapseAfterEnter(element: HTMLElement) {
    element.style.height = "auto";
  }

  private async handleCollapseLeave(element: HTMLElement) {
    element.style.height = getComputedStyle(element).height;

    // Force browser re-paint before setting height to 0
    getComputedStyle(element).height;
    await nextTick();
    element.style.height = "0";
  }

  private get hideSpacer() {
    return this.hideChevronIcon || this.noContentSpacer;
  }
}
