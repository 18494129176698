import { ProductAttributeDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class ProductDetailsTemplateDto {
  @JsonProperty() descriptionField!: string | null;
  @JsonProperty() childProductsTitle!: string | null;
  @JsonProperty() customText!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.setNullAttributesIfEmptyArray,
    type: ProductAttributeDto,
  })
  attributes!: Array<ProductAttributeDto>;
}
