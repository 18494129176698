import { ProductDetailsTemplateDto, UserDefinedFieldDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class ProductSettingsDto {
  @JsonProperty()
  productDetailsTemplate!: ProductDetailsTemplateDto;
  @JsonProperty() styleField!: string | null;
  @JsonProperty() colorField!: string | null;
  @JsonProperty() materialField!: string | null;
  @JsonProperty() productSetsIds!: Array<number> | null;
  @JsonProperty({
    beforeDeserialize: Utils.setEmptyIfNotArray,
    afterSerialize: Utils.omitFromRequest,
    type: UserDefinedFieldDto,
  })
  userDefinedFields!: Array<UserDefinedFieldDto>;
}
