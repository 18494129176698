
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Modal } from "@/components";

@Options({
  components: {
    Modal,
  },
})
export default class Thumbnail extends Vue {
  @Prop({
    type: String,
    // TODO FIXME: add some sort of default 'not found' image here?
    default: "",
    required: true,
  })
  url!: string;

  @Prop({
    type: Number,
    default: 16,
  })
  height!: number;

  @Prop({
    type: Number,
    default: 14,
  })
  width!: number;

  private showModal = false;

  openModal() {
    this.showModal = true;
  }
}
