import { Vue } from "vue-class-component";
import {
  IconNameTypes,
  DashboardSortTypes,
  OrderSalesHistoryDto,
  IFormattedMonthReport,
  OrderReportDto,
} from "@/types";
import { Utils } from "@/utils";
import { ApexOptions } from "apexcharts";
import type { IReportsRepository } from "@/types";
import { inject } from "inversify-props";
import { ChartColorsConfig, TimeDateLanguageConfig } from "@/config";

export class ChartMixin extends Vue {
  @inject() private reportsRepository!: IReportsRepository;

  protected IconNameTypes = IconNameTypes;
  protected loading = false;
  protected sortOptions = Utils.getDropdownFromEnum(DashboardSortTypes);
  protected chartSort = DashboardSortTypes.Week;
  protected compare = true;
  protected currentData = [0, 0, 0, 0, 0, 0, 0];
  protected previousData = [0, 0, 0, 0, 0, 0, 0];
  protected dates: Array<string> = [];
  protected points: Array<PointAnnotations> = [];
  protected customOptions?: ApexOptions;
  protected chartId = "";
  protected orderSalesHistory = new OrderSalesHistoryDto();
  protected formattedCurrentMonth!: Array<IFormattedMonthReport>;
  protected formattedPreviousMonth!: Array<IFormattedMonthReport>;

  protected chartOptions: ApexOptions = {
    chart: {
      id: this.chartId,
      fontFamily: "Konnect, Helvetica, Arial, sans-serif",
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      inverseOrder: true,
      theme: "dark",
    },
    legend: {
      showForSingleSeries: true,
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    annotations: {
      points: [],
    },
    colors: ChartColorsConfig.compare,
    xaxis: {
      categories: [],
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  protected get getPreviousData() {
    switch (this.chartSort) {
      case DashboardSortTypes.Week:
        return this.orderSalesHistory.previousWeek ?? [];
      case DashboardSortTypes.Month:
        return (
          this.formattedPreviousMonth?.map((d): OrderReportDto => {
            return {
              totalSales: d.totalSales,
              numberOfOrders: d.numberOfOrders,
              orderDate: d.start,
            };
          }) ?? []
        );
      case DashboardSortTypes.Quarter:
        return this.orderSalesHistory.previousYearQuarters ?? [];
      case DashboardSortTypes.Year:
        return this.orderSalesHistory.previousYear ?? [];
    }
  }

  protected get getCurrentData() {
    switch (this.chartSort) {
      case DashboardSortTypes.Week:
        return this.orderSalesHistory.currentWeek ?? [];
      case DashboardSortTypes.Month:
        return (
          this.formattedCurrentMonth?.map((d): OrderReportDto => {
            return {
              totalSales: d.totalSales,
              numberOfOrders: d.numberOfOrders,
              orderDate: d.start,
            };
          }) ?? []
        );
      case DashboardSortTypes.Quarter:
        return this.orderSalesHistory.currentYearQuarters ?? [];
      case DashboardSortTypes.Year:
        return this.orderSalesHistory.currentYear ?? [];
    }
  }

  protected dateRange() {
    switch (this.chartSort) {
      case DashboardSortTypes.Week:
        return this.orderSalesHistory.currentWeek?.map((d) => {
          return new Date(d.orderDate ?? "").toLocaleDateString(
            TimeDateLanguageConfig.usEnglish,
            {
              month: "short",
              day: "numeric",
            }
          );
        });
      case DashboardSortTypes.Month:
        return this.formattedCurrentMonth?.map((w) => {
          return (
            new Date(w.start ?? "").toLocaleDateString(
              TimeDateLanguageConfig.usEnglish,
              {
                month: "short",
                day: "numeric",
              }
            ) +
            " - " +
            new Date(w.end ?? "").toLocaleDateString(
              TimeDateLanguageConfig.usEnglish,
              {
                month: "short",
                day: "numeric",
              }
            )
          );
        });
      case DashboardSortTypes.Quarter:
        return ["Jan - Mar", "Apr - Jun", "Jul - Sep", "Oct - Dec"];
      case DashboardSortTypes.Year:
        return this.orderSalesHistory.currentYear.map((d) => {
          return new Date(d.orderDate ?? "").toLocaleDateString(
            TimeDateLanguageConfig.usEnglish,
            {
              month: "short",
            }
          );
        });
      default:
        return this.orderSalesHistory.currentMonth.map((d) => {
          return new Date(d.orderDate ?? "").toLocaleDateString(
            TimeDateLanguageConfig.usEnglish,
            {
              month: "short",
              day: "numeric",
            }
          );
        });
    }
  }

  protected async fetchData(multiLine: boolean, brandId?: number) {
    this.loading = true;
    const [data] = await Utils.try(
      multiLine && brandId
        ? this.reportsRepository.getOrderAndSalesHistory(brandId)
        : this.reportsRepository.getOrderAndSalesHistory()
    );

    if (data) {
      this.orderSalesHistory = data;
      this.formattedCurrentMonth = this.orderSalesHistory.getFormattedMonth(
        this.orderSalesHistory.currentMonth
      );
      this.formattedPreviousMonth = this.orderSalesHistory.getFormattedMonth(
        this.orderSalesHistory.previousMonth
      );
    }
  }

  protected setChart() {
    this.chartOptions = {
      ...this.chartOptions,
      chart: {
        ...this.chartOptions.chart,
        id: this.chartId,
      },
      colors: [
        ...(this.compare
          ? ChartColorsConfig.compare
          : ChartColorsConfig.noCompare),
      ],
      annotations: {
        points: this.points,
      },
      xaxis: {
        ...this.chartOptions?.xaxis,
        categories: this.dates,
      },
      ...this.customOptions,
    };

    this.chartSeries = [
      ...(this.compare
        ? [
            {
              name: this.getPreviousLabel(),
              data: this.previousData,
            },
          ]
        : []),
      {
        name: this.getCurrentLabel(),
        data: this.currentData,
      },
    ];

    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  protected chartSeries: Array<{ name: string; data: number[] }> = [];

  protected downloadClick() {
    (
      document.querySelector(
        `#apexcharts${this.chartOptions.chart?.id} .apexcharts-menu-icon`
      ) as HTMLElement
    ).click();
  }

  // We use this to make the chart annotations fade in and out on mount and data change
  protected fadeAnnotations() {
    const time = 0;
    document
      .querySelectorAll(
        `#apexcharts${this.chartOptions.chart?.id} .apexcharts-point-annotations [id*='Svgjs']`
      )
      .forEach((e) => {
        setTimeout(
          () =>
            e.setAttribute(
              "class",
              e.getAttribute("class")?.includes("show-annotation")
                ? e.getAttribute("class")?.replace("show-annotation", "") || ""
                : "show-annotation"
            ),
          time
        );
      });
  }

  protected getPreviousLabel() {
    switch (this.chartSort) {
      case DashboardSortTypes.Week:
        return "Previous Week";
      case DashboardSortTypes.Month:
        return "Previous Month";
      case DashboardSortTypes.Quarter:
        return "Previous Quarters";
      case DashboardSortTypes.Year:
        return "Previous Year";
    }
  }

  protected getCurrentLabel() {
    switch (this.chartSort) {
      case DashboardSortTypes.Week:
        return "Current Week";
      case DashboardSortTypes.Month:
        return "Current Month";
      case DashboardSortTypes.Quarter:
        return "Current Quarters";
      case DashboardSortTypes.Year:
        return "Current Year";
    }
  }
}
