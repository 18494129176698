import * as yup from "yup";
import { IDimensionsRange, ImageFileFormats } from "@/types";
import { Utils, Validators } from "@/utils";

const URL_DELETE = "delete";

export abstract class ValidationHelper {
  public static registerValidations() {
    yup.addMethod(yup.mixed, "maxFileSize", function (kb: number) {
      return this.test("maxFileSize", "File size is too large", (value) => {
        const array = Array.isArray(value) ? value : [value];
        return (
          array
            .filter(Boolean)
            .find((item) =>
              item.url == URL_DELETE
                ? true
                : item.isRemote ||
                  !item.file ||
                  (item.file && item.file.size <= kb * 1024)
            ) !== undefined
        );
      });
    });

    yup.addMethod(
      yup.mixed,
      "fileFormats",
      function (validFormats: ImageFileFormats[]) {
        return this.test("fileFormat", "Unsupported Format", (value) => {
          const array = Array.isArray(value) ? value : [value];
          return (
            array
              .filter(Boolean)
              .find((item) =>
                item.url == URL_DELETE
                  ? true
                  : item.isRemote ||
                    !item.file ||
                    (item.file && validFormats.includes(item.file.type))
              ) !== undefined
          );
        });
      }
    );

    yup.addMethod(
      yup.mixed,
      "fileDimensionsRange",
      function (range: IDimensionsRange) {
        return this.test(
          "fileDimensions",
          "Image dimensions outside range",
          (value) => {
            const array = Array.isArray(value) ? value : [value];
            return (
              array
                .filter(Boolean)
                .find((item) =>
                  item.url == URL_DELETE
                    ? true
                    : item.isRemote ||
                      !item.dimensions ||
                      (item.dimensions?.width >= range.min.width &&
                        item.dimensions?.height >= range.min.height &&
                        item.dimensions?.width <= range.max.width &&
                        item.dimensions?.height <= range.max.height)
                ) !== undefined
            );
          }
        );
      }
    );

    yup.addMethod(yup.mixed, "fileRequired", function () {
      return this.test("fileRequired", "Image is required", (value) => {
        const array = Array.isArray(value) ? value : [value];

        return (
          array.filter(Boolean).find((item) => item.url != null) !== undefined
        );
      });
    });

    yup.addMethod(yup.mixed, "isPhone", function () {
      return this.test("isPhone", "Phone number must be valid", (value) => {
        if (value === null || value === undefined || value?.length === 0) {
          return true;
        }
        const cleanedNumber = Utils.cleanPhone(value);
        return Validators.phone.test(cleanedNumber);
      });
    });

    yup.addMethod(yup.mixed, "urlHasProtocol", function () {
      return this.test(
        "urlHasProtocol",
        "URL must begin with protocol (http:// or https://)",
        (value) => {
          if (value === null || value === undefined || value?.length === 0) {
            return true;
          }
          return value.startsWith("http://") || value.startsWith("https://");
        }
      );
    });
  }
}
