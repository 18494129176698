import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.address)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.address.name)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.address.name), 1))
          : _createCommentVNode("", true),
        (_ctx.address.address1)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.address.address1), 1))
          : _createCommentVNode("", true),
        (_ctx.address.address2)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.address.address2), 1))
          : _createCommentVNode("", true),
        (_ctx.renderCityInfo(_ctx.address).trim())
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.renderCityInfo(_ctx.address)), 1))
          : _createCommentVNode("", true),
        (_ctx.address.country)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.address.country), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}