
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  IconNameTypes,
  LabelDto,
  WeightLabelTypes,
  WeightTypes,
} from "@/types";
import type { IShipmentsPostPayload } from "@/types";
import { Icon } from "@/components";
import { Utils } from "@/utils";

@Options({ components: { Icon } })
export default class ShipWithJuniper extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  shipment!: IShipmentsPostPayload;

  @Prop({
    type: Object,
    required: true,
  })
  label!: LabelDto;

  @Prop({
    type: String,
    required: true,
  })
  weightUnit!: string;

  private IconNameTypes = IconNameTypes;

  private get dimensions() {
    const d = this.shipment.packages[0].dimensions;
    return `${d.length} x ${d.width} x ${d.height}`;
  }

  private get weight() {
    const w = this.shipment.packages[0].weight;
    const weightLabel =
      WeightLabelTypes[
        (this.weightUnit as keyof typeof WeightTypes) || "Pound"
      ];
    return `${w.value} ${weightLabel}`;
  }

  private get shipmentCost() {
    return Utils.floatToCurrencyString(this.label.shipmentCost.amount || 0.0);
  }

  private get shipDate() {
    return this.shipment?.shipDate
      ? Utils.formatDate(new Date(this.shipment.shipDate))
      : "";
  }
}
