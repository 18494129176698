<template>
  <div>
    <h1>Error in authentication</h1>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class OidcCallbackError extends Vue {}
</script>

<style scoped></style>
