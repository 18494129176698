import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class CartLineItemDto {
  @JsonProperty() importMapping!: number;
  @JsonProperty() additionalImageCount!: number;
  @JsonProperty() basePrice!: number;
  @JsonProperty() appendToItemId!: string | null;
  @JsonProperty() appendItemId!: boolean;
  @JsonProperty() catalogCode!: string | null;
  @JsonProperty() catalogName!: string | null;
  @JsonProperty() categoryName!: string | null;
  @JsonProperty() category!: string | null;
  @JsonProperty() completeItemId!: string | null;
  @JsonProperty() cubes!: number;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty() description!: string | null;
  @JsonProperty() dimensions!: string | null;
  @JsonProperty() discontinued!: boolean;
  @JsonProperty() discountIsQtyBased!: boolean;
  @JsonProperty() discountScope!: string | null;
  @JsonProperty() discountType!: string | null;
  @JsonProperty() discountValue!: number;
  @JsonProperty() hasLineItemDiscount!: boolean;
  @JsonProperty() hasModifiers!: boolean;
  @JsonProperty() itemId!: string | null;
  @JsonProperty() itemName!: string | null;
  @JsonProperty() itemType!: string | null;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() modifierListItemIds!: string[];
  @JsonProperty() notes!: string | null;
  @JsonProperty() cartID!: string | null;
  @JsonProperty() lineItemID!: string | null;
  @JsonProperty() parentItemID!: string | null;
  @JsonProperty() photoName!: string | null;
  @JsonProperty() piecesBox!: number;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  price!: string | null;
  @JsonProperty() qty!: number;
  @JsonProperty() onHandQuantity!: number;
  @JsonProperty() sortOrder!: number;
  @JsonProperty() source!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  subTotal!: string | null;
  @JsonProperty() userDefinedFields!: string[];
  @JsonProperty() unitOfMeasure!: string | null;
  @JsonProperty() upc!: string | null;
  @JsonProperty() weight!: number;
  @JsonProperty() additionalPhotos!: string | null;
  @JsonProperty() reportCategory!: string | null;
  @JsonProperty() vendorName!: string | null;
}
