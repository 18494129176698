import { JsonProperty, Serializable } from "typescript-json-serializer";
import { CustomerShippingAddressDto } from "@/types";

@Serializable()
export class FullCustomerDto {
  @JsonProperty() customerId!: number;
  @JsonProperty() manufacturerID!: number;
  @JsonProperty() name!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty() address1!: string | null;
  @JsonProperty() address2!: string | null;
  @JsonProperty() authorizedDealer!: boolean;
  @JsonProperty() city!: string | null;
  @JsonProperty() contactEmail!: string | null;
  @JsonProperty() contactName!: string | null;
  @JsonProperty() contactPhone!: string | null;
  @JsonProperty() country!: string | null;
  @JsonProperty() exported!: boolean;
  @JsonProperty() failedPasswordAttemptCount!: number;
  @JsonProperty() fax!: string | null;
  @JsonProperty() isAddedToServer!: boolean;
  @JsonProperty() phone!: string | null;
  @JsonProperty() postalCode!: string | null;
  @JsonProperty() priceLevel!: number;
  @JsonProperty() pricingDiscount!: number;
  @JsonProperty() repNumber!: string | null;
  @JsonProperty() shipToAddress1!: string | null;
  @JsonProperty() shipToAddress2!: string | null;
  @JsonProperty() shipToCity!: string | null;
  @JsonProperty() shipToCode!: string | null;
  @JsonProperty() shipToContactName!: string | null;
  @JsonProperty() shipToCountry!: string | null;
  @JsonProperty() shipToEmail!: string | null;
  @JsonProperty() shipToFax!: string | null;
  @JsonProperty() shipToName!: string | null;
  @JsonProperty() shipToPhone!: string | null;
  @JsonProperty() shipToPostalCode!: string | null;
  @JsonProperty() shipToStateProvince!: string | null;
  @JsonProperty() source!: string | null;
  @JsonProperty() stateProvince!: string | null;
  @JsonProperty() terms!: string | null;
  @JsonProperty() udf1!: string | null;
  @JsonProperty() udf2!: string | null;
  @JsonProperty() udf3!: string | null;
  @JsonProperty() udf4!: string | null;
  @JsonProperty() udf5!: string | null;
  @JsonProperty() username!: string | null;
  @JsonProperty({ type: CustomerShippingAddressDto })
  shippingAddresses!: CustomerShippingAddressDto[];
  @JsonProperty() deletedBy!: string | null;
  @JsonProperty() deletedOn!: string | null;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty() modifiedBy!: string | null;
  @JsonProperty() createdBy!: string | null;
  @JsonProperty() modifiedOn!: string | null;
}
