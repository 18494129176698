
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Icon } from "@/components";
import { IconNameTypes, ButtonColorTypes, ButtonSizeTypes } from "@/types";

@Options({
  components: {
    Icon,
  },
})
export default class AdminButton extends Vue {
  @Prop({
    type: String,
    default: ButtonSizeTypes.Medium,
    validator: (val: ButtonSizeTypes) =>
      Object.values(ButtonSizeTypes).includes(val),
  })
  size!: ButtonSizeTypes;

  @Prop({
    type: String,
    default: ButtonColorTypes.Primary,
    validator: (val: ButtonColorTypes) =>
      Object.values(ButtonColorTypes).includes(val),
  })
  color!: ButtonColorTypes;

  @Prop({
    type: Boolean,
    default: false,
  })
  inactive!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  inactiveFilled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: Number,
    default: 8,
  })
  minWidth!: number;

  @Prop({
    type: Number,
    default: 1.25,
  })
  iconSize!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  icon!: IconNameTypes;

  @Prop({
    type: String,
    default: "button",
  })
  type!: string;

  get btnClassList() {
    return [
      ...(this.color === ButtonColorTypes.Brand &&
      !this.inactive &&
      !this.inactiveFilled
        ? ["bg-primary", "text-white", "border-primary", "hover:shadow-primary"]
        : []),
      ...(this.color === ButtonColorTypes.BrandSecondary &&
      !this.inactive &&
      !this.inactiveFilled
        ? ["bg-white", "text-primary", "border-white", "hover:shadow-md"]
        : []),
      ...(this.color === ButtonColorTypes.Primary &&
      !this.inactive &&
      !this.inactiveFilled
        ? [
            "bg-secondary-dark",
            "text-white",
            "hover:shadow-md",
            "border-transparent",
          ]
        : []),
      ...(this.color === ButtonColorTypes.Secondary &&
      !this.inactive &&
      !this.inactiveFilled
        ? [
            "bg-secondary",
            "text-white",
            "hover:shadow-secondary",
            "border-transparent",
          ]
        : []),
      ...(this.color === ButtonColorTypes.Ghost &&
      !this.inactive &&
      !this.inactiveFilled
        ? [
            "border-transparent",
            "text-secondary-dark",
            "hover:border-secondary-dark",
          ]
        : []),
      ...(this.color === ButtonColorTypes.Outline &&
      !this.inactive &&
      !this.inactiveFilled
        ? ["border-secondary-dark", "text-secondary-dark", "hover:shadow-md"]
        : []),
      ...(this.color === ButtonColorTypes.Success &&
      !this.inactive &&
      !this.inactiveFilled
        ? ["bg-success", "text-white", "border-success", "hover:shadow-success"]
        : []),
      ...(this.color === ButtonColorTypes.Action &&
      !this.inactive &&
      !this.inactiveFilled
        ? ["bg-action", "text-white", "border-action", "hover:shadow-action"]
        : []),
      ...(this.color === ButtonColorTypes.Error &&
      !this.inactive &&
      !this.inactiveFilled
        ? ["bg-error", "text-white", "hover:shadow-error"]
        : []),
      ...(this.size === ButtonSizeTypes.ExtraSmall ? ["h-8", "text-sm"] : []),
      ...(this.size === ButtonSizeTypes.Small
        ? ["h-10", "px-4", "text-sm"]
        : []),
      ...(this.size === ButtonSizeTypes.Medium
        ? ["h-13", "px-8", "text-sm"]
        : []),
      ...(this.size === ButtonSizeTypes.Large ? ["py-4", "px-8"] : []),
      ...(this.inactive
        ? ["border-gray-300", "text-gray-300", "cursor-not-allowed"]
        : []),
      ...(this.inactiveFilled
        ? ["text-gray-400", "bg-gray-200", "cursor-not-allowed"]
        : []),
      ...(this.fullWidth ? ["w-full"] : []),
    ];
  }

  get iconClassList() {
    return [
      ...(this.inactive ? ["text-gray-300"] : []),
      ...(this.inactiveFilled ? ["text-gray-400"] : []),
      ...(this.color === ButtonColorTypes.Outline &&
      !this.inactiveFilled &&
      !this.inactive
        ? ["text-secondary-dark"]
        : []),
      ...(this.color === ButtonColorTypes.Primary &&
      !this.inactiveFilled &&
      !this.inactive
        ? ["text-white"]
        : []),
    ];
  }
}
