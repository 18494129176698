
import { mixins, Options } from "vue-class-component";
import { Tag, AdminButton, NoResultsForFilter } from "@/components";
import {
  MainRouteTypes,
  AccessRequestsDto,
  AccessRequestStatusTypes,
  TableFilterTypes,
} from "@/types";
import type { TableConfig, IAccessRequestsRepository } from "@/types";
import { JunTableSortDirection, JunTableFilterOperator } from "@juniper/ui";
import objectMapper from "object-mapper";
import { LeadsMap } from "@/maps";
import { inject } from "inversify-props";
import { TableMixin } from "@/mixins";
import { namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";

const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    Tag,
    AdminButton,
    NoResultsForFilter,
  },
})
export default class LeadsTable extends mixins(TableMixin) {
  @inject() private accessRequestsRepository!: IAccessRequestsRepository;

  @manufacturers.Getter private manufacturerId!: number | null;

  private MainRouteTypes = MainRouteTypes;
  private accessRequests: AccessRequestsDto = new AccessRequestsDto();

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  async created() {
    this.initTable();
    await this.init({ filterVersion: TableFilterTypes.OData });

    // default filter is set to status of "Pending", if we get no results we remove
    // the filter and re-fetch to show all leads
    if (!this.accessRequests.data?.length) {
      this.tableOptions.filters = [];
      this.filters = [];
      await this.init({ filterVersion: TableFilterTypes.OData });
    }
  }

  private initTable(): void {
    this.tableHeaders = [
      {
        prop: "buyerName",
        text: "Buyer Name",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "email",
        text: "Email Address",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "customerName",
        text: "Customer Name",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "status",
        text: "Status",
        canSort: true,
        canFilter: true,
        filterOptions: Object.values(AccessRequestStatusTypes)
          .filter(
            (statusType) => statusType !== AccessRequestStatusTypes.Revoked
          )
          .map((statusType) => ({
            text: statusType,
            value: statusType,
          })),
      },
      {
        prop: "location",
        text: "Location",
        canSort: false,
        canFilter: false,
      },
      {
        prop: "dateRequested",
        text: "Requested On",
        canSort: true,
        canFilter: true,
      },
    ];
    this.page = 1;
    this.pageSize = 10000;
    this.sortDirection = JunTableSortDirection.Descending;
    this.sortBy = "dateRequested";
    this.dateFilterKeys.push("dateRequested");
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    this.tableOptions.filters = [
      {
        prop: "status",
        value: AccessRequestStatusTypes.Pending,
        operator: JunTableFilterOperator.Equals,
      },
    ];
    this.filters = [{ key: "status", value: AccessRequestStatusTypes.Pending }];
    this.getTableData = this.getAccessRequests;
  }

  private onNoFilterResultsGoBack() {
    this.removeLatestFilter(TableFilterTypes.OData);
  }

  private async getAccessRequests(): Promise<void> {
    const queryString = this.getQueryString();
    this.accessRequests = await this.accessRequestsRepository.getAll(
      queryString
    );

    this.tableOptions.paginationOptions = {
      page: this.page,
      pageSize: this.pageSize,
      totalRecords: this.accessRequests.totalRecords,
      // page: this.accessRequests.meta.page,
      // pageSize: this.accessRequests.meta.pageSize,
      // totalRecords: this.accessRequests.meta.totalRecords
    };
  }

  private getStatusColor(code: string): string {
    switch (code) {
      case AccessRequestStatusTypes.Pending:
        return "warning";
      case AccessRequestStatusTypes.Accepted:
        return "success";
      case AccessRequestStatusTypes.Declined:
        return "error";
      case AccessRequestStatusTypes.Revoked:
        return "error";
      default:
        return "error";
    }
  }

  get tableConfig(): TableConfig {
    return {
      heading: "All Leads",
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.accessRequests?.data?.length
        ? objectMapper(this.accessRequests.data, LeadsMap)
        : [],
      itemKey: "email",
    };
  }
}
