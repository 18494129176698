import { EventEmitter } from "events";

export class RepositoryErrorHandler extends EventEmitter {
  public constructor() {
    super();
  }

  public handleDeserializationError(error: Error): Promise<never> {
    this.emit("deserializationError", [error]);
    return Promise.reject(error);
  }
}
