import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-wrapper"
}
const _hoisted_2 = { class: "main-view" }
const _hoisted_3 = { class: "toasts" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "h-screen flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNav = _resolveComponent("SideNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_JunToast = _resolveComponent("JunToast")!
  const _component_JunSpinner = _resolveComponent("JunSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hasAccess && !_ctx.hideLayout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SideNav, { navigationItems: _ctx.navConfig }, null, 8, ["navigationItems"]),
          _createElementVNode("main", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_view),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: toast.message + index,
                    class: "my-4 print:hidden"
                  }, [
                    _createVNode(_component_JunToast, {
                      duration: toast.duration,
                      message: toast.message,
                      rawHtml: toast.rawHtml,
                      type: toast.type
                    }, null, 8, ["duration", "message", "rawHtml", "type"])
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasAccess && _ctx.hideLayout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hasAccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_JunSpinner, { size: "4rem" })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}