import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b52eedd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cmp-container" }
const _hoisted_2 = { class: "cmp-wrapper" }
const _hoisted_3 = { class: "outer-label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "modal-trigger-text" }
const _hoisted_6 = { class: "validation-error-wrapper" }
const _hoisted_7 = { class: "modal-content" }
const _hoisted_8 = { class: "new-email-wrapper" }
const _hoisted_9 = { class: "list-wrapper" }
const _hoisted_10 = { class: "cmp-message error" }
const _hoisted_11 = { class: "list" }
const _hoisted_12 = {
  key: 0,
  class: "w-full text-center py-4"
}
const _hoisted_13 = { class: "list-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_AdminInput = _resolveComponent("AdminInput")!
  const _component_Form = _resolveComponent("Form")!
  const _component_AdminButton = _resolveComponent("AdminButton")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.label) + " " + _toDisplayString(_ctx.msg ? `(${_ctx.msg})` : ""), 1))
            : _createCommentVNode("", true),
          (_ctx.tooltipMsg)
            ? (_openBlock(), _createBlock(_component_Tooltip, {
                key: 1,
                msg: _ctx.tooltipMsg
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Icon, {
                    class: "tooltip-icon",
                    name: _ctx.IconNameTypes.Info,
                    size: 1
                  }, null, 8, ["name"])
                ]),
                _: 1
              }, 8, ["msg"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "modal-trigger-wrapper",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.listModalOpen = true))
        }, [
          _createVNode(_component_Icon, {
            name: _ctx.localList.length ? 'email' : 'add',
            size: 1,
            class: "modal-trigger-icon"
          }, null, 8, ["name"]),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.modalTriggerText), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_Transition, { name: "slide-up" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['cmp-message', { error: _ctx.error }])
            }, _toDisplayString(_ctx.errorMessage), 3)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_ConfirmationModal, {
      title: _ctx.label,
      confirmText: "Save",
      cancelText: "Cancel",
      open: _ctx.listModalOpen,
      onConfirm: _ctx.onListModalConfirm,
      onCancel: _ctx.onListModalCancel,
      disableClickaway: true,
      onCloseModal: _ctx.onListModalCancel
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", null, _toDisplayString(_ctx.modalCopy), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Form, {
              ref: "form",
              "validation-schema": _ctx.validationSchema,
              class: "form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AdminInput, {
                  modelValue: _ctx.newItem,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newItem) = $event)),
                  name: "newItem",
                  type: "email",
                  label: "Email",
                  disabled: _ctx.itemLimitExceeded
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }, 8, ["validation-schema"]),
            _createVNode(_component_AdminButton, {
              size: "sm",
              minWidth: 6,
              color: "outline",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addItem(_ctx.newItem))),
              class: "ml-3 mt-1",
              inactive: _ctx.itemLimitExceeded
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add email")
              ]),
              _: 1
            }, 8, ["inactive"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createTextVNode(" Receivers (" + _toDisplayString(_ctx.localList.length) + ") ", 1),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.itemLimitExceeded ? "Max limit reached" : ""), 1),
            _createElementVNode("div", _hoisted_11, [
              (!_ctx.localList.length)
                ? (_openBlock(), _createElementBlock("p", _hoisted_12, " Hmm, it looks like you haven't added any emails here. "))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.localList, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item,
                      class: "list-item"
                    }, [
                      _createElementVNode("div", _hoisted_13, _toDisplayString(item), 1),
                      _createVNode(_component_Icon, {
                        name: "trash",
                        size: 1,
                        class: "cursor-pointer",
                        onClick: ($event: any) => (_ctx.removeItem(item))
                      }, null, 8, ["onClick"])
                    ]))
                  }), 128))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "open", "onConfirm", "onCancel", "onCloseModal"])
  ], 64))
}