import {
  IndustryCategoryDto,
  MappingsCategoryDto,
  ProductCategoryDto,
} from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class CategoriesDto {
  @JsonProperty({ type: IndustryCategoryDto })
  industries!: Array<IndustryCategoryDto>;
  @JsonProperty({ type: IndustryCategoryDto })
  subIndustries!: Array<IndustryCategoryDto>;
  @JsonProperty({ type: IndustryCategoryDto })
  leafCategories!: Array<IndustryCategoryDto>;
  @JsonProperty({ type: ProductCategoryDto })
  productCategories!: Array<ProductCategoryDto>;
  @JsonProperty({ type: MappingsCategoryDto })
  mappings!: Array<MappingsCategoryDto>;
}
