import { namespace } from "vuex-class";
import { Vue } from "vue-class-component";
import { inject } from "inversify-props";
import { Utils } from "@/utils";
import { SalesRepDto } from "@/types";
import type { ICustomersRepository } from "@/types";

const staticModule = namespace("staticContentVuexModule");

export class SalesRepMixin extends Vue {
  @inject() protected customersRepository!: ICustomersRepository;

  @staticModule.Getter protected salesReps?: SalesRepDto[];

  protected salesRepLoading = true;
  private salesRepNumber: string | null = null;

  protected get salesRep(): SalesRepDto | null {
    if (!this.salesRepNumber || !this.salesReps?.length) return null;
    return (
      this.salesReps.find((sr) => sr.repNumber === this.salesRepNumber) ?? null
    );
  }

  protected async initSalesRep({
    customerNumber,
    repNumber,
  }: {
    customerNumber?: string;
    repNumber?: string;
  }): Promise<void> {
    if (!customerNumber && !repNumber) {
      this.salesRepLoading = false;
      return;
    }
    if (repNumber) {
      this.salesRepNumber = repNumber;
    } else if (customerNumber) {
      await this.getRepNumberFromCustomer(customerNumber);
    }
    this.salesRepLoading = false;
  }

  private async getRepNumberFromCustomer(
    customerNumber: string
  ): Promise<void> {
    const [customer] = await Utils.try(
      this.customersRepository.get(customerNumber)
    );
    if (!customer?.repNumber) return;
    this.salesRepNumber = customer.repNumber;
  }
}
