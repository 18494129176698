
import { Vue, Options } from "vue-class-component";
import {
  InjectReactive,
  Provide,
  ProvideReactive,
} from "vue-property-decorator";
import { inject } from "inversify-props";
import { Utils } from "@/utils";
import {
  OrderShippingForm,
  OrderShippingDetails,
  OrderShippingInvoice,
} from "@/components";
import { OrderDto, ShippingSettingsDto } from "@/types";
import type { IShippingSettingsRepository } from "@/types";

@Options({
  components: {
    OrderShippingForm,
    OrderShippingDetails,
    OrderShippingInvoice,
  },
})
export default class OrderShipping extends Vue {
  @inject() private shippingSettingsRepository!: IShippingSettingsRepository;
  @Provide() private updateShippingLoading = this.toggleLoading.bind(this);
  @Provide()
  private updateCanCreateInvoice = this.onUpdateCanCreateInvoice.bind(this);
  @ProvideReactive() private shippingLoading = false;
  @ProvideReactive()
  private shippingSettings: ShippingSettingsDto = new ShippingSettingsDto();
  @ProvideReactive() private canCreateInvoice = false;
  @InjectReactive() private order!: OrderDto;

  async created() {
    this.shippingLoading = true;

    const [settings] = await Utils.try(this.shippingSettingsRepository.get());
    if (settings) this.shippingSettings = settings;

    // we are setting shippingLoading to false on OrderShippingForm
  }

  private toggleLoading(bool: boolean) {
    if (this.shippingLoading && bool) {
      // if loading is already true and trying to set to true return without settings
      return;
    }
    this.shippingLoading = bool;
  }

  private onUpdateCanCreateInvoice(updatedValue: boolean) {
    this.canCreateInvoice = updatedValue;
  }

  @ProvideReactive() get isShippingFormReady(): boolean {
    return Boolean(
      this.order &&
        this.order.orderID &&
        this.shippingSettings &&
        this.shippingSettings.shipMethod
    );
  }
}
