
import { mixins, Options } from "vue-class-component";
import { AdminButton, Icon } from "@/components";
import { EditableBoxMixin } from "@/mixins";

@Options({
  components: {
    AdminButton,
    Icon,
  },
})
export default class EditableBox extends mixins(EditableBoxMixin) {
  get hideButtons() {
    return (this.isEditStatus || this.isSavingStatus) && !this.hideActions;
  }

  get hideBoxTitle() {
    return (!this.hideActions && !this.hideIcon && this.title) || this.title;
  }
}
