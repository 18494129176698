
import { IndependentShipmentDto } from "@/types";
import { Utils } from "@/utils";
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ShipOnYourOwn extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  shipment!: IndependentShipmentDto;

  private get shipmentCost() {
    return this.shipment?.totalShipmentCost?.amount || "$0.00";
  }

  private get shipmentDate() {
    return this.shipment?.shipDate
      ? Utils.formatDate(new Date(this.shipment.shipDate))
      : "";
  }

  private get carrierName() {
    return (
      Utils.getShippingCarrierConfig(this.shipment.carrierName)?.label ??
      this.shipment.carrierName
    );
  }
}
