import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fa38c44"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-container"
}
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = {
  key: 0,
  class: "modal-close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade-expand" }, {
    default: _withCtx(() => [
      (_ctx.showModal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickaway && _ctx.onClickaway(...args))),
              class: "modal-bg"
            }),
            _createElementVNode("div", _hoisted_2, [
              (_ctx.showCloseBtn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("span", {
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                    }, "X")
                  ]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "header", {}, undefined, true),
              _renderSlot(_ctx.$slots, "body", {}, undefined, true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}