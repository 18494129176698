import { Utils } from "@/utils";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class PaymentTransactionDto {
  @JsonProperty() success!: boolean;
  @JsonProperty() status!: string | null;
  @JsonProperty() response!: string | null;
  @JsonProperty() cardLast4Digits!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.formatDateTime,
  })
  timeStamp!: string;
}
