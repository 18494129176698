
import { Options, Vue } from "vue-class-component";
import { AdminInput, Icon } from "@/components";
import { inject } from "inversify-props";
import { Inject, InjectReactive } from "vue-property-decorator";
import { IconNameTypes, BrandSelectItem, BrandsDto } from "@/types";
import type { IDashboardBrand } from "@/types";
import type { IBrandRepository } from "@/types";

@Options({
  components: {
    AdminInput,
    Icon,
  },
})
export default class DashboardBrandSelect extends Vue {
  @inject() private brandRepository!: IBrandRepository;
  @Inject() private setBrand!: (brand: IDashboardBrand) => void;
  @InjectReactive() private brand!: IDashboardBrand;

  private IconNameTypes = IconNameTypes;
  private showDropdown = false;
  private loading = false;
  private errorFetchingBrands = false;
  private brands: BrandSelectItem[] = [];
  private query = "";

  async created() {
    try {
      this.loading = true;
      const res = await this.brandRepository.get();
      this.setupBrandData(res);
    } catch {
      this.errorFetchingBrands = true;
    } finally {
      this.loading = false;
    }
  }

  setupBrandData({ visibleForVendors, brands }: BrandsDto) {
    const brandsWithVisibility = brands.map((b) => {
      return { ...b, visible: visibleForVendors.includes(b.catalogCode || "") };
    });
    this.setBrand({ ...this.brand, brandCount: brandsWithVisibility.length });
    this.brands = brandsWithVisibility.map((b) => {
      return {
        name: b.publisher,
        value: b.publisherManufacturerId,
        code: b.catalogName,
        selected: false,
      };
    });
  }

  get filteredBrands() {
    return this.brands.filter((brand: BrandSelectItem) => {
      return brand.name?.toLowerCase().includes(this.query.toLowerCase());
    });
  }

  get currentBrand() {
    return this.brands.find((item) => item.selected);
  }

  get brandName() {
    const length = 15;
    const tooLong = (this.currentBrand?.name?.length || 0) > length;
    const truncated = this.currentBrand?.name?.substring(0, length) + "...";
    return this.currentBrand
      ? tooLong
        ? truncated
        : this.currentBrand.name
      : "All Brands";
  }

  private openDropdown() {
    this.showDropdown = true;
    this.$nextTick(() => {
      const searchRef = this.$refs.searchRef as typeof AdminInput;
      // input won't appear if in loading or error state
      if (searchRef) {
        searchRef.focusInput();
      }
    });
  }

  private closeDropdown() {
    this.showDropdown = false;
    this.query = "";
  }

  private onBrandClick(item: BrandSelectItem) {
    this.brands = this.brands.map((b) => {
      return {
        ...b,
        selected: b.value === item.value && this.brand.brandId !== item.value,
      };
    });
    this.setBrand({
      ...this.brand,
      brandName: item.value === this.brand.brandId ? "" : item.name || "",
      brandCode: item.value === this.brand.brandId ? "" : item.code || "",
      brandId: item.value === this.brand.brandId ? 0 : item.value,
    });
    this.closeDropdown();
  }
}
