import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  IManufacturerSettingsRepository,
  ManufacturerStaticDto,
} from "@/types";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/manufacturer/settings/static`;

@injectable()
export class ManufacturerSettingsRepository
  implements IManufacturerSettingsRepository
{
  public async get(): Promise<ManufacturerStaticDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<ManufacturerStaticDto>(data, ManufacturerStaticDto);
  }
}
