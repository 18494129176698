import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class HandshakeTokenDto {
  @JsonProperty() accessToken!: string;
  @JsonProperty() identityToken!: string;
  @JsonProperty() scope!: string;
  @JsonProperty() issuedTokenType!: string;
  @JsonProperty() tokenType!: string;
  @JsonProperty() refreshToken!: string;
  @JsonProperty() errorDescription!: string;
  @JsonProperty() expiresIn!: number;
}
