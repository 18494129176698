import { JsonProperty, Serializable } from "typescript-json-serializer";
import { SellerStatusTypes } from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class SellerDto {
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  createdOn!: string | null;
  @JsonProperty() createdBy!: string | null;
  @JsonProperty() modifiedOn!: string | null;
  @JsonProperty() modifiedBy!: string | null;
  @JsonProperty() deletedOn!: string | null;
  @JsonProperty() deletedBy!: string | null;
  @JsonProperty() sellerReferenceId!: string | null;
  @JsonProperty() partnerReferenceId!: string | null;
  @JsonProperty() qualification!: string | null;
  @JsonProperty() disburseDays!: number;
  @JsonProperty({
    beforeDeserialize: Utils.transformSellerStatus,
  })
  status!: SellerStatusTypes | null;
  @JsonProperty() currency!: Array<string | null>;
  @JsonProperty() disbursementFee!: number;
  @JsonProperty() disbursementFeePercentage!: number | null;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() canApplyForCredit!: boolean; // Determines whether a seller can re-apply for JuniperCredit
}
