
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { inject } from "inversify-props";
import {
  Map,
  Shape,
  source,
  data,
  layer,
  AuthenticationType,
} from "azure-maps-control";
import { AddressInfo, Box, InfoList, SalesRepPopup, Icon } from "@/components";
import {
  AddressDto,
  ShippingAddressDto,
  BusinessInfoDto,
  TooltipPosition,
} from "@/types";
import type { IAzureMapRepository, InfoListItem } from "@/types";
import { SalesRepMixin } from "@/mixins";

@Options({
  components: {
    AddressInfo,
    Box,
    InfoList,
    SalesRepPopup,
    Icon,
  },
})
export default class LeadCompanyInfo extends mixins(SalesRepMixin) {
  @inject() private azureMapRepository!: IAzureMapRepository;

  @Prop({
    type: Boolean,
  })
  loading!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  customerNumber!: string;

  @Prop({
    type: Object,
  })
  billingAddress!: AddressDto | null;

  @Prop({
    type: Object,
  })
  shippingAddress!: ShippingAddressDto | null;

  @Prop({
    type: Object,
  })
  businessInfo!: BusinessInfoDto | null;

  private TooltipPosition = TooltipPosition;

  get infoItemsRowA() {
    return [
      {
        label: "Brand Name",
        text: this.businessInfo?.companyName,
      },
      {
        label: "Country Code",
        text: this.shippingAddress?.country,
      },
      {
        label: "Jurisdiction of Incorporation",
        text: this.businessInfo?.countryOfIncorporation,
      },
      {
        label: "Business ID",
        text: this.businessInfo?.businessId,
      },
      {
        label: "Industry",
        text: this.businessInfo?.industry,
      },
    ];
  }
  get infoItemsRowB() {
    const infoList: InfoListItem[] = [
      {
        label: "Products Sold",
        text: this.businessInfo?.productSold,
      },
      {
        label: "Business Type",
        text: this.businessInfo?.businessType,
      },
      {
        label: "Annual Sales",
        text: this.businessInfo?.annualSales,
      },
      {
        label: "Years in Business",
        text: this.businessInfo?.yearsInBusiness,
      },
      {
        label: "Website URL",
        link: this.businessInfo?.websiteURL,
      },
    ];
    if (this.customerNumber && !this.salesRepLoading) {
      infoList.push({
        label: "Sales Rep.",
        ...(this.salesRep ? { id: "salesRep" } : { text: "N/A" }),
      });
    }
    return infoList;
  }

  @Watch("loading")
  async onLoad(val: boolean) {
    if (!val) {
      const { lat, lon } = await this.getAddressCoords();
      this.initMap([lon, lat]);
      await this.initSalesRep({ customerNumber: this.customerNumber });
    }
  }

  async getAddressCoords() {
    const { address1, address2, stateProvince, postalCode } =
      this.billingAddress || this.shippingAddress || {};

    const address = `${address1 || ""} ${address2 || ""}, ${
      stateProvince || ""
    } ${postalCode || ""}`;

    return this.azureMapRepository.getCoordsFromAddress(address, {
      lat: 27,
      lon: -80,
    });
  }

  initMap(addressCoords: [number, number]) {
    const map = new Map("map", {
      center: addressCoords,
      zoom: 12,
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: process.env.VUE_APP_AZURE_MAP_SUBSCRIPTION_KEY,
      },
    });

    map.events.add("ready", () => this.addPinDrop(map, addressCoords));
  }

  addPinDrop(map: Map, addressCoords: [number, number]) {
    const dataSource = new source.DataSource();
    map.sources.add(dataSource);
    const point = new Shape(new data.Point(addressCoords));
    dataSource.add([point]);

    map.layers.add(new layer.SymbolLayer(dataSource));
  }
}
