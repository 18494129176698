import { JsonProperty, Serializable } from "typescript-json-serializer";
import { AppUserCustomerDto, LinkDto, ListMetaDto } from "@/types";

@Serializable()
export class AppUserCustomersDto {
  @JsonProperty({ type: AppUserCustomerDto })
  data!: AppUserCustomerDto[];
  @JsonProperty({ type: LinkDto }) links!: Array<LinkDto>;
  @JsonProperty() meta!: ListMetaDto;
}
