import { CountryTypes, CarrierTypes } from "@/types";

export const CountryCarriersConfig = {
  [CountryTypes.US as string]: [
    CarrierTypes.FedEx,
    CarrierTypes.UPS,
    CarrierTypes.USPS,
    CarrierTypes.DHL,
  ],
  [CountryTypes.CA as string]: [CarrierTypes.FedExCA, CarrierTypes.Canpar],
};
