
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { InfoListItem, InfoListVerticalAlignmentTypes } from "@/types";
import { Thumbnail, ImagePreview } from "@/components";
import { Utils } from "@/utils";

@Options({
  components: {
    Thumbnail,
    ImagePreview,
  },
})
export default class InfoList extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  title!: string;

  @Prop({
    type: String,
    default: null,
  })
  subtitle!: string;

  @Prop({
    type: Array,
    default: [],
  })
  items!: InfoListItem[];

  @Prop({
    type: String,
    default: null,
  })
  itemLabelWidth!: string;

  @Prop({
    type: String as PropType<InfoListVerticalAlignmentTypes>,
    default: InfoListVerticalAlignmentTypes.Middle,
  })
  private labelVerticalAlignment!: InfoListVerticalAlignmentTypes;

  private showDropdown = false;
  private InfoListVerticalAlignmentTypes = InfoListVerticalAlignmentTypes;

  onClick() {
    this.showDropdown = !this.showDropdown;
  }

  sanitizeUrl(url: string) {
    return Utils.sanitizeUrlForDisplay(url);
  }

  formatUrl(url: string) {
    return Utils.formatUrlForAnchorTag(url);
  }
}
