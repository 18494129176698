import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ProfileImagesDto {
  @JsonProperty() logoImage!: string | null;
  @JsonProperty() featureImage!: string | null;
  @JsonProperty() lifestyleImage1!: string | null;
  @JsonProperty() lifestyleImage2!: string | null;
  @JsonProperty() lifestyleImage3!: string | null;
}
