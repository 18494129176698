
import { Options, Vue } from "vue-class-component";
import {
  EditableBoxSidebar,
  ShippingSettings,
  ShipWithJuniperSettings,
  ShippingCarrierAcknowledgement,
  ShipOnYourOwnSettings,
  ShipWithPreferredCarrierSettings,
  ShippingMethodInfo,
  Box,
  ConfirmationModal,
} from "@/components";
import {
  EditableBoxStatusTypes,
  ShippingMethodTypes,
  ShippingSettingsDto,
  ShippingCarrierSettingsDto,
  ShippingCarrierAccountDto,
  CarrierTypes,
} from "@/types";
import type {
  CarrierAccountInfo,
  ToastConfig,
  IShippingSettingsRepository,
  IShippingConnectionsRepository,
  IShippingCarrierAccountsRepository,
} from "@/types";
import { inject } from "inversify-props";
import { BackupDataService } from "@/services";
import { Utils } from "@/utils";
import { namespace } from "vuex-class";
import { AxiosResponse } from "axios";
import { Provide, ProvideReactive } from "vue-property-decorator";
const notifications = namespace("notificationsVuexModule");

enum ModalTypes {
  CountryChange = "countryChange",
  MethodChange = "methodChange",
}

@Options({
  components: {
    Box,
    EditableBoxSidebar,
    ShippingSettings,
    ShippingCarrierAcknowledgement,
    ShipWithJuniperSettings,
    ShipWithPreferredCarrierSettings,
    ShipOnYourOwnSettings,
    ShippingMethodInfo,
    ConfirmationModal,
  },
})
export default class Shipping extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private shippingSettingsRepository?: IShippingSettingsRepository;
  @inject()
  shippingConnectionsRepository?: IShippingConnectionsRepository;
  @inject()
  shippingCarrierAccountsRepository?: IShippingCarrierAccountsRepository;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;

  private status = EditableBoxStatusTypes.View;
  private modalType: ModalTypes | null = null;
  private shippingSettings: ShippingSettingsDto = new ShippingSettingsDto();
  private selectedShipMethod: ShippingMethodTypes | null = null;
  private termsAcknowledged = false;
  private settingsLoaded = false;
  private showModal = false;
  private sharedAccounts: ShippingCarrierAccountDto[] = [];
  private sharedCarrierId = 0;
  private modalLoading = false;

  async created() {
    await this.init();
  }

  private async init(): Promise<void> {
    const [data] = await Utils.try(
      Promise.all([
        this.shippingSettingsRepository?.get(),
        this.shippingCarrierAccountsRepository?.getCarriers(),
      ])
    );
    if (!data) return;
    const [shippingSettings, sharedAccountsData] = data;
    if (!shippingSettings || !sharedAccountsData) return;
    this.shippingSettings = shippingSettings;
    this.selectedShipMethod = shippingSettings.shipMethod;
    this.sharedAccounts = sharedAccountsData;
    this.backupDataService?.saveBackup(this, this.shippingSettings);
    this.settingsLoaded = true;
    if (shippingSettings.isShared) {
      //set initial value of sharedCarrierID
      this.sharedCarrierId = this.shippingSettings.carrierAccountId;
    }
  }

  private resetSharedCarrierId() {
    // if (this.shippingSettings.isShared) {
    //   //set initial value of sharedCarrierID
    //   this.sharedCarrierId = this.shippingSettings.carrierAccountId;
    // }
    this.sharedCarrierId = 0;
  }

  private async saveShippingSettings(editMode = false) {
    try {
      await this.shippingSettingsRepository?.put(this.shippingSettings);
      if (editMode) {
        this.updateStatus(EditableBoxStatusTypes.Edit);
      } else {
        this.updateStatus(EditableBoxStatusTypes.View);
      }
      this.createToastSuccess?.({
        message: `Shipping Settings Saved.`,
      });
      this.backupDataService?.saveBackup(this, this.shippingSettings);
    } catch (err) {
      this.updateStatus(EditableBoxStatusTypes.Edit);
    }
  }

  private onEditableBoxEdit() {
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.status = EditableBoxStatusTypes.View;
    this.restoreBackupData();
  }

  private async onEditableBoxSave() {
    this.status = EditableBoxStatusTypes.Saving;
    if (this.selectedShipMethod === ShippingMethodTypes.Juniper) {
      // if connected carrier => Disconnect current carrier
      if (this.shippingSettings.isCarrierActive) {
        const data = await this.disconnectSharedAccount();
        if (data) {
          // connect selected carrier
          await this.connectSharedAccount(this.sharedCarrierId);
        }
      } else {
        // connect selected carrier
        await this.connectSharedAccount(this.sharedCarrierId);
      }
    } else if (this.selectedShipMethod !== ShippingMethodTypes.CustomCarrier) {
      if (this.selectedShipMethod) {
        this.shippingSettings.shipMethod = this.selectedShipMethod;
      }
      await this.saveShippingSettings();
    }
  }

  private onUpdateSharedCarrier(carrier: number) {
    this.sharedCarrierId = carrier;
  }

  private async connectSharedAccount(carrierId: number) {
    const carrierSettings =
      await this.shippingConnectionsRepository?.postShared(carrierId);
    if (carrierSettings) {
      if (this.selectedShipMethod) {
        this.shippingSettings.shipMethod = this.selectedShipMethod;
      }
      this.updateCarrierSettings(carrierSettings);
      this.createToastSuccess?.({
        message: `Shipping carrier successfully connected.`,
      });
    }
  }

  private async disconnectSharedAccount() {
    //disconnect shared account silently
    this.updateStatus(EditableBoxStatusTypes.Loading);
    const [data] = await Utils.try(
      this.shippingConnectionsRepository?.deleteShared(
        this.shippingSettings.carrierAccountId
      )
    );
    if (data) {
      return data;
    }
  }

  @ProvideReactive() accountInfo!: CarrierAccountInfo;
  @ProvideReactive() carrierType: CarrierTypes = CarrierTypes.Unset;
  @Provide() private setCarrierInfo(
    carrierType: CarrierTypes,
    accountInfo: CarrierAccountInfo
  ) {
    this.carrierType = carrierType;
    this.accountInfo = accountInfo;
  }

  @Provide() private backupCarrierInfo() {
    this.backupDataService?.saveBackup(this, this.carrierType);
    this.backupDataService?.saveBackup(this, this.accountInfo);
  }

  @Provide() private restoreCarrierInfo() {
    this.backupDataService?.restoreBackup(this, this.carrierType);
    this.backupDataService?.restoreBackup(this, this.accountInfo);
  }

  @Provide() private updateShippingSettings(
    shippingSettings: ShippingSettingsDto
  ) {
    this.shippingSettings = shippingSettings;
  }

  //we are manually updating the shippingSettings object
  //when we connect and disconnect shipping carriers
  @Provide() private updateCarrierSettings(
    carrierSettings: ShippingCarrierSettingsDto
  ) {
    //set up shippingSettings object with updated carrier settings then fire save
    this.shippingSettings.carrierAccountId = carrierSettings.carrierId;
    this.shippingSettings.carrierName = carrierSettings.carrierName;
    this.shippingSettings.isShared = carrierSettings.isShared;
    this.shippingSettings.isCarrierActive = carrierSettings.isCarrierActive;
    this.saveShippingSettings();
  }

  private deleteCarrierSettings() {
    //reset carrier settings to default (ship on your own)
    this.shippingSettings.carrierAccountId = 0;
    this.shippingSettings.carrierName = CarrierTypes.Unset;
    this.shippingSettings.shipMethod = ShippingMethodTypes.Unset;
    this.shippingSettings.isShared = false;
    this.shippingSettings.isCarrierActive = false;
    this.saveShippingSettings(true);
  }

  @Provide() private acknowledgementChange(acknowledged: boolean) {
    this.termsAcknowledged = acknowledged;
  }

  @Provide() private updateStatus(status: EditableBoxStatusTypes) {
    this.status = status;
  }

  @Provide() private updateSelectedShipMethod(shipMethod: ShippingMethodTypes) {
    this.selectedShipMethod = shipMethod;
  }

  @Provide() private fireWarningModal(modalType: ModalTypes) {
    this.modalType = modalType;
    this.showModal = true;
  }

  private restoreBackupData() {
    this.backupDataService?.restoreBackup(this, this.shippingSettings);
    this.selectedShipMethod = this.shippingSettings.shipMethod;
  }

  private cancelModal() {
    this.restoreBackupData();
    this.status = EditableBoxStatusTypes.View;
    this.$nextTick(() => {
      this.closeModal();
    });
  }

  private closeModal() {
    this.showModal = false;
    this.modalType = null;
  }

  private async onConfirm() {
    this.modalLoading = true;

    const data = this.shippingSettings.isShared
      ? await this.disconnectSharedAccount()
      : await this.disconnectAccount();

    if (data) {
      this.deleteCarrierSettings();
      this.showModal = false;
      this.modalLoading = false;
    }
  }

  @Provide() private async disconnectAccount(): Promise<
    AxiosResponse | null | undefined
  > {
    this.updateStatus(EditableBoxStatusTypes.Loading);
    const [data] = await Utils.try(
      this.shippingConnectionsRepository?.delete(
        this.shippingSettings.carrierAccountId
      )
    );
    if (data) {
      this.createToastSuccess?.({
        message: `Shipping carrier account #${this.shippingSettings.carrierAccountId} successfully deleted.`,
      });
    }
    return data;
  }

  @Provide() private shipMethodChange() {
    this.resetSharedCarrierId();
  }

  get modalMessage() {
    switch (this.modalType) {
      case ModalTypes.CountryChange:
        return "Updating this setting will reset your Shipping Carrier Settings. If you continue, you will need to reconnect to your shipping carrier.";
      case ModalTypes.MethodChange:
        return "Updating this setting will disconnect your Shipping Carrier.  Are you sure you wish to proceed?";
      default:
        return null;
    }
  }

  get currentShippingMethod() {
    switch (this.selectedShipMethod) {
      case ShippingMethodTypes.Juniper:
        return "ShipWithJuniperSettings";
      case ShippingMethodTypes.CustomCarrier:
        return "ShipWithPreferredCarrierSettings";
      case ShippingMethodTypes.Unset:
        return "ShipOnYourOwnSettings";
      default:
        return null;
    }
  }

  get saveDisabled() {
    let saveIsDisabled;
    switch (this.selectedShipMethod) {
      case ShippingMethodTypes.CustomCarrier:
        saveIsDisabled = !this.shippingSettings.isCarrierActive;
        break;
      case ShippingMethodTypes.Juniper:
        saveIsDisabled = this.sharedCarrierId === 0 || !this.termsAcknowledged;
        break;
      default:
        saveIsDisabled = !this.termsAcknowledged;
    }
    return saveIsDisabled;
  }
}
