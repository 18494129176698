import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class BrandDto {
  @JsonProperty() syndicationId!: number;
  @JsonProperty() publisher!: string | null;
  @JsonProperty() publisherManufacturerId!: number;
  @JsonProperty() catalogCode!: string | null;
  @JsonProperty() catalogName!: string | null;
  @JsonProperty() enabled!: boolean;
  @JsonProperty() visible?: boolean;
}
