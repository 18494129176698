
import { mixins, Options } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { inject } from "inversify-props";
import { AppUserProfileDto, MainRouteTypes, SubRouteTypes } from "@/types";
import type { ToastConfig, IAppUsersRepository } from "@/types";
import {
  Breadcrumbs,
  AssociatedBuyers,
  CompanyInfo,
  BuyerInfo,
  TopBar,
} from "@/components";
import { namespace } from "vuex-class";
import { Utils } from "@/utils";
import { DetailsMixin } from "@/mixins";

const notifications = namespace("notificationsVuexModule");
//TODO: Fix problem with @ alias with webpack for the component imports
@Options({
  components: {
    Breadcrumbs,
    AssociatedBuyers,
    BuyerInfo,
    CompanyInfo,
    TopBar,
  },
})
export default class BuyerDetails extends mixins(DetailsMixin) {
  @inject() private appUsersRepository!: IAppUsersRepository;

  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  private customerNum = "";
  private userId: number | undefined = undefined;
  private associated: AppUserProfileDto[] = [];
  private backPath = `/${MainRouteTypes.Buyers}/${SubRouteTypes.Active}`;

  async created() {
    this.setRouteParams();

    if (!this.customerNum || !this.userId) {
      this.createToastError({
        message: "Error: Incorrect Url pattern.",
      });

      return;
    }

    this.getDetailsData = this.getBuyerDetails;
    await this.init();
  }

  private setRouteParams() {
    const { customerNumber, appUserId } = this.$route.params;
    !Array.isArray(customerNumber) && (this.customerNum = customerNumber);
    !Array.isArray(appUserId) && (this.userId = Number(appUserId));
  }

  private async getBuyerDetails() {
    if (this.customerNum && this.userId) {
      const data = this.appUsersRepository.getAppUserCustomers(
        Utils.buildQueryString({
          filters: Utils.encodeFilters([
            {
              key: "CustomerNumber",
              value: this.customerNum,
            },
          ]),
        })
      );
      const appUser = this.appUsersRepository.getAppUser(this.userId);

      Promise.all([data, appUser]).then((res) => {
        const users = res[0];
        const buyer = res[1];

        if (users && users.data) {
          buyer && this.populateData(buyer);

          this.associated = users.data
            .filter((item) => {
              return (
                Utils.getAppUserIdFromAppUserCustomer(item) !== this.userId
              );
            })
            .map((item) => {
              return {
                ...item.profile,
                appUserId: Utils.getAppUserIdFromAppUserCustomer(item),
              };
            });

          this.breadcrumbPreviousTitle = "All Active Customers";
        }
      });
    }
  }

  private redirect(userId: number) {
    this.loading = true;
    this.$router.push(
      `/${MainRouteTypes.BuyerDetails}/${this.customerNum}/${userId}`
    );
  }

  @Watch("$route.params")
  private async onUserIdChange() {
    if (Utils.isCurrentRoute(MainRouteTypes.BuyerDetails)) {
      this.setRouteParams();
      await this.init();
    }
  }
}
