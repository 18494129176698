export enum MainRouteTypes {
  Dashboard = "dashboard",
  Buyers = "buyers",
  LeadDetails = "lead-details",
  BuyerDetails = "buyer-details",
  Orders = "orders",
  OrderDetails = "order-details",
  CartDetails = "cart-details",
  InvoiceDetails = "invoice-details",
  InvoicePreview = "invoice-preview",
  Credit = "credit",
  Settings = "settings",
  NoDivisionsSetUp = "no-divisions-set-up",
  OidcCallback = "oidc-callback",
  OidcSilentRenewCallback = "oidc-silent-renew-callback",
  OidcCallbackError = "oidc-callback-error",
  HandshakeLogin = "handshake-login",
  HandshakeError = "handshake-error",
  HandshakeSignOut = "handshake-sign-out",
}
