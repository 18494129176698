export enum EventTypes {
  // HTTP error events
  TokenExpired = "tokenExpired",
  NetworkServerError = "networkServerError",
  BadRequest = "badRequest",
  Unauthorized = "unauthorized",
  NotFound = "notFound",
  ServerError = "serverError",

  // Feature flag events
  FeatureFlagChange = "featureFlagChange",
  FeatureFlagError = "featureFlagError",
}
