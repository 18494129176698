
import { Options, Vue } from "vue-class-component";

@Options({})
export default class ErrorBoundary extends Vue {
  errorCaptured(err: Error) {
    console.log("errorBoundary", err);
    return false;
  }
}
