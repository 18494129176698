import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";
import {
  ShippingAddressDto,
  SocialMediaLinkDto,
  BusinessInfoDto,
  AddressDto,
  ProfileDto,
  AppPaymentMethodDto,
} from "@/types";

@Serializable()
export class AppUserDto {
  @JsonProperty() modifiedOn!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  createdOn!: string | null;
  @JsonProperty() createdBy!: string | null;
  @JsonProperty() modifiedBy!: string | null;
  @JsonProperty() deletedOn!: string | null;
  @JsonProperty() deletedBy!: string | null;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty({ type: AppPaymentMethodDto })
  paymentMethods!: AppPaymentMethodDto[];
  @JsonProperty({ type: ShippingAddressDto })
  shippingAddresses!: ShippingAddressDto[];
  @JsonProperty({ type: SocialMediaLinkDto })
  socialMediaLinks!: SocialMediaLinkDto[];
  @JsonProperty() appUserId!: number;
  @JsonProperty() appId!: string | null;
  @JsonProperty() profile!: ProfileDto;
  @JsonProperty() businessInfo!: BusinessInfoDto;
  @JsonProperty() billingAddress!: AddressDto;
  @JsonProperty() status!: string | null;
}
