
import { Options, Vue } from "vue-class-component";
import { TopBar, NavTabs } from "@/components";
import { MainRouteTypes } from "@/types";
import { namespace } from "vuex-class";

const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    TopBar,
    NavTabs,
  },
})
export default class Settings extends Vue {
  private MainRouteTypes = MainRouteTypes;

  @manufacturers.Getter private isDirectSeller!: boolean;
}
