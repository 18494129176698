import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5837f499"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cmp-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "dropdown-content-wrapper" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "px-2 mb-2" }
const _hoisted_6 = { class: "overflow-y-scroll max-h-52" }
const _hoisted_7 = {
  key: 0,
  class: "no-results"
}
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AdminInput = _resolveComponent("AdminInput")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cmp-container', _ctx.cmpWidth])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        class: _normalizeClass({ error: _ctx.error, active: _ctx.showDropdown })
      }, [
        (!_ctx.hideLabels)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(['dropdown', { active: _ctx.showDropdown, disabled: _ctx.disabled, error: _ctx.error }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDropdownClick && _ctx.onDropdownClick(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            title: _ctx.tooltipDisplay,
            class: _normalizeClass(['text', { placeholder: _ctx.noCurrentSelection }])
          }, _toDisplayString(_ctx.noCurrentSelection ? _ctx.placeholder : _ctx.selectionDisplay), 11, _hoisted_4),
          _createVNode(_component_Icon, {
            class: "icon",
            name: 
              _ctx.showDropdown ? _ctx.IconNameTypes.UpChevron : _ctx.IconNameTypes.DownChevron
            ,
            size: 0.75
          }, null, 8, ["name", "size"])
        ])
      ], 2),
      _createVNode(_Transition, { name: "slide-up" }, {
        default: _withCtx(() => [
          (_ctx.showDropdown)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(['list-wrapper', { active: _ctx.showDropdown }])
              }, [
                _withDirectives(_createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_AdminInput, {
                    modelValue: _ctx.query,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
                    icon: _ctx.IconNameTypes.Search,
                    placeholder: "Search",
                    name: "search",
                    type: "text",
                    fullWidth: "",
                    disableAutocomplete: "",
                    hideLabels: "",
                    ref: "searchRef"
                  }, null, 8, ["modelValue", "icon"])
                ], 512), [
                  [_vShow, _ctx.searchable]
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.filteredItems.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.noResultsMessage), 1))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredItems, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass([
                'list-item',
                { 'list-item-selected': _ctx.itemIsSelected(item) },
              ]),
                          key: item.name,
                          onClick: ($event: any) => (_ctx.onItemClick(item))
                        }, [
                          (_ctx.multi)
                            ? (_openBlock(), _createBlock(_component_Checkbox, {
                                key: 0,
                                class: "mr-2",
                                name: item.name,
                                checked: _ctx.itemIsSelected(item),
                                onChange: ($event: any) => (_ctx.onItemClick(item))
                              }, null, 8, ["name", "checked", "onChange"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(item.name), 1)
                        ], 10, _hoisted_8))
                      }), 128))
                ])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_Transition, { name: "slide-up" }, {
      default: _withCtx(() => [
        (!_ctx.hideMsgBox)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(['cmp-message', { error: _ctx.error }])
            }, _toDisplayString(_ctx.error ? _ctx.errorMessage : _ctx.msg), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2)), [
    [_directive_click_away, _ctx.closeDropdown]
  ])
}