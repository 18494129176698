import axios from "@/axios";
import { injectable } from "inversify-props";
import { CartDetailsDto, CartsDto, ICartsRepository } from "@/types";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Carts`;

@injectable()
export class CartsRepository implements ICartsRepository {
  public async getAll(queryString = ""): Promise<CartsDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}${queryString}`);
    return deserialize<CartsDto>(data, CartsDto);
  }

  public async getCart(cartId: string): Promise<CartDetailsDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${cartId}`);
    return deserialize<CartDetailsDto>(data, CartDetailsDto);
  }
}
