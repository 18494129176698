import { JsonProperty, Serializable } from "typescript-json-serializer";
import { LinkDto } from "@/types";

@Serializable()
export class BusinessInfoDto {
  @JsonProperty() websiteURL!: string;
  @JsonProperty() companyName!: string;
  @JsonProperty() industry!: string;
  @JsonProperty() businessType!: string;
  @JsonProperty() productSold!: string;
  @JsonProperty() yearsInBusiness!: string;
  @JsonProperty() annualSales!: string;
  @JsonProperty() businessId!: string;
  @JsonProperty() inBuyingGroup!: boolean;
  @JsonProperty() stateOfIncorporation!: string;
  @JsonProperty() countryOfIncorporation!: string;
  @JsonProperty({ type: LinkDto }) links?: Array<LinkDto>;
}
