import { injectable } from "inversify-props";
import { AxiosResponse } from "axios";
import axios from "@/axios";
import {
  PricingSettingsDto,
  PricingSettingsDetailsDto,
  IPricingSettingsRepository,
} from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/products/PricingSettings`;

@injectable()
export class PricingSettingsRepository implements IPricingSettingsRepository {
  public async get(): Promise<PricingSettingsDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<PricingSettingsDto>(data, PricingSettingsDto);
  }

  public async put(
    pricingSettingsDetails: PricingSettingsDetailsDto
  ): Promise<AxiosResponse<unknown>> {
    const payload = serialize(pricingSettingsDetails);
    return axios.put(ENDPOINT_URL, payload);
  }
}
