
import { Options, Vue } from "vue-class-component";
import objectMapper from "object-mapper";
import { TableBox } from "@/components";
import { inject } from "inversify-props";
import { JunTableSortDirection } from "@juniper/ui";
import { SingleLineOrdersMap, MultiLineOrdersMap } from "@/maps";
import { InjectReactive, Watch, Prop } from "vue-property-decorator";
import { JunTableColumn } from "@juniper/ui";
import { DashboardTableHeaders } from "@/config";
import {
  OrdersWithInvoiceDto,
  AgencyOrdersDto,
  MainRouteTypes,
  SubRouteTypes,
  TableBoxConfig,
  IMappedOrder,
  IMappedAgencyOrder,
  DashboardComponentConfig,
  QueryOperationTypes,
} from "@/types";
import type { IDashboardBrand, IOrdersRepository } from "@/types";

@Options({ components: { TableBox } })
export default class DashboardOrdersTable extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private config!: DashboardComponentConfig;

  @inject() private ordersRepository!: IOrdersRepository;
  @InjectReactive() private brand!: IDashboardBrand;

  @Watch("brand.brandId", { deep: true })
  private onBrandChange() {
    this.removeFilter = false;
  }

  private removeFilter = false;
  private MainRouteTypes = MainRouteTypes;
  private SubRouteTypes = SubRouteTypes;

  protected get ordersTable(): TableBoxConfig<
    AgencyOrdersDto | OrdersWithInvoiceDto
  > {
    return {
      tableHeaders: this.filterTableHeaders(),
      sortBy: "orderDate",
      sortDirection: JunTableSortDirection.Descending,
      pageSize: 15,
      persistentFilters: {
        Channel: "JuniperMarket",
        ...(this.brand.brandCode
          ? { CatalogName: !this.removeFilter ? this.brand.brandCode : "" }
          : { CatalogName: "" }),
        OrderDate: `${QueryOperationTypes.GreaterOrEqual} ${new Date(
          "1980-01-01"
        ).toISOString()} ${
          QueryOperationTypes.LessOrEqual
        } ${new Date().toISOString()}`,
      },
      hideTableHeadingBorder: true,
      dataFetch: this.fetchData,
      itemKey: "orderId",
      heading: "Recent Orders",
    };
  }

  private async fetchData(query: string) {
    const data = this.config.multiline
      ? await this.ordersRepository.getAgencyOrders(query)
      : await this.ordersRepository.getWithInvoices(query);
    return objectMapper(
      data.data,
      this.config.multiline ? MultiLineOrdersMap : SingleLineOrdersMap
    );
  }

  private filterTableHeaders(): Array<JunTableColumn> {
    return DashboardTableHeaders.filter((h) => {
      return this.config.multiline
        ? Array.isArray(h.multi)
          ? h.multi.includes(this.config.columnSpan)
          : h.multi
        : Array.isArray(h.single)
        ? h.single.includes(this.config.columnSpan)
        : h.single;
    }).map((h) => h.config);
  }

  private isAgencyOrder(
    item: IMappedAgencyOrder | IMappedOrder
  ): item is IMappedAgencyOrder {
    return (item as IMappedAgencyOrder).brandId !== 0;
  }

  private isOrder(
    item: IMappedAgencyOrder | IMappedOrder
  ): item is IMappedOrder {
    return (item as IMappedOrder).orderId !== 0;
  }

  private orderLink(item: IMappedOrder | IMappedAgencyOrder) {
    return this.config.multiline && this.isAgencyOrder(item)
      ? `/${MainRouteTypes.OrderDetails}/${SubRouteTypes.Summary}?guid=${item.orderGuid}&brand=${item.brandId}`
      : `/${MainRouteTypes.OrderDetails}/${SubRouteTypes.Summary}?guid=${item.orderGuid}`;
  }

  private orderId(item: IMappedOrder | IMappedAgencyOrder) {
    return this.config.multiline && this.isAgencyOrder(item)
      ? item.brandOrderId
      : this.isOrder(item) && item.orderId;
  }

  private onRemove() {
    this.removeFilter = true;
  }
}
