import { AxiosResponse } from "axios";
import axios from "@/axios";
import { injectable } from "inversify-props";
import { IOrdersExportRepository, IOrdersExportPayload } from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/OrdersExport`;

@injectable()
export class OrdersExportRepository implements IOrdersExportRepository {
  public async post(payload: IOrdersExportPayload): Promise<AxiosResponse> {
    return axios.post(
      ENDPOINT_URL,
      // exportFormat value is a placeholder until functionality of different
      // export types is built in back end
      { ...payload, exportFormat: "GenericFullTabDelimited_txt" },
      {
        responseType: "blob",
      }
    );
  }
}
