
import { Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

export default class ShippingPromo extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  disableWrap!: boolean;

  @Inject() private openShippingPromoDetailsModal!: () => void;
}
