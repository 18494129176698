import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class HandshakeCredentials {
  @JsonProperty() username: string;
  @JsonProperty() password: string;
  @JsonProperty() passwordSalt: string;

  constructor({ username = "", password = "", passwordSalt = "" }) {
    this.username = username;
    this.password = password;
    this.passwordSalt = passwordSalt;
  }
}
