import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  IDisputesRepository,
  IDisputeAcceptPayload,
  IDisputeCancelPayload,
  DisputeDto,
  IExtendedAxiosRequestConfig,
} from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Credit/Disputes`;
const requestConfig: IExtendedAxiosRequestConfig = {
  suppressToast: true,
};

@injectable()
export class DisputesRepository implements IDisputesRepository {
  public async accept(dispute: IDisputeAcceptPayload): Promise<DisputeDto> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/accept`,
      serialize(dispute),
      requestConfig
    );
    return deserialize<DisputeDto>(data, DisputeDto);
  }

  public async cancel(dispute: IDisputeCancelPayload): Promise<DisputeDto> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/cancel`,
      serialize(dispute),
      requestConfig
    );
    return deserialize<DisputeDto>(data, DisputeDto);
  }

  public async refund(dispute: IDisputeAcceptPayload): Promise<DisputeDto> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/refund`,
      serialize(dispute),
      requestConfig
    );
    return deserialize<DisputeDto>(data, DisputeDto);
  }
}
