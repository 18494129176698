
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { StatusComponentTypes } from "@/types";

@Options({})
export default class Status extends Vue {
  @Prop({
    type: String,
    default: StatusComponentTypes.Default,
  })
  type!: StatusComponentTypes;

  get circleColorClass() {
    switch (this.type) {
      case StatusComponentTypes.Default:
        return "bg-secondary-dark";
      case StatusComponentTypes.Error:
        return "bg-error";
      case StatusComponentTypes.Success:
        return "bg-success";
      case StatusComponentTypes.Warning:
        return "bg-warning";
      default:
        return "bg-secondary-dark";
    }
  }
}
