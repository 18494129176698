import { injectable } from "inversify-props";
import { errorHandlerConfig } from "@/config";
import {
  IExtendedAxiosError,
  IMatchErrorConfig,
  IMatchErrorResult,
  MatchErrorPattern,
  ToastConfig,
} from "@/types";
import { Utils } from "@/utils";

@injectable()
export class ErrorHandlerService {
  public getHttpErrorToastConfig(
    error: IExtendedAxiosError
  ): ToastConfig | null {
    return (
      errorHandlerConfig.find((config: IMatchErrorConfig) => {
        if (!error || !error.response?.data) return false;
        return this.matchError(error.response.data, config.pattern).match;
      })?.toastConfig ?? null
    );
  }

  public matchError(
    error: { [key: string]: {} | null } | string | null | undefined,
    pattern: MatchErrorPattern,
    errorTrace: (string | object)[] = [] // A stacks of "paths" that lead to the final error message, with the final error message at 0 position
  ): IMatchErrorResult {
    const notFoundResult: IMatchErrorResult = {
      match: false,
      pattern,
      errorMessage: null,
      matchResult: null,
      errorTrace: [],
    };

    if (!error) return notFoundResult;

    // Try to match the error patterns with an error message string.
    if (typeof error === "string") {
      if (Utils.isJSON(error)) {
        const nestedError = JSON.parse(error);
        const result = this.matchError(nestedError, pattern, errorTrace);
        if (result.match) errorTrace.push(error);
        return result;
      } else {
        const patterns = Array.isArray(pattern) ? pattern : [pattern];
        const matchResult: IMatchErrorResult = {
          match: true,
          pattern,
          errorMessage: error,
          matchResult: null,
          errorTrace,
        };

        for (const p of patterns) {
          if (typeof p === "string") {
            if (error.includes(p)) {
              errorTrace.push(error);
              return matchResult;
            }
          } else {
            const regExpMatchResult: RegExpMatchArray | null = error.match(p);
            if (regExpMatchResult) {
              matchResult.matchResult = regExpMatchResult;
              errorTrace.push(error);
              return matchResult;
            }
          }
        }
        return notFoundResult;
      }
    }

    // Try to match the error patterns with an array of errors.
    if (Array.isArray(error)) {
      for (const err of error) {
        const result = this.matchError(err, pattern, errorTrace);
        if (result.match) {
          errorTrace.push(error);
          return result;
        }
      }
      return notFoundResult;
    }

    // Try to match the error patterns with a nested error object.
    if (typeof error === "object") {
      for (const key of Object.keys(error)) {
        const result = this.matchError(error[key], pattern, errorTrace);
        if (result.match) {
          errorTrace.push({ [key]: error[key] }, error);
          return result;
        }
      }
      return notFoundResult;
    }

    return notFoundResult;
  }
}
