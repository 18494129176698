import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e40b513"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-wrapper h-256" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "modal-content" }
const _hoisted_10 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_NoResultsForFilter = _resolveComponent("NoResultsForFilter")!
  const _component_JunTable = _resolveComponent("JunTable")!
  const _component_ActiveBuyersDrawer = _resolveComponent("ActiveBuyersDrawer")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_JunTable, {
        heading: _ctx.tableConfig.heading,
        headers: _ctx.tableConfig.headers,
        options: _ctx.tableConfig.options,
        items: _ctx.tableConfig.items,
        loading: _ctx.tableConfig.loading,
        itemKey: _ctx.tableConfig.itemKey,
        showSelect: "",
        modelValue: _ctx.selectedItems,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event)),
        onSort: _ctx.onSort,
        onFilter: _ctx.onFilter,
        onPaginate: _ctx.onPaginate
      }, _createSlots({
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (!_ctx.deleteDisabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, "(" + _toDisplayString(_ctx.selectedItems.length) + ")", 1))
              : _createCommentVNode("", true),
            _createVNode(_component_Icon, {
              disabled: _ctx.deleteDisabled,
              onClick: _ctx.openDeleteModal,
              size: 1.25,
              name: _ctx.IconNameTypes.Trash,
              class: "cursor-pointer",
              "data-test-id": "delete-icon"
            }, null, 8, ["disabled", "onClick", "size", "name"])
          ])
        ]),
        default: _withCtx(({ item, header }) => [
          (header.prop === 'name')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("a", {
                  onClick: ($event: any) => (_ctx.openDrawer(item)),
                  "data-test-id": "customer-name-link"
                }, _toDisplayString(item.name), 9, _hoisted_5)
              ]))
            : _createCommentVNode("", true),
          (header.prop === 'priceLevel')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.priceLevelAlias(item.priceLevel)), 1))
            : _createCommentVNode("", true),
          (header.prop === 'billingAddress')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.addressFormat(item?.address)), 1))
            : _createCommentVNode("", true),
          (header.prop === 'shippingAddress')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.addressFormat(item?.shipping)), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (_ctx.tableOptions.filters?.length)
          ? {
              name: "noresults",
              fn: _withCtx(() => [
                _createVNode(_component_NoResultsForFilter, { onGoBack: _ctx.onNoFilterResultsGoBack }, null, 8, ["onGoBack"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["heading", "headers", "options", "items", "loading", "itemKey", "modelValue", "onSort", "onFilter", "onPaginate"])
    ]),
    _createVNode(_component_Drawer, {
      heading: _ctx.selectedCustomer?.name,
      subHeading: _ctx.drawerSubheading,
      open: _ctx.drawerOpen,
      onCloseDrawer: _ctx.closeDrawer,
      width: 50,
      "data-test-id": "customer-drawer"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ActiveBuyersDrawer, { customer: _ctx.selectedCustomer }, null, 8, ["customer"])
      ]),
      _: 1
    }, 8, ["heading", "subHeading", "open", "onCloseDrawer"]),
    _createVNode(_component_ConfirmationModal, {
      title: "Delete Customer(s)",
      cancelText: "Cancel",
      confirmText: "Delete",
      open: _ctx.showDeleteModal,
      onConfirm: _ctx.onDelete,
      onCancel: _ctx.closeDeleteModal,
      loading: _ctx.deleteLoading,
      onCloseModal: _ctx.closeDeleteModal,
      "data-test-id": "delete-modal"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, " The " + _toDisplayString(_ctx.selectedItems.length) + " customer(s) you've selected will be deleted. ", 1)
        ])
      ]),
      _: 1
    }, 8, ["open", "onConfirm", "onCancel", "loading", "onCloseModal"])
  ]))
}