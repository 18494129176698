import { Utils } from "@/utils";

export abstract class Masks {
  public static phone(phoneNumber?: string | null) {
    const cleaned = Utils.cleanPhone(phoneNumber);
    const match = cleaned.match(/^(1|52|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? `+${match[1]} ` : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return phoneNumber || "";
  }

  public static currency(price?: string | null) {
    //TODO: Review this for refactor
    const cleaned = Utils.cleanCurrency(price);
    const match = cleaned.match(/^-?(?:0|[1-9]\d{0,2}(?:,?\d{3})*)(?:\.\d+)?$/);

    if (match) {
      const length = cleaned.length;

      if (length === 0) {
        return "$0.00";
      }
      if (length === 1) {
        return `$0.0${cleaned}`;
      }
      if (length === 2) {
        return `$0.${cleaned}`;
      }

      return (
        "$" +
        (
          cleaned.substring(0, cleaned.length - 2) +
          "." +
          cleaned.substring(cleaned.length - 2)
        ).replace(/(\d)(?=(\d{3})+\b)/g, "$1,")
      );
    }

    return price || "";
  }
}
