//TODO: We can delete this class
import { ContactDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class BillingAddressDto {
  @JsonProperty() name!: string;
  @JsonProperty() address1!: string;
  @JsonProperty() address2!: string;
  @JsonProperty() city!: string;
  @JsonProperty() code!: string;
  @JsonProperty() contact!: ContactDto;
  @JsonProperty() country!: string;
  @JsonProperty() postalCode!: string;
  @JsonProperty() stateProvince!: string;
}
