import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd56e154"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-box" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "select-logo" }
const _hoisted_4 = { class: "selected-brand" }
const _hoisted_5 = { class: "select-icon" }
const _hoisted_6 = {
  key: 0,
  class: "list-wrapper"
}
const _hoisted_7 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_8 = {
  key: 1,
  class: "no-results"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "px-2 mb-2" }
const _hoisted_11 = { class: "overflow-y-scroll h-52" }
const _hoisted_12 = {
  key: 0,
  class: "no-results"
}
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_JunSpinner = _resolveComponent("JunSpinner")!
  const _component_AdminInput = _resolveComponent("AdminInput")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "dropdown-wrapper",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openDropdown && _ctx.openDropdown(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_Icon, {
            name: _ctx.IconNameTypes.Logo,
            size: 0.9
          }, null, 8, ["name", "size"])
        ]),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.brandName), 1)
      ]),
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_Icon, {
          name: _ctx.IconNameTypes.UpChevron,
          size: 0.5
        }, null, 8, ["name", "size"]),
        _createVNode(_component_Icon, {
          name: _ctx.IconNameTypes.DownChevron,
          size: 0.5
        }, null, 8, ["name", "size"])
      ])
    ]),
    _createVNode(_Transition, { name: "slide-up" }, {
      default: _withCtx(() => [
        (_ctx.showDropdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_JunSpinner)
                  ]))
                : (_ctx.errorFetchingBrands)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Error retrieving brands. "))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("section", null, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_AdminInput, {
                            modelValue: _ctx.query,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
                            icon: _ctx.IconNameTypes.Search,
                            placeholder: "Search",
                            name: "search",
                            type: "text",
                            fullWidth: "",
                            disableAutocomplete: "",
                            hideLabels: "",
                            ref: "searchRef"
                          }, null, 8, ["modelValue", "icon"])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          (_ctx.filteredBrands.length === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, " No results found for search criteria... "))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBrands, (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "list-item",
                              key: item.name,
                              onClick: ($event: any) => (_ctx.onBrandClick(item))
                            }, [
                              _createElementVNode("p", null, _toDisplayString(item.name), 1),
                              (item.selected)
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: _ctx.IconNameTypes.Check,
                                    size: 0.7
                                  }, null, 8, ["name", "size"]))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_13))
                          }), 128))
                        ])
                      ])
                    ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_click_away, _ctx.closeDropdown]
  ])
}