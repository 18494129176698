import axios from "@/axios";
import { injectable } from "inversify-props";
import { Coordinates, IAzureMapRepository } from "@/types";

@injectable()
export class AzureMapRepository implements IAzureMapRepository {
  public async getCoordsFromAddress(
    address: string,
    fallback?: Coordinates
  ): Promise<Coordinates> {
    const subscriptionKey = process.env.VUE_APP_AZURE_MAP_SUBSCRIPTION_KEY;
    const url = `https://atlas.microsoft.com/search/address/json?&subscription-key=${subscriptionKey}&api-version=1.0&language=en-US&query=${address}`;

    const { data } = await axios.get(url);
    return data?.results[0]?.position || fallback;
  }
}
