import { CardDetailDto, BillingAddressDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class AppPaymentMethodDto {
  @JsonProperty() appPaymentMethodId!: number;
  @JsonProperty() appUserId!: number;
  @JsonProperty() cardDetail!: CardDetailDto;
  @JsonProperty() billingAddress!: BillingAddressDto;
  @JsonProperty() phone!: string;
}
