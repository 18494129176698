
import { Options, Vue } from "vue-class-component";
import { DashboardComponentConfig } from "@/types";
import type { IDashboardBrand } from "@/types";
import {
  DashboardOrdersChart as OrdersChart,
  DashboardSalesChart as SalesChart,
  DashboardOrdersTable as OrdersTable,
  DashboardRequestsTable as RequestsTable,
  DashboardTopCustomersTable as TopCustomersTable,
  DashboardTiles,
  DashboardQuestionsBar,
} from "@/components";
import { DashboardComponentTypeSettings } from "@/config";
import { SellerDto, DashboardDisplayTypes } from "@/types";
import { ProvideReactive, Provide, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const manufacturers = namespace("manufacturersVuexModule");
const seller = namespace("sellerVuexModule");
const auth = namespace("authVuexModule");

@Options({
  components: {
    OrdersChart,
    SalesChart,
    OrdersTable,
    RequestsTable,
    DashboardTiles,
    DashboardQuestionsBar,
    TopCustomersTable,
  },
})
export default class DashboardComponent extends Vue {
  @auth.Getter private isMultiline!: boolean | null;

  @manufacturers.Getter private isGated!: boolean | null;
  @manufacturers.Getter private isDirectSeller!: boolean | null;

  @seller.Getter seller?: SellerDto | null;

  @Watch("dashboardDisplayStatus", { immediate: true })
  private onDisplayStatusChange() {
    // TODO: currently this doesnt take in a saved dashboard config
    // in the future when dashboard is configurable will need to refactor
    // to take in saved configurations and overwrite defaults.
    this.configureWidgets();
  }

  private widgets: Array<DashboardComponentConfig> = [];

  @ProvideReactive() private brand: IDashboardBrand = {
    brandId: 0,
    brandCode: "",
    brandName: "",
    brandCount: 0,
  };

  @Provide() private setBrand(brand: IDashboardBrand) {
    this.brand = brand;
  }

  private get dashboardDisplayStatus() {
    if (this.isMultiline) {
      return this.isGated
        ? DashboardDisplayTypes.MultiGated
        : DashboardDisplayTypes.MultiUngated;
    }
    return this.isGated
      ? DashboardDisplayTypes.SingleGated
      : DashboardDisplayTypes.SingleUngated;
  }

  private positionClasslist(widget: DashboardComponentConfig) {
    return `row-start-${widget.rowStart} row-span-${widget.rowSpan} col-span-${widget.columnSpan} col-start-${widget.columnStart}`;
  }

  private configureWidgets() {
    const widgetOptions = {
      multiline: this.isMultiline ?? false,
      direct: (this.isGated && this.isDirectSeller) ?? false,
      credit: false,
    };

    this.widgets = DashboardComponentTypeSettings.filter((comp) =>
      comp.componentToggleOptions.includes(this.dashboardDisplayStatus)
    ).map(
      (comp) =>
        new DashboardComponentConfig(
          { config: comp, ...widgetOptions },
          this.dashboardDisplayStatus
        )
    );
  }
}
