import { JsonProperty, Serializable } from "typescript-json-serializer";
import { PaymentMethodDto, OrderInvoiceDto } from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class OrderWithInvoiceDto {
  @JsonProperty() orderGuid!: string | null;
  @JsonProperty() orderId!: number;
  @JsonProperty({ type: OrderInvoiceDto }) invoices!: OrderInvoiceDto[];
  @JsonProperty() poNumber!: string | null;
  @JsonProperty() catalogName!: string | null;
  @JsonProperty() orderStatus!: string | null;
  @JsonProperty() manufacturerOrderStatus!: string | null;
  @JsonProperty({ beforeDeserialize: Utils.floatToCurrencyString })
  orderTotal!: string;
  @JsonProperty() createdOn!: string | null;
  @JsonProperty() modifiedOn!: string | null;
  @JsonProperty() buyerId!: string | null;
  @JsonProperty() channel!: string | null;
  @JsonProperty() paymentMethod!: PaymentMethodDto;
  @JsonProperty() customerName!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  orderDate!: string | null;
  //buyer Preferred shipDate
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  shipDate!: string | null;
}
