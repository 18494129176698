
import { Options, Vue } from "vue-class-component";
import { Prop, Watch, Emit } from "vue-property-decorator";
import { AdminButton, Modal } from "@/components";

@Options({
  components: {
    AdminButton,
    Modal,
  },
  emits: ["close-modal"],
})
export default class ConfirmationModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: String,
  })
  title!: string;

  @Prop({
    type: String,
    default: "Close",
  })
  closeText!: string;

  @Prop({
    type: String,
    default: "Yes",
  })
  confirmText!: string;

  @Prop({
    type: String,
    default: "No",
  })
  cancelText!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  closeOnly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  confirmDisabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  cancelDisabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  noFooterShadow!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disableClickaway!: boolean;

  @Prop({
    type: Function,
    required: false,
  })
  onConfirm!: () => void;

  @Prop({
    type: Function,
    required: false,
  })
  onCancel!: () => void;

  @Watch("open")
  onOpenChanged(val: boolean) {
    this.showModal = val;
  }

  @Emit()
  closeModal() {
    this.showModal = false;
  }

  private showModal = this.open;

  onYesClick() {
    if (this.onConfirm) {
      this.onConfirm();
    }
    // this.$emit("close-modal");
  }

  onNoClick() {
    if (this.onCancel) {
      this.onCancel();
    }
    // this.$emit("close-modal");
  }

  onCloseClick() {
    this.$emit("close-modal");
  }
}
