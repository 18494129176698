import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingAccountPackagesDto {
  @JsonProperty() packages!: ShippingAccountPackageDto[];
}

@Serializable()
export class ShippingAccountPackageDto {
  @JsonProperty() name!: string | null;
  @JsonProperty() packageCode!: string | null;
  @JsonProperty() description!: string | null;
}
