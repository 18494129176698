import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class InvoiceItemDto {
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() itemId!: string | null;
  @JsonProperty() itemName!: string | null;
  @JsonProperty() quantity!: number;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  price!: string;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  subTotal!: string;
  @JsonProperty() notes!: string | null;
  @JsonProperty() photoName!: string | null;
}
