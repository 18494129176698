import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  IPaymentsRepository,
  IPaymentPayload,
  PaymentDto,
  PaymentTransactionDto,
} from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/orders/Payments`;

@injectable()
export class PaymentsRepository implements IPaymentsRepository {
  public async getTransactions(
    orderId: number
  ): Promise<Array<PaymentTransactionDto>> {
    const { data } = await axios.get(
      `${ENDPOINT_URL}/transactions/order/${orderId}`
    );
    //TODO: Check if possible to deserialize the whole list at once (not using map)
    return data.map((pt: PaymentTransactionDto) => {
      return deserialize<PaymentTransactionDto>(pt, PaymentTransactionDto);
    });
  }

  public async post(payload: IPaymentPayload): Promise<PaymentDto> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/create`,
      serialize(payload)
    );
    return deserialize<PaymentDto>(data, PaymentDto);
  }
}
