import { AddressTypes, ContactDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class AddressDto {
  @JsonProperty() type?: AddressTypes;
  @JsonProperty() name!: string | null;
  @JsonProperty() address1!: string | null;
  @JsonProperty() address2!: string | null;
  @JsonProperty() city!: string | null;
  @JsonProperty() code!: string;
  @JsonProperty() contact!: ContactDto | null;
  @JsonProperty() country!: string | null;
  @JsonProperty() postalCode!: string | null;
  @JsonProperty() stateProvince!: string | null;
}
