import { Vue } from "vue-class-component";

import {
  UserDto,
  AddressDto,
  BusinessInfoDto,
  ProfileDto,
  ShippingAddressDto,
} from "@/types";

//TODO: Fix problem with @ alias with webpack for the component imports

export class DetailsMixin extends Vue {
  protected profile: ProfileDto | null = null;
  protected shippingAddress: ShippingAddressDto | null = null;
  protected billingAddress: AddressDto | null = null;
  protected businessInfo: BusinessInfoDto | null = null;
  protected loading = false;
  protected breadcrumbPreviousTitle = "";

  protected getDetailsData!: Function;

  protected async init(): Promise<void> {
    this.loading = true;
    await this.getDetailsData();
    this.loading = false;
  }

  populateData(data: UserDto) {
    this.profile = data.profile;
    this.businessInfo = data.businessInfo;
    this.billingAddress = data.billingAddress;
    this.shippingAddress =
      data.shippingAddresses.find((a) => a.defaultAddress) || null;
  }

  get breadcrumbItems() {
    return [this.breadcrumbPreviousTitle, this.profile?.buyerName];
  }
}
