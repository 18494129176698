
import { Options, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Breadcrumbs, NoResultsForFilter } from "@/components";
import { JunTableSortDirection, JunTableItem } from "@juniper/ui";
import {
  AgencyOrderDto,
  AgencyOrdersDto,
  MainRouteTypes,
  SubRouteTypes,
  OrdersWithInvoiceDto,
  OrderWithInvoiceDto,
  QueryOperationTypes,
  OrderStatusTypes,
  TableFilterTypes,
} from "@/types";
import type { TableConfig, IOrdersRepository } from "@/types";
import { inject } from "inversify-props";
import {
  singlelineOrdersHeaders,
  multilineOrdersHeaders,
  SingleLineOrderHeaderFilterMap,
  MultiLineOrderHeaderFilterMap,
} from "@/config";
import { TableMixin } from "@/mixins";
import { namespace } from "vuex-class";
import objectMapper from "object-mapper";
import { SingleLineOrdersMap, MultiLineOrdersMap } from "@/maps";

const exportOrders = namespace("exportOrdersVuexModule");
const manufacturers = namespace("manufacturersVuexModule");
const auth = namespace("authVuexModule");

@Options({
  components: {
    Breadcrumbs,
    NoResultsForFilter,
  },
})
export default class OrdersTable extends mixins(TableMixin) {
  @inject() private ordersRepository!: IOrdersRepository;

  @exportOrders.Mutation private setSelectedOrderIds!: (ids: number[]) => void;
  @manufacturers.Getter private manufacturerId!: number | null;
  @auth.Getter private isMultiline!: boolean | null;

  private MainRouteTypes = MainRouteTypes;
  private SubRouteTypes = SubRouteTypes;
  private agencyOrders: AgencyOrdersDto = new AgencyOrdersDto();
  private ordersWithInvoices: OrdersWithInvoiceDto = new OrdersWithInvoiceDto();
  private selectedItems: JunTableItem[] = [];
  private cancelledOnlyMode = false;

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  @Watch("selectedItems")
  onSelectedItemsChange(items: JunTableItem[]) {
    this.setSelectedOrderIds(
      items.map((i) => (this.isMultiline ? i.agencyOrderId : i.orderId))
    );
  }

  async created(): Promise<void> {
    if (this.$route.params.subRoute?.[0] === SubRouteTypes.CancelledOrders) {
      this.cancelledOnlyMode = true;
    } else {
      this.cancelledOnlyMode = false;
    }
    this.initTable();
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  private initTable(): void {
    this.tableHeaders = this.isMultiline
      ? multilineOrdersHeaders
      : singlelineOrdersHeaders;

    this.sortBy = "orderDate";
    this.sortDirection = JunTableSortDirection.Descending;
    this.pageSize = 16;
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    this.persistentFilters = [
      {
        key: "OrderDate",
        value: new Date("1980-01-01").toISOString(),
        operation: QueryOperationTypes.GreaterOrEqual,
      },
      {
        key: "OrderDate",
        value: new Date().toISOString(),
        operation: QueryOperationTypes.LessOrEqual,
      },
    ];
    this.filterKeyTransformers = this.isMultiline
      ? MultiLineOrderHeaderFilterMap
      : SingleLineOrderHeaderFilterMap;
    this.dateFilterKeys.push("OrderDate", "ShipDate");
    this.getTableData = this.isMultiline
      ? this.getAgencyOrders
      : this.getOrdersWithInvoices;

    if (this.cancelledOnlyMode) {
      this.tableHeaders = this.tableHeaders.map((th) =>
        th.prop === "orderStatus"
          ? { ...th, canFilter: false, canSort: false }
          : th
      );
      this.persistentFilters.push({
        key: "orderStatus",
        value: OrderStatusTypes.Cancelled,
        operation: QueryOperationTypes.Equals,
      });
    }
  }

  private async getOrdersWithInvoices(): Promise<void> {
    const queryString = this.getQueryString();

    this.ordersWithInvoices = await this.ordersRepository.getWithInvoices(
      queryString
    );

    this.tableOptions.paginationOptions = {
      page: this.ordersWithInvoices.meta.page,
      pageSize: this.ordersWithInvoices.meta.pageSize,
      totalRecords: this.ordersWithInvoices.meta.totalRecords,
    };
  }

  private onNoFilterResultsGoBack() {
    this.removeLatestFilter(TableFilterTypes.OData);
  }

  private async getAgencyOrders(): Promise<void> {
    const queryString = this.getQueryString();

    this.agencyOrders = await this.ordersRepository.getAgencyOrders(
      queryString
    );

    this.tableOptions.paginationOptions = {
      page: this.agencyOrders.meta.page,
      pageSize: this.agencyOrders.meta.pageSize,
      totalRecords: this.agencyOrders.meta.totalRecords,
    };
  }

  buildSinglelineTableItems(orders: OrderWithInvoiceDto[]) {
    return orders.length ? objectMapper(orders, SingleLineOrdersMap) : [];
  }

  buildMultilineTableItems(orders: AgencyOrderDto[]) {
    return orders.length ? objectMapper(orders, MultiLineOrdersMap) : [];
  }

  get tableConfig(): TableConfig {
    return {
      heading: this.cancelledOnlyMode ? "Cancelled Orders" : "All Orders",
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.isMultiline
        ? this.buildMultilineTableItems(this.agencyOrders.data || [])
        : this.buildSinglelineTableItems(this.ordersWithInvoices.data || []),
      itemKey: "orderGuid",
    };
  }
}
