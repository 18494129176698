import { JsonProperty, Serializable } from "typescript-json-serializer";
import { IImage } from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class CompanyImagesDto {
  @JsonProperty({ beforeDeserialize: Utils.setImageInitialValues })
  logo?: IImage | null;
  @JsonProperty({ beforeDeserialize: Utils.setImageInitialValues })
  header?: IImage | null;
  @JsonProperty({ beforeDeserialize: Utils.setImageInitialValues })
  lifestyle1?: IImage | null;
  @JsonProperty({ beforeDeserialize: Utils.setImageInitialValues })
  lifestyle2?: IImage | null;
  @JsonProperty({ beforeDeserialize: Utils.setImageInitialValues })
  lifestyle3?: IImage | null;
}
