import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShipRatesErrorDto {
  @JsonProperty() errorSource!: string | null;
  @JsonProperty() errorType!: string | null;
  @JsonProperty() field!: string | null;
  @JsonProperty() errorCode!: string | null;
  @JsonProperty() message!: string | null;
}
