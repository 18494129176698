export enum SellerStatusTypes {
  Created = "Created",

  Pending = "Pending",

  Approved = "Approved",
  Active = "Active",
  Inactive = "Inactive",

  Declined = "Declined",
  Terminated = "Terminated",
  Suspended = "Suspended",
  Cancelled = "Cancelled",
  Archived = "Archived",
  Withdrawn = "Withdrawn",
}
