
import { Vue, Options } from "vue-class-component";
import { Prop, InjectReactive } from "vue-property-decorator";
import { ShippingSettingsDto, OrderDto } from "@/types";
import type {
  DropdownItem,
  IIndependentShipmentsPostPayload,
  IDateRange,
} from "@/types";
import { CountryCarriersConfig } from "@/config";
import { Validators } from "@/utils";
import { AdminInput, Dropdown, AdminDatePicker } from "@/components";
import { Utils } from "@/utils";
import { Form } from "vee-validate";
import * as yup from "yup";

@Options({
  components: {
    AdminInput,
    Dropdown,
    Form,
    AdminDatePicker,
  },
  emits: ["update"],
})
export default class ShipOnYourOwnEdit extends Vue {
  @InjectReactive() private order!: OrderDto;
  @InjectReactive() private shippingSettings!: ShippingSettingsDto;

  @Prop({
    type: Object,
    required: true,
  })
  modelValue!: IIndependentShipmentsPostPayload;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  private validationSchema = yup.object().shape({
    carrierType: yup.string().required(),
    trackingNumber: yup.string().required(),
    shipmentCost: yup
      .string()
      .matches(Validators.currency, {
        message: "Shipping cost must be a valid currency amount",
        excludeEmptyString: true,
      })
      .required(),
    shipmentDate: yup.string().required("Shipment Date is a required field"),
  });

  private carriers: DropdownItem[] = [];
  private availableShipDateRange: IDateRange = (() => {
    const dates = Utils.getDatesInRange();
    return {
      from: dates[0],
      to: dates[dates.length - 1],
    };
  })();

  created() {
    this.carriers = this.constructCarriers();
  }

  private constructCarriers() {
    return CountryCarriersConfig[this.shippingSettings.shipFromCountry].map(
      (carrier) => {
        return {
          name: Utils.getShippingCarrierConfig(carrier)?.label || carrier,
          value: carrier,
        };
      }
    );
  }

  private get preferredShipDate() {
    return this.order.catalogDetail.shipDate
      ? Utils.parseDate(this.order.catalogDetail.shipDate)
      : null;
  }
}
