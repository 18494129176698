
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class Box extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  title!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  noPadding!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  noShadow!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  get showBoxBar() {
    return (
      this.title ||
      (this.$slots.actions &&
        this.$slots.actions().some((o) => o.type !== Comment)) ||
      (this.$slots.tag && this.$slots.tag().some((o) => o.type !== Comment))
    );
  }
}
