import axios from "axios";
import { injectable } from "inversify-props";
import { deserialize, serialize } from "typescript-json-serializer";

import {
  IMarketPaymentSettingsRepository,
  MarketPaymentSettingsDto,
} from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/MarketPaymentSettings`;

@injectable()
export class MarketPaymentSettingsRepository
  implements IMarketPaymentSettingsRepository
{
  public async get(): Promise<MarketPaymentSettingsDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<MarketPaymentSettingsDto>(
      data,
      MarketPaymentSettingsDto
    );
  }

  public async put(
    marketPaymentSettings: MarketPaymentSettingsDto
  ): Promise<MarketPaymentSettingsDto> {
    const payload = serialize(marketPaymentSettings);
    const { data } = await axios.put(ENDPOINT_URL, payload);
    return deserialize<MarketPaymentSettingsDto>(
      data,
      MarketPaymentSettingsDto
    );
  }
}
