export const SalesRepsMap = {
  "[].repNumber": [
    {
      key: "[].name",
    },
    {
      key: "[].value",
    },
  ],
};
