import { AccessRequestStatusTypes, UserDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class AccessRequestDto {
  @JsonProperty() manufacturerAppAccessRequestId!: number;
  @JsonProperty() user!: UserDto;
  @JsonProperty() appId!: string;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() status!: AccessRequestStatusTypes | null;
  @JsonProperty() statusNotes!: string;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  dateRequested!: string;
  @JsonProperty() dateApproved!: string;
}
