export enum ActionsMenuActionTypes {
  PublishToMarket = "publishToMarket",
  OpenBrandPreview = "openBrandPreview",
  NavigateToJuniperData = "navigateToJuniperData",
  NavigateToJuniperDataProductSets = "navigateToJuniperDataProductSets",
  NavigateToJuniperDataCategories = "navigateToJuniperDataCategories",
  NavigateToJuniperDataVendors = "navigateToJuniperDataVendors",
  NavigateToWebManager = "navigateToWebManager",
  NavigateToWebManagerPayments = "navigateToWebManagerPayments",
  NavigateToWebManagerCustomers = "navigateToWebManagerCustomers",
  PrintOrder = "printOrder",
  PrintInvoice = "printInvoice",
  ExportOrders = "exportOrders",
}
