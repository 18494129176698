export const BrandMap = {
  syndicationId: "id",
  publisher: [
    {
      key: "name",
    },
    {
      key: "catalogName",
    },
  ],
  catalogCode: "catalogCode",
  showCatalog: "enabled",
};
