import { IBrandOrdersRepository, IUpdateOrderRequest, OrderDto } from "@/types";
import { injectable } from "inversify-props";
import axios from "axios";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/BrandOrders`;

@injectable()
export class BrandOrdersRepository implements IBrandOrdersRepository {
  public async update(
    orderId: number,
    orderGuid: string,
    payload: IUpdateOrderRequest
  ) {
    const { data } = await axios.put(
      `${ENDPOINT_URL}/${orderId}/UpdateOrder/${orderGuid}`,
      payload
    );
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async cancel(
    orderId: number,
    cancellationReason = ""
  ): Promise<OrderDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${orderId}/Cancelled`, {
      reason: cancellationReason,
    });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async reject(
    orderId: number,
    rejectReason: string | null
  ): Promise<OrderDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${orderId}/Rejected`, {
      reason: rejectReason,
    });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async accept(
    orderId: number,
    { note }: { note: string }
  ): Promise<OrderDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${orderId}/Approved`, {
      note,
    });
    return deserialize<OrderDto>(data, OrderDto);
  }

  public async split(
    orderId: number,
    itemIds: number[]
  ): Promise<{ oldOrder: OrderDto; newOrder: OrderDto }> {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/${orderId}/Split`,
      itemIds
    );
    // response from API is a tuple [oldOrder, newOrder]
    return {
      oldOrder: deserialize<OrderDto>(data[0], OrderDto),
      newOrder: deserialize<OrderDto>(data[1], OrderDto),
    };
  }
}
