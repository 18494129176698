import { JsonProperty } from "typescript-json-serializer";
import { DisputeShippingTaxDetailDto } from "@/types";

export class DisputeDetailDto {
  @JsonProperty() sku!: string | null;
  @JsonProperty() description!: string | null;
  @JsonProperty() quantity!: number;
  @JsonProperty() unitPrice!: number;
  @JsonProperty() taxAmount!: number;
  @JsonProperty() subtotal!: number;
  @JsonProperty() discountAmount!: number;
  @JsonProperty({ type: DisputeShippingTaxDetailDto })
  taxDetails!: Array<DisputeShippingTaxDetailDto | null>;
}
