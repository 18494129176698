import { IPaymentSettingsRepository, PaymentSettingsDto } from "@/types";
import { injectable } from "inversify-props";
import axios from "@/axios";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Payments/PaymentSettings`;

@injectable()
export class PaymentSettingsRepository implements IPaymentSettingsRepository {
  public async get(queryString = ""): Promise<Array<PaymentSettingsDto>> {
    const { data } = await axios.get(`${ENDPOINT_URL}${queryString}`);
    return data.map((ps: PaymentSettingsDto) => {
      return deserialize<PaymentSettingsDto>(ps, PaymentSettingsDto);
    });
  }

  public async put(payload: PaymentSettingsDto): Promise<PaymentSettingsDto> {
    const { data } = await axios.put(ENDPOINT_URL, payload);
    return deserialize<PaymentSettingsDto>(data, PaymentSettingsDto);
  }
}
