import { JsonProperty, Serializable } from "typescript-json-serializer";

import { Utils } from "@/utils";

@Serializable()
export class TermsSettingsDto {
  @JsonProperty() enabled!: boolean;
  @JsonProperty({
    beforeDeserialize: Utils.commaSeparatedStringToArray,
    beforeSerialize: Utils.arrayToCommaSeparatedString,
  })
  defaultTerms!: Array<string> | null;
}
