
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Tooltip, Icon } from "@/components";
import { SalesRepDto, ToolTipColorSchemeTypes, TooltipPosition } from "@/types";
import type { ToastConfig } from "@/types";
import { Utils } from "@/utils";

const notifications = namespace("notificationsVuexModule");

@Options({
  name: "SalesRepPopup",
  components: {
    Tooltip,
    Icon,
  },
})
export default class SalesRepPopup extends Vue {
  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;
  @notifications.Mutation private createToastError?: (
    payload: ToastConfig
  ) => void;

  private TooltipPosition = TooltipPosition;
  private ToolTipColorSchemeTypes = ToolTipColorSchemeTypes;

  @Prop({
    type: Object as PropType<SalesRepDto>,
    required: true,
  })
  private salesRep!: SalesRepDto;

  @Prop({
    type: String as PropType<TooltipPosition>,
    default: TooltipPosition.Bottom,
  })
  private position!: TooltipPosition;

  private get fullName(): string | null {
    return Utils.getFullName(this.salesRep.firstName, this.salesRep.lastName);
  }

  private get initials(): string | null {
    return Utils.getInitials(this.salesRep.firstName, this.salesRep.lastName);
  }

  private get phoneNumber(): string {
    return this.salesRep.cellPhone ?? this.salesRep.phone ?? "N/A";
  }

  private async handleCopy(
    value: string,
    type: "phone" | "email"
  ): Promise<void> {
    const contactType = type === "phone" ? "phone number" : "email address";
    try {
      await Utils.copyText(value);
      this.createToastSuccess?.({
        message: `The sales rep's ${contactType} has been copied to your clipboard.`,
      });
    } catch (error) {
      this.createToastError?.({
        message: `Failed to copy the sales rep's ${contactType} to the clipboard, please try again.`,
      });
    }
  }
}
