
import { Options, Vue } from "vue-class-component";
import objectMapper from "object-mapper";
import { Prop } from "vue-property-decorator";
import { TableBox, Icon } from "@/components";
import { inject } from "inversify-props";
import { LeadsMap } from "@/maps";
import { JunTableSortDirection, JunTableContext } from "@juniper/ui";
import {
  IconNameTypes,
  TableBoxConfig,
  IMappedAccessRequest,
  AccessRequestStatusTypes,
  MainRouteTypes,
  SubRouteTypes,
  DashboardComponentConfig,
} from "@/types";
import type { IAccessRequestsRepository } from "@/types";

@Options({ components: { TableBox, Icon } })
export default class DashboardRequestsTable extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private config!: DashboardComponentConfig;

  @inject() private accessRequestsRepository!: IAccessRequestsRepository;

  private IconNameTypes = IconNameTypes;
  private MainRouteTypes = MainRouteTypes;
  private SubRouteTypes = SubRouteTypes;

  private get requestTable(): TableBoxConfig<IMappedAccessRequest> {
    return {
      tableHeaders: [
        {
          prop: "buyerName",
          text: "",
          canSort: false,
          canFilter: false,
          width: "200px",
          canClick: true,
        },
        {
          prop: "dateRequested",
          text: "",
          canSort: false,
          canFilter: false,
          canClick: true,
        },
      ],
      hideHeaderRow: true,
      hideTableHeadingBorder: true,
      pageSize: 5,
      persistentFilters: { status: AccessRequestStatusTypes.Pending },
      sortDirection: JunTableSortDirection.Descending,
      sortBy: "dateRequested",
      itemKey: "email",
      heading: "Recent Requests",
      dataFetch: this.fetchData,
      cellClick: this.requestClick,
    };
  }

  private async fetchData(query: string) {
    const data = await this.accessRequestsRepository.getAll(query);
    return objectMapper(data.data, LeadsMap);
  }

  private requestClick(req: JunTableContext<IMappedAccessRequest>) {
    this.$router.push(`/${MainRouteTypes.LeadDetails}/${req.item.leadId}`);
  }
}
