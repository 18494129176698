import { OrderStatusTypes, PaymentMethodTypes } from "@/types";
import { JunTableColumn } from "@juniper/ui";

export const singlelineOrdersHeaders: JunTableColumn[] = [
  {
    prop: "poNumber",
    text: "PO #",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "orderId",
    text: "Order Id",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "customerName",
    text: "Customer Name",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "customerNumber",
    text: "Customer #",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "orderStatus",
    text: "Status",
    canSort: true,
    canFilter: true,
    filterOptions: Object.values(OrderStatusTypes).map((statusType) => ({
      text: statusType,
      value: statusType,
    })),
  },
  {
    prop: "paymentMethod",
    text: "Payment Method",
    canSort: false,
    canFilter: true,
    filterOptions: Object.values(PaymentMethodTypes).map(
      (paymentMethodTypes) => ({
        text: paymentMethodTypes,
        value: paymentMethodTypes,
      })
    ),
  },
  {
    prop: "orderDate",
    text: "Order Date",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "orderTotal",
    text: "Order Amount",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "invoiceNumber",
    text: "Invoice",
    canSort: false,
    canFilter: false,
  },
];

export const SingleLineOrderHeaderFilterMap: Record<string, Function> = {
  poNumber: () => "PONumber",
  orderId: () => "OrderID",
  customerName: () => "CustomerName",
  customerNumber: () => "CustomerNumber",
  orderStatus: () => "OrderStatus",
  paymentMethod: () => "PaymentMethod",
  orderDate: () => "OrderDate",
  orderTotal: () => "OrderTotal",
  invoiceNumber: () => "InvoiceNumber",
};

export const multilineOrdersHeaders: JunTableColumn[] = [
  {
    prop: "poNumber",
    text: "PO #",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "brandOrderId",
    text: "Order Id",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "catalogName",
    text: "Vendor",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "customerName",
    text: "Customer Name",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "customerNumber",
    text: "Customer #",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "orderStatus",
    text: "Status",
    canSort: true,
    canFilter: true,
    // filterOptions: Object.values(OrderStatusTypes).map(statusType => ({
    //   text: statusType,
    //   value: statusType
    // }))
  },
  {
    prop: "paymentMethod",
    text: "Payment Method",
    canSort: false,
    canFilter: true,
    filterOptions: Object.values(PaymentMethodTypes).map(
      (paymentMethodTypes) => ({
        text: paymentMethodTypes,
        value: paymentMethodTypes,
      })
    ),
  },
  {
    prop: "orderDate",
    text: "Order Date",
    canSort: true,
    canFilter: true,
  },
  {
    prop: "orderTotal",
    text: "Order Amount",
    canSort: true,
    canFilter: true,
  },
];

export const MultiLineOrderHeaderFilterMap: Record<string, Function> = {
  poNumber: () => "PONumber",
  brandOrderId: () => "BrandOrderId",
  catalogName: () => "CatalogName",
  customerName: () => "CustomerName",
  customerNumber: () => "CustomerNumber",
  orderStatus: () => "OrderStatus",
  paymentMethod: () => "PaymentMethod",
  orderDate: () => "OrderDate",
  orderTotal: () => "OrderTotal",
};
