import { AxiosResponse } from "axios";
import axios from "@/axios";
import { injectable } from "inversify-props";
import { BrandDto, BrandsDto, IBrandRepository } from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/vendor/Brands`;

@injectable()
export class BrandRepository implements IBrandRepository {
  public async get(): Promise<BrandsDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<BrandsDto>(data, BrandsDto);
  }

  public async put(brands: Array<BrandDto>): Promise<AxiosResponse<BrandsDto>> {
    const catalogCodes = brands
      .filter((f) => f.visible)
      .map((m) => m.catalogCode);
    const payload = serialize(catalogCodes);
    return axios.put(ENDPOINT_URL, payload);
  }
}
