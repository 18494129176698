
import { PropType } from "vue";
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Icon, Tooltip } from "@/components";
import { HTMLElementEvent, IconNameTypes, TooltipPosition } from "@/types";
import { VeeFieldMixin } from "@/mixins";

@Options({
  components: {
    Icon,
    Tooltip,
  },
  emits: ["update:modelValue", "change"],
})
export default class Switch extends mixins<[VueMixin<VeeFieldMixin<boolean>>]>(
  VeeFieldMixin
) {
  @Prop({
    type: Boolean,
    default: false,
  })
  modelValue!: boolean;

  @Prop({
    type: String,
    default: "",
    required: true,
  })
  name!: string;

  @Prop({
    type: String,
    default: null,
  })
  label!: string;

  @Prop({
    type: String,
    default: null,
  })
  tooltipMsg!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String as PropType<TooltipPosition>,
    default: TooltipPosition.Top,
  })
  tooltipPosition!: TooltipPosition;

  private IconNameTypes = IconNameTypes;

  onChange(e: HTMLElementEvent<HTMLInputElement>) {
    this.$emit("update:modelValue", e.target.checked);
    this.$emit("change", e.target.checked);
    this.field.handleChange(e.target.checked);
  }
}
