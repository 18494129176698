
import { Options, Vue } from "vue-class-component";
import { Form } from "vee-validate";
import {
  EditableBox,
  Dropdown,
  AdminTextarea,
  DynamicLabels,
  InfoList,
} from "@/components";
import {
  EditableBoxStatusTypes,
  ProductDetailsTemplateDto,
  ProductSettingsDto,
} from "@/types";
import type {
  IValidateResponse,
  ToastConfig,
  IProductSettingsRepository,
} from "@/types";
import * as yup from "yup";
import mapValues from "lodash/mapValues";
import { inject } from "inversify-props";
import { BackupDataService } from "@/services";
import { namespace } from "vuex-class";
import { Utils } from "@/utils";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    Form,
    EditableBox,
    Dropdown,
    AdminTextarea,
    DynamicLabels,
    InfoList,
  },
})
export default class ProductTemplate extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private productSettingsRepository?: IProductSettingsRepository;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;

  private validationSchema = yup.lazy((obj) =>
    yup.object(
      // @ts-ignore
      mapValues(obj, (value, key: string) => {
        const index = key.split("-")[1];

        if (key === "description") {
          return yup.string().nullable().required();
        }
        if (key === "title") {
          return yup.string().nullable().required();
        }
        // only validate UDF if corresponding label is set
        if (key.includes("key-") && obj[`label-${index}`]) {
          return yup.string().nullable().required("UDF is a required field");
        }
        // only validate label if corresponding UDF is set
        if (key.includes("label-") && obj[`key-${index}`]) {
          return yup
            .string()
            .nullable()
            .required("Custom Label is a required field");
        }
      })
    )
  );

  private status = EditableBoxStatusTypes.View;
  private productSettings: ProductSettingsDto = new ProductSettingsDto();
  private productTemplate: ProductDetailsTemplateDto | null | undefined = null;

  private childTitleItems = [
    {
      name: "Shop the set",
      value: "Shop the set",
    },
    {
      name: "Related Products",
      value: "Related Products",
    },
    {
      name: "Associated Products",
      value: "Associated Products",
    },
    {
      name: "Other Sizes",
      value: "Other Sizes",
    },
    {
      name: "Other Options",
      value: "Other Options",
    },
  ];

  async mounted() {
    this.status = EditableBoxStatusTypes.Loading;
    const [productSettings] = await Utils.try(
      this.productSettingsRepository?.get()
    );
    if (!productSettings) return;
    this.productSettings = productSettings;
    this.productTemplate = this.productSettings.productDetailsTemplate;
    this.status = EditableBoxStatusTypes.View;
  }

  get infoItems() {
    if (this.productTemplate === null || this.productTemplate === undefined) {
      return [];
    }

    return [
      {
        label: "Description",
        text: this.productTemplate.descriptionField || "",
      },
      {
        label: "UDFs",
        list:
          this.productTemplate.attributes
            ?.filter((a) => a.label)
            .map((a) => a.label) || [],
      },
      {
        label: "Custom Text",
        text: this.productTemplate.customText || "",
      },
    ];
  }

  get childInfoItem() {
    if (this.productTemplate === null || this.productTemplate === undefined) {
      return [];
    }

    return [
      {
        label: "Title",
        text: this.productTemplate.childProductsTitle || "",
      },
    ];
  }

  get descriptionItems() {
    const presetOptions = [
      {
        name: "ItemName",
        value: "ItemName",
      },
      {
        name: "Description",
        value: "Description",
      },
      {
        name: "Notes",
        value: "Notes",
      },
    ];
    // TODO: Switch from productSettings to static endpoint
    const UDFs = this.productSettings.userDefinedFields.map((field) => {
      return { name: field.name, value: field.name };
    });
    return [...presetOptions, ...UDFs];
  }

  get dynamicFieldItems() {
    const fields = this.productSettings.userDefinedFields.filter((field) => {
      return field.name !== this.productTemplate?.descriptionField;
    });

    return fields.map((field) => {
      return { name: field.name, value: field.name };
    });
  }

  private onEditableBoxEdit() {
    this.backupDataService?.saveBackup(this, this.productTemplate);
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.backupDataService?.restoreBackup(this, this.productTemplate);
    this.status = EditableBoxStatusTypes.View;
  }

  private async onEditableBoxSave() {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();

    if (
      validateResponse.valid &&
      this.productTemplate &&
      this.productSettings
    ) {
      try {
        this.status = EditableBoxStatusTypes.Saving;

        // take initial response and clobber with new productDetailsTemplate created
        // in the form
        this.productSettings.productDetailsTemplate = {
          ...this.productTemplate,
          // form can submit with null fields on attribute mapping, so filter those out
          attributes: this.productTemplate.attributes.filter(
            (a) => a.fieldName
          ),
        };
        await this.productSettingsRepository?.put(this.productSettings);
        this.createToastSuccess?.({
          message: "Your product template has been successfully saved!",
        });
        this.status = EditableBoxStatusTypes.View;
      } catch (err) {
        this.status = EditableBoxStatusTypes.Edit;
      }
    }
  }
}
