export const SingleLineOrdersMap = {
  "[].poNumber": "[].poNumber?",
  "[].orderId": "[].orderId?",
  "[].orderGuid": "[].orderGuid?",
  "[].customerName": "[].customerName?",
  "[].customerNumber": "[].customerNumber?",
  "[].catalogName": "[].catalogName?",
  "[].orderStatus": "[].orderStatus?",
  "[].orderDate": "[].orderDate?",
  "[].shipDate": "[].shipDate?",
  "[].orderTotal": "[].orderTotal?",
  "[].invoices[0].invoiceNumber": "[].invoiceNumber?",
  "[].invoices[0].id": "[].invoiceId?",
  "[].paymentMethod.type": "[].paymentMethod?",
};
