import { createStore } from "vuex";
import PublishVuexModule from "@/store/modules/PublishVuexModule";
import ManufacturersVuexModule from "@/store/modules/ManufacturersVuexModule";
import NotificationsVuexModule from "@/store/modules/NotificationsVuexModule";
import AuthVuexModule from "@/store/modules/AuthVuexModule";
import StaticContentVuexModule from "@/store/modules/StaticContentVuexModule";
import BrandPreviewVuexModule from "@/store/modules/BrandPreviewVuexModule";
import SellerVuexModule from "./modules/SellerVuexModule";
import ExportOrdersVuexModule from "./modules/ExportOrdersVuexModule";

export default createStore({
  modules: {
    // conditionally adding oidcVuexModule for unit testing due to errors
    authVuexModule: AuthVuexModule,
    publishVuexModule: PublishVuexModule,
    manufacturersVuexModule: ManufacturersVuexModule,
    notificationsVuexModule: NotificationsVuexModule,
    staticContentVuexModule: StaticContentVuexModule,
    brandPreviewVuexModule: BrandPreviewVuexModule,
    sellerVuexModule: SellerVuexModule,
    exportOrdersVuexModule: ExportOrdersVuexModule,
  },
});
