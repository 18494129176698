import axios from "@/axios";
import { injectable } from "inversify-props";
import { ProfileBrandDto, IBrandSettingsRepository } from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/BrandSettings`;

@injectable()
export class BrandSettingsRepository implements IBrandSettingsRepository {
  public async get(): Promise<ProfileBrandDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<ProfileBrandDto>(data, ProfileBrandDto);
  }

  public async put(profileBrand: ProfileBrandDto): Promise<void> {
    const payload = serialize(profileBrand);
    return await axios.put(ENDPOINT_URL, payload);
  }
}
