
import { Vue, Options } from "vue-class-component";
import { Prop, Watch, Emit, InjectReactive } from "vue-property-decorator";
import * as yup from "yup";
import { Form } from "vee-validate";
import { Utils } from "@/utils";
import {
  ConfirmationModal,
  RadioButton,
  AdminInput,
  Dropdown,
  Checkbox,
} from "@/components";
import { CustomerDto } from "@/types";
import type {
  IValidateResponse,
  INewCustomer,
  HTMLElementEvent,
  ICustomerPostPayload,
  DropdownItem,
  ToastConfig,
  ICustomersRepository,
  IAccessRequestsRepository,
  IAppUsersRepository,
} from "@/types";
import { inject } from "inversify-props";
import { JunOption } from "@juniper/ui";
import { namespace } from "vuex-class";

const auth = namespace("authVuexModule");
const notifications = namespace("notificationsVuexModule");
const staticContent = namespace("staticContentVuexModule");

enum CustomerType {
  New = "new",
  Existing = "existing",
}

@Options({
  components: {
    ConfirmationModal,
    RadioButton,
    AdminInput,
    Dropdown,
    Checkbox,
    Form,
  },
  emits: ["close-modal", "on-success"],
})
export default class AssociateCustomerModal extends Vue {
  @inject() private customersRepository!: ICustomersRepository;
  @inject() private accessRequestsRepository!: IAccessRequestsRepository;
  @inject() private appUsersRepository!: IAppUsersRepository;

  @InjectReactive() private agencyHideFieldsFlag!: boolean;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;

  @auth.Getter private isMultiline!: boolean | null;

  @staticContent.Getter private priceLevelsDropdownOptions!: DropdownItem[];
  @staticContent.Getter private termsDropdownOptions!: DropdownItem[];
  @staticContent.Getter private salesRepsDropdownOptions!: DropdownItem[];

  @Prop({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: Array,
    default: [],
  })
  appUserIds!: number[];

  @Prop({
    type: Object,
    default: {},
  })
  defaultCustomer!: INewCustomer;

  @Prop({
    type: Function,
    default: null,
  })
  onAssociateExisting!: (customerNumber: string | null) => Promise<void>;

  @Prop({
    type: Function,
    default: null,
  })
  onAssociateNew!: (customer: ICustomerPostPayload) => Promise<void>;

  @Prop({
    type: String,
    default: null,
  })
  title!: string;

  @Prop({
    type: String,
    default: null,
  })
  subTitle!: string;

  @Prop({
    type: String,
    default: null,
  })
  radioLabel!: string;

  @Prop({
    type: String,
    default: null,
  })
  successMsg!: string;

  private showForm = false;
  private showModal = this.open;
  private CustomerType = CustomerType;
  private customerType: CustomerType | null = CustomerType.Existing;
  private selectedCustomer: JunOption | null = null;
  private customerSearchItems: JunOption[] = [];
  private newCustomer: INewCustomer = {};
  private approveLoading = false;
  private noResults = false;
  private validationSchema = yup.object().shape({
    companyName: yup.string().nullable().required(),
    companyNumber: yup.string().nullable().required(),
    priceLevel: yup
      .string()
      .nullable()
      .test(
        "test-has-pricelevel",
        "PriceLevel must be selected.",
        (v) => this.isMultiline || yup.string().isValidSync(v)
      ),
    term: yup.string().nullable(),
    repNumber: yup.string().nullable().required(),
    billingAddress: yup.string().nullable().required(),
    billingCity: yup.string().nullable().required(),
    billingZipcode: yup.string().nullable().required(),
    billingPhone: yup.string().isPhone().nullable().required(),
    billingAddress2: yup.string().nullable(),
    billingState: yup.string().nullable().required(),
    billingCountry: yup.string().nullable().required(),
    billingFax: yup.string().isPhone().nullable(),
    shippingAddressType: yup.string().nullable(),
    shippingAddress: yup.string().nullable().required(),
    shippingAddress2: yup.string().nullable(),
    shippingContactName: yup.string().nullable().required(),
    shippingState: yup.string().nullable().required(),
    shippingCity: yup.string().nullable().required(),
    shippingZipcode: yup.string().nullable().required(),
    shippingCountry: yup.string().nullable().required(),
    shippingEmail: yup.string().email().nullable().required(),
    shippingPhone: yup.string().isPhone().nullable().required(),
    shippingFax: yup.string().isPhone().nullable(),
  });

  @Watch("open")
  onOpenChanged(val: boolean) {
    this.newCustomer = this.defaultCustomer;
    this.showModal = val;
  }

  @Emit()
  closeModal() {
    this.showModal = false;
  }

  private get hideFields() {
    return this.isMultiline ? this.agencyHideFieldsFlag : false;
  }

  get confirmText() {
    if (this.customerType === CustomerType.New && !this.showForm) {
      return "Next";
    } else {
      return "Save";
    }
  }

  get confirmDisabled() {
    if (this.customerType === null || this.approveLoading) {
      return true;
    }

    return (
      this.customerType === CustomerType.Existing && !this.selectedCustomer
    );
  }

  get modalTitle() {
    return this.showForm ? "Create New Customer" : this.title;
  }

  get cancelDisabled() {
    return this.approveLoading;
  }

  async searchCustomers(searchTerm: string) {
    // any modification to the autocomplete search (changing or clearing term) will
    // nullify the selected item
    this.selectedCustomer = null;
    if (searchTerm.length < 3) {
      this.customerSearchItems = [];
      return;
    }

    const [data] = await Utils.try(this.customersRepository.search(searchTerm));
    if (data) {
      this.setCustomerSearchOptions(data);
      this.noResults = data.length === 0;
    }
  }

  setCustomerSearchOptions(customers: CustomerDto[]) {
    this.customerSearchItems = customers.map((c) => {
      return {
        value: String(c.customerNumber),
        text: c.name || "",
        subtext: `# ${c.customerNumber}`,
      };
    });
  }

  async onConfirm() {
    // show create customer form
    if (!this.showForm && this.customerType === CustomerType.New) {
      this.showForm = true;
      // save with existing customer
    } else if (!this.showForm && this.customerType === CustomerType.Existing) {
      try {
        this.approveLoading = true;
        await this.onAssociateExisting(this.selectedCustomer?.value || null);
        this.onSuccess();
      } catch (err) {
        console.log(err);
      } finally {
        this.approveLoading = false;
      }
    } else {
      // save with new customer
      const validateResponse: IValidateResponse = await (
        this.$refs.form as HTMLFormElement
      ).validate();

      if (validateResponse.valid) {
        try {
          this.approveLoading = true;
          const customerPayload = this.createCustomerPostPayload();
          await this.onAssociateNew(customerPayload);
          this.onSuccess();
        } catch (err) {
          console.log(err);
        } finally {
          this.approveLoading = false;
        }
      } else {
        const topRef = this.$refs.topRef as HTMLDivElement;
        topRef.scrollIntoView();
      }
    }
  }

  onCancel() {
    if (!this.showForm) {
      this.closeModal();
    } else {
      this.showForm = false;
    }
  }

  createCustomerPostPayload(): ICustomerPostPayload {
    return {
      name: this.newCustomer.companyName,
      customerNumber: this.newCustomer.companyNumber,
      contactEmail: this.newCustomer.shippingEmail,
      contactName: this.newCustomer.shippingContactName,
      contactPhone: this.newCustomer.shippingPhone,
      address1: this.newCustomer.billingAddress,
      address2: this.newCustomer.billingAddress2,
      city: this.newCustomer.billingCity,
      country: this.newCustomer.billingCountry,
      fax: Utils.cleanPhone(this.newCustomer.billingFax),
      phone: Utils.cleanPhone(this.newCustomer.billingPhone),
      postalCode: this.newCustomer.billingZipcode,
      priceLevel: Number(this.newCustomer.priceLevel),
      repNumber: this.newCustomer.repNumber,
      shipToAddress1: this.newCustomer.shippingAddress,
      shipToAddress2: this.newCustomer.shippingAddress2,
      shipToCity: this.newCustomer.shippingCity,
      shipToCode: this.newCustomer.shippingZipcode,
      shipToContactName: this.newCustomer.shippingContactName,
      shipToCountry: this.newCustomer.shippingCountry,
      shipToEmail: this.newCustomer.shippingEmail,
      shipToFax: Utils.cleanPhone(this.newCustomer.shippingFax),
      shipToName: this.newCustomer.shippingContactName,
      shipToPhone: Utils.cleanPhone(this.newCustomer.shippingPhone),
      shipToPostalCode: this.newCustomer.shippingZipcode,
      shipToStateProvince: this.newCustomer.shippingState,
      stateProvince: this.newCustomer.billingState,
      terms: this.newCustomer.term,
    };
  }

  onSuccess() {
    this.closeModal();
    this.createToastSuccess({ message: this.successMsg });
    this.$emit("on-success");
  }

  setShippingSameAsBilling(e: HTMLElementEvent<HTMLInputElement>) {
    if (e.target.checked) {
      this.newCustomer.shippingAddress = this.newCustomer.billingAddress;
      this.newCustomer.shippingAddress2 = this.newCustomer.billingAddress2;
      this.newCustomer.shippingCity = this.newCustomer.billingCity;
      this.newCustomer.shippingState = this.newCustomer.billingState;
      this.newCustomer.shippingZipcode = this.newCustomer.billingZipcode;
      this.newCustomer.shippingCountry = this.newCustomer.billingCountry;
      this.newCustomer.shippingPhone = this.newCustomer.billingPhone;
      this.newCustomer.shippingFax = this.newCustomer.billingFax;
    } else {
      this.newCustomer.shippingAddress = "";
      this.newCustomer.shippingAddress2 = "";
      this.newCustomer.shippingCity = "";
      this.newCustomer.shippingState = "";
      this.newCustomer.shippingZipcode = "";
      this.newCustomer.shippingCountry = "";
      this.newCustomer.shippingPhone = "";
      this.newCustomer.shippingFax = "";
    }
  }
}
