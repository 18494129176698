
import { Vue, Options } from "vue-class-component";
import { InjectReactive, Inject, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { inject } from "inversify-props";
import { ShipAddressMap } from "@/maps";
import { Utils } from "@/utils";

import {
  IconNameTypes,
  EditableBoxStatusTypes,
  OrderStatusTypes,
  ShippingMethodTypes,
  CountryTypes,
  OrderDto,
  AddressDto,
  ShippingSettingsDto,
  ProfileBrandDto,
  RatesDto,
  ShipmentDto,
  IndependentShipmentDto,
  ShipmentItemDto,
  PackageDto,
  LabelDto,
  CustomsDto,
} from "@/types";
import type {
  TabItem,
  ToastConfig,
  IShipmentsPostPayload,
  IShipmentOrderItemPayload,
  IShipmentPackagePayload,
  IShipmentsPostPayloadWrapper,
  IBrandSettingsRepository,
  IShipmentsRepository,
  IShippingAccountRepository,
  IShippingRatesRepository,
  IShipmentsIndependentRepository,
  IShippingRatesPostPayload,
  IShippingLabelsRepository,
} from "@/types";
import {
  Icon,
  TabbedBox,
  EditableBox,
  AdminButton,
  ConfirmationModal,
  ShipOnYourOwn,
  ShipWithJuniper,
  ShipOnYourOwnEdit,
  ShipWithJuniperEdit,
} from "@/components";
import { BackupDataService } from "@/services";

const staticContent = namespace("staticContentVuexModule");
const notifications = namespace("notificationsVuexModule");

type shipTypes = IShipmentsPostPayload | IndependentShipmentDto;
type shipReturnTypes = ShipmentDto | IndependentShipmentDto;

@Options({
  components: {
    Icon,
    TabbedBox,
    EditableBox,
    AdminButton,
    ConfirmationModal,
    ShipOnYourOwn,
    ShipWithJuniper,
    ShipOnYourOwnEdit,
    ShipWithJuniperEdit,
  },
})
export default class OrderShippingForm extends Vue {
  @staticContent.Getter private manufacturerAddress!: AddressDto;
  @staticContent.Getter private weightUnit!: string;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;
  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;

  @inject() private backupDataService!: BackupDataService;
  @inject() private brandSettingsRepository!: IBrandSettingsRepository;
  @inject() private shippingAccountRepository!: IShippingAccountRepository;
  @inject() private shippingRatesRepository!: IShippingRatesRepository;
  @inject() private shippingLabelsRepository!: IShippingLabelsRepository;
  @inject() private shipmentsRepository!: IShipmentsRepository;
  @inject()
  private shipmentsIndependentRepository!: IShipmentsIndependentRepository;

  @InjectReactive() private order!: OrderDto;
  @InjectReactive() private orderLoading!: boolean;
  @InjectReactive() private shippingLoading!: boolean;
  @InjectReactive() private shippingSettings!: ShippingSettingsDto;
  @InjectReactive() private canCreateInvoice!: boolean;
  @InjectReactive() private isShippingFormReady!: boolean;
  @InjectReactive() private profileBrand!: ProfileBrandDto;

  @Inject() private updateShippingLoading!: (bool: boolean) => void;
  @Inject() private updateCanCreateInvoice!: (updatedValue: boolean) => void;

  private IconNameTypes = IconNameTypes;
  private OrderStatusTypes = OrderStatusTypes;
  private status = EditableBoxStatusTypes.Edit;
  private shipments: TabItem<shipTypes>[] = [];
  private shipRates: RatesDto[] = [];
  private shipmentObj = new ShipmentDto();
  private shippingLabel: LabelDto = new LabelDto();
  private rateId = 0;
  private shipmentId = 0;
  private shipmentNum = 0;
  private buttonLoading = false;
  private juniperFormLoaded = false;
  private independentShipmentAlreadyExists = false;
  private disableForm = false;
  private showShipOnYourOwn = false;
  private showCarrierErrorModal = false;
  private showDeleteModal = false;
  private showBuyShippingLabelModal = false;
  private showCancelShippingLabelModal = false;
  private buyLabelLoading = false;
  private cancelShippingLoading = false;
  private carrierRequestLoading = false;
  private carrierError = "";

  @Watch("isShippingFormReady")
  onShippingFormReady(_: boolean, prevReady: boolean) {
    if (prevReady) return;
    this.init();
  }

  @Watch("shipments", { deep: true })
  private onShipmentsChange() {
    if (this.shipmentId && this.juniperFormLoaded) {
      this.shipRates = [];
    }
  }

  // Only enable invoice creation for shipOnOwnYourOwn orders
  // when there is a shipment created
  @Watch("shipmentId", { immediate: true })
  onShipmentIdChange(newShipmentId: number): void {
    if (!this.showShipOnYourOwn) return;
    if (!newShipmentId || !this.shipments.length) {
      return this.updateCanCreateInvoice(false);
    }
    this.updateCanCreateInvoice(true);
  }

  // Only enable invoice creation for shipWithJuniper orders
  // when there is a shipment and a label created
  @Watch("shippingLabel.labelId", { immediate: true })
  onShippingLabelChange(newLabelId: number): void {
    if (this.showShipOnYourOwn) return;
    if (!newLabelId || !this.shipmentId) {
      this.updateCanCreateInvoice(false);
    } else {
      this.updateCanCreateInvoice(true);
    }
  }

  private async init() {
    this.showShipOnYourOwn =
      this.shippingSettings.shipMethod === ShippingMethodTypes.Unset;
    if (!this.showShipOnYourOwn) {
      // if ship with juniper check for created shipments
      const shipment = await this.checkForShipment();
      shipment ? this.onCreateShipment(shipment) : this.onCreateShipment();
    } else {
      const independentShipment = await this.checkForIndependentShipment();
      if (independentShipment) {
        this.shipmentId = independentShipment.independentShipmentId;
        this.status = EditableBoxStatusTypes.View;
        this.independentShipmentAlreadyExists = true;
        this.onCreateShipment(independentShipment);
      } else {
        this.onCreateShipment();
      }
    }

    this.updateShippingLoading(false);
  }

  private async onButtonClick(tab: TabItem<shipTypes>) {
    this.buttonLoading = true;

    // if its ship with juniper / preferred carrier
    if (!this.showShipOnYourOwn) {
      if (this.status === EditableBoxStatusTypes.Edit) {
        const isValid = await this.validateShipWithJuniper();
        if (this.shipmentId && this.shipRates.length && isValid) {
          this.openBuyShippingLabelModal();
        } else if (isValid) {
          this.shipmentId
            ? await this.updateShipment(tab as TabItem<IShipmentsPostPayload>)
            : await this.postShipment(tab as TabItem<IShipmentsPostPayload>);
        }
      } else {
        if (this.shippingLabel.labelId) {
          this.openCancelShippingLabelModal();
        }
      }
    } else {
      // if its ship on your own
      if (this.status === EditableBoxStatusTypes.View) {
        this.onEdit();
      } else if (!this.independentShipmentAlreadyExists) {
        // create new shipment
        const isValid = await this.validateShipOnYourOwn();

        if (isValid) {
          const [data] = await Utils.try(
            this.shipmentsIndependentRepository.post([
              this.shipments[0].data as IndependentShipmentDto,
            ])
          );

          if (data) {
            this.shipmentId = data.shipments[0].independentShipmentId;
            this.independentShipmentAlreadyExists = true;
            this.onSave();
            this.createToastSuccess({
              message: "Shipment successfully created.",
            });
            this.backupShipmentsData();
          } else {
            this.restoreShipmentsData();
          }
        }
      } else {
        // update current shipment
        const isValid = await this.validateShipOnYourOwn();

        if (isValid) {
          const [data] = await Utils.try(
            this.shipmentsIndependentRepository.put(
              this.shipmentId,
              this.shipments[0].data as IndependentShipmentDto
            )
          );

          if (data) {
            this.onSave();
            this.createToastSuccess({
              message: "Shipment successfully updated.",
            });
            this.backupShipmentsData();
          } else {
            this.restoreShipmentsData();
          }
        }
      }
    }

    this.buttonLoading = false;
  }

  // currently not implemented
  // get showDeleteButton() {
  //   return (
  //     this.shipmentId &&
  //     !this.shippingLabel.labelId &&
  //     this.formLoaded &&
  //     !this.disableForm &&
  //     this.order &&
  //     !this.order.invoices.length
  //   );
  // }

  get showDownloadShippingLabelButton() {
    return (
      !this.showShipOnYourOwn && this.status === EditableBoxStatusTypes.View
    );
  }

  get buttonText() {
    if (!this.showShipOnYourOwn) {
      // button text flow for ship with juniper
      if (this.status === EditableBoxStatusTypes.View) {
        return "Cancel Shipping Label";
      } else if (this.shipmentId && this.shipRates.length) {
        return "Buy Shipping Label";
      } else {
        return "View Rates";
      }
    } else {
      // button text flow for ship on your own
      if (this.status === EditableBoxStatusTypes.View) {
        return "Update Shipment Info";
      } else {
        return "Save Shipment Info";
      }
    }
  }

  get buttonColor() {
    if (!this.showShipOnYourOwn) {
      if (this.status === EditableBoxStatusTypes.View) {
        return "ghost";
      } else {
        return "primary";
      }
    } else {
      return "primary";
    }
  }

  get buttonInactive() {
    if (!this.showShipOnYourOwn) {
      if (this.status === EditableBoxStatusTypes.View) {
        return this.orderNotApproved || this.buttonLoading;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get buttonInactiveFilled() {
    if (!this.showShipOnYourOwn) {
      if (this.status === EditableBoxStatusTypes.View) {
        return this.orderHasInvoice;
      } else {
        return this.orderNotApproved || this.buttonLoading;
      }
    } else {
      return (
        this.orderNotApproved || this.buttonLoading || this.orderHasInvoice
      );
    }
  }

  private get modalContent() {
    if (this.showDeleteModal) {
      return {
        title: "Delete Shipment",
        cancelText: "Cancel",
        confirmText: "Delete",
        closeOnly: false,
        open: this.showDeleteModal,
        onConfirm: this.onDeleteShipment,
        onCancel: this.closeDeleteModal,
        loading: this.disableForm,
        closeModal: this.closeDeleteModal,
        text: "This will delete the shipment. Are you sure?",
        subText: "",
      };
    } else if (this.showBuyShippingLabelModal) {
      return {
        title: "Buy Shipping Label",
        cancelText: "Cancel",
        confirmText: "Confirm",
        closeOnly: false,
        open: this.showBuyShippingLabelModal,
        onConfirm: this.buyShippingLabel,
        onCancel: this.closeBuyShippingLabelModal,
        loading: this.buyLabelLoading,
        closeModal: this.closeBuyShippingLabelModal,
        text: "Are you sure you want to purchase a shipping label?",
        subText:
          "Verify that all your shipping information is correct before purchasing a label. If you find that there are errors, you will have to cancel the label to make any changes. If shipping with UPS, you will have to wait 24 hours before you can cancel a label.",
      };
    } else if (this.showCancelShippingLabelModal) {
      return {
        title: "Cancel Shipping Label",
        cancelText: "Cancel",
        confirmText: "Confirm",
        closeOnly: false,
        open: this.showCancelShippingLabelModal,
        onConfirm: this.cancelShippingLabel,
        onCancel: this.closeCancelShippingLabelModal,
        loading: this.cancelShippingLoading,
        closeModal: this.closeCancelShippingLabelModal,
        text: "This will cancel your shipping label. Are you sure?",
        subText:
          "If you cancel the label, a void request will be sent to the shipping carrier. If you have any problems please contact the shipping carrier directly.",
      };
    } else if (this.showCarrierErrorModal) {
      const carrierLabel =
        Utils.getShippingCarrierConfig(this.shippingSettings.carrierName)
          ?.label || this.shippingSettings.carrierName;

      return {
        title: "Service Error",
        closeOnly: true,
        open: this.showCarrierErrorModal,
        loading: this.carrierRequestLoading,
        closeModal: this.closeCarrierErrorModal,
        text: `An error occurred with your ${carrierLabel} account.`,
        subText: this.carrierError,
        subSubText: `Please contact ${carrierLabel} if you need help resolving this issue.`,
      };
    }
    return { open: false };
  }

  private get formLoaded() {
    if (!this.showShipOnYourOwn) {
      return this.juniperFormLoaded;
    } else {
      return true;
    }
  }

  private get isInternational() {
    return this.order.shippingAddress.country
      ? Utils.toCountryCode(this.order.shippingAddress.country) !==
          CountryTypes.US
      : null;
  }

  private async carrierId() {
    const [data] = await Utils.try(this.shippingAccountRepository.get());
    if (data && data.carrierId) {
      return data.carrierId;
    }
    this.createToastError({
      message:
        "Error getting your carrier settings. Navigate to the shipping settings to confirm settings.",
    });
    return null;
  }

  private populateCustomsData(data?: CustomsDto) {
    return data
      ? data
      : {
          contents: "",
          nonDelivery: "",
          customsItems: this.order.orderItems.map((i) => {
            return {
              description:
                i.description.slice(0, 95) ||
                "No Product Description was Provided",
              quantity: i.qty,
              value: i.basePrice,
              countryOfOrigin: Utils.toCountryCode(
                this.manufacturerAddress.country || "Us"
              ),
              // TODO: update with preferred unit of messaure
              skuDescription:
                i.description.slice(0, 95) || "No Sku Description was Provided",
              unitOfMeasure: this.weightUnit || "Pound",
              sku: i.itemID,
            };
          }),
        };
  }

  private populateOrderItems(
    data?: ShipmentItemDto[]
  ): IShipmentOrderItemPayload[] {
    return data
      ? // if we have order item data already map to item payload
        data.map((i) => {
          return {
            name: i.name || "",
            quantity: i.quantity,
            orderId: i.orderId || "",
            orderItemId: i.orderItemId || "",
          };
        })
      : this.order.orderItems.map((i) => {
          // otherwise look at the order object and create them
          return {
            name: i.itemName,
            quantity: i.qty,
            orderId: i.orderID.toString(),
            orderItemId: i.orderItemID.toString(),
          };
        });
  }

  private populatePackages(data?: PackageDto[]): IShipmentPackagePayload[] {
    // TODO: When we have more packages per shipment will need to be expanded
    return data
      ? // if we have package data already map and output as post payload
        data.map((p) => {
          return {
            packageCode: p.packageCode || "",
            weight: {
              value: p.weight.value || 0,
              unit: p.weight.unit || "Pound",
            },
            dimensions: {
              unit: p.dimensions.unit || "",
              length: p.dimensions.length || 0,
              width: p.dimensions.width || 0,
              height: p.dimensions.height || 0,
            },
          };
        })
      : [
          // If we dont have package data already just create a single package payload
          {
            packageCode: "",
            weight: {
              value: 0,
              unit: this.weightUnit || "Pound",
            },
            dimensions: {
              unit: "",
              length: 0,
              width: 0,
              height: 0,
            },
          } as IShipmentPackagePayload,
        ];
  }

  private generateShipmentObj(data?: shipReturnTypes) {
    // TODO: logic will need to change when we can create multiple shipments
    // depending on your shipping method generate different objects
    return this.showShipOnYourOwn
      ? this.shipOnYourOwnData(data as IndependentShipmentDto)
      : this.shipWithJuniperData(data as ShipmentDto);
  }

  private shipWithJuniperData(data?: ShipmentDto): IShipmentsPostPayload {
    // creates a base object or if we have a shipment already populates with the data
    const shipmentPayload = {
      carrierId: data?.carrierId || "",
      orderId: this.order.orderID.toString(),
      items: this.populateOrderItems(data?.items),
      shipDate: data?.shipDate?.split("T")[0] || "",
      shipTo: data?.shipTo
        ? data.shipTo
        : this.createShipAddress(this.order.shippingAddress),
      shipFrom: data?.shipFrom
        ? data.shipFrom
        : this.createShipAddress(this.manufacturerAddress, true),
      returnTo: data?.returnTo
        ? data.returnTo
        : this.createShipAddress(this.manufacturerAddress, true),
      packages: this.populatePackages(data?.packages),
    } as IShipmentsPostPayload;

    // if internation add the customs object
    this.isInternational &&
      (shipmentPayload.customs = this.populateCustomsData(data?.customs));

    return shipmentPayload;
  }

  private shipOnYourOwnData(
    data?: IndependentShipmentDto
  ): IndependentShipmentDto {
    if (!data) {
      const independentShipment = new IndependentShipmentDto();
      independentShipment.orderId = this.order.orderID;
      independentShipment.trackingNumber = "";
      independentShipment.shipDate = "";
      independentShipment.totalShipmentCost.currency = "Usd";
      independentShipment.totalShipmentCost.amount = "$0.00";
      return independentShipment;
    } else {
      return data;
    }
  }

  private async checkShipRates(id: number, packageType: string) {
    const [checkedRates] = await Utils.try(
      this.shipmentsRepository.getRates(id.toString())
    );

    if (checkedRates && checkedRates.rates.length) {
      this.shipRates = checkedRates.rates;
    } else {
      if (checkedRates?.errors.length) {
        this.carrierError =
          checkedRates.errors[0].message || "Error requesting rates";
        this.openCarrierErrorModal();
      } else {
        await this.getShipRates(id, packageType);
      }
    }
  }
  private async getShipRates(id: number, packageType: string) {
    // Build the ship rates payload
    const payload = {
      shipmentId: id,
      rateOptions: {
        packageTypes: [packageType],
        calculateTaxAmount: false,
        preferredCurrency: "Usd",
      },
    } as IShippingRatesPostPayload;
    // fetch and set rates
    const [rates, err] = await Utils.try(
      this.shippingRatesRepository.calculateRates(payload)
    );
    if (rates?.rateResponse.rates.length) {
      this.shipRates = rates.rateResponse.rates;
    } else {
      if (rates?.rateResponse.errors.length) {
        this.carrierError =
          rates.rateResponse.errors[0].message || "Error requesting rates";
        this.openCarrierErrorModal();
      } else {
        err &&
          (this.carrierError = JSON.parse(
            err?.response?.data.errors[0].message
          ).errors[0].message);
        err && this.openCarrierErrorModal();
        this.createToastError({
          message: "Error fetching shipment rates.",
        });
      }
    }
  }

  private async checkForIndependentShipment() {
    // Check if shipment is already created by OrderId
    const query = Utils.buildQueryString({
      page: 1,
      pageSize: 25,
      orderId: this.order.orderID,
    });
    const [shipmentsIndependentResults] = await Utils.try(
      this.shipmentsIndependentRepository.getAll(query)
    );
    return shipmentsIndependentResults?.shipments[0] ?? null;
  }

  private async checkForShipment() {
    // Check if shipment is already created by OrderId
    const query = Utils.buildQueryString({
        page: 1,
        pageSize: 25,
        orderId: this.order.orderID,
      }),
      [shipments] = await Utils.try(this.shipmentsRepository.getAll(query));
    // If we have the order save shipmentId and check for label
    // if we dont have label fetch rates and then return the shipment for populating
    // if not we return null
    if (shipments && shipments.total > 0) {
      const sh = shipments.shipments.find(
        (s) =>
          s.shipmentId &&
          s.packages[0].packageCode &&
          s.shipmentStatus !== "Cancelled"
      );
      if (sh && sh.shipmentId && sh.packages[0].packageCode) {
        this.shipmentId = sh.shipmentId;
        this.shipmentObj = sh;
        const label = await this.checkForShippingLabel();
        if (!label) {
          await this.checkShipRates(
            this.shipmentId,
            sh.packages[0].packageCode
          );
        }
        return sh;
      } else {
        return null;
      }
    }
    return null;
  }

  private async onDeleteShipment() {
    this.disableForm = true;

    let cancelResponse;
    if (!this.showShipOnYourOwn) {
      [cancelResponse] = await Utils.try(
        this.shipmentsRepository.cancel(this.shipmentId.toString())
      );
    } else {
      [cancelResponse] = await Utils.try(
        this.shipmentsIndependentRepository.cancel(this.shipmentId)
      );
    }

    if (cancelResponse) {
      this.createToastSuccess({
        message: "Shipment Canceled.",
      });
      // after we cancel shipment we need to clear data
      this.shipmentId = 0;
      this.shipRates = [];
      const t =
        this.shipments.find((s) => s.active) || ({} as TabItem<shipTypes>);
      if (this.showShipOnYourOwn) {
        this.independentShipmentAlreadyExists = false; // TODO: will need to change when we have multiple shipments
        this.onEdit();
      }
      t.data = this.generateShipmentObj();
      this.onShipmentUpdate(t);
      this.backupShipmentsData();
    } else {
      this.createToastError({
        message: "Error canceling Shipment.",
      });
      this.restoreShipmentsData();
    }
    this.disableForm = false;
  }

  private async updateShipment(tab: TabItem<IShipmentsPostPayload>) {
    const d = this.shipmentObj;
    d.packages[0] = tab.data.packages[0];
    d.shipDate = tab.data.shipDate;

    const [shipment] = await Utils.try(
      this.shipmentsRepository.update(this.shipmentId.toString(), d)
    );
    if (shipment && shipment.shipmentId && shipment.packages[0].packageCode) {
      this.createToastSuccess({
        message: "Shipment Updated.",
      });
      // after we update shipment we need to fetch and set rates
      await this.getShipRates(
        shipment.shipmentId,
        shipment.packages[0].packageCode
      );
    } else {
      this.createToastError({
        message: "Error updating Shipment.",
      });
    }
  }

  private async postShipment(tab: TabItem<IShipmentsPostPayload>) {
    const d = tab.data,
      id = await this.carrierId(),
      shipments = { shipments: [] } as IShipmentsPostPayloadWrapper;
    if (id) {
      d.carrierId = id.toString();
      // update tab data with new shipment info
      this.onShipmentUpdate(tab);
      // push shipment to shipment array
      // TODO: will need to expand further when we have multiple shipments
      shipments.shipments.push(d);
      const [shipment, err] = await Utils.try(
        this.shipmentsRepository.post(shipments)
      );
      const sh = shipment?.shipments[0];
      if (sh && sh.shipmentId && sh.packages[0].packageCode) {
        this.shipmentId = sh.shipmentId;
        this.shipmentObj = sh;

        this.createToastSuccess({
          message: "Shipment Created.",
        });
        // after we create shipment and have ID fetch and set rates
        await this.getShipRates(sh.shipmentId, sh.packages[0].packageCode);
      } else {
        err &&
          (this.carrierError = JSON.parse(
            JSON.parse(err?.response?.data.errors[0].message).errors[0].message
          ).errors[0].message);
        err && this.openCarrierErrorModal();
        this.createToastError({
          message: "Error creating Shipment.",
        });
      }
    }
  }

  private async checkForShippingLabel() {
    // Check if order has shipping label
    const query = Utils.buildQueryString({
        page: 1,
        pageSize: 25,
        orderId: this.order.orderID,
      }),
      [labels] = await Utils.try(this.shippingLabelsRepository.getAll(query));
    // If we have the label save label and change display
    // if not we return null
    const la = labels?.labels.find((l) => !l.voided);
    if (la && la.labelId && la.labelDownload.href) {
      this.juniperFormLoaded = true;
      this.shippingLabel = la;
      this.onSave();
      return true;
    } else if (!labels) {
      this.createToastError({
        message: "Error checking for shipping label.",
      });
    }
    return null;
  }

  private async cancelShippingLabel() {
    this.cancelShippingLoading = true;
    // cancel a shipping label by labelID
    const [label] = await Utils.try(
      this.shippingLabelsRepository.void(this.shippingLabel.labelId)
    );

    // if  we cancled the label clear the saved data and change the state back to edit
    if (label && label.approved && this.shippingLabel.packageCode) {
      await this.getShipRates(
        this.shippingLabel.shipmentId,
        this.shippingLabel.packageCode
      );
      this.shippingLabel = new LabelDto();
      this.onEdit();
      this.createToastSuccess({
        message: "Successfully canceled shipping label.",
      });
    } else {
      this.createToastError({
        message: label?.message || "Error in canceling shipping label.",
      });
    }

    this.cancelShippingLoading = false;
    this.closeCancelShippingLabelModal();
  }

  private async buyShippingLabel() {
    // buy a shipping label with selected rateId
    this.buyLabelLoading = true;
    const [label, err] = await Utils.try(
      this.shippingLabelsRepository.post(this.rateId)
    );

    if (label) {
      // if label was purchased set and toggle to view state
      this.shippingLabel = label;
      this.onSave();
      this.createToastSuccess({
        message: "Shipping label purchased.",
      });
    } else {
      // throw error if shipping label failed
      err &&
        (this.carrierError = JSON.parse(
          JSON.parse(err?.response?.data.errors[0].message).errors[0].message
        ).errors[0].message);
      err && this.closeBuyShippingLabelModal();
      err && this.openCarrierErrorModal();
      this.createToastError({
        message: "Error purchasing shipping label.",
      });
    }
    this.buyLabelLoading = false;
    this.closeBuyShippingLabelModal();
  }

  private async validateShipWithJuniper() {
    const shipWithJuniperRef = this.$refs.shipWithJuniperRef as InstanceType<
      typeof ShipWithJuniperEdit
    >;
    const res = await shipWithJuniperRef.$refs.form.validate();
    return res.valid;
  }

  private async validateShipOnYourOwn() {
    const shipOnYourOwnRef = this.$refs.shipOnYourOwnRef as InstanceType<
      typeof ShipOnYourOwnEdit
    >;
    const res = await shipOnYourOwnRef.$refs.form.validate();
    return res.valid;
  }

  // TODO: need to pass in contact info from order obj and brandSettings
  private createShipAddress(address: AddressDto, manuf = false) {
    if (!this.showShipOnYourOwn) {
      if (manuf) {
        const a = address;
        !a.name && (a.name = this.profileBrand.sellerName);
        !a.contact?.phone && (a.name = this.profileBrand.contact.phone);
        return ShipAddressMap(a);
      } else {
        const a = address;
        !a.name && (a.name = this.order.customerName);
        !a.contact?.phone && (a.name = this.order.contact.phone);
        return ShipAddressMap(a);
      }
    } else {
      return ShipAddressMap(address);
    }
  }

  private onCreateShipment(data?: shipReturnTypes) {
    if (this.shipments.length < 7) {
      if (this.shipments.length) this.deactivateCurrentTab();
      this.shipmentNum++;
      this.shipments.push({
        name: `Shipment ${this.shipmentNum}`,
        // TODO: logic will need to change when we can create multiple shipments
        data: this.generateShipmentObj(data),
        active: true,
      });
      this.backupShipmentsData();
    }
  }

  private onShipmentRemove(tab: TabItem<shipTypes>) {
    const i = this.shipments.indexOf(tab);
    this.shipments.splice(i, 1);
    // if shipment tab is first in array activate index after instead of before.
    this.shipments[i === 0 ? i + 1 : i - 1].active = true;
  }

  private setCurrentShipment(tab: TabItem<shipTypes>) {
    this.deactivateCurrentTab();
    const i = this.shipments.indexOf(tab);
    this.shipments[i].active = true;
  }

  private deactivateCurrentTab() {
    const i = this.shipments.findIndex((t) => t.active);
    this.shipments[i].active = false;
  }

  private onShipmentUpdate(tab: TabItem<shipTypes>) {
    const i = this.shipments.indexOf(tab);
    this.shipments[i] = tab;
  }

  private backupShipmentsData() {
    this.backupDataService.saveBackup(this, this.shipments);
  }

  private restoreShipmentsData() {
    this.backupDataService.restoreBackup(this, this.shipments);
  }

  private onEdit() {
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onSave() {
    this.status = EditableBoxStatusTypes.View;
  }

  private closeDeleteModal() {
    this.showDeleteModal = false;
  }

  private openDeleteModal() {
    this.showDeleteModal = true;
  }

  private closeBuyShippingLabelModal() {
    this.showBuyShippingLabelModal = false;
  }

  private openBuyShippingLabelModal() {
    this.showBuyShippingLabelModal = true;
  }

  private closeCancelShippingLabelModal() {
    this.showCancelShippingLabelModal = false;
  }

  private openCancelShippingLabelModal() {
    this.showCancelShippingLabelModal = true;
  }

  private openCarrierErrorModal() {
    this.showCarrierErrorModal = true;
  }

  private closeCarrierErrorModal() {
    this.showCarrierErrorModal = false;
  }

  get orderHasInvoice(): boolean {
    return Boolean(this.order?.invoices.length);
  }

  get orderNotApproved() {
    return (
      this.order.catalogDetail.orderStatus !== this.OrderStatusTypes.Approved
    );
  }

  get editDisabled() {
    const shouldDisabledEdit =
      this.order.catalogDetail.orderStatus !== this.OrderStatusTypes.Approved ||
      this.disableForm;
    if (!this.showShipOnYourOwn) {
      return shouldDisabledEdit;
    } else {
      return shouldDisabledEdit || this.orderHasInvoice;
    }
  }
}
