
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { AdminButton } from "@/components";

@Options({
  components: {
    AdminButton,
  },
})
export default class NoResultsForFilter extends Vue {
  @Prop({
    type: Function,
    required: true,
  })
  onGoBack!: () => void;
}
