import { EventDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingLabelTrackingDto {
  @JsonProperty() trackingNumber!: string | null;
  @JsonProperty() statusCode!: string | null;
  @JsonProperty() statusDescription!: string | null;
  @JsonProperty() carrierStatusCode!: string | null;
  @JsonProperty() carrierStatusDescription!: string | null;
  @JsonProperty() shipDate!: string | null;
  @JsonProperty() estimatedDeliveryDate!: string | null;
  @JsonProperty() actualDeliveryDate!: string | null;
  @JsonProperty() exceptionDescription!: string | null;
  @JsonProperty({ type: EventDto }) events!: EventDto[];
}
