export enum PublishValidationSubTypes {
  SellerName = "SellerName",
  SellerBiography = "SellerBiography",
  Industry = "Industry",
  IndustryCategory = "IndustryCategory",
  CustomerContactEmail = "CustomerContactEmail",
  Contact = "Contact",
  OrderMinimum = "OrderMinimum",
  ReOrderMinimum = "ReOrderMinimum",
  ImagesUploaded = "ImagesUploaded",
  MSRP = "MsrpPriceLevel",
}
