
import { mixins, Options } from "vue-class-component";
import { ProvideReactive, Watch } from "vue-property-decorator";
import { inject } from "inversify-props";
import {
  ErrorHandlerService,
  HttpClientService,
  OidcService,
} from "@/services";
import { ErrorBoundary, Icon, SideNav } from "@/components";
import { navigationConfig, oidcConfig } from "@/config";
import type {
  IBrandSettingsRepository,
  IFeatureFlag,
  IOidcState,
  ToastConfig,
} from "@/types";
import {
  AuthTypes,
  EventTypes,
  FeatureFlagTypes,
  IExtendedAxiosError,
  IFeatureFlagService,
  MainRouteNameTypes,
  MainRouteTypes,
  ManufacturerDetailsDto,
  OidcEventTypes,
  ProfileBrandDto,
} from "@/types";
import { JunToastConfig } from "@juniper/ui";
import { namespace } from "vuex-class";
import { Utils } from "@/utils";
import { AuthMixin } from "@/mixins";
import { dashboardDefaultRoute, settingsDefaultRoute } from "./router";
import { RouteRecordNormalized } from "vue-router";
import * as Sentry from "@sentry/vue";
import { OriginationsTypes } from "./types";

const manufacturers = namespace("manufacturersVuexModule");
const notifications = namespace("notificationsVuexModule");
const auth = namespace("authVuexModule");
const seller = namespace("sellerVuexModule");

@Options({
  components: {
    ErrorBoundary,
    SideNav,
    Icon,
  },
})
export default class App extends mixins(AuthMixin) {
  @inject() private httpClientService!: HttpClientService;
  @inject() private featureFlagService!: IFeatureFlagService;
  @inject() public oidcService!: OidcService;
  @inject() public brandSettingsRepository!: IBrandSettingsRepository;
  @inject() private errorHandlerService!: ErrorHandlerService;

  @auth.Getter private isAuthenticated!: boolean;
  @auth.Getter private isMultiline!: boolean | null;
  @seller.Action private initCredit!: () => Promise<void>;

  @notifications.Getter private toasts!: JunToastConfig[];
  @notifications.Mutation private createToastError!: (
    payload: ToastConfig
  ) => void;
  @notifications.Mutation private createToastWarning!: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOff!: () => void;
  @notifications.Action private handleAutoPublishOn!: () => void;

  @manufacturers.Getter public isDivisionsSetUp!: boolean;
  @manufacturers.Getter private isDirectSeller!: boolean;
  @manufacturers.Getter private isLive!: boolean;
  @manufacturers.Getter private autoPublish!: boolean;
  @manufacturers.Getter private manufacturerId!: number | null;
  @manufacturers.Getter private manufacturersDetails!:
    | ManufacturerDetailsDto[]
    | undefined;
  @manufacturers.Getter private availableManufacturerIds!: number[] | null;

  @ProvideReactive() private dashboardFlag = false;
  @ProvideReactive() private shipWithJuniperFlag = false;
  @ProvideReactive() private agencyHideFieldsFlag = false;
  @ProvideReactive() private agencyOrderWorkflowEnabled = false;
  @ProvideReactive() private msrpRequiredFlag = false;
  @ProvideReactive() private defaultTermsFlag = false;
  @ProvideReactive() private contactEmailFieldsFlag = false;
  @ProvideReactive() private upsInvoiceConnectionFlag = false;
  @ProvideReactive() private freeShippingPromoFlag: boolean | null = null;
  @ProvideReactive() private profileBrand: ProfileBrandDto =
    new ProfileBrandDto();

  private isOidcAuthenticated = false;

  created() {
    this.oidcService.initialize(oidcConfig);
    const isHandshakeLogin = window.localStorage.getItem("isHandshakeLogin");

    if (!isHandshakeLogin) {
      this.subscribeToOidcEvents();
    }
  }

  async mounted() {
    let authToken = window.localStorage.getItem("authToken");
    const isHandshakeLogin = window.localStorage.getItem("isHandshakeLogin");

    this.subscribeToHttpErrorsEvents();

    // For when page gets refreshed and is handshake login
    if (isHandshakeLogin && authToken) {
      authToken = authToken.replace("Bearer ", "");
      await this.handleAuthCredentials(authToken, AuthTypes.Handshake, true);
    }

    this.subscribeToFeatureFlagEvents();

    await this.featureFlagService.initialize([FeatureFlagTypes.Dashboard]);
    this.subscribeToManufacturerIdReload();
  }

  private onFlagChanged(flag: IFeatureFlag) {
    switch (flag.id) {
      case FeatureFlagTypes.UPSInvoiceConnection:
        this.upsInvoiceConnectionFlag = flag.value;
        break;
      case FeatureFlagTypes.ContactEmailFields:
        this.contactEmailFieldsFlag = flag.value;
        break;
      case FeatureFlagTypes.ShipWithJuniper:
        this.shipWithJuniperFlag = flag.value;
        break;
      case FeatureFlagTypes.AgencyHiddenFields:
        this.agencyHideFieldsFlag = flag.value;
        break;
      case FeatureFlagTypes.AgencyOrderWorkflow:
        this.agencyOrderWorkflowEnabled = flag.value;
        break;
      case FeatureFlagTypes.Dashboard: {
        this.dashboardFlag = flag.value;
        if (flag.value) {
          this.$router.addRoute(dashboardDefaultRoute);
          if (this.$route.name === MainRouteNameTypes.Default) {
            this.$router.replace(dashboardDefaultRoute);
          }
        } else {
          this.$router.addRoute(settingsDefaultRoute);
          if (this.$route.name === MainRouteNameTypes.Default) {
            this.$router.replace(settingsDefaultRoute);
          }
        }
        break;
      }
      case FeatureFlagTypes.MSRPRequired: {
        this.msrpRequiredFlag = flag.value;
        break;
      }
      case FeatureFlagTypes.DefaultTerms: {
        this.defaultTermsFlag = flag.value;
        break;
      }
      case FeatureFlagTypes.FreeShippingPromo: {
        this.freeShippingPromoFlag = flag.value;
        break;
      }
      default:
        break;
    }
  }

  private get navConfig() {
    if (!this.isDivisionsSetUp) {
      return [];
    }

    return navigationConfig.filter((configItem) => {
      if (
        !this.dashboardFlag &&
        configItem.mainRouteId === MainRouteTypes.Dashboard
      ) {
        return false;
      }

      const route = this.$router
        .getRoutes()
        .find(
          (route: RouteRecordNormalized) =>
            route.meta?.mainRouteId === configItem.mainRouteId
        );

      if (!route) return false;
      return !Utils.routeIsRestricted(route?.meta?.restrictions);
    });
  }

  private get hasAccess() {
    const isHandshakeLogin = window.localStorage.getItem("isHandshakeLogin");
    const authTokenExists = window.localStorage.getItem("authToken");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const isPostLogoutRedirect = Boolean(params.isPostLogoutRedirect);
    return Boolean(
      (this.isOidcAuthenticated ||
        this.$route.meta.isPublic ||
        (isHandshakeLogin && authTokenExists)) &&
        !isPostLogoutRedirect
    );
  }

  private get hideLayout() {
    return this.$route.meta.hideLayout;
  }

  //TODO: This can't be a computed prop. This must be refactored
  private get canInitCredit(): boolean {
    // Make sure manufacturerId, isMultiline are loaded, and credit is enabled before we init credit
    return Boolean(
      Number.isFinite(this.manufacturerId) && this.isMultiline === false
    );
  }

  private handlePublishWarning() {
    if (this.isLive && !this.autoPublish) {
      this.handleAutoPublishOff();
    } else {
      this.handleAutoPublishOn();
    }
  }

  private async handleOidcAuth(newOidcState: IOidcState) {
    this.isOidcAuthenticated = newOidcState.isAuthenticated;
    if (
      newOidcState.isAuthenticated &&
      newOidcState.accessToken &&
      newOidcState.user &&
      !Utils.isCurrentRoute(MainRouteTypes.HandshakeSignOut)
    ) {
      await this.handleAuthCredentials(
        newOidcState.accessToken,
        AuthTypes.Oidc,
        true
      );
    } else if (!newOidcState.isAuthenticated) {
      await this.oidcService.signOut();
    }
  }

  @Watch("manufacturerId")
  onManufacturerIdChange(newId: string | null, prevId: string | null) {
    if (
      !Utils.isCurrentRoute(MainRouteTypes.HandshakeLogin) &&
      prevId !== null
    ) {
      this.getManufacturerContent();
    }

    this.checkIfRouteRestrictedAndRedirect();

    if (Number.isFinite(prevId) && this.canInitCredit) {
      // Re-init credit on manufacturerId change
      this.initCredit();
    }

    // close or open warning depending on manufacturer status
    this.handlePublishWarning();
  }

  @Watch("manufacturersDetails")
  async onManufacturerDetailsChange(
    newDetails: ManufacturerDetailsDto | undefined,
    prevDetails: ManufacturerDetailsDto | undefined
  ) {
    if (this.hasAvailableManufacturer) {
      await this.fetchBrandSettings();
    }

    if (!prevDetails) {
      this.handlePublishWarning();
    }
  }

  @Watch("isDirectSeller")
  onDirectSellerChange() {
    this.checkIfRouteRestrictedAndRedirect();
  }

  @Watch("isMultiline")
  onIsMultilineChange() {
    this.checkIfRouteRestrictedAndRedirect();
  }

  @Watch("canInitCredit", { immediate: true })
  async onCanInitCreditChange(canInitCredit: boolean): Promise<void> {
    if (!canInitCredit) return;
    await this.initCredit();
  }

  private checkIfRouteRestrictedAndRedirect() {
    if (Utils.routeIsRestricted(this.$route.meta.restrictions)) {
      this.$router.replace("/");
    }
  }

  private handleHttpError(err: IExtendedAxiosError, label: string) {
    const toastConfig = this.buildToastConfig(err, label);
    if (toastConfig) this.createToastError(toastConfig);
  }

  private subscribeToHttpErrorsEvents(): void {
    this.httpClientService.on(
      EventTypes.NetworkServerError,
      this.handleHttpError
    );
    this.httpClientService.on(EventTypes.BadRequest, this.handleHttpError);
    this.httpClientService.on(EventTypes.Unauthorized, this.handleHttpError);
    this.httpClientService.on(EventTypes.NotFound, this.handleHttpError);
    this.httpClientService.on(EventTypes.ServerError, this.handleHttpError);
    this.httpClientService.on(EventTypes.TokenExpired, () => {
      this.userSignOut();
    });
  }

  private subscribeToFeatureFlagEvents(): void {
    this.featureFlagService.on(
      EventTypes.FeatureFlagChange,
      this.onFlagChanged
    );
    this.featureFlagService.on(EventTypes.FeatureFlagError, (err: unknown) => {
      // TODO: Turn off all flags.
      console.log(err);
    });
  }

  private subscribeToManufacturerIdReload(): void {
    addEventListener("storage", (event) => {
      if (
        event.key === "manufacturerId" &&
        Number(event.newValue) &&
        !this.availableManufacturerIds?.includes(Number(event.newValue))
      ) {
        window.location.reload();
      }
    });
  }

  private subscribeToOidcEvents(): void {
    this.oidcService.on(
      OidcEventTypes.StateChanged,
      (newOidcState: IOidcState) => {
        this.handleOidcAuth(newOidcState);
      }
    );

    this.oidcService.on(
      OidcEventTypes.Error,
      (methodName?: string, error?: Error, state?: IOidcState) => {
        Sentry.captureMessage(
          `OidcError: ${methodName ?? ""} - ${error?.toString?.() ?? ""} - ${
            state ? JSON.stringify(state, null, 2) : ""
          }`
        );
      }
    );

    // this.oidcService.on(OidcEventTypes.UserSignedOut, () => {
    //   if (process.env.VUE_APP_ENV !== "localhost") {
    //     this.userSignOut();
    //   }
    // });
    //TODO: Please do not delete this, it is for testing purposes
    //   this.oidcService.on(OidcEventTypes.AccessTokenExpiring, () =>
    //     console.log("OIDC AccessTokenExpiring")
    //   );
    //   this.oidcService.on(OidcEventTypes.AccessTokenExpired, () =>
    //     console.log("OIDC AccessTokenExpired")
    //   );
    //   this.oidcService.on(OidcEventTypes.SilentRenewError, () =>
    //     console.log("OIDC SilentRenewError")
    //   );
    //   this.oidcService.on(OidcEventTypes.UserSessionChanged, () =>
    //     console.log("OIDC UserSessionChanged")
    //   );
    //   this.oidcService.on(OidcEventTypes.UserUnloaded, () =>
    //     console.log("OIDC UserUnloaded")
    //   );
    //   this.oidcService.on(OidcEventTypes.UserSessionChanged, () =>
    //     console.log("OIDC UserSignedIn")
    //   );
  }

  //TODO: This is duplicated in App.vue and in SideNav.vue
  private userSignOut() {
    if (window.localStorage.getItem("isHandshakeLogin")) {
      this.$router.push(`/${MainRouteTypes.HandshakeSignOut}`);
    } else {
      this.oidcService.signOut();
    }
  }

  private async fetchBrandSettings(): Promise<void> {
    const [profileBrand] = await Utils.try(this.brandSettingsRepository.get());
    if (profileBrand) this.profileBrand = profileBrand;
  }

  private buildToastConfig(
    error: IExtendedAxiosError,
    label: string
  ): ToastConfig | null {
    const status = error.response?.status || null;
    const suppressToast = Boolean(
      error.config.suppressToast ||
        (typeof status === "number" &&
          error.config.suppressToastOnStatus?.includes(status)) ||
        error.response?.data.origination ===
          OriginationsTypes.AutoPublishFailure ||
        error.response?.data.origination ===
          OriginationsTypes.PaymentTransactionFailure
    );
    const defaultToastConfig: ToastConfig = {
      message: `${label}: Error occurred, please try again later.`,
    };
    const toastConfig: ToastConfig =
      this.errorHandlerService.getHttpErrorToastConfig(error) ??
      defaultToastConfig;
    return suppressToast ? null : toastConfig;
  }
}
