
import { PropType } from "vue";
import { namespace } from "vuex-class";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { AdminDatePicker } from "@/components";
import { Utils } from "@/utils";
import { DatePickerAlignmentTypes } from "@/types";
import type { IDateRange, ToastConfig } from "@/types";

const notifications = namespace("notificationsVuexModule");

@Options({
  name: "AdminDateRange",
  components: {
    AdminDatePicker,
  },
  emits: [
    "update:modelValue",
    "dateRangeUpdated",
    "fromDateUpdated",
    "toDateUpdated",
  ],
})
export default class AdminDateRange extends Vue {
  @Prop({
    type: Object as PropType<IDateRange>,
    required: true,
  })
  modelValue!: IDateRange;

  @Prop({
    type: Boolean as PropType<boolean>,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: [Object, null] as PropType<Date | null>,
    default: null,
  })
  earliestAvailableDate!: Date | null;

  @Prop({
    type: [Object, null] as PropType<Date | null>,
    default: null,
  })
  latestAvailableDate!: Date | null;

  @notifications.Mutation private createToastWarning?: (
    payload: ToastConfig
  ) => void;

  private DatePickerAlignmentTypes = DatePickerAlignmentTypes;

  @Watch("modelValue", { deep: true })
  private onModelValueChange(newModalValue: IDateRange): void {
    this.$emit("update:modelValue", newModalValue);
    this.handleDateRangeUpdate();
  }

  private get fromDateValue(): string {
    return this.modelValue.from.toISOString();
  }

  private get toDateValue(): string {
    return this.modelValue.to.toISOString();
  }

  private onFromDateChange(newFromDate: string): void {
    if (Date.parse(newFromDate) > Date.now()) {
      return this.createToastWarning?.({
        message: "From date can't be later than current date",
      });
    } else if (Date.parse(newFromDate) > this.modelValue.to.getTime()) {
      return this.createToastWarning?.({
        message: "From date can't be later than to date",
      });
    }
    const fromDate = new Date(newFromDate);
    this.modelValue.from = fromDate;
    this.$emit("fromDateUpdated", fromDate);
    this.closeDatePicker("from");
  }

  private onToDateChange(newToDate: string): void {
    if (Date.parse(newToDate) > Date.now()) {
      return this.createToastWarning?.({
        message: "To date can't be later than current date",
      });
    } else if (Date.parse(newToDate) < this.modelValue.from.getTime()) {
      return this.createToastWarning?.({
        message: "To date can't be earlier than from date",
      });
    }
    const toDate = new Date(
      Utils.roundToNearestDate(new Date(newToDate)).getTime() +
        (1000 * 60 * 60 * 24 - 1) // cover selected to date up to 23:59:59
    );
    this.modelValue.to = toDate;
    this.$emit("toDateUpdated", toDate);
    this.closeDatePicker("to");
  }

  private closeDatePicker(side: "from" | "to"): void {
    const datePicker = (
      side === "from" ? this.$refs.fromDatePicker : this.$refs.toDatePicker
    ) as typeof AdminDatePicker;
    if (datePicker && datePicker.toggleCalendar) {
      datePicker.toggleCalendar(false);
    }
  }

  private handleDateRangeUpdate(): void {
    this.$emit("dateRangeUpdated", {
      from: this.modelValue.from,
      to: this.modelValue.to,
    } as IDateRange);
  }
}
