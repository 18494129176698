import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-652546fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-container" }
const _hoisted_2 = { class: "box-title" }
const _hoisted_3 = { class: "box-actions" }
const _hoisted_4 = {
  key: 1,
  class: "box-tabs"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 2,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunSpinner = _resolveComponent("JunSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["box-bar", { 'shadow-box': !_ctx.noShadow }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.title), 1),
            _renderSlot(_ctx.$slots, "tag", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "actions", {
              tab: _ctx.tabItems.find((t) => t.active)
            }, undefined, true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.tabItems.length > 1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabItems, (tab) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass({ active: tab.active }),
              key: tab.name,
              onClick: ($event: any) => (_ctx.setCurrent(tab))
            }, _toDisplayString(tab.name), 11, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_JunSpinner, {
            class: "loading-spinner",
            size: "3rem"
          })
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass({ 'slot-wrapper': !_ctx.noPadding })
        }, [
          _renderSlot(_ctx.$slots, "content", {
            tab: _ctx.tabItems.find((t) => t.active)
          }, undefined, true)
        ], 2))
  ]))
}