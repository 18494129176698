export enum FeatureFlagTypes {
  Dashboard = "dashboard",
  ShipWithJuniper = "shipWithJuniper",
  AgencyHiddenFields = "agencyHiddenFields",
  MSRPRequired = "msrpRequired",
  DefaultTerms = "defaultTerms",
  AgencyOrderWorkflow = "agencyOrderWorkflow",
  ContactEmailFields = "contactEmailFields",
  UPSInvoiceConnection = "upsInvoiceConnection",
  FreeShippingPromo = "freeShippingPromo",
}
