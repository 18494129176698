import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f5419b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "py-6" }
const _hoisted_2 = { class: "modal-title" }
const _hoisted_3 = {
  key: 0,
  class: "modal-buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminButton = _resolveComponent("AdminButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.showModal,
    disableClickaway: _ctx.disableClickaway,
    onCloseModal: _ctx.onCloseClick
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true),
        (_ctx.closeOnly)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_AdminButton, {
                onClick: _ctx.onCloseClick,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.closeText), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['modal-buttons', { shadow: !_ctx.noFooterShadow }])
            }, [
              _createVNode(_component_AdminButton, {
                onClick: _ctx.onNoClick,
                inactive: _ctx.cancelDisabled || _ctx.loading,
                color: "outline",
                class: "mr-4",
                "data-test-id": "modal-secondary-action"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                ]),
                _: 1
              }, 8, ["onClick", "inactive"]),
              _createVNode(_component_AdminButton, {
                onClick: _ctx.onYesClick,
                inactiveFilled: _ctx.confirmDisabled || _ctx.loading,
                loading: _ctx.loading,
                color: "primary",
                "data-test-id": "modal-primary-action"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                ]),
                _: 1
              }, 8, ["onClick", "inactiveFilled", "loading"])
            ], 2))
      ])
    ]),
    _: 3
  }, 8, ["open", "disableClickaway", "onCloseModal"]))
}