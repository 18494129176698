import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cca93600"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-wrapper" }
const _hoisted_2 = { class: "grid-wrapper" }
const _hoisted_3 = { class: "left-panel" }
const _hoisted_4 = { class: "right-panel" }
const _hoisted_5 = { class: "right-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderShippingForm = _resolveComponent("OrderShippingForm")!
  const _component_OrderShippingDetails = _resolveComponent("OrderShippingDetails")!
  const _component_OrderShippingInvoice = _resolveComponent("OrderShippingInvoice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_OrderShippingForm)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_OrderShippingDetails)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_OrderShippingInvoice)
      ])
    ])
  ]))
}