import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class CompanyAddressDto {
  @JsonProperty() addressLine1!: string | null;
  @JsonProperty() country!: string | null;
  @JsonProperty() addressLine2!: string | null;
  @JsonProperty() city!: string | null;
  @JsonProperty() state!: string | null;
  @JsonProperty() zip!: string | null;
}
