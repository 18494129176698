import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66316605"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "input-wrapper" }
const _hoisted_4 = ["placeholder", "type", "name", "id", "value", "disabled", "maxlength", "min", "max", "autocomplete"]
const _hoisted_5 = {
  key: 0,
  class: "limit-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cmp-container', _ctx.cmpWidth])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['cmp-wrapper', { 'hide-arrows': _ctx.hideArrows }])
    }, [
      (!_ctx.hideLabels)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.name,
            class: _normalizeClass({ error: _ctx.error, active: _ctx.isInputActive, disabled: _ctx.disabled })
          }, [
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
              : _createCommentVNode("", true),
            (_ctx.tooltipMsg)
              ? (_openBlock(), _createBlock(_component_Tooltip, {
                  key: 1,
                  msg: _ctx.tooltipMsg
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, {
                      class: "tooltip-icon",
                      name: _ctx.IconNameTypes.Info,
                      size: 1
                    }, null, 8, ["name"])
                  ]),
                  _: 1
                }, 8, ["msg"]))
              : _createCommentVNode("", true)
          ], 10, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          placeholder: _ctx.placeholder,
          class: _normalizeClass({
            error: _ctx.error,
            'with-icon': _ctx.icon,
            'with-clearable': _ctx.clearable,
            'mt-0': _ctx.hideLabels,
          }),
          type: _ctx.type,
          name: _ctx.name,
          id: _ctx.name,
          value: _ctx.field.inputValue,
          disabled: _ctx.disabled,
          maxlength: _ctx.maxLength,
          min: _ctx.min,
          max: _ctx.max,
          autocomplete: _ctx.disableAutocomplete ? 'off' : 'on',
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputChange && _ctx.onInputChange(...args))),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputBlur && _ctx.onInputBlur(...args))),
          onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInputFocus && _ctx.onInputFocus(...args))),
          ref: "inputRef"
        }, null, 42, _hoisted_4),
        (_ctx.innerLabel)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(['input-inner-label', { 'hide-labels': !_ctx.hideLabels }])
            }, _toDisplayString(_ctx.innerLabel), 3))
          : _createCommentVNode("", true),
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              name: _ctx.icon,
              size: 1,
              color: _ctx.isInputActive ? '#000' : '#777',
              class: _normalizeClass(['input-icon', { 'hide-labels': _ctx.hideLabels }])
            }, null, 8, ["name", "color", "class"]))
          : _createCommentVNode("", true),
        (_ctx.clearable && _ctx.modelValue !== null && _ctx.modelValue.length > 0)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 2,
              name: _ctx.IconNameTypes.CloseV2,
              size: 1,
              color: _ctx.isInputActive ? '#000' : '#777',
              onClick: _ctx.clearInput,
              class: _normalizeClass(['input-close-icon', { 'hide-labels': _ctx.hideLabels }])
            }, null, 8, ["name", "color", "onClick", "class"]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (!_ctx.hideLabels)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.messageContainerClass)
        }, [
          (_ctx.showCharLimit)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.charLimitMsg), 1))
            : _createCommentVNode("", true),
          _createVNode(_Transition, { name: "slide-up" }, {
            default: _withCtx(() => [
              (!_ctx.hideMsgBox)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['cmp-message', { error: _ctx.error }])
                  }, _toDisplayString(_ctx.error ? _ctx.errorMessage : _ctx.msg), 3))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}