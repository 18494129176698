import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ProfileDto {
  @JsonProperty() emailAddress!: string;
  @JsonProperty() profileId!: string;
  @JsonProperty() firstName!: string;
  @JsonProperty() lastName!: string;
  @JsonProperty() buyerName!: string;
  @JsonProperty() phone!: string;
  @JsonProperty() fax!: string;
  @JsonProperty() profileIdentityProvider!: string;
  @JsonProperty() externalProfileId!: string;
  @JsonProperty() buyerReferenceId!: string;
}
