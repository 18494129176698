import { JsonProperty, Serializable } from "typescript-json-serializer";
import {
  CountryDto,
  ProvinceDto,
  AddressTypeDto,
  PricingLevelDto,
  CreditRefundReasonDto,
} from "@/types";

@Serializable()
export class StaticDto {
  @JsonProperty({ type: CountryDto }) countries!: CountryDto[];
  @JsonProperty({ type: ProvinceDto }) usStates!: ProvinceDto[];
  @JsonProperty({ type: ProvinceDto }) caProvinces!: ProvinceDto[];
  @JsonProperty({ type: AddressTypeDto }) processorTypes!: AddressTypeDto[];
  @JsonProperty({ type: PricingLevelDto }) pricingLevels!: PricingLevelDto[];
  @JsonProperty({ type: AddressTypeDto }) userDefinedFields!: AddressTypeDto[];
  @JsonProperty({ type: AddressTypeDto }) addressTypes!: AddressTypeDto[];
  @JsonProperty({ type: AddressTypeDto }) appUserStatuses!: AddressTypeDto[];
  @JsonProperty({ type: AddressTypeDto })
  appAccessRequestStatuses!: AddressTypeDto[];
  @JsonProperty({ type: CreditRefundReasonDto })
  juniperCreditRefundReasons!: Array<CreditRefundReasonDto> | null;
  @JsonProperty({ type: AddressTypeDto }) weightUnits!: AddressTypeDto[];
}
