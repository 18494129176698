
import { Options, Vue } from "vue-class-component";
import { Prop, Watch, Emit } from "vue-property-decorator";
import { AdminButton, Icon } from "@/components";
import type { DrawerSubHeading } from "@/types";

@Options({
  components: {
    AdminButton,
    Icon,
  },
  emits: ["close-drawer"],
})
export default class Drawer extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  open!: boolean;

  @Prop({
    type: Number,
    default: 40,
  })
  width!: number;

  @Prop({
    type: String,
    default: null,
  })
  heading!: string;

  @Prop({
    type: Object,
    default: null,
  })
  subHeading!: DrawerSubHeading;

  @Prop({
    type: String,
    default: "Close",
  })
  primaryActionButtonText!: string;

  @Prop({
    type: [Function, null],
    default: null,
  })
  primaryAction!: Function | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  disablePrimaryAction!: boolean;

  @Prop({
    type: String,
    default: "Close",
  })
  secondaryActionButtonText!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disableSecondaryAction!: boolean;

  @Prop({
    type: [Function, null],
    default: null,
  })
  secondaryAction!: Function | null;

  @Prop({
    type: Number,
    default: 14,
  })
  actionButtonWidth!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  showCloseIcon!: boolean;

  @Watch("open")
  onOpenChanged(val: boolean) {
    this.showDrawer = val;
  }

  private showDrawer = this.open;

  private handlePrimaryAction() {
    if (this.primaryAction) {
      return this.primaryAction();
    }
    this.closeDrawer();
  }

  @Emit()
  closeDrawer() {
    this.showDrawer = false;
  }
}
