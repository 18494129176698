import { Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import { inject } from "inversify-props";
import { SegmentService } from "@/services";
import { AuthTypes, MainRouteTypes, IAuthCredentialsConfig } from "@/types";

const manufacturers = namespace("manufacturersVuexModule");
const staticContent = namespace("staticContentVuexModule");
const auth = namespace("authVuexModule");

export class AuthMixin extends Vue {
  @inject() public segmentService!: SegmentService;

  @manufacturers.Getter public hasAvailableManufacturer!: boolean;
  @manufacturers.Action public initManufacturers!: () => Promise<void>;

  @staticContent.Action public getStaticContent!: () => Promise<void>;
  @staticContent.Action public getManufacturerContent!: () => Promise<void>;

  @auth.Getter public userName!: string;
  @auth.Getter public email!: string;
  @auth.Getter public companyId!: string;

  @auth.Action public setAuthCredentials!: (
    config: IAuthCredentialsConfig
  ) => Promise<void>;
  async handleAuthCredentials(
    authToken: string,
    authType: AuthTypes,
    shouldSetupManufacturers: boolean,
    redirectPath?: string
  ) {
    await this.setAuthCredentials({ authType, authToken });
    this.segmentService.identifyUser(this.email, this.userName, this.companyId);

    if (shouldSetupManufacturers) {
      await this.getStaticContent();
      await this.initManufacturers();

      if (this.hasAvailableManufacturer) {
        await this.getManufacturerContent();

        if (redirectPath) {
          await this.$router.push(redirectPath);
        }
      } else {
        await this.$router.push(`/${MainRouteTypes.NoDivisionsSetUp}`);
      }
    }
  }
}
