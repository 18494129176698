
import { Options, Vue } from "vue-class-component";
import { inject } from "inversify-props";
import { OidcService } from "@/services";
import * as Sentry from "@sentry/vue";

@Options({
  components: {},
})
export default class OidcCallback extends Vue {
  @inject() public oidcService!: OidcService;

  async mounted() {
    try {
      const redirectPath = await this.oidcService.signInCallback();
      await this.$router.replace(redirectPath ?? "/");
    } catch (error) {
      Sentry.captureMessage(`OidcCallback: ${error}`);
      await this.$router.push("/oidc-callback-error");
    }
  }
}
