
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Dropdown, AdminInput, Icon } from "@/components";
import { IconNameTypes, DropdownItem, DynamicLabelsItem } from "@/types";
import { VeeFieldMixin } from "@/mixins";

@Options({
  components: {
    Dropdown,
    AdminInput,
    Icon,
  },
  emits: ["update:modelValue"],
})
export default class DynamicLabels extends mixins<
  [VueMixin<VeeFieldMixin<DynamicLabelsItem[]>>]
>(VeeFieldMixin) {
  @Prop({
    type: Array,
    default: [],
  })
  modelValue!: DynamicLabelsItem[];

  @Prop({
    type: Array,
    default: [],
  })
  dropdownItems!: DropdownItem[];

  @Prop({
    type: Number,
    default: null,
  })
  itemLimit!: number | null;

  @Prop({
    type: String,
    default: "",
  })
  name!: string;

  label = null;

  @Watch("modelValue")
  onModelValueChanged(val: DynamicLabelsItem[]) {
    this.field.handleChange(val);
  }

  @Watch("dropdownItems")
  onDropdownItemsChanged(items: DropdownItem[]) {
    // available dropdown items can change from parent. a UDF selected for Product Description
    // won't be valid here so we need to search and remove any field set to it
    const itemValues = items.map((item) => item.value);
    this.modelValue.forEach((val, index) => {
      if (!itemValues.includes(val.fieldName || "")) {
        this.modelValue[index].fieldName = null;
      }
    });
  }

  private IconNameTypes = IconNameTypes;

  get addDisabled() {
    if (this.itemLimit === null) {
      return this.modelValue.length === this.dropdownItems.length;
    } else {
      return this.modelValue.length === this.itemLimit;
    }
  }

  availableDropdownItems(index: number) {
    return this.dropdownItems.filter((item) => {
      const currentSelections = this.modelValue.map((x) => x.fieldName);
      // show option if not already selected somewhere else OR if that is the option
      // already currently selected in this dropdown
      return (
        !currentSelections.includes(item.value) ||
        currentSelections[index] === item.value
      );
    });
  }

  addRow() {
    if (this.addDisabled) {
      return;
    }

    this.$emit("update:modelValue", [
      ...this.modelValue,
      { fieldName: null, label: null },
    ]);
  }

  deleteRow(index: number) {
    if (this.modelValue.length === 1) {
      return;
    }

    this.$emit(
      "update:modelValue",
      this.modelValue.filter((item, idx) => index !== idx)
    );
  }
}
