import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = { class: "table-wrapper h-256" }
const _hoisted_3 = {
  key: 0,
  "data-test-id": "order-id-cell"
}
const _hoisted_4 = {
  key: 1,
  "data-test-id": "invoice-number-cell"
}
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NoResultsForFilter = _resolveComponent("NoResultsForFilter")!
  const _component_JunTable = _resolveComponent("JunTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_JunTable, {
        heading: _ctx.tableConfig.heading,
        headers: _ctx.tableConfig.headers,
        options: _ctx.tableConfig.options,
        loading: _ctx.tableConfig.loading,
        items: _ctx.tableConfig.items,
        itemKey: _ctx.tableConfig.itemKey,
        onSort: _ctx.onSort,
        onFilter: _ctx.onFilter,
        onPaginate: _ctx.onPaginate,
        showSelect: true,
        modelValue: _ctx.selectedItems,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event))
      }, _createSlots({
        default: _withCtx(({ item, header }) => [
          (header.prop === 'orderId' || header.prop === 'brandOrderId')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: 
                _ctx.isMultiline
                  ? `/${_ctx.MainRouteTypes.OrderDetails}/${_ctx.SubRouteTypes.Summary}?guid=${item.orderGuid}&brand=${item.brandId}`
                  : `/${_ctx.MainRouteTypes.OrderDetails}/${_ctx.SubRouteTypes.Summary}?guid=${item.orderGuid}`
              
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isMultiline ? item.brandOrderId : item.orderId), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            : _createCommentVNode("", true),
          (header.prop === 'invoiceNumber')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_router_link, {
                  to: `/${_ctx.MainRouteTypes.InvoiceDetails}/${item.invoiceId}`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.invoiceNumber), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            : _createCommentVNode("", true),
          (header.prop === 'orderStatus')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.orderStatus ?? "New"), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (_ctx.tableOptions.filters?.length)
          ? {
              name: "noresults",
              fn: _withCtx(() => [
                _createVNode(_component_NoResultsForFilter, { onGoBack: _ctx.onNoFilterResultsGoBack }, null, 8, ["onGoBack"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["heading", "headers", "options", "loading", "items", "itemKey", "onSort", "onFilter", "onPaginate", "modelValue"])
    ])
  ]))
}