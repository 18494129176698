
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import { debounce, DebouncedFunc } from "lodash";
import { Prop } from "vue-property-decorator";
import { JunToastConfig } from "@juniper/ui";
import {
  Icon,
  Tooltip,
  AdminCollapsible,
  InfoList,
  AdminButton,
  Switch,
} from "@/components";
import { sellerStatusTextColorConfig } from "@/config";
import {
  PaymentSettingsDto,
  IconNameTypes,
  SellerDto,
  SellerStatusTypes,
  InfoListItem,
} from "@/types";

const DEBOUNCE_WAIT_TIME = 500;

const notifications = namespace("notificationsVuexModule");

@Options({
  name: "JuniperCreditCollapsible",
  components: {
    Icon,
    Tooltip,
    AdminCollapsible,
    InfoList,
    AdminButton,
    Switch,
  },
})
export default class JuniperCreditCollapsible extends Vue {
  @Prop({
    type: [Object, null] as PropType<PaymentSettingsDto | null>,
    default: null,
  })
  private creditCardSetting!: PaymentSettingsDto | null;

  @Prop({
    type: [Object, null] as PropType<SellerDto | null>,
    default: null,
  })
  private seller!: SellerDto | null;

  @Prop({
    type: Boolean as PropType<boolean>,
  })
  private canApplyForCredit!: boolean;

  @Prop({
    type: Function as PropType<() => Promise<void>>,
  })
  private applyForJuniperCredit!: () => Promise<void>;

  @Prop({
    type: Function as PropType<
      (newPaymentSettings: PaymentSettingsDto) => Promise<boolean>
    >,
  })
  private updatePaymentSettings!: (
    newPaymentSettings: PaymentSettingsDto
  ) => Promise<boolean>;

  @notifications.Mutation private createToastSuccess?: (
    config: JunToastConfig
  ) => void;

  private IconNameTypes = IconNameTypes;
  private debouncedHandleToggleJuniperCredit: DebouncedFunc<
    (enabled: boolean) => Promise<void>
  > | null = null;

  async created() {
    await this.init();
  }

  private async init(): Promise<void> {
    this.debouncedHandleToggleJuniperCredit = debounce(
      this.handleToggleJuniperCredit,
      DEBOUNCE_WAIT_TIME
    );
  }

  private async handleToggleJuniperCredit(enabled: boolean) {
    if (!this.creditCardSetting || !this.showCreditToggle) return;
    const payload: PaymentSettingsDto = {
      ...this.creditCardSetting,
      enableJuniperCredit: enabled,
    };
    const updateSuccessful = await this.updatePaymentSettings(payload);
    if (updateSuccessful) {
      this.createToastSuccess?.({
        message: `Juniper Credit is ${enabled ? "enabled" : "disabled"}.`,
      });
    }
  }

  private get sellerIsCreated(): boolean {
    return Boolean(
      this.seller && this.seller.status === SellerStatusTypes.Created
    );
  }

  private get showApplyButton(): boolean {
    return Boolean(
      this.seller &&
        (this.seller.status === SellerStatusTypes.Created ||
          this.seller.status === SellerStatusTypes.Declined)
    );
  }

  private get showCreditToggle(): boolean {
    return Boolean(
      this.seller &&
        (this.seller.status === SellerStatusTypes.Approved ||
          this.seller.status === SellerStatusTypes.Active)
    );
  }

  private get creditToggleLabel() {
    return `JuniperCredit ${
      !this.creditCardSetting || !this.creditCardSetting.enableJuniperCredit
        ? "Disabled"
        : "Enabled"
    } for Buyers on the marketplace`;
  }

  private get disableApplyButton(): boolean {
    return Boolean(
      this.seller &&
        this.seller.status === SellerStatusTypes.Declined &&
        !this.seller.canApplyForCredit
    );
  }

  private get juniperCreditInfoListItems(): Array<InfoListItem> {
    if (!this.seller || !this.seller.status) return [];
    const statusTextColor =
      this.seller.status && sellerStatusTextColorConfig[this.seller.status]
        ? sellerStatusTextColorConfig[this.seller.status]
        : null;
    const items: InfoListItem[] = [
      {
        label: "Application Status",
        text: this.seller.status,
        textColor: statusTextColor,
      },
    ];
    if (
      this.seller.status === SellerStatusTypes.Approved ||
      this.seller.status === SellerStatusTypes.Active
    ) {
      items.push({
        label: "Settlement Terms",
        text: Number.isFinite(this.seller.disburseDays)
          ? `${this.seller.disburseDays} days`
          : "N/A",
      });
    }
    return items;
  }
}
