
import { Options, Vue } from "vue-class-component";
import { InjectReactive, Watch } from "vue-property-decorator";
import { Form } from "vee-validate";
import * as yup from "yup";
import {
  EditableBox,
  Dropdown,
  Switch,
  Tooltip,
  Icon,
  AdminButton,
} from "@/components";
import {
  EditableBoxStatusTypes,
  IconNameTypes,
  PricingSettingsDto,
  PriceLevelDto,
  PricingSettingsDetailsDto,
} from "@/types";
import type {
  IValidateResponse,
  DropdownItem,
  DropdownItemValue,
  ToastConfig,
  IPricingSettingsRepository,
} from "@/types";
import { JunTableItem, JunTableColumn } from "@juniper/ui";
import { inject } from "inversify-props";
import { BackupDataService } from "@/services";
import { namespace } from "vuex-class";
import { Utils } from "@/utils";

const notifications = namespace("notificationsVuexModule");
const staticContent = namespace("staticContentVuexModule");
const publish = namespace("publishVuexModule");
const auth = namespace("authVuexModule");

@Options({
  components: {
    EditableBox,
    Dropdown,
    Switch,
    Tooltip,
    Icon,
    Form,
    AdminButton,
  },
})
export default class PriceMapping extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private pricingSettingsRepository?: IPricingSettingsRepository;
  @InjectReactive() private msrpRequiredFlag!: boolean;

  @auth.Getter private isMultiline!: boolean | null;

  @staticContent.Getter private priceLevelAlias?: (
    val: number
  ) => string | null;

  @staticContent.Getter private priceLevelsDropdownOptions?: DropdownItem[];

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOff!: () => void;

  @publish.Getter private isMsrpValid?: boolean;
  @publish.Mutation private setMsrpAsValid?: () => void;

  private validationSchema!: ReturnType<typeof yup.object>;
  private loading = false;
  private status = EditableBoxStatusTypes.View;
  private pricingSettingsDetails: PricingSettingsDetailsDto =
    new PricingSettingsDetailsDto();
  private IconNameTypes = IconNameTypes;
  private priceLevels: PriceLevelDto[] = [];
  private tableItemKey = "pricing";
  private tableItems: JunTableItem[] = [];
  private tableHeaders!: JunTableColumn[];

  @Watch("msrpRequiredFlag", { immediate: true })
  private onMsrpRrequiredFlagChange(): void {
    this.buildValidationSchema();
    this.buildTableHeaders();
    this.populatePriceMappingData();
  }

  async created() {
    if (!this.isMsrpValid) {
      this.handleInvalidForPublish();
    }
  }

  private buildTableHeaders(): void {
    this.tableHeaders = [
      {
        prop: "pricing",
        text: "Pricing",
        ...(this.msrpRequiredFlag && { width: "80%" }),
      },
      ...(!this.msrpRequiredFlag
        ? [
            {
              prop: "showMSRP",
              text: "Show to unauthenticated buyer?",
              width: "80%",
            },
          ]
        : []),
      {
        prop: "mappedTo",
        text: "Mapped to",
        ...(this.msrpRequiredFlag && { width: "20%" }),
      },
    ];
  }

  private buildValidationSchema(): void {
    this.validationSchema = yup.object().shape({
      defaultPricing: yup.string().nullable().required(),
      ...(this.msrpRequiredFlag && {
        MSRP: yup
          .string()
          .nullable()
          .test((v) =>
            !this.isMultiline ? (v && v?.length > 0) || false : true
          ),
      }),
    });
  }

  @Watch("isMsrpValid")
  onMsrpValidChange(valid: boolean) {
    if (!valid) {
      this.handleInvalidForPublish();
    }
  }

  private async populatePriceMappingData(): Promise<void> {
    this.loading = true;
    const [priceSettings] = await Utils.try(
      this.pricingSettingsRepository?.get()
    );
    if (!priceSettings) return;
    const items = this.buildTableItems(priceSettings);
    this.pricingSettingsDetails = priceSettings.settings;
    this.priceLevels = priceSettings.priceLevels;
    this.tableItems = items;
    this.loading = false;
  }

  private getPriceLevelName(
    priceLevels: PriceLevelDto[],
    id: number | null | undefined
  ) {
    const priceLevel = priceLevels.find((level) => level.value === id);

    if (!priceLevel) {
      return "No Price Level";
    } else {
      return this.priceLevelAlias?.(priceLevel.value) ?? null;
    }
  }

  private buildTableItems({
    settings,
    priceLevels,
  }: PricingSettingsDto): JunTableItem[] {
    return [
      {
        pricing: "MSRP",
        ...(!this.msrpRequiredFlag && {
          showMSRP: settings.showMSRPForNonAuthenticatedUser ? "Yes" : "No",
        }),
        mappedTo: this.getPriceLevelName(priceLevels, settings.msrpPriceLevel),
      },
      {
        pricing: "Default",
        ...(!this.msrpRequiredFlag && { showMSRP: "" }),
        mappedTo: this.getPriceLevelName(
          priceLevels,
          settings.defaultPriceLevel
        ),
      },
    ];
  }

  handleInvalidForPublish() {
    this.status = EditableBoxStatusTypes.Edit;
    // turning status to 'Edit' opens the form, so using nextTick here
    // to ensure the form has been mounted before trying to validate it
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).validate();
    });
  }

  onMsrpDropdownChange(val: DropdownItemValue) {
    if (val === null && this.pricingSettingsDetails) {
      this.pricingSettingsDetails.showMSRPForNonAuthenticatedUser = false;
    }
  }

  get msrpDropdownOptions() {
    return [
      ...(!this.msrpRequiredFlag
        ? [{ name: "No Price Level", value: null }]
        : []),
      ...(this.priceLevelsDropdownOptions || []),
    ];
  }

  private onEditableBoxEdit() {
    this.backupDataService?.saveBackup(this, this.pricingSettingsDetails);
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.backupDataService?.restoreBackup(this, this.pricingSettingsDetails);
    this.status = EditableBoxStatusTypes.View;
  }

  private async onEditableBoxSave() {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();

    if (validateResponse.valid && this.pricingSettingsDetails) {
      try {
        this.status = EditableBoxStatusTypes.Saving;
        await this.pricingSettingsRepository?.put(this.pricingSettingsDetails);
        // refresh table data after change
        await this.populatePriceMappingData();
        this.handleAutoPublishOff();
        this.createToastSuccess?.({
          message: "Your price mappings have been successfully saved!",
        });
        this.status = EditableBoxStatusTypes.View;
      } catch (err) {
        this.status = EditableBoxStatusTypes.Edit;
      }
    }
  }
}
