
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import type { TableConfig, TableBoxConfig } from "@/types";
import { TableFilterTypes } from "@/types";
import { TableMixin } from "@/mixins";
import { namespace } from "vuex-class";
import { JunTableItem } from "@juniper/ui";

const manufacturers = namespace("manufacturersVuexModule");

@Options({})
export default class TableBox<T> extends mixins(TableMixin) {
  @Prop({
    type: Object,
    required: true,
  })
  config!: TableBoxConfig<T>;

  @Prop({
    type: Boolean,
    default: false,
  })
  pill!: boolean;

  @manufacturers.Getter private manufacturerId!: number | null;

  private tableItems: JunTableItem[] = [];

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.Legacy });
  }

  async created() {
    this.initTable();
    await this.init({ filterVersion: TableFilterTypes.Legacy });
  }

  private initTable(): void {
    this.tableHeaders = this.config.tableHeaders;
    this.sortBy = this.config.sortBy;
    this.config.sortDirection &&
      (this.sortDirection = this.config.sortDirection);
    this.config.pageSize && (this.pageSize = this.config.pageSize);
    this.tableOptions.paginationOptions = undefined;
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    if (
      this.config.persistentFilters !== null &&
      this.config.persistentFilters !== undefined
    ) {
      this.persistentFilters = Object.entries(
        this.config.persistentFilters
      ).map(([key, value]) => ({ key, value }));
    }
    this.cellClick = this.config.cellClick;
    this.getTableData = this.fetchData;
  }

  private get itemTotal() {
    return this.tableConfig.items.length;
  }

  private async fetchData() {
    this.tableItems = await this.config.dataFetch(this.getQueryString());
  }

  get tableConfig(): TableConfig {
    return {
      heading: this.config.heading,
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.tableItems,
      itemKey: this.config.itemKey,
      hideHeaderRow: this.config.hideHeaderRow ?? false,
      hideTableHeadingBorder: this.config.hideTableHeadingBorder ?? false,
      cellClick: this.cellClick?.bind(this),
    };
  }
}
