
import { AttributeMappingField, EditableBox } from "@/components";
import { BackupDataService } from "@/services";
import type {
  AttributeMappingFieldData,
  IProductAttributesRepository,
  IProductSettingsRepository,
  TableConfig,
  ToastConfig,
} from "@/types";
import {
  AttributesTypes,
  EditableBoxStatusTypes,
  ProductAttributesDto,
  ProductSettingsDto,
} from "@/types";
import { Utils } from "@/utils";
import { JunTableColumn, JunTableOptions } from "@juniper/ui";
import { inject } from "inversify-props";
import { Form } from "vee-validate";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    Form,
    EditableBox,
    AttributeMappingField,
  },
})
export default class AttributeMapping extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private productAttributesRepository?: IProductAttributesRepository;
  @inject() private productSettingsRepository?: IProductSettingsRepository;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;

  private status = EditableBoxStatusTypes.View;
  private tableOptions: JunTableOptions = {};

  private isTableProcessing = false;

  private productAttributes: ProductAttributesDto = new ProductAttributesDto();
  private productSettings: ProductSettingsDto = new ProductSettingsDto();
  private attributeMappingFields: Array<AttributeMappingFieldData> = [];

  async created() {
    await this.init();
  }

  private async init(): Promise<void> {
    this.status = EditableBoxStatusTypes.Loading;
    const [data] = await Utils.try(
      Promise.all([
        this.productAttributesRepository?.get(),
        this.productSettingsRepository?.get(),
      ])
    );
    if (!data) return;
    const [productAttributes, productSettings] = data;
    if (!productAttributes || !productSettings) return;
    this.productAttributes = productAttributes;
    this.productSettings = productSettings;
    this.attributeMappingFields = [
      {
        dataField: this.productAttributes.styleField,
        attribute: "Style",
        key: AttributesTypes.Style,
      },
      {
        dataField: this.productAttributes.colorField,
        attribute: "Color",
        key: AttributesTypes.Color,
      },
      {
        dataField: this.productAttributes.materialField,
        attribute: "Materials",
        key: AttributesTypes.Materials,
      },
    ];
    this.status = EditableBoxStatusTypes.View;
  }

  private get isNoShadowSlot(): boolean {
    return this.status === EditableBoxStatusTypes.Edit;
  }

  private tableHeaders: JunTableColumn[] = [
    {
      prop: "attribute",
      text: "Marketplace Attributes",
      canSort: false,
      canFilter: false,
    },
    {
      prop: "dataField",
      text: "Your Data Fields",
      canSort: false,
      canFilter: false,
    },
  ];

  get tableConfig(): TableConfig {
    return {
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.attributeMappingFields,
      itemKey: "key",
    };
  }

  private onEditableBoxEdit() {
    this.backupDataService?.saveBackup(this, this.attributeMappingFields);
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.backupDataService?.restoreBackup(this, this.attributeMappingFields);
    this.status = EditableBoxStatusTypes.View;
  }

  private async onEditableBoxSave() {
    try {
      this.status = EditableBoxStatusTypes.Saving;
      this.attributeMappingFields.forEach((item: AttributeMappingFieldData) => {
        switch (item.key) {
          case AttributesTypes.Style:
            this.productAttributes.styleField = item.dataField;
            break;
          case AttributesTypes.Color:
            this.productAttributes.colorField = item.dataField;
            break;
          case AttributesTypes.Materials:
            this.productAttributes.materialField = item.dataField;
            break;
        }
      });
      await this.productAttributesRepository?.put(this.productAttributes);
      this.createToastSuccess?.({
        message: "Your attribute mappings have been successfully saved!",
      });
      this.status = EditableBoxStatusTypes.View;
    } catch (err) {
      this.status = EditableBoxStatusTypes.Edit;
    }
  }
}
