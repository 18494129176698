import { JsonProperty, Serializable } from "typescript-json-serializer";
import { ProfileBrandContactDto, ProfileSocialMediaDto } from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class ProfileBrandDto {
  @JsonProperty() gated!: boolean;
  @JsonProperty() directSeller!: boolean;
  @JsonProperty() sellerName!: string | null;
  @JsonProperty() sellerBiography!: string | null;
  @JsonProperty() countryAvailability!: Array<string> | null;
  @JsonProperty({
    beforeDeserialize: Utils.getFirstOrNull,
    afterSerialize: Utils.strToArray,
  })
  industry!: string | null;
  @JsonProperty() industryCategory!: Array<string> | null;
  @JsonProperty() contact!: ProfileBrandContactDto;
  @JsonProperty() socialMedia!: ProfileSocialMediaDto;

  constructor() {
    this.contact = new ProfileBrandContactDto();
    this.socialMedia = new ProfileSocialMediaDto();
  }
}
