
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

import { Checkbox } from "@/components";
import { EditableBoxStatusTypes, ShippingMethodTypes } from "@/types";

@Options({
  // inject: ["acknowledgementChange"],
  components: {
    Checkbox,
  },
})
export default class ShippingCarrierAcknowledgement extends Vue {
  @Inject() private acknowledgementChange!: (acknowledged: boolean) => void;

  @Prop({
    type: String,
    default: EditableBoxStatusTypes.View,
  })
  status!: EditableBoxStatusTypes;

  @Prop({
    type: String,
    default: ShippingMethodTypes.Juniper,
  })
  selectedShipMethod!: ShippingMethodTypes;

  private acknowledged = false;

  mounted() {
    if (
      this.status === EditableBoxStatusTypes.View &&
      (this.selectedShipMethod === ShippingMethodTypes.Juniper ||
        this.selectedShipMethod === ShippingMethodTypes.Unset)
    ) {
      this.acknowledged = true;
    } else {
      this.acknowledged = false;
    }
  }

  get editDisabled() {
    return this.status !== "edit";
  }

  get acknowledgementMessage() {
    const shippingBrand = "@Market ANDMORE";
    return this.selectedShipMethod === ShippingMethodTypes.Juniper
      ? `I acknowledge that by connecting to Ship with ${shippingBrand}, ${shippingBrand} will bill my account each month for Ship with ${shippingBrand} activity from the previous month.`
      : "I acknowledge that by selecting “Ship on your own” that I will be responsible for manually inputting the shipping costs and tracking information for orders.";
  }
}
