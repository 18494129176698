
import { Options, Vue } from "vue-class-component";
import { AdminButton, Box, Icon, InfoList } from "@/components";
import {
  ButtonColorTypes,
  IconNameTypes,
  InfoListItem,
  SellerDto,
  SellerStatusTypes,
} from "@/types";
import { sellerStatusTextColorConfig } from "@/config";
import { namespace } from "vuex-class";

const seller = namespace("sellerVuexModule");

@Options({
  components: { AdminButton, Icon, Box, InfoList },
})
export default class DashboardJuniperCredit extends Vue {
  @seller.Getter seller?: SellerDto | null;
  @seller.Action applyForJuniperCredit?: () => Promise<void>;

  private ButtonColorTypes = ButtonColorTypes;
  private IconNameTypes = IconNameTypes;

  private get showApply(): boolean {
    return Boolean(
      this.seller && this.seller.status === SellerStatusTypes.Declined
    );
  }

  private get showStatus() {
    return Boolean(
      this.seller &&
        (this.seller.status === SellerStatusTypes.Pending ||
          this.seller.status === SellerStatusTypes.Declined)
    );
  }

  private get creditStatusItems(): Array<InfoListItem> {
    if (!this.seller || !this.seller.status) return [];
    const statusTextColor =
      this.seller.status && sellerStatusTextColorConfig[this.seller.status]
        ? sellerStatusTextColorConfig[this.seller.status]
        : null;
    return [
      {
        label: "Application Status",
        text: this.seller.status,
        textColor: statusTextColor,
      },
    ];
  }

  private get disableApply(): boolean {
    return Boolean(
      this.seller &&
        this.seller.status === SellerStatusTypes.Declined &&
        !this.seller.canApplyForCredit
    );
  }
}
