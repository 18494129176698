import { injectable } from "inversify-props";
import { IProductRepository, ProductSetDto } from "@/types";
import { deserialize, serialize } from "typescript-json-serializer";
import axios from "@/axios";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/profile/ProductSets`;

@injectable()
export class ProductRepository implements IProductRepository {
  public async get(): Promise<Array<ProductSetDto>> {
    const { data } = await axios.get(ENDPOINT_URL);
    //TODO: Check if possible to deserialize the whole list at once (not using map)
    return data.map((ps: ProductSetDto) => {
      return deserialize<ProductSetDto>(ps, ProductSetDto);
    });
  }

  public async put(productSets: Array<ProductSetDto>): Promise<void> {
    const payload = serialize(productSets);
    return await axios.put(ENDPOINT_URL, payload);
  }
}
