import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class PricingLevelAliasesDto {
  @JsonProperty() 0?: string | null;
  @JsonProperty() 1?: string | null;
  @JsonProperty() 2?: string | null;
  @JsonProperty() 3?: string | null;
  @JsonProperty() 4?: string | null;
  @JsonProperty() 5?: string | null;
  @JsonProperty() 6?: string | null;
  @JsonProperty() 7?: string | null;
  @JsonProperty() 8?: string | null;
  @JsonProperty() 9?: string | null;
  @JsonProperty() 10?: string | null;
  @JsonProperty() 11?: string | null;
  @JsonProperty() 12?: string | null;
  @JsonProperty() 13?: string | null;
  @JsonProperty() 14?: string | null;
  @JsonProperty() 15?: string | null;
  @JsonProperty() 16?: string | null;
  @JsonProperty() 17?: string | null;
  @JsonProperty() 18?: string | null;
  @JsonProperty() 19?: string | null;
  @JsonProperty() 20?: string | null;
}
