import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class TransactionDto {
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  createdOn!: string | null;
  @JsonProperty() createdBy!: string | null;
  @JsonProperty() modifiedOn!: string | null;
  @JsonProperty() modifiedBy!: string | null;
  @JsonProperty() deletedOn!: string | null;
  @JsonProperty() deletedBy!: string | null;
  @JsonProperty() transactionId!: number;
  @JsonProperty() transactionReferenceId!: string | null;
  @JsonProperty() sellerReferenceId!: string | null;
  @JsonProperty() buyerReferenceId!: string | null;
  @JsonProperty() externalSellerReferenceId!: string | null;
  @JsonProperty() externalBuyerReferenceId!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty() customerName!: string | null;
  @JsonProperty() currency!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  totalAmount!: string;
  @JsonProperty() taxAmount!: number;
  @JsonProperty() shippingAmount!: number;
  @JsonProperty() shippingTaxAmount!: number;
  @JsonProperty() orderNumber!: string | null;
  @JsonProperty() orderId!: number;
  @JsonProperty() poNumber!: string | null;
  @JsonProperty() authorizationId!: string | null;
  @JsonProperty() parentTransactionReferenceId!: string | null;
  @JsonProperty() chargeComment!: string | null;
  @JsonProperty() transactionType!: string | null;
  @JsonProperty() disputeReason!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.getTransactionStatusLabel,
  })
  status!: string;
  @JsonProperty() disputeComment!: string | null;
  @JsonProperty() cancellationComment!: string | null;
  @JsonProperty() cancellationReason!: string | null;
  @JsonProperty() returnComment!: string | null;
  @JsonProperty() returnReason!: string | null;
  @JsonProperty() buyerId!: number;
  @JsonProperty() sellerId!: number;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  disbursementAmount!: string;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  feeAmount!: string;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  disputeExpirationDate!: string | null;
}
