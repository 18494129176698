import {
  Mutation,
  Action,
  Module,
  // MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import * as Sentry from "@sentry/browser";
import { inject } from "inversify-props";
import store from "@/store";
import { ManufacturerDetailsDto } from "@/types";
import type { IManufacturersRepository } from "@/types";
import LogRocket from "logrocket";

@Module({
  store: store,
  namespaced: true,
})
export default class ManufacturersVuexModule extends VuexModule {
  @inject() private manufacturersRepository!: IManufacturersRepository;

  private _manufacturerId: number | null = null;
  private _manufacturersDetails: ManufacturerDetailsDto[] | undefined;
  private _availableManufacturerIds: number[] | null = null;

  // GETTERS
  public get isDirectSeller(): boolean | null {
    const manufacturerDetails = this._manufacturersDetails?.find(
      (md: ManufacturerDetailsDto) => md.manufacturerId === this._manufacturerId
    );
    return manufacturerDetails
      ? manufacturerDetails.settings.brandSettings.directSeller
      : null;
  }

  public get isGated(): boolean | null {
    const manufacturerDetails = this._manufacturersDetails?.find(
      (md: ManufacturerDetailsDto) => md.manufacturerId === this._manufacturerId
    );
    return manufacturerDetails
      ? manufacturerDetails.settings.brandSettings.gated
      : null;
  }

  public get isLive(): boolean | null {
    const manufacturerDetails = this._manufacturersDetails?.find(
      (md: ManufacturerDetailsDto) => md.manufacturerId === this._manufacturerId
    );
    return manufacturerDetails ? manufacturerDetails.isLive : null;
  }

  public get autoPublish(): boolean | null {
    const manufacturerDetails = this._manufacturersDetails?.find(
      (md: ManufacturerDetailsDto) => md.manufacturerId === this._manufacturerId
    );
    return manufacturerDetails ? manufacturerDetails.autoPublish : null;
  }

  public get manufacturerName(): string | null {
    const manufacturerDetails = this._manufacturersDetails?.find(
      (md: ManufacturerDetailsDto) => md.manufacturerId === this._manufacturerId
    );
    return manufacturerDetails
      ? manufacturerDetails.settings?.brandSettings?.sellerName
      : null;
  }

  public get isDivisionsSetUp(): boolean {
    return (
      this._manufacturersDetails !== undefined &&
      this._manufacturersDetails.length > 0
    );
  }

  public get manufacturerId(): number | null {
    return this._manufacturerId;
  }

  public get manufacturersDetails(): ManufacturerDetailsDto[] | undefined {
    return this._manufacturersDetails;
  }

  public get availableManufacturerIds(): number[] | null {
    return this._availableManufacturerIds;
  }

  public get hasAvailableManufacturer(): boolean {
    return this._availableManufacturerIds
      ? this._availableManufacturerIds.length > 0
      : false;
  }

  // MUTATIONS
  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this._manufacturerId = null;
    this._manufacturersDetails = undefined;
  }

  @Mutation
  public setManufacturerId(manufacturerId: number): void {
    this._manufacturerId = manufacturerId;

    localStorage.setItem("manufacturerId", String(manufacturerId));
    Sentry.setTag("manufacturerId", manufacturerId);
    LogRocket.identify(String(manufacturerId), {
      environment: String(process.env.VUE_APP_SENTRY_ENVIRONMENT),
    });
  }

  @Mutation
  public setManufacturersDetails(
    manufacturersDetails: ManufacturerDetailsDto[]
  ): void {
    this._manufacturersDetails = manufacturersDetails;
  }

  @Mutation
  public setAutoPublishStatus(status: boolean) {
    this._manufacturersDetails?.forEach((mnf) => {
      if (mnf.manufacturerId === this._manufacturerId) {
        mnf.autoPublish = status;
      }
    });
  }

  @Mutation
  public setAvailableManufacturerIds(ids: number[]): void {
    this._availableManufacturerIds = ids;
  }

  // ACTIONS
  @Action({ rawError: true })
  public async setManufacturer(manufacturerIds: number[]): Promise<void> {
    const storedManufacturerId = Number(localStorage.getItem("manufacturerId"));
    if (!manufacturerIds.includes(storedManufacturerId)) {
      this.context.commit("setManufacturerId", manufacturerIds[0]);
    } else {
      this.context.commit("setManufacturerId", storedManufacturerId);
    }
  }

  @Action({ rawError: true })
  public async initManufacturers(): Promise<void> {
    //TODO: Move this API request out of the Vuex module, we should pass the response to the Action instead
    const manufacturersDetails = await this.manufacturersRepository.getMany();
    this.context.commit(
      "setAvailableManufacturerIds",
      manufacturersDetails.map((man) => man.manufacturerId)
    );

    const manufacturerIdExist = manufacturersDetails.find(
      (item: ManufacturerDetailsDto) =>
        item.manufacturerId === this._manufacturerId
    );

    if (!manufacturerIdExist && manufacturersDetails.length > 0) {
      this.context.commit(
        "setManufacturerId",
        manufacturersDetails[0].manufacturerId
      );
    }

    this.context.commit("setManufacturersDetails", manufacturersDetails);
  }
}
