export enum OidcEventTypes {
  StateChanged = "oidc:state-changed",
  AccessTokenExpiring = "oidc:access-token-expiring",
  AccessTokenExpired = "oidc:access-token-expired",
  SilentRenewError = "oidc:silent-review-error",
  UserSignedOut = "oidc:user-signed-out",
  UserSessionChanged = "oidc:user-session-changed",
  Error = "oidc:error",
  UserUnloaded = "oidc:user-unloaded",
  UserSignedIn = "oidc:user-signed-in",
}
