import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import {
  Dashboard,
  HandshakeLogin,
  HandshakeError,
  OidcCallback,
  HandshakeSignOut,
  OidcSilentRenewCallback,
  BlankRedirect,
} from "@/views";
import {
  MainRouteTypes,
  MainRouteNameTypes,
  RouteRestrictionTypes,
} from "@/types";
import { Utils } from "@/utils";
import { OidcService } from "@/services";

export const dashboardDefaultRoute = {
  path: "/",
  name: MainRouteNameTypes.Default,
  component: Dashboard,
  meta: {
    mainRouteId: MainRouteTypes.Dashboard,
  },
};

export const settingsDefaultRoute = {
  path: "/",
  name: MainRouteNameTypes.Default,
  redirect: { name: MainRouteNameTypes.Settings },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: MainRouteNameTypes.Default,
    component: BlankRedirect,
  },
  {
    path: `/${MainRouteTypes.Credit}/:subRoute(.*)*`,
    name: MainRouteNameTypes.Credit,
    component: () =>
      import(/* webpackChunkName: "credit" */ "@/views/private/Credit"),
    meta: {
      mainRouteId: MainRouteTypes.Credit,
      restrictions: [
        RouteRestrictionTypes.Multiline,
        RouteRestrictionTypes.NoJuniperCreditApp,
      ],
    },
  },
  {
    path: `/${MainRouteTypes.Buyers}/:subRoute(.*)*`,
    name: MainRouteNameTypes.Buyers,
    component: () =>
      import(/* webpackChunkName: "buyers" */ "@/views/private/Buyers"),
    meta: {
      mainRouteId: MainRouteTypes.Buyers,
      restrictions: [RouteRestrictionTypes.IndirectSeller],
    },
  },
  {
    path: `/${MainRouteTypes.LeadDetails}/:leadId`,
    name: MainRouteNameTypes.LeadDetails,
    component: () =>
      import(/* webpackChunkName: "details" */ "@/views/private/LeadDetails"),
    meta: {
      mainRouteId: MainRouteTypes.LeadDetails,
    },
  },
  {
    path: `/${MainRouteTypes.BuyerDetails}/:customerNumber/:appUserId`,
    name: MainRouteNameTypes.BuyerDetails,
    component: () =>
      import(
        /* webpackChunkName: "buyer-details" */ "@/views/private/BuyerDetails"
      ),
    meta: {
      mainRouteId: MainRouteTypes.BuyerDetails,
    },
  },
  {
    path: `/${MainRouteTypes.Orders}/:subRoute(.*)*`,
    name: MainRouteNameTypes.Orders,
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/private/Orders"),
    meta: {
      mainRouteId: MainRouteTypes.Orders,
    },
  },
  {
    path: `/${MainRouteTypes.OrderDetails}/:subRoute(.*)*`,
    name: MainRouteNameTypes.OrderDetails,
    component: () =>
      import(
        /* webpackChunkName: "order-details" */ "@/views/private/OrderDetails"
      ),
    props: (route) => ({
      orderGUID: route.query.guid,
      brandId: route.query.brand,
    }),
    meta: {
      mainRouteId: MainRouteTypes.OrderDetails,
    },
  },
  {
    path: `/${MainRouteTypes.CartDetails}/:cartId(.*)*`,
    name: MainRouteNameTypes.CartDetails,
    component: () =>
      import(
        /* webpackChunkName: "cart-details" */ "@/views/private/CartDetails"
      ),
    meta: {
      mainRouteId: MainRouteTypes.CartDetails,
    },
  },
  {
    path: `/${MainRouteTypes.InvoiceDetails}/:invoiceId`,
    name: MainRouteNameTypes.InvoiceDetails,
    component: () =>
      import(
        /* webpackChunkName: "invoice-details" */ "@/views/private/InvoiceDetails"
      ),
    meta: {
      mainRouteId: MainRouteTypes.InvoiceDetails,
    },
  },
  {
    path: `/${MainRouteTypes.InvoicePreview}`,
    name: MainRouteNameTypes.InvoicePreview,
    component: () =>
      import(
        /* webpackChunkName: "invoice-preview" */ "@/views/private/InvoiceDetails"
      ),
    props: (route) => ({
      orderGUID: route.query.guid,
      brandId: route.query.brand,
      isPreview: true,
    }),
    meta: {
      mainRouteId: MainRouteTypes.InvoicePreview,
    },
  },
  {
    path: `/${MainRouteTypes.Settings}/:subRoute(.*)*`,
    name: MainRouteNameTypes.Settings,
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/private/Settings"),
    meta: {
      mainRouteId: MainRouteTypes.Settings,
    },
  },
  {
    path: `/${MainRouteTypes.NoDivisionsSetUp}`,
    name: MainRouteNameTypes.NoDivisionsSetUp,
    component: () =>
      import(
        /* webpackChunkName: "no-divisions-set-up" */ "@/views/private/NoDivisionsSetUp"
      ),
    meta: {
      mainRouteId: MainRouteTypes.NoDivisionsSetUp,
    },
  },
  {
    path: `/${MainRouteTypes.OidcCallback}`,
    name: MainRouteNameTypes.OidcCallback,
    component: OidcCallback,
    meta: {
      isOidcCallback: true,
      isPublic: true,
      hideLayout: true,
      mainRouteId: MainRouteTypes.OidcCallback,
    },
  },
  {
    path: `/${MainRouteTypes.OidcSilentRenewCallback}`,
    name: MainRouteNameTypes.OidcSilentRenewCallback,
    component: OidcSilentRenewCallback,
    meta: {
      isPublic: true,
      hideLayout: true,
      mainRouteId: MainRouteTypes.OidcSilentRenewCallback,
    },
  },
  {
    path: `/${MainRouteTypes.OidcCallbackError}`,
    name: MainRouteNameTypes.OidcCallbackError,
    component: () =>
      import(
        /* webpackChunkName: "oidc-callback-error" */ "@/views/public/OidcCallbackError"
      ),
    meta: {
      isPublic: true,
      mainRouteId: MainRouteTypes.OidcCallbackError,
    },
  },
  {
    path: `/${MainRouteTypes.HandshakeLogin}`,
    name: MainRouteNameTypes.HandshakeLogin,
    component: HandshakeLogin,
    props: (route) => ({
      username: route.query.username,
      password: route.query.password,
      passwordsalt: route.query.passwordsalt,
    }),
    meta: {
      isPublic: true,
      hideLayout: true,
    },
  },
  {
    path: `/${MainRouteTypes.HandshakeError}`,
    name: MainRouteNameTypes.HandshakeError,
    component: HandshakeError,
    meta: {
      isPublic: true,
      hideLayout: true,
    },
  },
  {
    path: `/${MainRouteTypes.HandshakeSignOut}`,
    name: MainRouteNameTypes.HandshakeSignOut,
    component: HandshakeSignOut,
    meta: {
      isPublic: true,
      hideLayout: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: MainRouteNameTypes.NotFound,
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/public/NotFound"),
    meta: {
      isPublic: true,
      hideLayout: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Router Navigation Guards
if (
  !(
    window.localStorage.getItem("isHandshakeLogin") ||
    Utils.isCurrentRoute(MainRouteTypes.HandshakeLogin)
  )
) {
  router.beforeEach(
    async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      // Handles signing user in on initial load, and checking access for subsequent page navigations
      if (to.meta.isPublic as boolean) return next();
      const oidcService = OidcService.getInstance();
      if (!(oidcService.state && oidcService.state.isAuthenticated)) {
        const [user] = await Utils.try(oidcService.getUser());
        if (!user) {
          const [, error] = await Utils.try(oidcService.signInSilent());
          // deny access
          if (error) {
            window.location.reload();
            return false;
          }
        }
      }
      return next(); // grant access
    }
  );
}

router.beforeEach((to, from, next) => {
  if (Utils.routeIsRestricted(to.meta.restrictions)) {
    next({ path: "/" });
  } else {
    next();
  }
});

export default router;
