
import { Options, Vue } from "vue-class-component";
import { InjectReactive, Prop } from "vue-property-decorator";
import { ProfileBrandDto, IconNameTypes } from "@/types";
import { Icon } from "@/components";
import { namespace } from "vuex-class";
import { inject } from "inversify-props";
import { ImageUrlService } from "@/services";

const brandPreview = namespace("brandPreviewVuexModule");
const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    Icon,
  },
})
export default class ProfilePreview extends Vue {
  @inject() private imageUrlService!: ImageUrlService;

  @InjectReactive() private contactEmailFieldsFlag!: boolean;

  @manufacturers.Getter private manufacturerId!: number | null;

  @brandPreview.Getter private profileLogo!: File | null;
  @brandPreview.Getter private profileHeader!: File | null;

  @Prop({
    type: Object,
  })
  brand!: ProfileBrandDto | null;

  private logoUrl: string | null = null;
  private headerUrl: string | null = null;
  private IconNameTypes = IconNameTypes;
  private navItems = [
    "apparel",
    "beauty & wellness",
    "food & beverage",
    "furniture",
    "gift",
    "home decor",
    "housewares",
    "jewelry",
  ];

  async created() {
    this.setExistingImages();
  }

  private get logo() {
    return this.profileLogo
      ? URL.createObjectURL(this.profileLogo)
      : this.logoUrl;
  }

  private get header() {
    return this.profileHeader
      ? URL.createObjectURL(this.profileHeader)
      : this.headerUrl;
  }

  private get email() {
    return this.contactEmailFieldsFlag && this.brand
      ? this.brand.contact.brandContactEmail
      : this.brand && this.brand.contact.customerContactEmail;
  }

  private async setExistingImages() {
    if (!this.profileLogo) {
      const { url } = await this.imageUrlService.getCompanyLogo();
      this.logoUrl = url;
    }

    if (!this.profileHeader) {
      const { url } = await this.imageUrlService.getHeaderImage();
      this.headerUrl = url;
    }
  }
}
