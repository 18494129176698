export const CustomersMap = {
  "[].name": "[].name",
  "[].customerNumber": "[].customerNumber",
  "[].priceLevel": "[].priceLevel?",
  "[].address1": "[].address.address1?",
  "[].address2": "[].address.address2?",
  "[].city": "[].address.city?",
  "[].country": "[].address.country?",
  "[].stateProvince": "[].address.stateProvince?",
  "[].postalCode": "[].address.postalCode?",
  "[].shipToContactName": "[].shipping.name?",
  "[].shipToEmail": "[].shipping.email?",
  "[].shipToFax": "[].shipping.fax?",
  "[].shipToAddress1": "[].shipping.address1?",
  "[].shipToAddress2": "[].shipping.address2?",
  "[].shipToCity": "[].shipping.city?",
  "[].shipToCountry": "[].shipping.country?",
  "[].shipToStateProvince": "[].shipping.state?",
  "[].shipToPostalCode": "[].shipping.postalCode?",
  "[].shipToCode": "[].shipping.code?",
};
