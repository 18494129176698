export const imageDimensionsConfig = {
  logo: {
    min: { width: 175, height: 0 },
    max: { width: 350, height: 1000 },
  },
  header: {
    min: { width: 2000, height: 500 },
    max: { width: 10000, height: 10000 },
  },
  lifestyle: {
    min: { width: 2000, height: 500 },
    max: { width: 10000, height: 10000 },
  },
};
