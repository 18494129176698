import axios from "@/axios";
import { injectable } from "inversify-props";
import { IStaticRepository, StaticDto } from "@/types";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Static`;

@injectable()
export class StaticRepository implements IStaticRepository {
  public async get(): Promise<StaticDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<StaticDto>(data, StaticDto);
  }
}
