import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import { inject } from "inversify-props";
import store from "@/store";
import {
  ManufacturerStaticDto,
  StaticDto,
  PricingLevelAliasesDto,
  AddressDto,
  CountryDto,
  ProvinceDto,
  CreditRefundReasonDto,
  SalesRepDto,
} from "@/types";
import type {
  DropdownItem,
  IManufacturerSettingsRepository,
  IStaticRepository,
} from "@/types";
import objectMapper from "object-mapper";
import { AddressMap, SalesRepsMap } from "@/maps";
import omitBy from "lodash/omitBy";

@Module({
  store,
  namespaced: true,
})
export default class StaticContentVuexModule extends VuexModule {
  @inject()
  private manufacturerSettingsRepository!: IManufacturerSettingsRepository;
  @inject() private staticRepository!: IStaticRepository;

  private staticContent: StaticDto | null = null;
  private manufacturerContent: ManufacturerStaticDto | null = null;

  // MUTATIONS
  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this.staticContent = null;
    this.manufacturerContent = null;
  }

  @Mutation
  public setStaticContent(staticContent: StaticDto) {
    this.staticContent = staticContent;
  }

  @Mutation
  public setManufacturerContent(manufacturerContent: ManufacturerStaticDto) {
    this.manufacturerContent = manufacturerContent;
  }

  // ACTIONS
  @Action({ rawError: true })
  public async getStaticContent(): Promise<void> {
    const staticContent = await this.staticRepository.get();
    this.context.commit("setStaticContent", staticContent);
  }

  @Action({ rawError: true })
  public async getManufacturerContent(): Promise<void> {
    const manufacturerContent = await this.manufacturerSettingsRepository.get();
    this.context.commit("setManufacturerContent", manufacturerContent);
  }

  // GETTERS
  public get priceLevelAliases(): Partial<PricingLevelAliasesDto> | null {
    if (!this.manufacturerContent) {
      return null;
    }
    return omitBy(this.manufacturerContent.priceLevelAliases, (p) => !p);
  }

  public get priceLevelAlias() {
    return (val: keyof PricingLevelAliasesDto) => {
      if (!this.manufacturerContent || !this.staticContent) {
        return "";
      }
      const alias = this.manufacturerContent.priceLevelAliases[val];
      return alias ? alias.trim() : "";
    };
  }

  public get priceLevelAliasToId() {
    return (val: string) => {
      if (!this.manufacturerContent || !this.staticContent) {
        return null;
      }
      const aliases = this.manufacturerContent.priceLevelAliases;
      const id = Object.keys(aliases).find((key) =>
        String((aliases as never)[key])
          .toLowerCase()
          .includes(val.toLowerCase().trim())
      );
      return id ? Number(id) : null;
    };
  }

  public get addressTypesDropdownOptions(): DropdownItem[] {
    return this.staticContent
      ? objectMapper(this.staticContent.addressTypes, AddressMap)
      : [];
  }

  public get priceLevelsDropdownOptions(): DropdownItem[] {
    if (!this.staticContent) {
      return [];
    }
    return this.staticContent.pricingLevels
      .filter((item) => {
        return this.priceLevelAlias(item.value);
      })
      .map((item) => {
        return {
          name: this.priceLevelAlias(item.value),
          value: item.value,
        };
      });
  }

  public get termsDropdownOptions(): DropdownItem[] {
    return this.manufacturerContent
      ? this.manufacturerContent.terms.map((t) => ({ name: t, value: t }))
      : [];
  }

  public get salesRepsDropdownOptions(): DropdownItem[] {
    return this.manufacturerContent
      ? objectMapper(this.manufacturerContent.salesReps, SalesRepsMap)
      : [];
  }

  public get salesReps(): SalesRepDto[] {
    return this.manufacturerContent ? this.manufacturerContent.salesReps : [];
  }

  public get manufacturerAddress(): AddressDto | {} {
    return this.manufacturerContent ? this.manufacturerContent.address : {};
  }

  public get paymentProcessorName(): String | null {
    return this.manufacturerContent
      ? this.manufacturerContent.paymentGatewayType
      : null;
  }

  public get weightUnit(): string | null {
    return this.manufacturerContent
      ? this.manufacturerContent.weightUnit
      : null;
  }

  public get countries(): CountryDto[] {
    return this.staticContent ? this.staticContent.countries : [];
  }

  public get caProvinces(): ProvinceDto[] {
    return this.staticContent ? this.staticContent.caProvinces : [];
  }

  public get usStates(): ProvinceDto[] {
    return this.staticContent ? this.staticContent.usStates : [];
  }

  public get creditRefundReasons(): CreditRefundReasonDto[] {
    return this.staticContent?.juniperCreditRefundReasons ?? [];
  }
}
