import { UpsInvoiceDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class UpsInformationDto {
  @JsonProperty() accountNumber!: string | null;
  @JsonProperty() accountCountryCode!: string | null;
  @JsonProperty() accountPostalCode!: string | null;
  @JsonProperty() title!: string | null;
  @JsonProperty() firstName!: string | null;
  @JsonProperty() lastName!: string | null;
  @JsonProperty() company!: string | null;
  @JsonProperty() address1!: string | null;
  @JsonProperty() address2!: string | null;
  @JsonProperty() city!: string | null;
  @JsonProperty() state!: string | null;
  @JsonProperty() postalCode!: string | null;
  @JsonProperty() countryCode!: string | null;
  @JsonProperty() email!: string | null;
  @JsonProperty() phone!: string | null;
  @JsonProperty() invoice?: UpsInvoiceDto;
  @JsonProperty() agreeToTechnologyAgreement!: boolean;
}
