import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";
import {
  AddressDto,
  ContactDto,
  CartCatalogDetailDto,
  CartLineItemDto,
} from "@/types";

@Serializable()
export class CartDto {
  @JsonProperty() id!: string | null;
  @JsonProperty() billingAddress!: AddressDto;
  @JsonProperty() shippingAddress!: AddressDto;
  @JsonProperty() contact!: ContactDto;
  @JsonProperty({ type: CartCatalogDetailDto })
  catalogDetails!: CartCatalogDetailDto[];
  @JsonProperty() shipToCode!: string | null;
  @JsonProperty() shipToCompanyName!: string | null;
  @JsonProperty() holdForConfirmation!: boolean;
  @JsonProperty() containerCubes!: number;
  @JsonProperty() containerName!: string | null;
  @JsonProperty() currencyCode!: string | null;
  @JsonProperty() customerId!: number;
  @JsonProperty() customerName!: string | null;
  @JsonProperty() customerNumber!: string | null;
  @JsonProperty() discount!: number;
  @JsonProperty() exportDate!: string | null;
  @JsonProperty() exported!: boolean;
  @JsonProperty() freightOnBoard!: string | null;
  @JsonProperty() isContainer!: boolean;
  @JsonProperty() isPlaced!: boolean;
  @JsonProperty() manufacturerId!: number;
  @JsonProperty() marketName!: string | null;
  @JsonProperty() cartDate!: string | null;
  @JsonProperty() origin!: string | null;
  @JsonProperty() priceLevel!: number;
  @JsonProperty() repNumber!: string | null;
  @JsonProperty() salesRepId!: number;
  @JsonProperty() source!: string | null;
  @JsonProperty() taxRate!: number;
  @JsonProperty() taxTotal!: number;
  @JsonProperty() writtenByName!: string | null;
  @JsonProperty() writtenForName!: string | null;
  @JsonProperty() writtenForRepNumber!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  cartTotal!: string | null;
  @JsonProperty() shippingTotal!: number;
  @JsonProperty() subTotal!: number;
  @JsonProperty() lineItemCount!: number;
  @JsonProperty() lineItemQty!: number;
  @JsonProperty() isDefault!: boolean;
  @JsonProperty() splitByCatalogCode!: boolean;
  @JsonProperty() isActive!: boolean;
  @JsonProperty() modifiedBy!: string | null;
  @JsonProperty() createdBy!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  createdOn!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  modifiedOn!: string | null;
  @JsonProperty({ type: CartLineItemDto }) lineItems!: CartLineItemDto[];
}
