
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Icon } from "@/components";
import { IconNameTypes } from "@/types";

@Options({
  components: {
    Icon,
  },
})
export default class ImageCarousel extends Vue {
  @Prop({
    type: Number,
    default: 24,
  })
  minHeight!: number;

  @Prop({
    type: Number,
    default: 30,
  })
  width!: number;

  @Prop({
    type: Array,
    default: [],
  })
  imageUrls!: string[];

  private currentIndex = 0;
  private IconNameTypes = IconNameTypes;

  onRightArrowClick() {
    if (!this.disableRightArrow) {
      this.currentIndex = this.currentIndex + 1;
    }
  }

  onLeftArrowClick() {
    if (!this.disableLeftArrow) {
      this.currentIndex = this.currentIndex - 1;
    }
  }

  get disableLeftArrow() {
    return this.currentIndex === 0;
  }

  get disableRightArrow() {
    return this.currentIndex === this.imageUrls.length - 1;
  }
}
