import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3db31a3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-wrapper" }
const _hoisted_2 = { class: "left-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyAndContactDetails = _resolveComponent("CompanyAndContactDetails")!
  const _component_OrderMinimumsCmp = _resolveComponent("OrderMinimumsCmp")!
  const _component_CompanyImagesCmp = _resolveComponent("CompanyImagesCmp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CompanyAndContactDetails)
    ]),
    _createVNode(_component_OrderMinimumsCmp, { class: "right-panel" }),
    _createVNode(_component_CompanyImagesCmp, { class: "right-panel" })
  ]))
}