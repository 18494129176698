export enum DashboardOrdersTableHeightTypes {
  Normal = 2,
}

export enum DashboardOrdersTableWidthTypes {
  Small = 3,
  Medium = 5,
  Large = 6,
  Full = 8,
}
