import store from "@/store";
import { onMounted, onUnmounted } from "vue";
import { Masks } from "@/utils/Masks";
import {
  CarrierCodeTypes,
  IAddress,
  IExtendedAxiosError,
  IImage,
  IQueryFilter,
  MainRouteTypes,
  PaymentMethodTypes,
  ProductAttributeDto,
  RatesDto,
  QueryOperationTypes,
  RouteRestrictionTypes,
  TransactionStatusTypes,
  UserDefinedFieldDto,
  IDateRange,
  CarrierTypes,
  DropdownItem,
  SellerStatusTypes,
  AppUserCustomerDto,
  TableFilterTypes,
  IApiError,
  IApiErrorResponse,
} from "@/types";
import { AxiosResponse } from "axios";
import { transactionStatusLabelMap, shippingCarriersConfig } from "@/config";

export abstract class Utils {
  public static cleanPhone(phoneNumber?: string | null): string {
    return ("" + phoneNumber).replace(/\D/g, "");
  }

  /**
   * Utility function for creating and cleaning eventListeners
   * on vue lifecycles
   *
   * @remarks
   * This method needs to be used with vue setup hook
   */
  public static useEventListener(
    target: EventTarget,
    event: string,
    callback: EventListenerOrEventListenerObject,
    options: boolean | AddEventListenerOptions = {}
  ) {
    onMounted(() => target.addEventListener(event, callback, options));
    onUnmounted(() => target.removeEventListener(event, callback, options));
  }

  public static cleanCurrency(price?: string | null): string {
    return Number(("" + price).replace(/\D+/g, "")).toString();
  }

  public static extractNumberFromCurrency(price: string): number {
    return parseFloat(price.replace(/[^\d.]/g, ""));
  }

  public static sanitizeUrlForDisplay(url: string) {
    try {
      // Decode url in case it is encoded
      url = decodeURI(url);
      // remove query parameters
      url = url.split("?")[0];
      // trim trailing slash
      if (url.slice(-1) === "/") {
        url = url.substring(0, url.length - 1);
      }
      // remove protocol
      url = url.split("://").length > 1 ? url.split("://")[1] : url;
    } catch (ex) {
      console.log(ex);
    }
    return url;
  }

  public static formatUrlForAnchorTag(url: string) {
    if (url.startsWith("http://") || url.startsWith("https://")) return url;

    return `http://${url}`;
  }

  // remove the null and empty string values from the returned object
  public static removeEmptyAndNull<T>(obj: T): T {
    return Object.keys(obj)
      .filter((v) => {
        const k = v as keyof T;
        return obj[k] != null && obj[k];
      })
      .reduce((acc, v) => {
        const k = v as keyof T;
        acc[k] = obj[k];
        return acc;
      }, {} as T);
  }

  public static numberToString(num: number): string {
    return num.toString();
  }

  public static floatToString(num: number): string {
    return parseFloat(num.toString()).toFixed(2);
  }

  public static floatToCurrencyString(num: number): string {
    return Masks.currency(parseFloat(num.toString()).toFixed(2));
  }

  public static formatNumber(num: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
      .slice()
      .reverse()
      .find((item) => {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol
      : "0";
  }

  public static stringToNumber(str: string): number | null {
    //TODO: Return null or undefined depending on the endpoint requirements
    const cleanedNumber = Utils.extractNumberFromCurrency(str);
    return isNaN(cleanedNumber) ? null : cleanedNumber;
  }

  public static getFirstOrNull(
    arr: Array<string | null> | null
  ): string | null {
    return arr?.length ? arr[0] : null;
  }

  public static strToArray(str: string): Array<string> {
    return [str];
  }

  public static formatDate(date: Date): string {
    const month =
      date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);

    const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();

    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  public static parseDate(str: string): string {
    const date = new Date(Date.parse(str));
    return Utils.formatDate(date);
  }

  public static formatDateTime(str: string): string {
    const date = new Date(Date.parse(str));
    return date
      .toLocaleString("en-US", {
        hour12: false,
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", "");
  }

  public static getDateISOString(
    date: string | number | Date,
    { datePartOnly = false }: { datePartOnly?: boolean } = {}
  ): string {
    const fullISOString = new Date(date).toISOString();
    if (datePartOnly) return fullISOString.split("T").filter(Boolean)[0];
    return fullISOString;
  }

  public static roundToNearestDate(date: Date = new Date()): Date {
    return new Date(date.toDateString());
  }

  public static getDefaultDateRange(): IDateRange {
    const oneDayInMs = 1000 * 60 * 60 * 24;
    return {
      from: Utils.roundToNearestDate(new Date(Date.now() - oneDayInMs * 30)), // default range starts from one month ago
      to: new Date(
        Utils.roundToNearestDate(new Date()).getTime() + (oneDayInMs - 1)
      ),
    };
  }

  public static formatAddress(address?: IAddress) {
    if (!address) return "";

    const { address1, address2, city, stateProvince, postalCode } = address;
    return `${address1 || ""} ${address2 || ""}${city ? ", " : " "}${
      city || ""
    } ${stateProvince || ""} ${postalCode || ""}`;
  }

  public static getDatesInRange({
    startDate = new Date().getDate() + 1,
    endDate = new Date().getMonth() + 1,
    intervalInMilliSeconds = 24 * 60 * 60 * 1000,
  }: {
    startDate?: Date | number | string;
    endDate?: Date | number | string;
    intervalInMilliSeconds?: number;
  } = {}): Array<Date> {
    const initialTime = new Date();
    const endTime = new Date();
    initialTime.setDate(new Date(startDate).getTime());
    endTime.setMonth(new Date(endDate).getTime());
    const datesInRange: Array<Date> = [];
    for (
      let date = initialTime;
      date <= endTime;
      date = new Date(date.getTime() + intervalInMilliSeconds)
    ) {
      datesInRange.push(date);
    }
    return datesInRange;
  }

  public static getAppUserIdFromAppUserCustomer(customer: AppUserCustomerDto) {
    const { appUserId } =
      customer.shippingAddresses.find((address) => address.defaultAddress) ||
      {};
    return appUserId || 0;
  }

  public static transformShippingRates(rates: RatesDto[]): RatesDto[] {
    const isInvalidFedExRate = (r: RatesDto) => {
      return (
        r.carrierCode === CarrierCodeTypes.FedEx &&
        Number.isFinite(Number(r.carrierDeliveryDays))
      );
    };
    const isInvalidUSPSRate = (r: RatesDto) => {
      return (
        r.carrierCode === CarrierCodeTypes.USPS && !r.estimatedDeliveryDate
      );
    };
    const transformUSPSRate = (r: RatesDto) => {
      return r.carrierCode === CarrierCodeTypes.USPS
        ? {
            ...r,
            carrierDeliveryDays: `Estimated delivery date - ${Utils.formatDate(
              new Date(r.estimatedDeliveryDate as string)
            )}`,
          }
        : r;
    };
    return rates
      .filter((r) => !isInvalidFedExRate(r) && !isInvalidUSPSRate(r))
      .map(transformUSPSRate);
  }

  public static setEmptyIfNotArray(
    arr: Array<UserDefinedFieldDto>
  ): Array<UserDefinedFieldDto> {
    return Array.isArray(arr) ? arr : [];
  }

  public static getPaymentMethodLabel(type: PaymentMethodTypes): string {
    switch (type) {
      case PaymentMethodTypes.NoSet:
        return "Not Set";
      case PaymentMethodTypes.Terms:
        return "Terms";
      case PaymentMethodTypes.CreditCard:
        return "Credit Card";
      case PaymentMethodTypes.JuniperCredit:
        return "JuniperCredit";
      default:
        return "";
    }
  }

  public static getShippingCarrierConfig(
    type: CarrierTypes
  ): typeof shippingCarriersConfig[0] | undefined {
    return shippingCarriersConfig.find((c) => c.type === type);
  }

  public static getDropdownFromEnum<T>(e: T): Array<DropdownItem> {
    return Object.entries(e).map(([name, value]) => {
      return {
        name: name,
        value: value,
      } as DropdownItem;
    });
  }

  public static getTransactionStatusLabel(
    type: TransactionStatusTypes
  ): string {
    return transactionStatusLabelMap[type] || "";
  }

  public static omitFromRequest(): null {
    return null;
  }

  // If no attributes, need to add placeholder to initialize DynamicLabels dropdowns
  public static setNullAttributesIfEmptyArray(
    arr: Array<ProductAttributeDto>
  ): Array<ProductAttributeDto> {
    return arr.length === 0 ? [new ProductAttributeDto()] : arr;
  }

  public static nullToZero(num: number | null): number {
    return num ? num : 0;
  }

  public static setImageInitialValues(
    obj: IImage | Array<IImage>
  ): IImage | Array<IImage> {
    if (Array.isArray(obj)) {
      obj.forEach((value) => {
        value.file = null;
        value.isRemote = !!value.url;
      });
    } else {
      obj.file = null;
      obj.isRemote = !!obj.url;
    }
    return obj;
  }

  public static async try<T>(
    action?: Promise<T>
  ): Promise<[T | null, IExtendedAxiosError | null]> {
    if (!action) return [null, null];
    try {
      const res = await action;
      return [res, null];
    } catch (err) {
      return [null, err as IExtendedAxiosError];
    }
  }

  public static toCountryCode(country: string) {
    switch (country.toLocaleLowerCase()) {
      case "united states":
        return "US";
      case "usa":
        return "US";
      case "canada":
        return "CA";
      default:
        return country;
    }
  }

  public static buildQueryString(
    obj: Record<string, string | number | boolean | null>
  ): string {
    let queryString = "?";
    Object.keys(obj).forEach(
      (param: string) => (queryString += `${param}=${obj[param]}&`)
    );
    return queryString.slice(0, -1);
  }

  public static sleep(delay: number) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  public static encodeFilters(
    filters: IQueryFilter[],
    filterVersion: TableFilterTypes = TableFilterTypes.OData
  ): string {
    let queryString = "";
    filters.forEach(({ key, value, operation, useWildcard }) => {
      if (filterVersion === TableFilterTypes.Legacy) {
        // "@" character is the wildcard for the filter
        queryString += `${key}=${useWildcard ? "@" : ""}${value}${
          useWildcard ? "@" : ""
        },`;
      } else {
        // "*" character is the wildcard for the filter
        const op = operation || QueryOperationTypes.Equals;
        const val = `${useWildcard ? "*" : ""}${value}${
          useWildcard ? "*" : ""
        }`;
        queryString += `${key} ${op} ${val},`;
      }
    });
    return encodeURIComponent(queryString.slice(0, -1));
  }

  // TODO - need to thoroughly test this before using it
  // public static poll<T>({
  //   fn,
  //   fnCondition = () => false,
  //   interval = 5 * 1000,
  //   maxAttempts = Infinity,
  //   immediate = true,
  // }: {
  //   fn: Function;
  //   fnCondition?: (result?: T) => boolean;
  //   interval?: number;
  //   maxAttempts?: number;
  //   immediate?: boolean;
  // }) {
  //   let running = false;
  //   let attempts = 0;
  //   let result: T | undefined = undefined;
  //   const start = async () => {
  //     if (running) return;
  //     running = true;
  //     if (immediate) {
  //       result = await fn();
  //     }
  //     while (running && attempts <= maxAttempts && !fnCondition(result)) {
  //       await Utils.sleep(interval);
  //       result = await fn();
  //       attempts++;
  //     }
  //     running = false;
  //   };
  //   const stop = () => {
  //     running = false;
  //     attempts = 0;
  //   };
  //   return [start, stop] as const;
  // }

  public static generateRandomId(idPrefix = ""): string {
    return (
      (idPrefix ? `${idPrefix}_` : "") + Math.random().toString(36).substr(2, 9)
    );
  }

  public static parseJson<T>(str: string): string | T {
    return this.isJSON(str) ? JSON.parse(str) : str;
  }

  public static parseErrorArray(errs: Array<IApiError>): Array<string> {
    return errs
      .map((error) => {
        const errMsg = error.message ?? "";
        const str: IApiErrorResponse | string = this.parseJson(errMsg);
        return typeof str === "object"
          ? str.errors
            ? this.parseErrorArray(str.errors)
            : Object.values(str).flat()
          : str;
      })
      .flat();
  }

  public static downloadBlobFromResponse(res: AxiosResponse) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(res.data);
    link.download = res.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0];
    link.click();
  }

  public static purgeOidcKeysFromLocalStorage(): void {
    const oidcRegex = new RegExp("oidc");

    Object.keys(window.localStorage).forEach(function (key) {
      if (key.match(oidcRegex)) {
        window.localStorage.removeItem(key);
      }
    });
  }

  public static isCurrentRoute(type: MainRouteTypes): boolean {
    const handshakePath = new RegExp(type);
    return handshakePath.test(window.location.pathname);
  }

  public static joinStringParts(
    strParts: string[],
    separator = ",",
    lastSeparator = "and"
  ): string {
    let result = "";
    strParts.forEach((str, idx) => {
      if (idx === 0) {
        result += str;
      } else if (idx === strParts.length - 1) {
        result += ` ${lastSeparator} ${str}`;
      } else {
        result += `${separator} ${str}`;
      }
    });
    return result;
  }

  public static async copyText(text: string): Promise<void> {
    if (!navigator.clipboard) {
      return await Utils.copyTextFallback(text);
    }
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      await Utils.copyTextFallback(text);
    }
  }

  public static async copyTextFallback(text: string): Promise<void> {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  public static getFullName(
    firstName?: string | null,
    lastName?: string | null
  ): string | null {
    if (!firstName && !lastName) return null;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    if (firstName) return firstName;
    return lastName as string;
  }

  public static getInitials(
    firstName?: string | null,
    lastName?: string | null
  ): string | null {
    if (!firstName && !lastName) return null;
    const [firstInitial, lastInitial] = [
      firstName?.slice(0, 1).toUpperCase() || null,
      lastName?.slice(0, 1).toUpperCase() || null,
    ];
    if (firstInitial && lastInitial) return firstInitial + lastInitial;
    if (firstInitial) return firstInitial;
    return lastInitial;
  }

  public static arrayToCommaSeparatedString(arr: Array<string>): string {
    return arr?.filter(Boolean)?.join(",") ?? "";
  }

  public static commaSeparatedStringToArray(str: string): Array<string> {
    return str?.split(",")?.filter(Boolean) ?? [];
  }

  public static routeIsRestricted(
    restrictions?: RouteRestrictionTypes[] | unknown
  ) {
    if (Array.isArray(restrictions)) {
      for (const restriction of restrictions) {
        switch (restriction) {
          case RouteRestrictionTypes.DirectSeller:
            if (store.getters["manufacturersVuexModule/isDirectSeller"]) {
              return true;
            }
            break;
          case RouteRestrictionTypes.IndirectSeller:
            if (
              // explicitly check for false, can be null and null means still fetching
              store.getters["manufacturersVuexModule/isDirectSeller"] === false
            ) {
              return true;
            }
            break;
          case RouteRestrictionTypes.Multiline:
            if (store.getters["authVuexModule/isMultiline"]) {
              return true;
            }
            break;
          case RouteRestrictionTypes.Singleline:
            if (store.getters["authVuexModule/isMultiline"] === false) {
              return true;
            }
            break;
          case RouteRestrictionTypes.NoJuniperCreditApp:
            if (
              store.getters["sellerVuexModule/seller"]?.status ===
              SellerStatusTypes.Created
            ) {
              return true;
            }
            break;
          case RouteRestrictionTypes.Global:
            return true;
          default:
            break;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  public static transformSellerStatus(
    status: string | null
  ): SellerStatusTypes | null {
    if (!status) return null;
    if (
      Object.values(SellerStatusTypes).some(
        (statusType) => statusType === status
      )
    ) {
      return status as SellerStatusTypes;
    }
    return SellerStatusTypes.Created;
  }

  public static isJSON(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
