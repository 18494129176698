import { UserManagerSettings } from "oidc-client-ts";

export const oidcConfig: UserManagerSettings = {
  authority: process.env.VUE_APP_OIDC_AUTHORITY || "",
  client_id: "SellerAdmin-SPA",
  redirect_uri: process.env.VUE_APP_OIDC_REDIRECT_URI || "",
  response_type: "code",
  scope: "openid profile SellerAdminAPI",
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.VUE_APP_OIDC_SILENT_REDIRECT_URI,
  post_logout_redirect_uri: process.env.VUE_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
  monitorSession: true,
};
