export enum MainRouteNameTypes {
  Default = "Default",
  Dashboard = "Dashboard",
  Buyers = "Buyers",
  BuyerDetails = "Buyer Details",
  LeadDetails = "Lead Details",
  Orders = "Orders",
  OrderDetails = "Order Details",
  CartDetails = "Cart Details",
  InvoiceDetails = "Invoice Details",
  InvoicePreview = "Invoice Preview",
  Credit = "Credit",
  Settings = "Settings",
  NoDivisionsSetUp = "NoDivisionsSetUp",
  OidcCallback = "OidcCallback",
  OidcSilentRenewCallback = "OidcSilentRenewCallback",
  OidcCallbackError = "OidcCallbackError",
  HandshakeLogin = "HandshakeLogin",
  HandshakeError = "HandshakeError",
  HandshakeSignOut = "HandshakeSignOut",
  NotFound = "Not Found",
}
