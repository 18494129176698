import { LabelDto, ShipLinksDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShippingLabelsDto {
  @JsonProperty() total!: number;
  @JsonProperty() page!: number;
  @JsonProperty() pages!: number;
  @JsonProperty() links!: ShipLinksDto;
  @JsonProperty() additionalProperties!: Record<string, string>;
  @JsonProperty({ type: LabelDto }) labels!: LabelDto[];
}
