import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3447f8de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-container" }
const _hoisted_2 = { class: "box-actions" }
const _hoisted_3 = { class: "grid-wrapper" }
const _hoisted_4 = { class: "left-panel" }
const _hoisted_5 = { class: "right-panel" }
const _hoisted_6 = {
  key: 0,
  class: "spinner-wrapper"
}
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AdminButton = _resolveComponent("AdminButton")!
  const _component_JunSpinner = _resolveComponent("JunSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["box-title", _ctx.noShadow ? null : 'shadow-box'])
    }, [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isViewStatus && !_ctx.hideIcon)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              onClick: _ctx.onEditClick,
              name: _ctx.IconNameTypes.Edit,
              size: 1,
              class: "edit-icon"
            }, null, 8, ["onClick", "name"]))
          : _createCommentVNode("", true),
        (_ctx.isEditStatus || _ctx.isSavingStatus)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_AdminButton, {
                size: "sm",
                minWidth: 6,
                color: "outline",
                onClick: _ctx.onCancelClick,
                disabled: _ctx.isCancelDisabled,
                inactiveFilled: _ctx.isCancelDisabled
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "inactiveFilled"]),
              _createVNode(_component_AdminButton, {
                size: "sm",
                minWidth: 6,
                class: "box-save",
                onClick: _ctx.onSaveClick,
                loading: _ctx.isSavingStatus,
                disabled: _ctx.isSaveDisabled,
                inactiveFilled: _ctx.isSaveDisabled
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["onClick", "loading", "disabled", "inactiveFilled"])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.noPadding ? null : 'slot-wrapper')
    }, [
      _renderSlot(_ctx.$slots, "top-content", {}, undefined, true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "sidebar", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.isLoadingStatus)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_JunSpinner, {
                  class: "loading-spinner",
                  size: "3rem"
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.isEditStatus || _ctx.isSavingStatus)
                  ? _renderSlot(_ctx.$slots, "editing", { key: 0 }, undefined, true)
                  : _createCommentVNode("", true),
                (_ctx.isViewStatus)
                  ? _renderSlot(_ctx.$slots, "viewing", { key: 1 }, undefined, true)
                  : _createCommentVNode("", true)
              ]))
        ])
      ]),
      _renderSlot(_ctx.$slots, "bottom-content", {}, undefined, true)
    ], 2)
  ]))
}