import {
  BackupDataService,
  BrandRepository,
  HttpClientService,
  IndustryCategoryService,
  AzureMapRepository,
  ProductRepository,
  AutoPublishRepository,
  ManufacturersRepository,
  ImageUrlService,
  OrderingRepository,
  ProductAttributesRepository,
  CategoriesRepository,
  PricingSettingsRepository,
  ProductSettingsRepository,
  BrandSettingsRepository,
  CustomersRepository,
  OrdersRepository,
  BrandOrdersRepository,
  CartsRepository,
  HandshakeRepository,
  ImagesRepository,
  AccessRequestsRepository,
  PaymentSettingsRepository,
  StaticRepository,
  ManufacturerSettingsRepository,
  AppUsersRepository,
  InvoicesRepository,
  ShippingSettingsRepository,
  ShippingConnectionsRepository,
  ShippingAccountRepository,
  ShippingRatesRepository,
  ShippingLabelsRepository,
  ShipmentsRepository,
  ShipmentsIndependentRepository,
  ShippingCarrierAccountsRepository,
  TransactionsRepository,
  SellerRepository,
  PaymentsRepository,
  ShippingTrackingRepository,
  OrdersExportRepository,
  DisputesRepository,
  FeatureFlagService,
  SegmentService,
  ErrorHandlerService,
  OidcService,
  MarketPaymentSettingsRepository,
  ReportsRepository,
} from "@/services";
import type {
  IBrandRepository,
  IProductRepository,
  IAutoPublishRepository,
  IManufacturersRepository,
  IOrderingRepository,
  IProductAttributesRepository,
  ICategoriesRepository,
  IPricingSettingsRepository,
  IProductSettingsRepository,
  IBrandSettingsRepository,
  ICustomersRepository,
  IOrdersRepository,
  IBrandOrdersRepository,
  ICartsRepository,
  IHandshakeRepository,
  IImagesRepository,
  IAccessRequestsRepository,
  IAzureMapRepository,
  IPaymentSettingsRepository,
  IStaticRepository,
  IManufacturerSettingsRepository,
  IAppUsersRepository,
  IInvoicesRepository,
  IShippingSettingsRepository,
  IShippingConnectionsRepository,
  IShippingAccountRepository,
  IShippingRatesRepository,
  IShippingLabelsRepository,
  IShipmentsRepository,
  IShipmentsIndependentRepository,
  IShippingCarrierAccountsRepository,
  IPaymentsRepository,
  IShippingTrackingRepository,
  ISellerRepository,
  ITransactionsRepository,
  IOrdersExportRepository,
  IDisputesRepository,
  IMarketPaymentSettingsRepository,
  IReportsRepository,
  IFeatureFlagService,
} from "@/types";
import { container } from "inversify-props";

export abstract class IoCHelper {
  public static buildDependencyContainer(): void {
    container.addSingleton<IFeatureFlagService>(FeatureFlagService);
    container.addSingleton<IProductRepository>(ProductRepository);
    container.addSingleton<IProductAttributesRepository>(
      ProductAttributesRepository
    );
    container.addSingleton<IProductSettingsRepository>(
      ProductSettingsRepository
    );
    container.addSingleton<IBrandRepository>(BrandRepository);
    container.addSingleton<IBrandSettingsRepository>(BrandSettingsRepository);
    container.addSingleton<IAutoPublishRepository>(AutoPublishRepository);
    container.addSingleton<IManufacturersRepository>(ManufacturersRepository);
    container.addSingleton<HttpClientService>(HttpClientService);
    container.addSingleton<BackupDataService>(BackupDataService);
    container.addSingleton<IndustryCategoryService>(IndustryCategoryService);
    container.addSingleton<ImageUrlService>(ImageUrlService);
    container.addSingleton<IAzureMapRepository>(AzureMapRepository);
    container.addSingleton<IOrderingRepository>(OrderingRepository);
    container.addSingleton<ICustomersRepository>(CustomersRepository);
    container.addSingleton<ICategoriesRepository>(CategoriesRepository);
    container.addSingleton<IHandshakeRepository>(HandshakeRepository);
    container.addSingleton<IPricingSettingsRepository>(
      PricingSettingsRepository
    );
    container.addSingleton<IOrdersRepository>(OrdersRepository);
    container.addSingleton<IBrandOrdersRepository>(BrandOrdersRepository);
    container.addSingleton<ICartsRepository>(CartsRepository);
    container.addSingleton<IImagesRepository>(ImagesRepository);
    container.addSingleton<IAccessRequestsRepository>(AccessRequestsRepository);
    container.addSingleton<IPaymentSettingsRepository>(
      PaymentSettingsRepository
    );
    container.addSingleton<IManufacturerSettingsRepository>(
      ManufacturerSettingsRepository
    );
    container.addSingleton<IShippingSettingsRepository>(
      ShippingSettingsRepository
    );
    container.addSingleton<IShippingCarrierAccountsRepository>(
      ShippingCarrierAccountsRepository
    );
    container.addSingleton<IShippingConnectionsRepository>(
      ShippingConnectionsRepository
    );
    container.addSingleton<IShippingAccountRepository>(
      ShippingAccountRepository
    );
    container.addSingleton<IShippingRatesRepository>(ShippingRatesRepository);
    container.addSingleton<IShippingLabelsRepository>(ShippingLabelsRepository);
    container.addSingleton<IShipmentsRepository>(ShipmentsRepository);
    container.addSingleton<IShipmentsIndependentRepository>(
      ShipmentsIndependentRepository
    );
    container.addSingleton<IPaymentsRepository>(PaymentsRepository);
    container.addSingleton<IShippingTrackingRepository>(
      ShippingTrackingRepository
    );
    container.addSingleton<IStaticRepository>(StaticRepository);
    container.addSingleton<IAppUsersRepository>(AppUsersRepository);
    container.addSingleton<IInvoicesRepository>(InvoicesRepository);
    container.addSingleton<ISellerRepository>(SellerRepository);
    container.addSingleton<ITransactionsRepository>(TransactionsRepository);
    container.addSingleton<IOrdersExportRepository>(OrdersExportRepository);
    container.addSingleton<IMarketPaymentSettingsRepository>(
      MarketPaymentSettingsRepository
    );
    container.addSingleton<IDisputesRepository>(DisputesRepository);
    container.addSingleton<SegmentService>(SegmentService);
    container.addSingleton<ErrorHandlerService>(ErrorHandlerService);
    container.addSingleton<OidcService>(OidcService);
    container.addSingleton<IReportsRepository>(ReportsRepository);
  }
}
