import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7758350"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_DashboardComponent = _resolveComponent("DashboardComponent")!
  const _component_JunSpinner = _resolveComponent("JunSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar, { title: "Dashboard" }),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_DashboardComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_JunSpinner, { size: "4rem" })
        ]))
  ], 64))
}