
import { Vue, Options } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { TabItem } from "@/types";

@Options({
  emits: ["tabChange"],
})
export default class TabbedBox extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  title!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  noPadding!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  noShadow!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Array,
    default: null,
  })
  tabs!: TabItem<unknown>[];

  private tabItems!: TabItem<unknown>[];

  created() {
    this.tabItems = this.tabs;
  }

  private setCurrent(tab: TabItem<unknown>) {
    this.$emit("tabChange", tab);
  }

  @Watch("tabs")
  updateTabs() {
    this.tabItems = this.tabs;
  }
}
