import { Utils } from "@/utils";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class MaskedValueDto {
  @JsonProperty() currency!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    beforeSerialize: Utils.extractNumberFromCurrency,
  })
  amount!: string | null;
}
