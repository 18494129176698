
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { IconNameTypes } from "@/types";
import type { DropdownItem, DropdownItemValue } from "@/types";
import { Icon, Checkbox, AdminInput } from "@/components";
import { VeeFieldMixin } from "@/mixins";

@Options({
  components: {
    Icon,
    Checkbox,
    AdminInput,
  },
  emits: ["update:modelValue", "change"],
})
export default class Dropdown extends mixins<
  [VueMixin<VeeFieldMixin<DropdownItemValue | DropdownItemValue[]>>]
>(VeeFieldMixin) {
  @Prop({
    type: Array,
    default: [],
  })
  items!: DropdownItem[];

  @Prop({
    type: Object,
    default: null,
  })
  defaultSelection!: DropdownItem;

  @Prop({
    type: String,
    default: null,
  })
  placeholder!: string;

  @Prop({
    type: [String, Number, Array],
    default: null,
  })
  modelValue!: DropdownItemValue | DropdownItemValue[];

  @Prop({
    type: [Number, String],
    default: 96,
  })
  width!: number | string;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  msg!: string;

  @Prop({
    type: String,
    default: null,
  })
  label!: string;

  @Prop({
    type: String,
    default: "",
    required: true,
  })
  name!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  searchable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideErrorBox!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideLabels!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  multi!: boolean;

  @Prop({
    type: Number,
    default: 1,
  })
  multiLimit!: number;

  @Prop({
    type: String,
    default: "No results found...",
  })
  noResultsMessage!: string;

  private showDropdown = false;
  private selection: DropdownItem | DropdownItem[] | null = this.initSelection;
  private IconNameTypes = IconNameTypes;
  private query = "";

  // on every v-model change we need to make sure we update selection state and
  // vee-validate state
  @Watch("modelValue")
  onModelValueChanged(val: DropdownItemValue | DropdownItemValue[]) {
    this.$emit("change", val);
    this.field.handleChange(val);

    if (Array.isArray(val)) {
      this.selection = this.items.filter((item) => val.includes(item.value));
    } else {
      this.selection = this.items.find((x) => x.value === val) || null;
    }
  }

  get initSelection() {
    if (this.defaultSelection) {
      return this.defaultSelection;
    }

    if (this.multi) {
      if (!Array.isArray(this.modelValue)) {
        return [];
      }

      const selection: DropdownItem[] = [];
      const value = this.modelValue as DropdownItemValue[];

      this.items.forEach((item) => {
        if (value.includes(item.value)) {
          selection.push(item);
        }
      });

      return selection;
    } else {
      return this.items.find((item) => item.value === this.modelValue) || null;
    }
  }

  get noCurrentSelection() {
    // no selection if currently null and null is not the value of an available option
    const noSingleSelection =
      this.selection === null &&
      !this.items.some((item) => item.value === null);
    const noMultiSelection =
      Array.isArray(this.selection) && this.selection.length === 0;

    return noSingleSelection || noMultiSelection;
  }

  get selectionDisplay() {
    if (Array.isArray(this.selection)) {
      const selectionsToDisplay = this.selection.slice(0, this.multiLimit);
      const extraSelectionsSize = this.selection.slice(this.multiLimit).length;

      const itemsString = selectionsToDisplay.map((x) => x.name).join(", ");
      const extraItemsString =
        extraSelectionsSize > 0
          ? ` + ${extraSelectionsSize} item${
              extraSelectionsSize > 1 ? "s" : ""
            }`
          : "";
      return itemsString + extraItemsString;
    } else {
      return this.selection?.name;
    }
  }

  get tooltipDisplay() {
    if (Array.isArray(this.selection)) {
      return this.selection.map((x) => x.name).join(", ");
    } else {
      return this.selection?.name;
    }
  }

  get filteredItems() {
    return this.items.filter((item) => {
      return item.name.toLowerCase().includes(this.query.toLowerCase());
    });
  }

  get cmpWidth() {
    return this.fullWidth ? "w-full" : `w-${this.width}`;
  }

  get hideMsgBox() {
    return this.hideErrorBox && (!this.error || !this.msg);
  }

  itemIsSelected(item: DropdownItem) {
    if (Array.isArray(this.selection)) {
      return this.selection.some((x) => x.value === item.value);
    }

    return this.selection?.value === item.value;
  }

  onItemClick(item: DropdownItem) {
    // if selection is array, we are in multi select mode. in that case add new item unless
    // it is already selected, in which case remove it
    if (Array.isArray(this.selection)) {
      let newSelection = [];

      if (this.selection.some((x) => x.value === item.value)) {
        newSelection = this.selection.filter((x) => x.value !== item.value);
      } else {
        newSelection = [...this.selection, item];
      }

      const newValues = newSelection.map((selection) => selection.value);
      this.handleItemsUpdate(newSelection, newValues);

      // non-multi method. passes item as is and closes dropdown on click
    } else {
      this.closeDropdown();

      if (this.selection?.value === item.value) {
        // if user clicks already selected item, clear the dropdown
        this.handleItemsUpdate(null, null);
      } else {
        this.handleItemsUpdate(item, item.value);
      }
    }
  }

  handleItemsUpdate(
    selection: DropdownItem | DropdownItem[] | null,
    value: DropdownItemValue | null
  ) {
    this.selection = selection;
    this.field.handleChange(value);
    this.$emit("update:modelValue", value);
  }

  onDropdownClick() {
    if (this.disabled) {
      return;
    }

    this.showDropdown = !this.showDropdown;

    if (this.searchable && this.showDropdown) {
      // nextTick makes sure function runs in next cycle when input has mounted
      this.$nextTick(() => {
        const searchRef = this.$refs.searchRef as typeof AdminInput;
        searchRef.focusInput();
      });
    }
  }

  closeDropdown() {
    this.showDropdown = false;
  }
}
