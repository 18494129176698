import { JsonProperty, Serializable } from "typescript-json-serializer";
import { IndependentShipmentDto } from "@/types";

@Serializable()
export class IndependentShipmentListDto {
  @JsonProperty() total!: number;
  @JsonProperty() page!: number;
  @JsonProperty() pages!: number;
  @JsonProperty() additionalProperties!: Record<string, {}>;
  @JsonProperty({ type: IndependentShipmentDto })
  shipments!: IndependentShipmentDto[];
}
