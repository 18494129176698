
import { Options, Vue } from "vue-class-component";
import { InjectReactive, Watch } from "vue-property-decorator";
import { InfoBox, Icon, DashboardBrandSelect } from "@/components";
import { IconNameTypes, ThirtyDayHistoryDto, TooltipPosition } from "@/types";
import type { IReportsRepository, IDashboardBrand } from "@/types";
import { inject } from "inversify-props";
import { Utils } from "@/utils";
import { namespace } from "vuex-class";

const auth = namespace("authVuexModule");

@Options({
  components: {
    InfoBox,
    Icon,
    DashboardBrandSelect,
  },
})
export default class DashboardTiles extends Vue {
  @auth.Getter private isMultiline!: boolean | null;

  @inject() private reportsRepository!: IReportsRepository;
  @InjectReactive() private brand!: IDashboardBrand;

  @Watch("brand.brandId", { immediate: true })
  private async onBrandChange(v: number, ov: number) {
    if (v !== ov) await this.init();
  }

  private IconNameTypes = IconNameTypes;
  private TooltipPosition = TooltipPosition;
  private loading = false;
  private thirtyDayHistory = new ThirtyDayHistoryDto();

  private get tileClassName() {
    return [`info-tile`, { multi: this.isMultiline }];
  }

  private get brandText() {
    return this.brand.brandCount ? `${this.brand.brandCount} total brands` : ``;
  }

  private get avgOrder() {
    return this.getFormattedPrice(this.thirtyDayHistory.averageOrderAmount);
  }

  private get totalSales() {
    return this.getFormattedPrice(this.thirtyDayHistory.totalSales);
  }

  private get newOrders() {
    return this.getFormattedNumber(this.thirtyDayHistory.newOrders);
  }

  private get inProgressOrders() {
    return this.getFormattedNumber(this.thirtyDayHistory.inProgressOrders);
  }

  private get closedOrders() {
    return this.getFormattedNumber(this.thirtyDayHistory.closedOrders);
  }

  private async init() {
    this.loading = true;
    const [data] = await Utils.try(
      this.isMultiline && this.brand.brandId
        ? this.reportsRepository.getThirtyDayHistory(this.brand.brandId)
        : this.reportsRepository.getThirtyDayHistory()
    );

    if (data) this.thirtyDayHistory = data;

    this.loading = false;
  }

  private getFormattedPrice(val: number) {
    return "$" + Utils.formatNumber(val);
  }

  private getFormattedNumber(val: number) {
    return Utils.formatNumber(val);
  }
}
