import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "table-wrapper h-256" }
const _hoisted_2 = {
  key: 0,
  "data-test-id": "status-cell"
}
const _hoisted_3 = {
  key: 1,
  "data-test-id": "buyer-name-cell"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NoResultsForFilter = _resolveComponent("NoResultsForFilter")!
  const _component_JunTable = _resolveComponent("JunTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_JunTable, {
      heading: _ctx.tableConfig.heading,
      headers: _ctx.tableConfig.headers,
      options: _ctx.tableConfig.options,
      loading: _ctx.tableConfig.loading,
      items: _ctx.tableConfig.items,
      itemKey: _ctx.tableConfig.itemKey,
      onSort: _ctx.onSort,
      onFilter: _ctx.onFilter,
      onPaginate: _ctx.onPaginate
    }, _createSlots({
      default: _withCtx(({ item, header }) => [
        (header.prop === 'status')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Tag, {
                type: _ctx.getStatusColor(item.status)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.status), 1)
                ]),
                _: 2
              }, 1032, ["type"])
            ]))
          : _createCommentVNode("", true),
        (header.prop === 'buyerName')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: `/${_ctx.MainRouteTypes.LeadDetails}/${item.leadId}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.buyerName), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.tableOptions.filters?.length)
        ? {
            name: "noresults",
            fn: _withCtx(() => [
              _createVNode(_component_NoResultsForFilter, { onGoBack: _ctx.onNoFilterResultsGoBack }, null, 8, ["onGoBack"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["heading", "headers", "options", "loading", "items", "itemKey", "onSort", "onFilter", "onPaginate"])
  ]))
}