import store from "@/store";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import type { IPublishStatus } from "@/types";

@Module({
  store,
  namespaced: true,
})
export default class PublishVuexModule extends VuexModule {
  private _status: IPublishStatus | null = null;

  // GETTERS
  public get isProfileValid() {
    return !this._status
      ? true
      : this._status.subComponents.companyDetails &&
          this._status.subComponents.images;
  }

  public get isCompanyDetailsValid() {
    return !this._status ? true : this._status.subComponents.companyDetails;
  }

  public get isImageUploadValid() {
    return !this._status ? true : this._status.subComponents.images;
  }

  public get isMsrpValid() {
    return !this._status ? true : this._status.subComponents.msrp;
  }

  // MUTATIONS
  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this._status = null;
  }

  @Mutation
  public setStatus(status: IPublishStatus) {
    this._status = status;
  }

  @Mutation
  public setCompanyDetailsAsValid() {
    if (this._status) {
      this._status.subComponents.companyDetails = true;
    }
  }

  @Mutation
  public setImageUploadAsValid() {
    if (this._status) {
      this._status.subComponents.images = true;
    }
  }

  @Mutation
  public setMsrpAsValid() {
    if (this._status) {
      this._status.subComponents.msrp = true;
    }
  }
}
