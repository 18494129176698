import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1fb5f6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-wrapper h-256 category-mapping-edit-table" }
const _hoisted_2 = {
  key: 0,
  class: "cell-wrapper",
  "data-test-id": "datafield-block"
}
const _hoisted_3 = { class: "field-dotted" }
const _hoisted_4 = { class: "field-dotted-text" }
const _hoisted_5 = {
  key: 1,
  "data-test-id": "category-block"
}
const _hoisted_6 = {
  key: 2,
  "data-test-id": "subcategory-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_JunTable = _resolveComponent("JunTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_JunTable, {
      headers: _ctx.tableHeaders,
      options: _ctx.tableOptions,
      items: _ctx.tableItems,
      loading: _ctx.isTableProcessing,
      itemKey: "dataField",
      onPaginate: _ctx.onPaginate,
      "data-test-id": "jun-table"
    }, {
      default: _withCtx(({ item, header }) => [
        (header.prop === 'dataField')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(item.dataField), 1)
              ]),
              _createVNode(_component_Icon, {
                class: "field-arrow ml-4",
                name: _ctx.IconNameTypes.ForwardArrow,
                size: 1.25
              }, null, 8, ["name", "size"])
            ]))
          : _createCommentVNode("", true),
        (header.prop === 'industry')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Dropdown, {
                class: "field-cat",
                items: _ctx.getIndustryDropdownItems(),
                modelValue: _ctx.modelValue[item.index].industry,
                "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.index].industry) = $event),
                placeholder: "Select a category",
                name: 'industry-' + item.index,
                searchable: "",
                onChange: ($event: any) => (_ctx.onIndustryChange(item.index)),
                "full-width": ""
              }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "name", "onChange"])
            ]))
          : _createCommentVNode("", true),
        (header.prop === 'subcategory')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_Dropdown, {
                class: "field-sub-cat",
                items: _ctx.getSubCategoryDropdownItems(item.index),
                modelValue: _ctx.modelValue[item.index].subcategory,
                "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.index].subcategory) = $event),
                placeholder: "Select a subcategory",
                name: 'subcategory-' + item.index,
                searchable: "",
                disabled: _ctx.modelValue[item.index].industry === null,
                "full-width": ""
              }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "name", "disabled"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["headers", "options", "items", "loading", "onPaginate"])
  ]))
}