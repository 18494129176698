
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { HTMLElementEvent } from "@/types";
import { VeeFieldMixin } from "@/mixins";

@Options({
  emits: ["update:modelValue"],
})
export default class RadioButton extends mixins<
  [VueMixin<VeeFieldMixin<string>>]
>(VeeFieldMixin) {
  @Prop({
    type: [Number, String],
    default: "",
  })
  modelValue!: string;

  @Prop({
    type: String,
    default: "",
  })
  name!: string;

  @Prop({
    type: [String, Number],
    required: true,
  })
  value!: string | number;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Watch("modelValue")
  onModelValueChanged(val: string) {
    this.field.inputValue = val;
  }

  onChange(e: HTMLElementEvent<HTMLInputElement>) {
    this.field.handleChange(e.target.value);
    this.$emit("update:modelValue", e.target.value);
  }
}
