
import { Vue, Options } from "vue-class-component";
import { ProvideReactive, Provide, Prop } from "vue-property-decorator";
import { inject } from "inversify-props";
import { Utils } from "@/utils";
import {
  TopBar,
  NavTabs,
  Breadcrumbs,
  OrderDetailsBox,
  OrderItemsBox,
} from "@/components";
import { OrderDto, MainRouteTypes, SubRouteTypes } from "@/types";
import type { IOrdersRepository } from "@/types";

@Options({
  components: {
    TopBar,
    NavTabs,
    Breadcrumbs,
    OrderDetailsBox,
    OrderItemsBox,
  },
})
export default class OrderDetails extends Vue {
  @inject() private ordersRepository!: IOrdersRepository;
  @ProvideReactive() private order: OrderDto | null = null;
  @ProvideReactive() private orderLoading = false;
  @Provide() private updateOrderState = this.updateOrder.bind(this);

  @Prop({
    type: String,
    required: true,
  })
  orderGUID!: string;

  @Prop({
    type: String,
  })
  brandId?: string;

  private MainRouteTypes = MainRouteTypes;

  async mounted() {
    const path = this.$route.params.subRoute[0];
    if (
      path !== SubRouteTypes.OrderShipping &&
      path !== SubRouteTypes.Summary
    ) {
      this.$router.push(
        `/${MainRouteTypes.OrderDetails}/${SubRouteTypes.Summary}?guid=${path}`
      );
      await this.init(path);
    }
  }
  async created() {
    this.orderGUID && (await this.init());
  }

  private async init(id?: string) {
    this.orderLoading = true;

    const res = await this.fetchData(id);
    const [data] = res.data;
    if (data) this.order = data;

    this.orderLoading = false;
  }

  async fetchData(id?: string) {
    const data = Utils.try(
      this.ordersRepository.get(
        id ?? this.orderGUID,
        this.brandId ? Number(this.brandId) : undefined
      )
    );

    return {
      data: await data,
    };
  }

  get topBarTitle() {
    return `Order # ${this.order?.orderID || ""}`;
  }

  get breadcrumbItems() {
    return ["Orders", "Order Details"];
  }

  private updateOrder(state: OrderDto) {
    this.order = state;
  }
}
