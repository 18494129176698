import { PaymentMethodDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class CatalogDetailDto {
  @JsonProperty() orderType!: string | null;
  @JsonProperty() orderStatus!: string | null;
  @JsonProperty() catalogName!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  cancelDate!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  doNotShipBefore!: string | null;
  @JsonProperty() poNumber!: string | null;
  // buyer preferred shipDate
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  shipDate!: string | null;
  @JsonProperty() shipVia!: string | null;
  @JsonProperty() terms!: string | null;
  @JsonProperty() freightTerms!: string | null;
  @JsonProperty() notes!: string | null;
  @JsonProperty() catalogCode!: string | null;
  @JsonProperty() paymentMethod!: PaymentMethodDto;
  @JsonProperty() shippingPreferredRateCode!: string | null;
}
