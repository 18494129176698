
import { Options, Vue } from "vue-class-component";
import { CategoryMappingField, EditableBox } from "@/components";
import {
  EditableBoxStatusTypes,
  CategoriesDto,
  IndustryCategoryDto,
  MappingsCategoryDto,
  ProductCategoryDto,
} from "@/types";
import type {
  CategoryMappingFieldData,
  IValidateResponse,
  ToastConfig,
  ICategoriesRepository,
} from "@/types";
import { JunTableColumn } from "@juniper/ui";
import { Form } from "vee-validate";
import * as yup from "yup";
import mapValues from "lodash/mapValues";
import { inject } from "inversify-props";
import { BackupDataService, IndustryCategoryService } from "@/services";
import { namespace } from "vuex-class";
import { Utils } from "@/utils";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    Form,
    EditableBox,
    CategoryMappingField,
  },
})
export default class CategoryMapping extends Vue {
  @inject() private backupDataService?: BackupDataService;
  @inject() private industryCategoryService?: IndustryCategoryService;
  @inject() private categoriesRepository?: ICategoriesRepository;

  @notifications.Mutation private createToastSuccess?: (
    payload: ToastConfig
  ) => void;
  @notifications.Action private handleAutoPublishOff!: () => void;

  private status = EditableBoxStatusTypes.View;
  private tableHeaders: JunTableColumn[] = [
    {
      prop: "dataField",
      text: "Your Data Fields",
    },
    {
      prop: "industry",
      text: "Marketplace Category",
    },
    {
      prop: "subcategory",
      text: "Marketplace Subcategory",
    },
  ];

  private isTableProcessing = false;
  private industries: Array<IndustryCategoryDto> | null = null;
  private industrySubCategories: Array<IndustryCategoryDto> | null = null;
  private productCategories: Array<ProductCategoryDto> | null = null;
  private categoryMappingFields: Array<CategoryMappingFieldData> = [];
  private existingCategoryMappings: Array<MappingsCategoryDto> = [];

  private validationSchema = yup.lazy((obj) =>
    yup.object(
      // @ts-ignore
      mapValues(obj, (value, key: string) => {
        if (key.includes("subcategory-")) {
          const industry = "industry" + key.substr(11);
          if (obj[industry]) {
            return yup
              .string()
              .nullable()
              .required("This subcategory is a required field");
          }
        }
      })
    )
  );

  async created() {
    await this.init();
  }

  private async init(): Promise<void> {
    this.isTableProcessing = true;
    const [categories] = await Utils.try(this.categoriesRepository?.get());
    if (!categories) return;
    this.existingCategoryMappings = categories.mappings;
    this.industries = categories.industries;
    this.industrySubCategories = categories.leafCategories;
    this.productCategories = categories.productCategories;
    this.setCategoryMappingFields(categories);
    this.isTableProcessing = false;
  }

  private get isNoShadowSlot(): boolean {
    return this.status === EditableBoxStatusTypes.Edit;
  }

  private get tableItems() {
    return this.categoryMappingFields.map((field) => {
      return {
        dataField: field.dataField,
        industry:
          this.industryCategoryService?.getIndustryName(
            this.industries,
            field.industry
          ) ?? null,
        subcategory:
          this.industryCategoryService?.getSubcategoryName(
            this.industrySubCategories,
            field.subcategory
          ) ?? null,
      };
    });
  }

  private setCategoryMappingFields({
    productCategories,
    mappings,
    leafCategories,
    industries,
  }: CategoriesDto) {
    this.categoryMappingFields = productCategories.map((productCategory) => {
      const associatedMapping = mappings
        .filter((map) => !map.isDeleted)
        .find((map) => map.productCategoryId === productCategory.categoryId);

      const associatedLeafCategory = leafCategories.find((subCat) => {
        return subCat.appCategoryId === associatedMapping?.appCategoryId;
      });

      const associatedIndustry = industries.find((industry) => {
        return (
          industry.appCategoryId ===
          associatedLeafCategory?.industryAppCategoryId
        );
      });

      return {
        dataField: productCategory.name,
        industry: associatedIndustry?.appCategoryId || null,
        subcategory: associatedLeafCategory?.appCategoryId || null,
      } as CategoryMappingFieldData;
    });
  }

  private buildMappings(): MappingsCategoryDto[] {
    const newAddedCategoryMappings = this.categoryMappingFields
      .filter((field) => field.industry)
      .map((field) => {
        const mappingsCategory = new MappingsCategoryDto();
        mappingsCategory.appCategoryId = field.subcategory || 0;
        mappingsCategory.productCategoryId =
          this.industryCategoryService?.getProductCategoryId(
            this.productCategories,
            field.dataField
          ) ?? 0;
        mappingsCategory.isDeleted = false;
        return mappingsCategory;
      });
    const newDeletedCategoryMappings = this.existingCategoryMappings
      .filter(
        (exiCatMap) =>
          !exiCatMap.isDeleted &&
          !newAddedCategoryMappings.some(
            (newCatMap) =>
              newCatMap.appCategoryId === exiCatMap.appCategoryId &&
              newCatMap.productCategoryId === exiCatMap.productCategoryId
          )
      )
      .map((newDelCatMap) => ({ ...newDelCatMap, isDeleted: true }));
    return [
      ...this.existingCategoryMappings.filter(
        (exiCatMap) => exiCatMap.isDeleted
      ), // CategoriesRepository.put requires the entire mappings array, including previously deleted entries.
      ...newAddedCategoryMappings,
      ...newDeletedCategoryMappings,
    ];
  }

  private onEditableBoxEdit() {
    this.backupDataService?.saveBackup(this, this.categoryMappingFields);
    this.status = EditableBoxStatusTypes.Edit;
  }

  private onEditableBoxCancel() {
    this.backupDataService?.restoreBackup(this, this.categoryMappingFields);
    this.status = EditableBoxStatusTypes.View;
  }

  private async onEditableBoxSave() {
    const validateResponse: IValidateResponse = await (
      this.$refs.form as HTMLFormElement
    ).validate();

    if (validateResponse.valid) {
      try {
        this.status = EditableBoxStatusTypes.Saving;
        const updatedCategoryMappings = this.buildMappings();
        await this.categoriesRepository?.put(updatedCategoryMappings);
        this.createToastSuccess?.({
          message: "Your category mappings have been successfully saved!",
        });
        this.existingCategoryMappings = updatedCategoryMappings;
        this.handleAutoPublishOff();
        this.status = EditableBoxStatusTypes.View;
      } catch (err) {
        this.status = EditableBoxStatusTypes.Edit;
      }
    }
  }
}
