
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

//TODO: Move this to types directory
enum TagType {
  Error = "error",
  Success = "success",
  Warning = "warning",
}

@Options({})
export default class Tag extends Vue {
  @Prop({
    type: String,
    default: TagType.Error,
  })
  type!: string;
}
