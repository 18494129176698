import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  ShippingCarrierAccountDto,
  IShippingCarrierAccountsRepository,
} from "@/types";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/ShippingCarrierAccounts?isShared=true`;

@injectable()
export class ShippingCarrierAccountsRepository
  implements IShippingCarrierAccountsRepository
{
  public async getCarriers(): Promise<Array<ShippingCarrierAccountDto>> {
    const { data } = await axios.get(ENDPOINT_URL);
    const sharedAccounts: Array<ShippingCarrierAccountDto> =
      data.carrierAccounts;
    return sharedAccounts.map((sca: ShippingCarrierAccountDto) => {
      return deserialize<ShippingCarrierAccountDto>(
        sca,
        ShippingCarrierAccountDto
      );
    });
  }
}
