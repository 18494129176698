import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class ProductSetDto {
  @JsonProperty() id!: string;

  @JsonProperty({
    beforeDeserialize: Utils.numberToString,
  })
  numberOfProducts!: string | null;

  @JsonProperty() name!: string | null;
  @JsonProperty() description!: string | null;
  @JsonProperty() enabled!: boolean;
}
