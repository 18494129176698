
import { Options, Vue } from "vue-class-component";
import { Prop, Watch, Inject } from "vue-property-decorator";
import {
  CarrierTypes,
  EditableBoxStatusTypes,
  ShippingSettingsDto,
} from "@/types";
import { CountryCarriersConfig } from "@/config";
import {
  Dropdown,
  Fedex,
  FedexCa,
  Ups,
  StampsCom,
  DhlExpress,
  Canpar,
} from "@/components";
import { Utils } from "@/utils";
import { AxiosResponse } from "axios";
import { PropType } from "@vue/runtime-core";

@Options({
  components: {
    Dropdown,
    Fedex,
    FedexCa,
    Ups,
    StampsCom,
    DhlExpress,
    Canpar,
  },
})
export default class ShipWithPreferredCarrierSettings extends Vue {
  @Prop({
    type: String,
    default: EditableBoxStatusTypes.View,
  })
  status!: EditableBoxStatusTypes;

  @Prop({
    type: String,
  })
  carrierType!: CarrierTypes;

  @Prop({
    type: Object as PropType<ShippingSettingsDto>,
  })
  shippingSettings!: ShippingSettingsDto;

  @Inject() disconnectAccount!: () => Promise<AxiosResponse | null | undefined>;

  @Watch("selectedCarrier")
  onCarrierChanged() {
    if (this.shippingSettings.isCarrierActive) {
      this.disconnectAccount();
    }
  }

  created() {
    this.selectedCarrier = this.carrierType
      ? this.carrierType
      : this.shippingSettings.carrierName;
  }

  private selectedCarrier = this.shippingSettings.carrierName;

  get carrierDropdownItems() {
    return CountryCarriersConfig[this.shippingSettings.shipFromCountry].map(
      (carrier) => {
        return {
          name: Utils.getShippingCarrierConfig(carrier)?.label || carrier,
          value: carrier,
        };
      }
    );
  }
}
