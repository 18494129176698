import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, Transition as _Transition, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d6d8afb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cmp-wrapper" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "whitespace-nowrap"
}
const _hoisted_4 = {
  key: 0,
  class: "msgContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_AdminInput = _resolveComponent("AdminInput")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([_ctx.separator && { '--separator': `'${_ctx.separator}'` }]),
    class: _normalizeClass([
      'cmp-container',
      'multi-input',
      _ctx.cmpWidth,
      { seperated: _ctx.separator },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.hideLabels)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.localInputs[0].name,
            class: _normalizeClass({ error: _ctx.error, disabled: _ctx.disabled })
          }, [
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
              : _createCommentVNode("", true),
            (_ctx.tooltipMsg)
              ? (_openBlock(), _createBlock(_component_Tooltip, {
                  key: 1,
                  msg: _ctx.tooltipMsg
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, {
                      class: "tooltip-icon",
                      name: _ctx.IconNameTypes.Info,
                      size: 1
                    }, null, 8, ["name"])
                  ]),
                  _: 1
                }, 8, ["msg"]))
              : _createCommentVNode("", true)
          ], 10, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['input-container', _ctx.cmpWidth])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localInputs, (input) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "input-wrapper",
            key: input.name
          }, [
            _createVNode(_component_AdminInput, {
              modelValue: input.value,
              "onUpdate:modelValue": ($event: any) => ((input.value) = $event),
              onError: _ctx.handleError,
              hideLabels: "",
              type: input.type,
              hideArrows: input.type === 'number',
              name: `${_ctx.name}.${input.name}`,
              width: input.width || 16,
              placeholder: input.placeholder,
              innerLabel: input.label,
              disableAutocomplete: _ctx.disableAutocomplete,
              onFocus: _ctx.onFocus,
              onBlur: _ctx.onBlur,
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onError", "type", "hideArrows", "name", "width", "placeholder", "innerLabel", "disableAutocomplete", "onFocus", "onBlur", "disabled"])
          ]))
        }), 128))
      ], 2)
    ]),
    (!_ctx.hideMsgBox)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_Transition, { name: "slide-up" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(['cmp-message', { error: _ctx.error }])
              }, _toDisplayString(_ctx.error ? _ctx.errorMessage : _ctx.msg), 3)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 6))
}