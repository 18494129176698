import { Vue } from "vue-class-component";
import {
  DocumentExtended,
  TabHiddenStateNameTypes,
  TabVisibilityChangeEventNameTypes,
} from "@/types";

export class TabVisibilityMixin extends Vue {
  protected hiddenState: TabHiddenStateNameTypes =
    TabHiddenStateNameTypes.hidden;
  protected visibilityChangeEvent: TabVisibilityChangeEventNameTypes =
    TabVisibilityChangeEventNameTypes.visibilityChange;

  protected onTabGainedVisibility: (() => Promise<unknown> | unknown) | null =
    null;
  protected onTabLostVisibility: (() => Promise<unknown> | unknown) | null =
    null;

  created(): void {
    // Store the correct visibility hidden state name and visibility change event name
    // based on the browser type
    if (typeof document.hidden !== "undefined") {
      // Handle Opera 12.10 & Firefox 18 onwards
      this.hiddenState = TabHiddenStateNameTypes.hidden;
      this.visibilityChangeEvent =
        TabVisibilityChangeEventNameTypes.visibilityChange;
    } else if (typeof (document as DocumentExtended).msHidden !== "undefined") {
      // Handle IE
      this.hiddenState = TabHiddenStateNameTypes.msHidden;
      this.visibilityChangeEvent =
        TabVisibilityChangeEventNameTypes.msVisibilityChange;
    } else if (
      typeof (document as DocumentExtended).webkitHidden !== "undefined"
    ) {
      // Handle other browsers
      this.hiddenState = TabHiddenStateNameTypes.webkitHidden;
      this.visibilityChangeEvent =
        TabVisibilityChangeEventNameTypes.webkitVisibilityChange;
    }
  }

  mounted(): void {
    document.addEventListener(
      this.visibilityChangeEvent,
      this.handleVisibilityChange
    );
  }

  beforeUnmount(): void {
    document.removeEventListener(
      this.visibilityChangeEvent,
      this.handleVisibilityChange
    );
  }

  protected async handleVisibilityChange(): Promise<void> {
    if ((document as DocumentExtended)[this.hiddenState]) {
      await this.onTabLostVisibility?.();
    } else {
      await this.onTabGainedVisibility?.();
    }
  }
}
