import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-wrapper h-128",
  "data-test-id": "table-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunTable = _resolveComponent("JunTable")!
  const _component_AttributeMappingField = _resolveComponent("AttributeMappingField")!
  const _component_Form = _resolveComponent("Form")!
  const _component_EditableBox = _resolveComponent("EditableBox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EditableBox, {
      title: "Attribute Mapping",
      status: _ctx.status,
      noPadding: true,
      noShadow: _ctx.isNoShadowSlot,
      onEdit: _ctx.onEditableBoxEdit,
      onCancel: _ctx.onEditableBoxCancel,
      onSave: _ctx.onEditableBoxSave,
      "data-test-id": "editable-box"
    }, {
      viewing: _withCtx(() => [
        (_ctx.tableConfig)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_JunTable, {
                headers: _ctx.tableConfig.headers,
                options: _ctx.tableConfig.options,
                items: _ctx.tableConfig.items,
                loading: _ctx.tableConfig.loading,
                itemKey: _ctx.tableConfig.itemKey
              }, null, 8, ["headers", "options", "items", "loading", "itemKey"])
            ]))
          : _createCommentVNode("", true)
      ]),
      editing: _withCtx(() => [
        _createVNode(_component_Form, {
          ref: "form",
          class: "form",
          "data-test-id": "edit-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AttributeMappingField, {
              modelValue: _ctx.attributeMappingFields,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.attributeMappingFields) = $event)),
              userDefinedFields: _ctx.productSettings.userDefinedFields
            }, null, 8, ["modelValue", "userDefinedFields"])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["status", "noShadow", "onEdit", "onCancel", "onSave"])
  ]))
}