import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  IShippingConnectionsRepository,
  ShippingCarrierSettingsDto,
  CarrierTypes,
  CarrierAccountInfo,
} from "@/types";
import { serialize, deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Shipments/Connections`;

@injectable()
export class ShippingConnectionsRepository
  implements IShippingConnectionsRepository
{
  public async post(
    carrierInfo: CarrierAccountInfo,
    carrierType: CarrierTypes
  ): Promise<ShippingCarrierSettingsDto> {
    let carrierId = "";

    switch (carrierType) {
      case CarrierTypes.FedEx:
        carrierId = "Fedex";
        break;
      case CarrierTypes.FedExCA:
        carrierId = "FedexCa";
        break;
      case CarrierTypes.UPS:
        carrierId = "Ups";
        break;
      case CarrierTypes.USPS:
        carrierId = "Stamps";
        break;
      case CarrierTypes.DHL:
        carrierId = "DhlExpress";
        break;
      case CarrierTypes.Canpar:
        carrierId = "CanparCa";
        break;
    }

    const payload = serialize(carrierInfo);
    const { data } = await axios.post(`${ENDPOINT_URL}/${carrierId}`, payload);
    return deserialize<ShippingCarrierSettingsDto>(
      data,
      ShippingCarrierSettingsDto
    );
  }

  public async postShared(
    carrierId: number
  ): Promise<ShippingCarrierSettingsDto> {
    const { data } = await axios.post(`${ENDPOINT_URL}/Shared/${carrierId}`);
    return deserialize<ShippingCarrierSettingsDto>(
      data,
      ShippingCarrierSettingsDto
    );
  }

  public async delete(carrierId: number) {
    return axios.delete(`${ENDPOINT_URL}/${carrierId}`);
  }

  public async deleteShared(carrierId: number) {
    return axios.delete(`${ENDPOINT_URL}/Shared/${carrierId}`);
  }
}
