
import { Options, mixins } from "vue-class-component";
import { ChartMixin } from "@/mixins";
import { Watch, Prop, InjectReactive } from "vue-property-decorator";
import { Box, Dropdown, Icon, Checkbox } from "@/components";
import VueApexCharts from "vue3-apexcharts";
import { DashboardComponentConfig } from "@/types";
import type { IDashboardBrand } from "@/types";
import { Utils } from "@/utils";
import { ChartColorsConfig } from "@/config";

@Options({
  components: {
    Box,
    Dropdown,
    VueApexCharts,
    Icon,
    Checkbox,
  },
})
export default class DashboardOrdersChart extends mixins(ChartMixin) {
  @Prop({
    type: Object,
    required: true,
  })
  private config!: DashboardComponentConfig;

  @InjectReactive() private brand!: IDashboardBrand;

  @Watch("brand.brandId", { immediate: true })
  private async onBrandIdChange(v: number, ov: number) {
    if (v !== ov) await this.init();
  }

  @Watch("compare")
  @Watch("chartSort")
  private onDataChange() {
    this.loading = true;
    this.setChartOptions();
  }

  private async init() {
    await this.fetchData(this.config.multiline, this.brand.brandId);
    this.chartId = "ordersChart";
    this.setChartOptions();
  }

  private get orderPoints() {
    return [
      ...(this.compare
        ? this.previousData.map((v, i) => {
            return {
              x: this.dateRange()[i],
              y: v,
              marker: {
                size: 5,
                strokeColor: ChartColorsConfig.compare[0],
              },
            };
          })
        : []),
      ...this.currentData.map((v, i) => {
        return {
          x: this.dateRange()[i],
          y: v,
          marker: {
            size: 5,
            strokeColor: ChartColorsConfig.compare[1],
          },
        };
      }),
    ];
  }

  private setChartOptions() {
    this.currentData =
      this.getCurrentData?.map((d) => Math.floor(d.numberOfOrders)) ?? [];
    this.previousData =
      this.getPreviousData?.map((d) => Math.floor(d.numberOfOrders)) ?? [];
    this.points = this.orderPoints;
    this.dates = this.dateRange();
    this.customOptions = {
      yaxis: {
        labels: {
          formatter: (val: number) => {
            return Utils.formatNumber(val);
          },
        },
      },
    };
    this.setChart();
  }
}
