
import { mixins, Options } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { inject } from "inversify-props";
import {
  Drawer,
  Icon,
  AdminButton,
  AssociateCustomerModal,
  InfoList,
  SalesRepPopup,
  NoResultsForFilter,
} from "@/components";
import { Utils } from "@/utils";
import {
  AppUserCustomerDto,
  MainRouteTypes,
  CustomerDto,
  TooltipPosition,
  InfoListVerticalAlignmentTypes,
  TableFilterTypes,
} from "@/types";
import type {
  BuyerTableItem,
  INewCustomer,
  ICustomerPostPayload,
  IAppUsersRepository,
  TableConfig,
  InfoListItem,
  DropdownItem,
} from "@/types";
import { SalesRepMixin, TableMixin } from "@/mixins";
import { namespace } from "vuex-class";

const staticContent = namespace("staticContentVuexModule");
const manufacturers = namespace("manufacturersVuexModule");

@Options({
  components: {
    Drawer,
    Icon,
    AdminButton,
    AssociateCustomerModal,
    InfoList,
    SalesRepPopup,
    NoResultsForFilter,
  },
})
export default class ActiveBuyersDrawer extends mixins(
  TableMixin,
  SalesRepMixin
) {
  @inject() private appUsersRepository!: IAppUsersRepository;

  @staticContent.Getter private priceLevelsDropdownOptions?: DropdownItem[];
  @manufacturers.Getter private manufacturerId!: number | null;

  @Prop({
    type: Object,
  })
  customer!: CustomerDto | null;

  private MainRouteTypes = MainRouteTypes;
  private TooltipPosition = TooltipPosition;
  private InfoListVerticalAlignmentTypes = InfoListVerticalAlignmentTypes;
  private selectedItems: BuyerTableItem[] = [];
  private showMoveModal = false;
  private activeBuyers: AppUserCustomerDto[] = [];

  @Watch("manufacturerId")
  async onManufacturerIdChange(): Promise<void> {
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  @Watch("customer", { immediate: true, deep: true })
  private async onCustomerChange(customer: CustomerDto | null): Promise<void> {
    if (!customer) return;
    await this.initSalesRep({
      customerNumber: customer.customerNumber ?? undefined,
      repNumber: customer.repNumber ?? undefined,
    });
  }

  async created() {
    this.initTable();
    await this.init({ filterVersion: TableFilterTypes.OData });
  }

  private get customerInfoA(): InfoListItem[] {
    if (!this.customer) return [];
    const infoList: InfoListItem[] = [
      {
        label: "Customer name",
        text: this.customer.name ?? "N/A",
      },
      {
        label: "Customer #",
        text: this.customer.customerNumber ?? "N/A",
      },
      {
        label: "Price Level",
        text:
          this.priceLevelsDropdownOptions?.find(
            (level) => level.value === this.customer?.priceLevel
          )?.name ?? "N/A",
      },
    ];
    if (!this.salesRepLoading) {
      infoList.splice(2, 0, {
        label: "Sales Rep.",
        ...(this.salesRep ? { id: "salesRep" } : { text: "N/A" }),
      });
    }
    return infoList;
  }

  private get customerInfoB(): InfoListItem[] {
    if (!this.customer) return [];
    return [
      {
        label: "Billing Address",
        text: this.customer.address1 ?? "N/A",
      },
      {
        label: "Shipping Address",
        text: this.customer.shipToAddress1 ?? "N/A",
      },
    ];
  }

  private onNoFilterResultsGoBack() {
    this.removeLatestFilter(TableFilterTypes.OData);
  }

  get actionsDisabled() {
    return this.selectedItems.length === 0;
  }

  get appUserIds() {
    return this.selectedItems.map((customer) => customer.appUserId);
  }

  get tableConfig(): TableConfig {
    return {
      heading: "Active Buyers",
      loading: this.isTableProcessing,
      headers: this.tableHeaders,
      options: this.tableOptions,
      items: this.buildTableItems(this.activeBuyers),
      itemKey: "createdOn",
    };
  }

  async initTable() {
    this.tableHeaders = [
      {
        prop: "buyerName",
        text: "Buyer Name",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "emailAddress",
        text: "Email Address",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "createdOn",
        text: "Created On",
        canSort: true,
        canFilter: true,
      },
      {
        prop: "industry",
        text: "Industry",
        canSort: true,
        canFilter: true,
      },
    ];
    this.page = 1;
    this.pageSize = 10;
    this.sortBy = "buyerName";
    this.tableOptions.sortOptions = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
    this.persistentFilters = [
      { key: "CustomerNumber", value: this.customer?.customerNumber || "" },
    ];
    this.dateFilterKeys.push("createdOn");
    this.getTableData = this.getActiveBuyers;
  }

  onSuccess() {
    this.init({ filterVersion: TableFilterTypes.OData });
    this.selectedItems = [];
  }

  private async getActiveBuyers() {
    const queryString = this.getQueryString();
    const { data, meta } = await this.appUsersRepository.getAppUserCustomers(
      queryString
    );

    this.activeBuyers = data;
    this.tableOptions.paginationOptions = {
      page: meta.page,
      pageSize: meta.pageSize,
      totalRecords: meta.totalRecords,
    };
  }

  private buildTableItems(customers: AppUserCustomerDto[]): BuyerTableItem[] {
    return customers.map((customer) => {
      return {
        buyerName: customer.profile.buyerName,
        emailAddress: customer.profile.emailAddress,
        createdOn: customer.createdOn,
        appUserId: Utils.getAppUserIdFromAppUserCustomer(customer),
        industry: customer.businessInfo.industry,
        customerNumber: customer.customerNumber,
      };
    });
  }

  moveToExistingCustomer(customerNumber: string | null) {
    const promises: Promise<void>[] = [];

    this.appUserIds.forEach((id) => {
      const promise = this.appUsersRepository.changeCustomerNumber(
        id,
        customerNumber
      );
      promises.push(promise);
    });

    return Promise.allSettled(promises);
  }

  async moveToNewCustomer(customer: ICustomerPostPayload) {
    // create new customer, then use response to move to resulting customer number
    const newCustomer = await this.customersRepository.post(customer);

    const promises: Promise<void>[] = [];
    this.appUserIds.forEach((id) => {
      const promise = this.appUsersRepository.changeCustomerNumber(
        id,
        newCustomer.customerNumber
      );
      promises.push(promise);
    });

    return Promise.allSettled(promises);
  }

  get defaultCustomer(): INewCustomer {
    return {
      companyName: this.customer?.name,
      priceLevel: this.customer?.priceLevel,
      billingAddress: this.customer?.address1,
      billingAddress2: this.customer?.address2,
      billingCity: this.customer?.city,
      billingState: this.customer?.stateProvince,
      billingZipcode: this.customer?.postalCode,
      billingCountry: this.customer?.country,
      billingPhone: this.customer?.phone,
      billingFax: this.customer?.fax,
      shippingAddress: this.customer?.shipToAddress1,
      shippingAddress2: this.customer?.shipToAddress2,
      shippingContactName: this.customer?.shipToContactName,
      shippingCity: this.customer?.shipToCity,
      shippingState: this.customer?.shipToStateProvince,
      shippingZipcode: this.customer?.shipToPostalCode,
      shippingCountry: this.customer?.shipToCountry,
      shippingEmail: this.customer?.shipToEmail,
      shippingPhone: this.customer?.shipToPhone,
      shippingFax: this.customer?.shipToFax,
    };
  }
}
