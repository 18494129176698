
import { mixins, Options } from "vue-class-component";
import { AdminButton, Icon } from "@/components";
import { EditableBoxMixin } from "@/mixins";

@Options({
  components: {
    AdminButton,
    Icon,
  },
})
export default class EditableBoxSidebar extends mixins(EditableBoxMixin) {}
