import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f5fb9e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-container" }
const _hoisted_2 = {
  key: 0,
  class: "list-title"
}
const _hoisted_3 = {
  key: 1,
  class: "list-subtitle"
}
const _hoisted_4 = { class: "list-items" }
const _hoisted_5 = {
  key: 0,
  class: "list-item-images"
}
const _hoisted_6 = {
  key: 1,
  class: "list-items-sublist"
}
const _hoisted_7 = {
  key: 2,
  class: "list-item-value"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 3,
  class: "list-item-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Thumbnail = _resolveComponent("Thumbnail")!
  const _component_ImagePreview = _resolveComponent("ImagePreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.label,
          class: _normalizeClass([
          'list-item',
          {
            'items-center':
              _ctx.labelVerticalAlignment === _ctx.InfoListVerticalAlignmentTypes.Middle,
            'items-start':
              _ctx.labelVerticalAlignment === _ctx.InfoListVerticalAlignmentTypes.Top,
            'items-end':
              _ctx.labelVerticalAlignment === _ctx.InfoListVerticalAlignmentTypes.Bottom,
          },
        ])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["list-item-label", _ctx.itemLabelWidth || 'w-48'])
          }, _toDisplayString(item.label), 3),
          _renderSlot(_ctx.$slots, item.id, { item: item }, () => [
            (item.images)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.images, (image, index) => {
                      return (_openBlock(), _createBlock(_component_Thumbnail, {
                        key: index,
                        url: image
                      }, null, 8, ["url"]))
                    }), 128))
                  ]),
                  _createVNode(_component_ImagePreview, {
                    urls: item.images
                  }, null, 8, ["urls"])
                ]))
              : (item.list)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (subItem, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "w-full py-2"
                      }, _toDisplayString(subItem), 1))
                    }), 128))
                  ]))
                : (item.link)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("a", {
                        href: _ctx.formatUrl(item.link),
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }, _toDisplayString(_ctx.sanitizeUrl(item.link)), 9, _hoisted_8)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("span", {
                        class: _normalizeClass({ [item.textColor]: item.textColor })
                      }, [
                        _createTextVNode(_toDisplayString(item.text) + " ", 1),
                        (item.postText)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(item.postTextColor)
                            }, _toDisplayString(item.postText), 3))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]))
          ], true)
        ], 2))
      }), 128))
    ])
  ]))
}