import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ShippingCarrierAcknowledgement = _resolveComponent("ShippingCarrierAcknowledgement")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dropdown, {
      items: _ctx.carrierDropdownItems,
      disabled: _ctx.statusIsView,
      modelValue: _ctx.selectedCarrier,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCarrier) = $event)),
      placeholder: "Select Carrier",
      name: "carrier",
      msg: "required"
    }, null, 8, ["items", "disabled", "modelValue"]),
    _createVNode(_component_ShippingCarrierAcknowledgement, {
      status: _ctx.status,
      selectedShipMethod: _ctx.selectedShipMethod
    }, null, 8, ["status", "selectedShipMethod"])
  ]))
}