import {
  AddressDto,
  CatalogDetailDto,
  ContactDto,
  OrderChannelDto,
  OrderItemDto,
  OrderInvoiceDto,
} from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";
import { SalesRepDto } from "@/types";

@Serializable()
export class OrderDto {
  @JsonProperty() orderGUID!: string | null;
  @JsonProperty() billingAddress!: AddressDto;
  @JsonProperty() shippingAddress!: AddressDto;
  @JsonProperty() contact!: ContactDto;
  @JsonProperty({ type: OrderItemDto }) orderItems!: Array<OrderItemDto>;
  @JsonProperty() catalogDetail!: CatalogDetailDto;
  @JsonProperty() last4CC!: string; //TODO Review this (a last4CC already exist on PaymentMethodDto)
  @JsonProperty({ type: OrderInvoiceDto }) invoices!: OrderInvoiceDto[];
  @JsonProperty() shipToCode!: string;
  @JsonProperty() manufacturerOrderID!: string;
  @JsonProperty() manufacturerOrderStatus!: string;
  @JsonProperty() shipToCompanyName!: string;
  @JsonProperty() holdForConfirmation!: boolean | null;
  @JsonProperty() containerCubes!: number | null; //float
  @JsonProperty() containerName!: string;
  @JsonProperty() currencyCode!: string;
  @JsonProperty() customerID!: number;
  @JsonProperty() customerName!: string;
  @JsonProperty() customerNumber!: string;
  @JsonProperty() discount!: number; //float
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  exportDate!: string | null; //date
  @JsonProperty() exported!: boolean | null;
  @JsonProperty() freightOnBoard!: string;
  @JsonProperty() isContainer!: boolean | null;
  @JsonProperty() isPlaced!: boolean;
  @JsonProperty() manufacturerID!: number;
  @JsonProperty() marketName!: string;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  orderDate!: string | null; //date
  @JsonProperty() origin!: string;
  @JsonProperty() priceLevel!: number | null;
  @JsonProperty() repNumber!: string;
  @JsonProperty() salesRepID!: number;
  @JsonProperty({ type: SalesRepDto })
  salesRep!: SalesRepDto | null;
  @JsonProperty() source!: string;
  @JsonProperty() taxRate!: number; //float
  @JsonProperty() taxTotal!: number; //float
  @JsonProperty() writtenByName!: string;
  @JsonProperty() writtenForName!: string;
  @JsonProperty() writtenForRepNumber!: string;
  @JsonProperty() orderID!: number;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  orderTotal!: string; //float
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  shippingTotal!: string; //float
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
  })
  subTotal!: string; //float
  @JsonProperty() isDefault!: boolean | null;
  @JsonProperty() isActive!: boolean;
  @JsonProperty() originalPriceLevel!: number | null;
  @JsonProperty() channel!: OrderChannelDto;
  @JsonProperty() id!: number | null;
  @JsonProperty() modifiedOn!: string | null; //date
  @JsonProperty() createdOn!: string | null; //date
  @JsonProperty() createdBy!: string;
  @JsonProperty() modifiedBy!: string;
  @JsonProperty() isDeleted!: boolean;
  @JsonProperty() deletedOn!: string | null; //date
  @JsonProperty() deletedBy!: string;
  @JsonProperty() destroy!: boolean;
  @JsonProperty() isDirty!: boolean;
}
