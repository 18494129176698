import { JsonProperty, Serializable } from "typescript-json-serializer";
import { Utils } from "@/utils";

@Serializable()
export class UpsInvoiceDto {
  @JsonProperty() invoiceDate!: string | null;
  @JsonProperty() invoiceNumber!: string | null;
  @JsonProperty() controlId!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    beforeSerialize: Utils.extractNumberFromCurrency,
  })
  invoiceAmount!: string | null;
  @JsonProperty() invoiceCurrencyCode!: string | null;
}
