import { ValueDto } from "@/types";
import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class RatesDto {
  @JsonProperty() externalRateId!: string | null;
  @JsonProperty() rateId!: number;
  @JsonProperty() rateType!: string | null;
  @JsonProperty() externalCarrierId!: string | null;
  @JsonProperty() carrierId!: number | null;
  @JsonProperty() shippingAmount!: ValueDto;
  @JsonProperty() insuranceAmount!: ValueDto;
  @JsonProperty() confirmationAmount!: ValueDto;
  @JsonProperty() otherAmount!: ValueDto;
  @JsonProperty() taxAmount!: ValueDto;
  @JsonProperty() zone!: number | null;
  @JsonProperty() packageType!: string | null;
  @JsonProperty() deliveryDays!: number | null;
  @JsonProperty() guaranteedService!: boolean;
  @JsonProperty() estimatedDeliveryDate!: string | null;
  @JsonProperty() carrierDeliveryDays!: string | null;
  @JsonProperty() shipDate!: string | null;
  @JsonProperty() negotiatedRate!: boolean;
  @JsonProperty() serviceType!: string | null;
  @JsonProperty() serviceCode!: string | null;
  @JsonProperty() trackable!: boolean;
  @JsonProperty() carrierCode!: string | null;
  @JsonProperty() carrierNickname!: string | null;
  @JsonProperty() carrierFriendlyName!: string | null;
  @JsonProperty() validationStatus!: string | null;
  @JsonProperty({ type: String }) warningMessages!: string[];
  @JsonProperty({ type: String }) errorMessages!: string[];
}
