
import { Vue, Options } from "vue-class-component";
import { OrderDto, AddressDto } from "@/types";
import { AddressInfo, Box } from "@/components";
import { InjectReactive } from "vue-property-decorator";
import { namespace } from "vuex-class";

const staticContent = namespace("staticContentVuexModule");

@Options({
  components: { AddressInfo, Box },
})
export default class OrderShippingDetails extends Vue {
  @staticContent.Getter private manufacturerAddress!: AddressDto;

  @InjectReactive() private order!: OrderDto | null;
  @InjectReactive() private orderLoading!: boolean;
  @InjectReactive() private shippingLoading!: boolean;
}
