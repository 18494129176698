
import { Options, mixins } from "vue-class-component";
import {
  AdminButton,
  AdminInput,
  Dropdown,
  InfoList,
  Checkbox,
  Icon,
} from "@/components";
import { CarrierTypes, DhlInformationDto } from "@/types";
import { Watch } from "vue-property-decorator";
import * as yup from "yup";
import { ShippingCarrierMixin } from "@/mixins";

@Options({
  components: {
    AdminButton,
    AdminInput,
    Dropdown,
    InfoList,
    Checkbox,
    Icon,
  },
})
export default class DhlExpress extends mixins(ShippingCarrierMixin) {
  created() {
    this.accountInfo &&
      this.isDhl(this.accountInfo) &&
      (this.dhlInformation = this.accountInfo);
    this.updateInfo();
  }

  @Watch("dhlInformation", { deep: true })
  onInfoChange() {
    this.updateInfo();
  }

  private connectClick() {
    this.updateInfo();
    this.connectAccount(CarrierTypes.DHL, this.dhlInformation);
  }

  private updateInfo() {
    this.setCarrierInfo(
      !this.carrierType ? CarrierTypes.DHL : this.carrierType,
      !this.accountInfo ? this.dhlInformation : this.accountInfo
    );
  }

  private dhlInformation: DhlInformationDto = {
    accountNumber: "",
  };

  private validationSchema = yup.object().shape({
    accountNumber: yup.string().required(),
  });
}
