import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ShipmentItemDto {
  @JsonProperty() name!: string | null;
  @JsonProperty() salesOrderId?: string | null;
  @JsonProperty() salesOrderItemId?: string | null;
  @JsonProperty() quantity!: number;
  @JsonProperty() sku?: string | null;
  @JsonProperty() orderId!: string | null;
  @JsonProperty() orderItemId!: string | null;
  @JsonProperty() asin?: string | null;
  @JsonProperty() orderSourceCode?: string | null;
}
