import { JsonProperty, Serializable } from "typescript-json-serializer";
import {
  DisputeDetailDto,
  DisputeShippingTaxDetailDto,
  SupplierDto,
  ShipToDto,
  MetaDataDto,
  PaymentDetailDto,
} from "@/types";
import { Utils } from "@/utils";

@Serializable()
export class ChargeDto {
  @JsonProperty() id!: string | null;
  @JsonProperty() authorizationId!: string | null;
  @JsonProperty() sellerId!: string | null;
  @JsonProperty() buyerId!: string | null;
  @JsonProperty() currency!: string | null;
  @JsonProperty() status!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  created!: string;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  modified!: string;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  originalTotalAmount!: string | null;
  @JsonProperty() sellerCreditNoteNumber!: string | null;
  @JsonProperty() sellerTaxCreditNoteUrl!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  totalAmount!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  taxAmount!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  shippingAmount!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  shippingTaxAmount!: string | null;
  @JsonProperty() paidAmountCurrency!: string | null;
  @JsonProperty() paidAmount!: string | null;
  @JsonProperty() orderUrl!: string | null;
  @JsonProperty() orderNumber!: string | null;
  @JsonProperty() foreignExchangeFee!: string | null;
  @JsonProperty() creditAmountCurrency!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  creditAmount!: string | null;
  @JsonProperty() invoiceUrl!: string | null;
  @JsonProperty() poNumber!: string | null;
  @JsonProperty() previousChargeId!: string | null;
  @JsonProperty() comment!: string | null;
  @JsonProperty({ type: DisputeShippingTaxDetailDto })
  shippingTaxDetails!: Array<DisputeShippingTaxDetailDto | null>;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  shippingDiscountAmount!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  discountAmount!: string | null;
  @JsonProperty({ type: DisputeDetailDto })
  details!: Array<DisputeDetailDto | null>;
  @JsonProperty() supplier!: SupplierDto;
  @JsonProperty() shipTo!: ShipToDto;
  @JsonProperty({
    beforeDeserialize: Utils.parseDate,
  })
  dueDate!: string;
  @JsonProperty({ type: MetaDataDto }) metadata!: Array<MetaDataDto | null>;
  @JsonProperty() cancellationReason!: string | null;
  @JsonProperty() cancellationComment!: string | null;
  @JsonProperty() returnReason!: string | null;
  @JsonProperty() returnComment!: string | null;
  @JsonProperty() disputeReason!: string | null;
  @JsonProperty() disputeComment!: string | null;
  @JsonProperty() buyerCurrency!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  buyerTotalAmount!: string | null;
  @JsonProperty({
    beforeDeserialize: Utils.floatToCurrencyString,
    afterSerialize: Utils.stringToNumber,
  })
  buyerOpenAmount!: string | null;
  @JsonProperty({ type: PaymentDetailDto })
  buyerPaymentDetail!: Array<PaymentDetailDto | null>;
  @JsonProperty() inDispute!: boolean;
  @JsonProperty() chargePaymentDays!: string | null;
  @JsonProperty() sellerTaxInvoiceNumber!: string | null;
  @JsonProperty() sellerTaxInvoiceUrl!: string | null;
  @JsonProperty() httpStatusCode!: string | null;
  @JsonProperty() transactionReferenceId!: string | null;
}
