import store from "@/store";
import { ProfileBrandDto } from "@/types";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";

@Module({
  store,
  namespaced: true,
})
export default class BrandPreviewVuexModule extends VuexModule {
  private showBrandPreviewModal = false;
  private brandImagesValid: boolean | null = null;
  private brandDetailsValid: boolean | null = null;
  private brand: ProfileBrandDto | null = null;
  private logo: File | null = null;
  private header: File | null = null;

  // GETTERS
  public get showProfilePreviewModal() {
    return this.showBrandPreviewModal;
  }

  public get isProfileValid() {
    return this.brandImagesValid && this.brandDetailsValid;
  }

  public get profileBrand() {
    return this.brand;
  }

  public get profileLogo() {
    return this.logo;
  }

  public get profileHeader() {
    return this.header;
  }

  // MUTATIONS
  @Mutation // for restoring starting state in unit tests
  public resetState(): void {
    this.showBrandPreviewModal = false;
    this.brandImagesValid = null;
    this.brandDetailsValid = null;
    this.brand = null;
    this.logo = null;
    this.header = null;
  }

  @Mutation
  public setShowProfilePreviewModal(show: boolean) {
    this.showBrandPreviewModal = show;
  }

  @Mutation
  public setBrandImagesValid() {
    this.brandImagesValid = true;
  }

  @Mutation
  public setBrandDetailsValid() {
    this.brandDetailsValid = true;
  }

  @Mutation
  public setBrandDetails(brand: ProfileBrandDto) {
    this.brand = brand;
  }

  @Mutation
  public setLocalBrandLogo(logo: File | null) {
    this.logo = logo;
  }

  @Mutation
  public setLocalBrandHeader(header: File | null) {
    this.header = header;
  }
}
