
import { Options, mixins } from "vue-class-component";
import {
  NavTabs,
  TopBar,
  AdminButton,
  ConfirmationModal,
  CreditLandingPage,
  MessagePanel,
} from "@/components";
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  MainRouteTypes,
  SellerDto,
  SellerStatusTypes,
} from "@/types";
import { namespace } from "vuex-class";
import { TabVisibilityMixin } from "@/mixins";
import { ProvideReactive } from "vue-property-decorator";

const seller = namespace("sellerVuexModule");

@Options({
  components: {
    NavTabs,
    TopBar,
    AdminButton,
    ConfirmationModal,
    CreditLandingPage,
    MessagePanel,
  },
})
export default class Credit extends mixins(TabVisibilityMixin) {
  @seller.Getter private seller?: SellerDto;
  @seller.Getter private showApprovedModal?: boolean;
  @seller.Mutation private setShowApprovedModal?: (show: boolean) => void;
  @seller.Action private getSeller?: () => Promise<SellerDto | null>;
  @seller.Action
  @ProvideReactive()
  private applyForJuniperCredit?: () => Promise<void>;

  private isLoading = false;
  private MainRouteTypes = MainRouteTypes;
  private ButtonSizeTypes = ButtonSizeTypes;
  private ButtonColorTypes = ButtonColorTypes;

  async created() {
    if (!this.getSeller) return;
    if (!this.seller) this.isLoading = true;
    await this.getSeller();
    this.isLoading = false;
    this.onTabGainedVisibility = this.getSeller;
  }

  private get hideCreditLandingPage(): boolean {
    return Boolean(
      this.seller &&
        (this.seller.status === SellerStatusTypes.Approved ||
          this.seller.status === SellerStatusTypes.Active ||
          this.seller.status === SellerStatusTypes.Inactive)
    );
  }

  @ProvideReactive() private get disableApplyButton(): boolean {
    if (!this.seller) return true;
    if (this.seller.status === SellerStatusTypes.Declined) {
      if (this.seller.canApplyForCredit) return false;
      return true;
    }
    return this.seller.status !== SellerStatusTypes.Created;
  }

  // private handleCloseApprovedModal() {
  //   this.setShowApprovedModal?.(false);
  // }
}
