import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize, serialize } from "typescript-json-serializer";
import {
  IndependentShipmentDto,
  IndependentShipmentListDto,
  IShipmentsIndependentRepository,
  IndependentShipmentResponseDto,
} from "@/types";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/ShipmentsIndependent`;

@injectable()
export class ShipmentsIndependentRepository
  implements IShipmentsIndependentRepository
{
  public async getAll(queryString = ""): Promise<IndependentShipmentListDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}${queryString}`);
    return deserialize<IndependentShipmentListDto>(
      data,
      IndependentShipmentListDto
    );
  }

  public async get(shipmentId: number): Promise<IndependentShipmentDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${shipmentId}`);
    return deserialize<IndependentShipmentDto>(data, IndependentShipmentDto);
  }

  public async post(
    shipments: Array<IndependentShipmentDto>
  ): Promise<IndependentShipmentResponseDto> {
    const payload = {
      shipments: shipments.map((shipment) => serialize(shipment)),
    };
    const { data } = await axios.post(ENDPOINT_URL, payload);
    return deserialize<IndependentShipmentResponseDto>(
      data,
      IndependentShipmentResponseDto
    );
  }

  public async put(
    shipmentId: number,
    shipmentData: IndependentShipmentDto
  ): Promise<IndependentShipmentDto> {
    const payload = serialize(shipmentData);
    const { data } = await axios.put(`${ENDPOINT_URL}/${shipmentId}`, payload);
    return deserialize<IndependentShipmentDto>(data, IndependentShipmentDto);
  }

  public async cancel(shipmentId: number): Promise<void> {
    return await axios.delete(`${ENDPOINT_URL}/${shipmentId}`);
  }
}
