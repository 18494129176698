
import { mixins, Options, VueMixin } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import {
  AdminButton,
  Modal,
  ImagePreview,
  Tooltip,
  Icon,
  ConfirmationModal,
} from "@/components";
import { IDimensions, IImage, IconNameTypes, ToastConfig } from "@/types";
import { VeeFieldMixin } from "@/mixins";
import { namespace } from "vuex-class";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    AdminButton,
    Icon,
    Modal,
    ImagePreview,
    Tooltip,
    ConfirmationModal,
  },
  emits: ["update:modelValue"],
})
export default class ImageUpload extends mixins<
  [VueMixin<VeeFieldMixin<IImage | null>>]
>(VeeFieldMixin) {
  @Prop({
    type: Object,
    default: null,
  })
  modelValue!: IImage | null;

  @Prop({
    type: String,
    default: null,
  })
  label!: string;

  @Prop({
    type: String,
    default: null,
    required: true,
  })
  name!: string;

  @Prop({
    type: String,
    default: "Select image...",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: null,
  })
  msg!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  canDelete!: boolean;

  @Prop({
    type: Number,
    default: 64,
  })
  width!: number;

  @Prop({
    type: String,
    default: null,
  })
  tooltipMsg!: string;

  @Ref() readonly inputRef!: HTMLInputElement;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;

  private selectedIndex = 0;
  private selection: IImage | null = null;
  private imageUrls: string[] = this.defaultImgs;
  private IconNameTypes = IconNameTypes;
  private isDraggedOver = false;
  private showDeleteModal = false;

  get defaultImgs() {
    if (this.modelValue === null) {
      return [];
    }

    if (Array.isArray(this.modelValue)) {
      return this.modelValue.flatMap(({ url }) => (url ? url : []));
    } else {
      return this.modelValue.url
        ? this.modelValue.url === "delete"
          ? []
          : [this.modelValue.url]
        : [];
    }
  }

  get imageEmpty() {
    return this.imageUrls.length === 0;
  }

  get cmpWidth() {
    return this.fullWidth ? "w-full" : `w-${this.width}`;
  }

  getDimensionsFromDataUrl = (dataURL: string): Promise<IDimensions> =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
        URL.revokeObjectURL(img.src);
      };
      img.src = dataURL;
    });

  private handleClickImage(i: number) {
    this.selectedIndex = i;
    this.inputRef.click();
  }

  private openDeleteModal() {
    if (!this.imageEmpty && !this.showDeleteModal) {
      this.showDeleteModal = true;
    }
  }

  private closeDeleteModal() {
    this.showDeleteModal = false;
  }

  private deleteImage() {
    const val = this.modelValue as IImage;
    this.inputRef.files = null;
    this.imageUrls = [];

    const selection: IImage = {
      id: val.id || null,
      url: "delete",
      file: val.file,
    };

    this.updateSelection(selection);
    this.createToastSuccess({
      message: "Delete was successful!",
    });
    this.closeDeleteModal();
  }

  updateSelection(selection: IImage | null) {
    this.selection = selection;
    this.field.handleChange(selection);
    this.$emit("update:modelValue", selection);
  }

  async pickFile(files: FileList | null) {
    let fileList = this.inputRef.files;
    if (files) {
      fileList = files;
    }

    if (fileList && fileList.length > 0) {
      // wipe existing image urls for preview when new files picked
      this.imageUrls = [];
      [...fileList].forEach((file) => {
        this.imageUrls.push(URL.createObjectURL(file));
      });

      const val = this.modelValue as IImage;
      const url = URL.createObjectURL(fileList[0]);
      const dimensions = await this.getDimensionsFromDataUrl(url);
      const selection: IImage = {
        id: val.id || null,
        url,
        file: fileList[0],
        dimensions,
      };

      this.updateSelection(selection);
    }
  }
  dragover(event: DragEvent) {
    event.preventDefault();
    this.isDraggedOver = true;
  }
  dragleave() {
    this.isDraggedOver = false;
  }

  drop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer != undefined && event.currentTarget) {
      this.pickFile(event.dataTransfer.files);
    }
    this.isDraggedOver = false;
  }
}
