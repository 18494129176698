import axios from "@/axios";
import { injectable } from "inversify-props";
import {
  IShippingAccountRepository,
  ShippingAccountDto,
  ShippingAccountOptionsDto,
  ShippingAccountPackagesDto,
  ShippingAccountServicesDto,
} from "@/types";
import { deserialize } from "typescript-json-serializer";

const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Shipping/Account`;

@injectable()
export class ShippingAccountRepository implements IShippingAccountRepository {
  public async get(): Promise<ShippingAccountDto> {
    const { data } = await axios.get(ENDPOINT_URL);
    return deserialize<ShippingAccountDto>(data, ShippingAccountDto);
  }

  public async getOptions(): Promise<ShippingAccountOptionsDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/Options`);
    return deserialize<ShippingAccountOptionsDto>(
      data,
      ShippingAccountOptionsDto
    );
  }

  public async getPackages(): Promise<ShippingAccountPackagesDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/Packages`);
    return deserialize<ShippingAccountPackagesDto>(
      data,
      ShippingAccountPackagesDto
    );
  }

  public async getServices(): Promise<ShippingAccountServicesDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/Services`);
    return deserialize<ShippingAccountServicesDto>(
      data,
      ShippingAccountServicesDto
    );
  }
}
