import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6319ac33"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-container" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-wrapper"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "info-pill"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunSpinner = _resolveComponent("JunSpinner")!
  const _component_JunTable = _resolveComponent("JunTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.config.boxLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_JunSpinner, {
            class: "loading-spinner",
            size: "3rem"
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_JunTable, {
            heading: _ctx.tableConfig.heading,
            headers: _ctx.tableConfig.headers,
            items: _ctx.tableConfig.items,
            options: _ctx.tableConfig.options,
            loading: _ctx.tableConfig.loading,
            itemKey: _ctx.tableConfig.itemKey,
            hideTableHeadingBorder: _ctx.tableConfig.hideTableHeadingBorder,
            hideHeaderRow: _ctx.tableConfig.hideHeaderRow,
            onSort: _ctx.onSort,
            onFilter: _ctx.onFilter,
            onClickCell: _ctx.tableConfig.cellClick
          }, {
            info: _withCtx(() => [
              (_ctx.pill)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.itemTotal), 1))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "info", {}, undefined, true)
            ]),
            actions: _withCtx(() => [
              _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
            ]),
            default: _withCtx(({ item, header }) => [
              _renderSlot(_ctx.$slots, "default", {
                item: item,
                header: header
              }, undefined, true)
            ]),
            _: 3
          }, 8, ["heading", "headers", "items", "options", "loading", "itemKey", "hideTableHeadingBorder", "hideHeaderRow", "onSort", "onFilter", "onClickCell"])
        ]))
  ]))
}