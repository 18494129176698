
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { inject } from "inversify-props";
import {
  Box,
  Icon,
  AdminButton,
  ConfirmationModal,
  AdminTextarea,
  AssociateCustomerModal,
} from "@/components";
import {
  AccessRequestStatusTypes,
  IconNameTypes,
  ProfileDto,
  AddressDto,
  ShippingAddressDto,
  BusinessInfoDto,
  MainRouteTypes,
  SubRouteTypes,
} from "@/types";
import type {
  ToastConfig,
  INewCustomer,
  ICustomerPostPayload,
  IAccessRequestsRepository,
  ICustomersRepository,
} from "@/types";
import { Utils } from "@/utils";
import { namespace } from "vuex-class";

const notifications = namespace("notificationsVuexModule");

@Options({
  components: {
    Box,
    Icon,
    AdminButton,
    AdminTextarea,
    ConfirmationModal,
    AssociateCustomerModal,
  },
  emits: ["refresh-data"],
})
export default class LeadBuyerInfo extends Vue {
  @inject() private accessRequestsRepository!: IAccessRequestsRepository;
  @inject() private customersRepository!: ICustomersRepository;

  @notifications.Mutation private createToastSuccess!: (
    payload: ToastConfig
  ) => void;
  @notifications.Mutation private createToastError!: (
    paylaod: ToastConfig
  ) => void;

  @Prop({
    type: Boolean,
  })
  loading!: boolean;

  @Prop({
    type: Object,
  })
  profile!: ProfileDto | null;

  @Prop({
    type: Object,
    default: {},
  })
  billingAddress!: AddressDto | null;

  @Prop({
    type: Object,
    default: {},
  })
  shippingAddress!: ShippingAddressDto | null;

  @Prop({
    type: Object,
    default: {},
  })
  businessInfo!: BusinessInfoDto | null;

  @Prop({
    type: String,
    default: "",
  })
  status!: AccessRequestStatusTypes;

  private showDeclineModal = false;
  private showDeleteModal = false;
  private showAcceptModal = false;
  private declineMessage = "";
  private declineLoading = false;
  private deleteLoading = false;
  private restoreLoading = false;
  private IconNameTypes = IconNameTypes;

  get statusIsDeclined() {
    return this.status === AccessRequestStatusTypes.Declined;
  }

  get statusIsAccepted() {
    return this.status === AccessRequestStatusTypes.Accepted;
  }

  get statusIsPending() {
    return this.status === AccessRequestStatusTypes.Pending;
  }

  get leadId() {
    let { leadId } = this.$route.params;
    if (Array.isArray(leadId)) {
      leadId = leadId[0];
    }
    return Number(leadId);
  }

  get defaultCustomer(): INewCustomer {
    return {
      companyName: this.businessInfo?.companyName,
      billingAddress: this.billingAddress?.address1,
      billingAddress2: this.billingAddress?.address2,
      billingCity: this.billingAddress?.city,
      billingState: this.billingAddress?.stateProvince,
      billingZipcode: this.billingAddress?.postalCode,
      billingCountry: this.billingAddress?.country,
      billingPhone: this.billingAddress?.contact?.phone,
      billingFax: this.billingAddress?.contact?.fax,
      shippingAddress: this.shippingAddress?.address1,
      shippingAddress2: this.shippingAddress?.address2,
      shippingContactName: this.shippingAddress?.contact?.name,
      shippingCity: this.shippingAddress?.city,
      shippingState: this.shippingAddress?.stateProvince,
      shippingZipcode: this.shippingAddress?.postalCode,
      shippingCountry: this.shippingAddress?.country,
      shippingEmail: this.shippingAddress?.contact?.email,
      shippingPhone: this.shippingAddress?.contact?.phone,
      shippingFax: this.shippingAddress?.contact?.fax,
    };
  }

  get confirmDeleteModalText() {
    let text =
      "This request will be deleted and it will no longer appear in your Leads Table.";
    if (this.statusIsAccepted) {
      text = `${text} Please note that this buyer's access will also be revoked.`;
    }
    return text;
  }

  async onDeclineConfirm() {
    this.declineLoading = true;

    const [data] = await Utils.try(
      this.accessRequestsRepository.decline(this.leadId, this.declineMessage)
    );

    if (data) {
      this.closeDeclineModal();
      this.createToastSuccess({
        message: `Lead #${this.leadId} has been successfully declined.`,
      });
      setTimeout(() => {
        this.$router.push(`/${MainRouteTypes.Buyers}/${SubRouteTypes.Leads}`);
      }, 1000);
    }

    this.declineLoading = false;
  }

  async onDelete() {
    // don't allow user to re-click and submit another delete request while still loading
    if (this.deleteLoading) {
      return;
    }

    this.deleteLoading = true;

    let canDeleteLead = true;
    if (this.statusIsAccepted) {
      const [, error] = await Utils.try(
        this.accessRequestsRepository.revoke(this.leadId)
      );
      if (error) {
        canDeleteLead = false;
        this.createToastError({
          message: `Failed to revoke the buyer's access, cannot delete Lead #${this.leadId} at this time.`,
        });
      }
    }

    if (canDeleteLead) {
      const [data] = await Utils.try(
        this.accessRequestsRepository.delete(this.leadId)
      );

      if (data) {
        this.createToastSuccess({
          message: `Lead #${this.leadId} has been successfully deleted.`,
        });
        this.closeDeleteModal();
        setTimeout(() => {
          this.$router.push(`/${MainRouteTypes.Buyers}/${SubRouteTypes.Leads}`);
        }, 1000);
      }
    }

    this.deleteLoading = false;
  }

  async onRestore() {
    this.restoreLoading = true;

    const [data] = await Utils.try(
      this.accessRequestsRepository.undecline(this.leadId)
    );

    if (data) {
      this.createToastSuccess({
        message: `Lead #${this.leadId} has been successfully restored.`,
      });
      // set new data in parent, right now this is mainly to update status for all components
      this.$emit("refresh-data", data);
    }

    this.restoreLoading = false;
  }

  approveWithExistingCustomer(customerNumber: string | null) {
    return this.accessRequestsRepository.approve(this.leadId, customerNumber);
  }

  async approveWithNewCustomer(customer: ICustomerPostPayload) {
    // create new customer, then use response to approve with resulting customer number
    const newCustomer = await this.customersRepository.post(customer);
    await this.accessRequestsRepository.approve(
      this.leadId,
      newCustomer.customerNumber
    );
  }

  onApproveSuccess() {
    setTimeout(() => {
      this.$router.push(`/${MainRouteTypes.Buyers}/${SubRouteTypes.Leads}`);
    }, 1000);
  }

  closeDeclineModal() {
    this.showDeclineModal = false;
  }

  closeDeleteModal() {
    this.showDeleteModal = false;
  }
}
