import {
  IDashboardTableHeader,
  DashboardOrdersTableWidthTypes as TableType,
} from "@/types";

export const DashboardTableHeaders: Array<IDashboardTableHeader> = [
  {
    config: {
      prop: "orderId",
      text: "Order ID",
      canSort: true,
      canFilter: true,
      width: "8%",
      overflow: false,
    },
    single: true,
    multi: false,
  },
  {
    config: {
      prop: "catalogName",
      text: "Brand",
      canSort: true,
      canFilter: true,
      width: "8%",
      overflow: false,
    },
    single: false,
    multi: true,
  },
  {
    config: {
      prop: "brandOrderId",
      text: "Order ID",
      canSort: true,
      canFilter: true,
      width: "8%",
      overflow: false,
    },
    single: false,
    multi: true,
  },
  {
    config: {
      prop: "customerName",
      text: "Customer Name",
      canSort: true,
      canFilter: true,
      overflow: false,
      width: "13%",
    },
    single: true,
    multi: true,
  },
  {
    config: {
      prop: "customerNumber",
      text: "Customer #",
      canSort: true,
      canFilter: true,
      width: "10%",
      overflow: false,
    },
    single: [TableType.Medium, TableType.Large, TableType.Full],
    multi: [TableType.Medium, TableType.Large, TableType.Full],
  },
  {
    config: {
      prop: "orderDate",
      text: "Order Date",
      canSort: true,
      canFilter: true,
      width: "9%",
      overflow: false,
    },
    single: true,
    multi: true,
  },
  {
    config: {
      prop: "orderStatus",
      text: "Status",
      canSort: true,
      canFilter: true,
      width: "6%",
      overflow: false,
    },
    single: true,
    multi: true,
  },
  {
    config: {
      prop: "orderTotal",
      text: "Order Amount",
      canSort: true,
      canFilter: true,
      width: "10%",
      overflow: false,
    },
    single: [TableType.Medium, TableType.Large, TableType.Full],
    multi: [TableType.Full],
  },
  {
    config: {
      prop: "invoiceNumber",
      text: "Invoice",
      canSort: false,
      canFilter: false,
      width: "10%",
      overflow: false,
    },
    single: [TableType.Full],
    multi: false,
  },
];
