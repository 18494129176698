
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class MessagePanel extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
  })
  message!: string;
}
