
import {
  AdminInput,
  Checkbox,
  Icon,
  InfoList,
  RadioButton,
  Tooltip,
} from "@/components";
import {
  CountryTypes,
  EditableBoxStatusTypes,
  IconNameTypes,
  InfoListItem,
  ShippingMethodTypes,
  ShippingSettingsConfirmationModalTypes,
  ShippingSettingsDto,
} from "@/types";
import { Masks, Validators } from "@/utils";
import { PropType } from "@vue/runtime-core";
import { Form } from "vee-validate";
import { Options, Vue } from "vue-class-component";
import { Inject, InjectReactive, Prop, Watch } from "vue-property-decorator";
import * as yup from "yup";

@Options({
  components: {
    Form,
    RadioButton,
    Checkbox,
    Icon,
    Tooltip,
    AdminInput,
    InfoList,
  },
})
export default class ShippingSettings extends Vue {
  @Prop({
    type: Object as PropType<ShippingSettingsDto>,
  })
  shippingSettings!: ShippingSettingsDto;

  @Prop({
    type: String,
  })
  selectedShipMethod!: ShippingMethodTypes;

  @Prop({
    type: String,
    default: EditableBoxStatusTypes.View,
  })
  status!: EditableBoxStatusTypes;

  private IconNameTypes = IconNameTypes;
  private CountryTypes = CountryTypes;

  @Inject() private fireWarningModal!: (
    modalType: ShippingSettingsConfirmationModalTypes
  ) => void;
  @Inject() private updateShippingSettings!: (
    shippingSettings: ShippingSettingsDto
  ) => void;
  @Inject() private shipMethodChange!: () => void;
  @Inject() private updateSelectedShipMethod!: (
    shipMethod: ShippingMethodTypes
  ) => void;
  @InjectReactive() private shipWithJuniperFlag!: boolean;

  private shipYourOwnMessage =
    "If you choose to ship on your own, you will be responsible for manually inputting the shipping costs and tracking information for orders.";
  private localShipMethod: ShippingMethodTypes | null = null;
  private validationSchema = yup.object().shape({
    shippingMethod: yup.string().required(),
    shipFromCountry: yup.string().required(),
    packingFees: yup.string().nullable().matches(Validators.currency, {
      message: "Packing fees must be a valid currency amount",
      excludeEmptyString: true,
    }),
    handlingFees: yup.string().nullable().matches(Validators.currency, {
      message: "Handling fees must be a valid currency amount",
      excludeEmptyString: true,
    }),
  });

  private isChecked(country: CountryTypes) {
    if (this.shippingSettings && this.shippingSettings.shipToCountries) {
      return this.shippingSettings.shipToCountries.includes(country);
    } else {
      return false;
    }
  }

  private shipToChange(country: CountryTypes) {
    if (this.shippingSettings.shipToCountries.includes(country)) {
      const index = this.shippingSettings.shipToCountries.indexOf(country);
      if (index > -1) {
        this.shippingSettings.shipToCountries.splice(index, 1);
      }
    } else {
      this.shippingSettings.shipToCountries.push(country);
    }
  }

  private shipFromCountryChange() {
    if (
      this.localShipMethod === ShippingMethodTypes.CustomCarrier &&
      this.shippingSettings.isCarrierActive
    ) {
      this.fireWarningModal(
        ShippingSettingsConfirmationModalTypes.CountryChange
      );
    }
  }

  get infoListItem(): Array<InfoListItem> {
    return [
      {
        label: "Packing Fees",
        text: Masks.currency(this.shippingSettings.packingFees),
      },
      {
        label: "Handling Fees",
        text: Masks.currency(this.shippingSettings.handlingFees),
      },
    ];
  }

  get editDisabled() {
    return this.status !== EditableBoxStatusTypes.Edit;
  }

  @Watch("shippingSettings", { deep: true, immediate: true })
  onShippingSettingsChange(newVal: ShippingSettingsDto) {
    this.updateShippingSettings(newVal);
  }
  @Watch("selectedShipMethod", { immediate: true })
  onSelectedShipMethodChange(newVal: ShippingMethodTypes) {
    this.localShipMethod = newVal;
  }
  @Watch("localShipMethod")
  onLocalShipMethodChange(
    newVal: ShippingMethodTypes,
    oldVal: ShippingMethodTypes
  ) {
    this.updateSelectedShipMethod(newVal);
    this.shipMethodChange();
    if (
      (oldVal === ShippingMethodTypes.CustomCarrier ||
        oldVal === ShippingMethodTypes.Juniper) &&
      this.shippingSettings.isCarrierActive
    ) {
      this.fireWarningModal(
        ShippingSettingsConfirmationModalTypes.MethodChange
      );
    }
  }
}
