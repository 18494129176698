import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "associated-buyers-table",
  style: {"display":"flex","flex-direction":"column","height":"400px","margin-top":"6px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JunTable = _resolveComponent("JunTable")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    title: "Associated Buyers",
    loading: _ctx.loading,
    noPadding: ""
  }, _createSlots({ _: 2 }, [
    (!_ctx.loading)
      ? {
          name: "content",
          fn: _withCtx(() => [
            (_ctx.tableConfig)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_JunTable, {
                    headers: _ctx.tableConfig.headers,
                    options: _ctx.tableConfig.options,
                    items: _ctx.tableConfig.items,
                    loading: _ctx.tableConfig.loading,
                    itemKey: _ctx.tableConfig.itemKey,
                    onClickCell: _ctx.buyerClick,
                    "data-test-id": "jun-table"
                  }, null, 8, ["headers", "options", "items", "loading", "itemKey", "onClickCell"])
                ]))
              : _createCommentVNode("", true)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["loading"]))
}