import { JsonProperty, Serializable } from "typescript-json-serializer";
import { LinkDto } from "@/types";

@Serializable()
export class AppUserProfileDto {
  @JsonProperty() emailAddress!: string | null;
  @JsonProperty() profileId!: string | null;
  @JsonProperty() firstName!: string | null;
  @JsonProperty() lastName!: string | null;
  @JsonProperty() buyerName!: string | null;
  @JsonProperty() phone!: string | null;
  @JsonProperty() fax!: string | null;
  @JsonProperty() profileIdentityProvider!: string | null;
  @JsonProperty() externalProfileId!: string | null;
  @JsonProperty() buyerReferenceId!: string | null;
  @JsonProperty({ type: LinkDto }) links?: Array<LinkDto>;
}
