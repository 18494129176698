export enum ButtonColorTypes {
  Primary = "primary",
  Secondary = "secondary",
  Outline = "outline",
  Ghost = "ghost",
  Success = "success",
  Error = "error",
  Brand = "brand",
  BrandSecondary = "brandSecondary",
  Action = "action",
}
