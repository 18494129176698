
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import { MultiLinePaymentTable, SingleLinePaymentSettings } from "@/components";
import { JunSpinner } from "@juniper/ui";

const auth = namespace("authVuexModule");

@Options({
  components: {
    MultiLinePaymentTable,
    SingleLinePaymentSettings,
    JunSpinner,
  },
})
export default class Payment extends Vue {
  @auth.Getter private isMultiline?: boolean | null;

  private get loading() {
    return this.isMultiline === null;
  }
}
