import { JsonProperty, Serializable } from "typescript-json-serializer";

@Serializable()
export class ManufacturerBrandSettingsDto {
  @JsonProperty() countryAvailability!: Array<string> | null;
  @JsonProperty() directSeller!: boolean;
  @JsonProperty() gated!: boolean;
  @JsonProperty() industry!: Array<string> | null;
  @JsonProperty() industryCategory!: Array<string> | null;
  @JsonProperty() sellerBiography!: string | null;
  @JsonProperty() sellerName!: string | null;
}
