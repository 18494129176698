import {
  DashboardOrdersTableWidthTypes,
  IDashboardComponentSettings,
  DashboardComponentTypes,
  DashboardChartComponentTypes,
  DashboardTableComponentTypes,
  DashboardDisplayTypes,
  DashboardRequestsTableColumnStartTypes,
  DashboardRequestsTableWidthTypes,
  DashboardRequestsTableHeightTypes,
  DashboardOrdersTableHeightTypes,
} from "@/types";

export const DashboardComponentTypeSettings: Array<IDashboardComponentSettings> =
  [
    {
      type: DashboardComponentTypes.Table,
      subType: DashboardTableComponentTypes.Orders,
      rowStart: 2,
      rowStartOptions: {},
      rowSpan: DashboardOrdersTableHeightTypes.Normal,
      rowSpanOptions: {},

      columnStart: 1,
      columnStartOptions: {
        [DashboardDisplayTypes.SingleGated]: 1,
        [DashboardDisplayTypes.MultiGated]: 1,
      },
      columnSpan: 4,
      columnSpanOptions: {
        [DashboardDisplayTypes.MultiGated]: DashboardOrdersTableWidthTypes.Full,
        [DashboardDisplayTypes.SingleGated]:
          DashboardOrdersTableWidthTypes.Full,
      },
      componentToggleOptions: [
        DashboardDisplayTypes.MultiGated,
        DashboardDisplayTypes.MultiUngated,
        DashboardDisplayTypes.SingleGated,
        DashboardDisplayTypes.SingleUngated,
      ],
    },
    {
      type: DashboardComponentTypes.Table,
      subType: DashboardTableComponentTypes.Requests,
      rowStart: 4,
      rowStartOptions: {},
      rowSpan: DashboardRequestsTableHeightTypes.Normal,
      rowSpanOptions: {},

      columnStart: DashboardRequestsTableColumnStartTypes.Normal,
      columnStartOptions: {
        [DashboardDisplayTypes.SingleGated]: 1,
        [DashboardDisplayTypes.MultiGated]: 1,
      },
      columnSpan: DashboardRequestsTableWidthTypes.Normal,
      columnSpanOptions: {
        [DashboardDisplayTypes.SingleGated]: 4,
        [DashboardDisplayTypes.MultiGated]: 4,
      },
      componentToggleOptions: [
        DashboardDisplayTypes.MultiGated,
        DashboardDisplayTypes.SingleGated,
      ],
    },
    {
      type: DashboardComponentTypes.Chart,
      subType: DashboardChartComponentTypes.Sales,
      rowStart: 4,
      rowStartOptions: {
        [DashboardDisplayTypes.SingleGated]: 6,
        [DashboardDisplayTypes.MultiGated]: 6,
      },
      rowSpan: DashboardOrdersTableHeightTypes.Normal,
      rowSpanOptions: {},

      columnStart: 1,
      columnStartOptions: {},
      columnSpan: 4,
      columnSpanOptions: {},
      componentToggleOptions: [
        DashboardDisplayTypes.MultiGated,
        DashboardDisplayTypes.MultiUngated,
        DashboardDisplayTypes.SingleGated,
        DashboardDisplayTypes.SingleUngated,
      ],
    },
    {
      type: DashboardComponentTypes.Chart,
      subType: DashboardChartComponentTypes.Orders,
      rowStart: 4,
      rowStartOptions: {
        [DashboardDisplayTypes.SingleGated]: 6,
        [DashboardDisplayTypes.MultiGated]: 6,
      },
      rowSpan: DashboardOrdersTableHeightTypes.Normal,
      rowSpanOptions: {},

      columnStart: 5,
      columnStartOptions: {},
      columnSpan: 4,
      columnSpanOptions: {},
      componentToggleOptions: [
        DashboardDisplayTypes.MultiGated,
        DashboardDisplayTypes.MultiUngated,
        DashboardDisplayTypes.SingleGated,
        DashboardDisplayTypes.SingleUngated,
      ],
    },
    {
      type: DashboardComponentTypes.Table,
      subType: DashboardTableComponentTypes.TopCustomers,
      rowStart: 2,
      rowStartOptions: {
        [DashboardDisplayTypes.SingleGated]: 4,
        [DashboardDisplayTypes.MultiGated]: 4,
      },
      rowSpan: 2,
      rowSpanOptions: {},

      columnStart: 5,
      columnStartOptions: {},
      columnSpan: 4,
      columnSpanOptions: {},
      componentToggleOptions: [
        DashboardDisplayTypes.MultiGated,
        DashboardDisplayTypes.MultiUngated,
        DashboardDisplayTypes.SingleGated,
        DashboardDisplayTypes.SingleUngated,
      ],
    },
  ];
