
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import {
  IconNameTypes,
  ButtonSizeTypes,
  ButtonColorTypes,
  PaymentSettingsDto,
  MainRouteTypes,
  SubRouteTypes,
  SellerDto,
} from "@/types";
import { Icon, AdminButton } from "@/components";
import { Prop } from "vue-property-decorator";
import { PropType } from "@vue/runtime-core";

const publish = namespace("publishVuexModule");
const auth = namespace("authVuexModule");
const seller = namespace("sellerVuexModule");

@Options({
  components: {
    Icon,
    AdminButton,
  },
})
export default class PublishChecklist extends Vue {
  @Prop({
    type: [Object, null] as PropType<PaymentSettingsDto | null>,
    required: true,
  })
  private paymentSettings!: PaymentSettingsDto;

  @publish.Getter private isCompanyDetailsValid!: boolean;
  @publish.Getter private isImageUploadValid!: boolean;
  @publish.Getter private isMsrpValid!: boolean;
  @auth.Getter private isMultiline!: boolean | null;
  @seller.Getter seller?: SellerDto;
  @seller.Getter canApplyForCredit?: boolean;

  private ButtonSizeTypes = ButtonSizeTypes;
  private ButtonColorTypes = ButtonColorTypes;
  private IconNameTypes = IconNameTypes;

  get checklistIconName() {
    return (isValid: boolean | undefined) => {
      return isValid ? IconNameTypes.Success : IconNameTypes.CloseV2;
    };
  }

  get checklistClass() {
    return (isValid: boolean | undefined) => {
      return isValid ? "valid-icon" : "invalid-icon";
    };
  }

  get publishChecklistCopy() {
    return this.isCompanyDetailsValid &&
      this.isImageUploadValid &&
      this.isMsrpValid
      ? "Please consider updating your payment options for the marketplace."
      : "Unable to process publish request. Please make sure to complete all the required information before publishing to the marketplace.";
  }

  get shouldShowPaymentReminders() {
    if (this.isMultiline || !this.paymentSettings) return false;
    return Boolean(!this.paymentSettings.acceptCreditCards);
  }

  private onSetUpCreditCardsClick(): void {
    this.$router.push(`/${MainRouteTypes.Settings}/${SubRouteTypes.Payment}`);
    this.$emit("close");
  }
}
