import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b5e9464"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['thumbnail', `h-${_ctx.height}`, `w-${_ctx.width}`]),
      style: _normalizeStyle({ 'background-image': `url(${_ctx.url})` }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
    }, null, 6),
    _createVNode(_component_Modal, {
      open: _ctx.showModal,
      showCloseBtn: "",
      onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false))
    }, {
      body: _withCtx(() => [
        _createElementVNode("img", {
          class: "image-preview",
          src: _ctx.url
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["open"])
  ], 64))
}