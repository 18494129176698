import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75f47c4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-wrapper" }
const _hoisted_2 = { class: "grid-wrapper" }
const _hoisted_3 = { class: "left-panel" }
const _hoisted_4 = { class: "right-panel" }
const _hoisted_5 = { class: "right-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_CompanyInfo = _resolveComponent("CompanyInfo")!
  const _component_BuyerInfo = _resolveComponent("BuyerInfo")!
  const _component_AssociatedBuyers = _resolveComponent("AssociatedBuyers")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar, {
      backPath: _ctx.backPath,
      hideDivisionSelect: "",
      showBackButton: "",
      title: "Buyer Details"
    }, null, 8, ["backPath"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumbs, { items: _ctx.breadcrumbItems }, null, 8, ["items"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CompanyInfo, {
            billingAddress: _ctx.billingAddress,
            businessInfo: _ctx.businessInfo,
            customerNumber: _ctx.customerNum,
            loading: _ctx.loading,
            shippingAddress: _ctx.shippingAddress
          }, null, 8, ["billingAddress", "businessInfo", "customerNumber", "loading", "shippingAddress"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BuyerInfo, {
            loading: _ctx.loading,
            profile: _ctx.profile,
            shippingAddress: _ctx.shippingAddress
          }, null, 8, ["loading", "profile", "shippingAddress"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AssociatedBuyers, {
            loading: _ctx.loading,
            profiles: _ctx.associated,
            onBuyerClick: _ctx.redirect
          }, null, 8, ["loading", "profiles", "onBuyerClick"])
        ])
      ])
    ])
  ], 64))
}