import { SellerStatusTypes, TransactionStatusTypes } from "@/types";

export const sellerStatusTextColorConfig: Record<
  SellerStatusTypes,
  string | null
> = {
  [SellerStatusTypes.Created]: null,
  [SellerStatusTypes.Pending]: "text-warning",
  [SellerStatusTypes.Approved]: null,
  [SellerStatusTypes.Active]: null,
  [SellerStatusTypes.Inactive]: null,
  [SellerStatusTypes.Declined]: "text-error",
  [SellerStatusTypes.Terminated]: "text-error",
  [SellerStatusTypes.Suspended]: "text-error",
  [SellerStatusTypes.Cancelled]: "text-error",
  [SellerStatusTypes.Archived]: "text-error",
  [SellerStatusTypes.Withdrawn]: "text-error",
};

export const transactionStatusLabelMap = {
  [TransactionStatusTypes.Created]: "New",
  [TransactionStatusTypes.Preauthorized]: "Preauthorized",
  [TransactionStatusTypes.Captured]: "Captured",
  [TransactionStatusTypes.Disputed]: "Dispute Requested",
  [TransactionStatusTypes.Cancelled]: "Cancelled",
  [TransactionStatusTypes.Error]: "Error",
  [TransactionStatusTypes.Unknown]: "Unknown",
  [TransactionStatusTypes.Refunded]: "Refunded",
  [TransactionStatusTypes.Paid]: "Paid",
  [TransactionStatusTypes.PaymentSettled]: "Payment Settled",
  [TransactionStatusTypes.DisputeResolved]: "Dispute Resolved (Full Refund)",
  [TransactionStatusTypes.DisputePartiallyPaid]:
    "Dispute Resolved (Partial Refund)",
  [TransactionStatusTypes.DisputeCancelled]: "Dispute Rejected",
  [TransactionStatusTypes.ChargeRefunded]: "Charge Fully Refunded",
  [TransactionStatusTypes.ChargePartiallyPaid]: "Charge Partially Refunded",
};
