import axios from "@/axios";
import { injectable } from "inversify-props";
import { deserialize, serialize } from "typescript-json-serializer";
import {
  IShippingLabelsRepository,
  ShippingLabelsDto,
  IShippingLabelPostPayload,
  LabelDto,
  VoidDto,
  ShippingLabelTrackingDto,
} from "@/types";
const ENDPOINT_URL = `${process.env.VUE_APP_API_URL}/v1/Shipping/Labels`;

@injectable()
export class ShippingLabelsRepository implements IShippingLabelsRepository {
  public async getAll(query = ""): Promise<ShippingLabelsDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}${query}`);
    return deserialize<ShippingLabelsDto>(data, ShippingLabelsDto);
  }

  public async post(rateId: number): Promise<LabelDto> {
    const payload = serialize({
        validateAddress: "NoValidation",
        labelLayout: "_4x6",
      } as IShippingLabelPostPayload),
      { data } = await axios.post(`${ENDPOINT_URL}/Rates/${rateId}`, payload);
    return deserialize<LabelDto>(data, LabelDto);
  }

  public async get(labelId: number): Promise<LabelDto> {
    const { data } = await axios.get(`${ENDPOINT_URL}/${labelId}`);
    return deserialize<LabelDto>(data, LabelDto);
  }

  public async void(labelId: number): Promise<VoidDto> {
    const { data } = await axios.put(`${ENDPOINT_URL}/${labelId}/Void`);
    return deserialize<VoidDto>(data, VoidDto);
  }

  public async track(labelId: number): Promise<ShippingLabelTrackingDto> {
    const { data } = await axios.post(`${ENDPOINT_URL}/${labelId}/Track`);
    return deserialize<ShippingLabelTrackingDto>(
      data,
      ShippingLabelTrackingDto
    );
  }
}
